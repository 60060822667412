<template>
  <div>
    <!-- OPTIONS MODAL -->
    <div>
      <v-row>
        <v-card min-width="300px" max-height="80vh" class="overflow-y-auto">
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-card-text style="width: 100%;">
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in items"
                      :key="index"
                    >
                      <v-list-item-title
                        v-if="index == 0"
                        class="text-red"
                      >
                        {{ item.title }}
                        <p style="float: right">v{{ eboardUpdates.eboardversion }}</p>
                      </v-list-item-title>
                      <v-list-item-title
                        v-else
                      >
                        <span
                          style="cursor: pointer;"
                          class="text-decoration-none"
                          @click="handleClick(item.action)"
                        >
                          {{ item.title }}
                        </span>
                      </v-list-item-title>
                      <v-divider thickness="2px"></v-divider>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
        </v-card>
      </v-row>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from "../../stores/auth";
import { useIndexStore } from "../../stores/index";
import { useAlertStore } from "../../stores/alert";

export default {
  name: "OptionsMenu",
  props: {
    comment: {
      type: Object,
      default: () => {},
    },
    replies: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    valid: false,
    reply: "",
    show: false,
    items: [
      { 
        title: 'Options',
        action: 0, 
      },
      { 
        title: 'Contact Support',
        action: 1, 
      },
      { 
        title: 'Report an Issue',
        action: 2, 
      },
      { 
        title: 'License & Policies',
        action: 3, 
      },
      { 
        title: 'Links & News',
        action: 4, 
      },
      { 
        title: 'Knowledgeware',
        action: 5, 
      },
      { 
        title: 'Feedback',
        action: 6, 
      },
      { 
        title: 'Logout',
        action: 7, 
      },
    ],
    opened: [],
    rules: {
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
      selectRules: [(v) => !!v || "Select is required"],
      textRules: [
        v => !!v || 'Field is required',
        v => (v && v.length >= 3) || 'Min 3 characters',
      ],
      characterRules: [
        v => (v && v.length >= 3) || 'Min 3 characters',
      ],
    },
    token: localStorage.getItem("token") ? JSON.stringify(localStorage.getItem("token")).slice(1, -1) : "",
    auth: useAuthStore(),
    store: useIndexStore(),
    alert: useAlertStore(),
  }),

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },

    dialog_password: {
      get: function () {
        return this.store.getDialogPassword;
      },
      set: function (value) {
        this.store.setDialogPassword(value);
      }
    },

    eboardUpdates() {
      return this.auth.getEboardUpdates;
    },
  },
  
  methods: {
    contactSupport() {
      this.$emit('contact_support');
      this.store.setDialogSupport(true);
    },

    licenseAndPolicies() {
      this.$router.replace("/License%20And%20Policies");
    },

    linksAndNews() {
      this.$router.replace("/Links%20And%20News");
    },

    knowledgeware() {
      this.$router.replace("/Knowledgeware");
    },

    feedback() {
      this.$router.replace("/Feedback");
    },

    logout() {
      this.store.setAuthentication(false);
      this.$router.replace("/");
      this.alert.showInfo('Logged Out Successfully');
    },

    handleClick(action) {
      switch(action) {
        case 1:
          this.store.setMenuOption(1);
          this.contactSupport();
          break;
        case 2:
          this.store.setMenuOption(2);
          this.contactSupport();
          break;
        case 3:
          this.store.setMenuOption(3);
          this.licenseAndPolicies();
          break;
        case 4:
          this.store.setMenuOption(4);
          this.linksAndNews();
          break;
        case 5:
          this.store.setMenuOption(5);
          this.knowledgeware();
          break;
        case 6:
          this.store.setMenuOption(6);
          this.feedback();
          break;
        case 7:
          this.store.setMenuOption(7);
          this.logout();
          break;
      }
    },
  },
};
</script>

<style scoped>
span {
  text-decoration: none;
  color: #000000;
}

span:hover {
  color: #F44336;
}
</style>