<template>
  <v-tooltip 
    :text="data" 
    :location="location" 
    :content-class="styling"
  >
    <template v-slot:activator="{ props }">
      <span 
        v-bind="props"
        style="cursor: pointer;"
      >
        {{ truncateText(data, truncate) }}
      </span>
    </template>
  </v-tooltip>
</template>

<script>
export default {
  name: "Tooltip",

  props: {
    data: {
      type: String,
      default: "",
    },

    styling: {
      type: String,
      default: "",
    },

    location: {
      type: String,
      default: "",
    },

    truncate: {
      type: Number,
      default: 50,
    },
  },

  methods: {
    truncateText(str, n) {
      return str.length > n ? str.substr(0, n - 1) + "..." : str;
    },
  },
}
</script>

<style scoped>
span {
  text-decoration: none;
  color: #000000;
}

span:hover {
  color: rgb(227, 58, 58);
}
</style>