<template>
  <div>
    <!-- COMMENTS MENU -->
    <div class="comments">
      <v-row>
        <v-card class="text-center">
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-card-text>
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <v-list density="compact">
                    <v-list-item-group
                      v-model="selected"
                      active-class="pink--text"
                      multiple
                    >
                      <div
                        v-for="(item, index) in (chairman == 1 ? chairman_items : items)"
                        :key="index"
                      >
                        <v-list-item
                          active-color="rgb(72, 61, 139)"
                          class="list-striped"
                          @click.stop="loadEvaluatorDialog(item)"
                        >
                          <v-list-item-title>
                            <a
                              style="cursor: pointer;" 
                              @click.stop="loadEvaluatorDialog(item)"
                            >
                              {{ item.title }}
                            </a>
                          </v-list-item-title>
                        </v-list-item>
                        <v-divider
                          v-if="index < (chairman == 1 ? chairman_items.length : items.length) - 1"
                          :key="index"
                        ></v-divider>
                      </div>
                    </v-list-item-group>
                  </v-list>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
        </v-card>
      </v-row>
    </div>
  </div>
</template>

<script>
var moment = require("moment");
import { useAuthStore } from "../../stores/auth";
import { useIndexStore } from "../../stores/index";
import { useAlertStore } from "../../stores/alert";

export default {
  name: "EvaluationComments",
  props: {
    evaluation: {
      type: Object,
      default: () => {},
    },
    chairman: {
      type: String,
      default: "0",
    },
    category: {
      type: Object,
      default: () => {},
    },
    load: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    load() {
      return this.openCommentsDialog();
    },
  },

  data: () => ({
    valid: false,
    valid_comments: false,
    dialog: false,
    comments: "",
    type: {},
    items: [
      {
        id: 1, 
        title: 'Comment on Evaluation' 
      },
      {
        id: 2, 
        title: 'Comment on Current Category' 
      },
    ],
    chairman_items: [
      {
        id: 1, 
        title: 'Comment on Evaluation',
      },
      {
        id: 2, 
        title: 'Comment on Current Category',
      },
      {
        id: 3, 
        title: 'Comment as Chairman',
      },
    ],
    rules: {
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
      selectRules: [(v) => !!v || "Select is required"],
      textRules: [
        v => !!v || 'Field is required',
        v => (v && v.length >= 3) || 'Min 3 characters',
      ],
      characterRules: [
        v => (v && v.length >= 3) || 'Min 3 characters',
      ],
    },
    token: localStorage.getItem("token") ? JSON.stringify(localStorage.getItem("token")).slice(1, -1) : "",
    auth: useAuthStore(),
    store: useIndexStore(),
    alert: useAlertStore(),
    selected: [],
  }),

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },

    dialog_evaluation_comments: {
      get() {
        return this.store.getDialogEvaluationComments;
      },
      set(value) {
        this.store.setDialogEvaluationComments(value);
      },
    },

    comments_type() {
      return this.store.getCommentsType;
    },
  },
  
  methods: {
    formatDate(date) {
      return moment.unix(date).format("DD MMM YYYY");
    },

    formatDateAndTime(date) {
      return moment.unix(date).format("DD MMM YYYY, hh:mm A");
    },

    validate() {
      return this.$refs.form.validate();
    },

    validateComments() {
      return this.$refs.comments_form.validate();
    },

    resetForm() {
      return this.$refs.form.reset();
    },

    resetValidation() {
      return this.$refs.form.resetValidation();
    },

    reset() {
      this.comments = "";
      this.store.setDialogEvaluationComments(false);
      this.$emit('comments_opened', false);
      document.querySelector('html').classList.remove('v-overlay-scroll-blocked');
    },

    loadEvaluatorDialog(item) {
      this.store.setCommentsType(item);
      this.store.setDialogEvaluationComments(true);
    },
  },
};
</script>

<style scoped>
.list-striped a {
  text-decoration: none;
  color: #000;
}

.list-striped a:hover {
  color: rgb(72, 61, 139);
}

.list-striped a:active {
  color: rgb(72, 61, 139);
}
</style>