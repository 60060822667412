<template>
  <div>
    <!-- EVENTS CALENDAR MODAL -->
    <div class="text-center">
      <v-dialog v-model="dialogEventsCalendar" max-width="75%" scrollable>
        <v-row justify="center">
          <v-card style="background-color: #f5f5f5; width: 100%;">
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-table
                fixed-header
                fixed-footer
                height="80vh"
              >
                <tbody>
                  <tr>
                    <td>         
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12" md="12" sm="12">
                              <div>
                                <FullCalendar :options="localCalendarOptions" ref="fullCalendar">
                                  <template v-slot:eventContent="arg">
                                    <v-tooltip :text="arg.event.title" location="top">
                                      <template v-slot:activator="{ props }">
                                        <div v-bind="props" v-if="arg.view.type !== 'timeGridDay'">
                                          <b>{{ arg.event.title }} ({{ arg.timeText }})</b>
                                        </div>

                                        <div v-else>
                                          <v-card 
                                            color="transparent" 
                                            elevation="0" 
                                            v-if="opened.includes(arg.event.extendedProps.eventId)"
                                          >
                                            <v-row>
                                              <v-col cols="6" md="6" sm="6">
                                                <div v-bind="props">
                                                  <b>{{ arg.event.title }} - {{ arg.event.extendedProps.companyName }}</b>
                                                </div>
                                              </v-col>

                                              <v-col cols="6" md="6" sm="6" :style="'background-color: #FFFFFF; border: 1px solid ' + (arg.event.borderColor) + ';'">     
                                                <v-card-text>
                                                  <v-list>
                                                    <v-form
                                                      ref="form"
                                                      v-model="valid"
                                                      lazy-validation
                                                    >
                                                      <v-list-item>
                                                        <v-list-item-content>
                                                          <b :style="'color: ' + arg.event.borderColor + ';'">{{ arg.event.title }}</b>
                                                        </v-list-item-content>
                                                      </v-list-item>
                                                      <v-list-item>
                                                        <v-list-item-content>
                                                          <span :style="'color: ' + arg.event.borderColor + ';'">Date</span> - {{ formatMeetingDate(arg.event.start) }}
                                                        </v-list-item-content>
                                                      </v-list-item>
                                                      <v-list-item>
                                                        <v-list-item-content>
                                                          <span :style="'color: ' + arg.event.borderColor + ';'">Time</span> - {{ arg.timeText }}
                                                        </v-list-item-content>
                                                      </v-list-item>
                                                      <v-list-item>
                                                        <v-list-item-content>
                                                          <span :style="'color: ' + arg.event.borderColor + ';'">Event Type</span> - {{ arg.event.extendedProps.eventType.name }}
                                                        </v-list-item-content>
                                                      </v-list-item>
                                                      <v-list-item>
                                                        <v-list-item-content>
                                                          <span :style="'color: ' + arg.event.borderColor + ';'">Location</span> - {{ arg.event.extendedProps.location || 'Location not specified' }}
                                                        </v-list-item-content>
                                                      </v-list-item>
                                                      <v-list-item>
                                                        <v-list-item-content>
                                                          <span :style="'color: ' + arg.event.borderColor + ';'">Agenda</span> - {{ arg.event.extendedProps.agenda || 'No agenda available' }}
                                                        </v-list-item-content>
                                                      </v-list-item>
                                                      <v-list-item v-if="arg.event.extendedProps.eventType.id == 4">
                                                        <v-list-item-content>
                                                          <span :style="'color: ' + arg.event.borderColor + ';'">Actions</span>
                                                        </v-list-item-content>
                                                      </v-list-item>
                                                      <v-list-item
                                                        v-for="(action, index) in arg.event.extendedProps.eventAction"
                                                        :key="index"
                                                      >
                                                        <v-list-item-content>
                                                          <v-btn
                                                            class="text-capitalize text-white"
                                                            :color="arg.event.borderColor"
                                                            :loading="store.getLoading"
                                                            @click="submitRSVP(action, arg.event.extendedProps)"
                                                          >
                                                            {{ action.actionName }}
                                                          </v-btn>
                                                        </v-list-item-content>

                                                        <template v-slot:append v-if="action.isSelected == 1">
                                                          <v-icon
                                                            :color="arg.event.borderColor"
                                                          >
                                                            mdi-radiobox-marked</v-icon
                                                          >
                                                        </template>
                                                      </v-list-item>
                                                    </v-form>
                                                  </v-list>
                                                </v-card-text>
                                                <v-card-actions v-if="arg.event.extendedProps.eventType.id == 4">
                                                  <v-btn
                                                    block
                                                    :color="arg.event.borderColor"
                                                    class="text-capitalize"
                                                    @click="openCalendarMeeting(arg.event.extendedProps)"
                                                  >
                                                    Open Meeting
                                                  </v-btn>
                                                </v-card-actions>
                                              </v-col>
                                            </v-row>
                                          </v-card>

                                          <div v-bind="props" v-else>
                                            <b>{{ arg.event.title }} ({{ arg.timeText }})</b>
                                          </div>
                                        </div>
                                      </template>
                                    </v-tooltip>
                                  </template>
                                </FullCalendar>
                              </div>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td class="bg-white pa-2">
                      <v-card-actions>
                        <v-btn 
                          block
                          :color="edit ? 'success' : 'error'"
                          class="text-capitalize"
                          style="font-size: 1.25rem;"
                          @click="closeModal"
                        >
                          Close
                        </v-btn>
                      </v-card-actions>
                    </td>
                  </tr>
                </tfoot>
              </v-table>
            </v-form>
          </v-card>
        </v-row>
      </v-dialog>
    </div>
  </div>
</template>

<script>
var moment = require("moment");
import { useAuthStore } from "../../stores/auth";
import { useIndexStore } from "../../stores/index";
import { useAlertStore } from "../../stores/alert";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import multiMonthPlugin from '@fullcalendar/multimonth';
import axios from "axios";
import UserData from "../repository/UserData";

export default {
  name: "EventsCalendar",
  props: {
    calendarOptions: {
      type: Object,
      default: () => {},
      required: true,
    },
    allMeetings: {
      type: Array,
      default: () => [],
      required: true,
    },
  },

  components: {
    FullCalendar,
  },

  data: (vm) => ({
    token: localStorage.getItem("token") ? JSON.stringify(localStorage.getItem("token")).slice(1, -1) : "",
    auth: useAuthStore(),
    store: useIndexStore(),
    alert: useAlertStore(),
    submit_rsvp: false,
    valid: false,
    opened: [],
    calendarPlugins: [ 
      dayGridPlugin,
      timeGridPlugin,
      interactionPlugin,
      multiMonthPlugin,
    ],
    localCalendarOptions: {
      plugins: [],
      initialView: 'dayGridMonth',
      events: [],
      eventClick: vm.handleEventClick,
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'multiMonthYear,dayGridMonth,timeGridDay',
      },
      editable: true,
      selectable: true,
      selectMirror: true,
      dayMaxEvents: true,
      weekends: true,
      buttonText: {
        today: 'Today',
        month: 'Month',
        year: 'Year',
        day: 'Day',
        list: 'List'
      },
    },
  }),

  beforeMount() {
    this.$nextTick(() => {
      this.localCalendarOptions.plugins = this.calendarPlugins;
    });
  },

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },
    dialogEventsCalendar() {
      return this.store.getDialogEventsCalendar;
    },
  },

  watch: {
    calendarOptions: {
      handler(val) {
        this.$nextTick(() => {
          this.localCalendarOptions = Object.assign({}, val);
          this.localCalendarOptions.eventClick = this.handleEventClick;
          this.localCalendarOptions.plugins = this.calendarPlugins;
        });
      },
      deep: true,
    },
  },

  methods: {
    validate() {
      return this.$refs.form.validate();
    },

    resetForm() {
      return this.$refs.form.reset();
    },

    resetValidation() {
      return this.$refs.form.resetValidation();
    },

    formatMeetingDate(date) {
      return moment(date).format("dddd, MMM D, YYYY");
    },

    handleEventClick(clickInfo) {
      if (clickInfo.view.type !== 'timeGridDay') {
        let calendarApi = this.$refs.fullCalendar.getApi();
        calendarApi.changeView('timeGridDay', clickInfo.event.start);
      } 

      if (!this.submit_rsvp) {
        this.toggle(clickInfo.event.extendedProps.eventId);
      }
    },

    toggle(id) {
      const index = this.opened.indexOf(id);
      if (index > -1) {
        this.opened.splice(index, 1);
      } else {
        this.opened = [];
        this.opened.push(id);
      }
    },

    closeModal() {
      this.opened = [];
      this.store.setDialogEventsCalendar(false);
    },

    submitRSVP(action, item) {
      let meetingId = item.meetingId;
      this.submit_rsvp = true;
      this.validate().then((validator_response) => {
        this.valid = validator_response.valid;
        if (this.valid && item.eventType.id == 4) {
          const formData = new FormData();
          formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
          formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
          formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
          formData.append("model", "sendEventAction");
          formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
          formData.append("meetingId", meetingId)
          formData.append("eventId", item.eventId);
          formData.append("actionId", action.actionId);
          formData.append("eventType", 2);

          this.store.setLoading(true);
          axios.post(UserData.getBaseUrl(), formData).then((response) => {
            if (response.data.success == 1) {
              action.isSelected = 1;
              this.$emit('get_calendar_events');
              this.store.setLoading(false);
              this.alert.showSuccess(response.data.message);
              this.submit_rsvp = false;
            }
          })
          .catch((e) => {
            console.log("Error", e);
            this.store.setLoading(false);
          });
        }
      });
    },

    openCalendarMeeting(event) {
      if (event.meetingId && event.module) {
        this.store.setDialogEventsCalendar(false);
        let meetingId = event.meetingId + '_' + event.module;
        this.setCurrentMeeting(this.allMeetings.find(meeting => meeting.itemId == meetingId));
      }
    },

    setCurrentMeeting(meeting) {
      this.store.setCurrentMeeting(meeting);
      this.store.setCurrentComponent('view-02');
      this.store.setDashboardComponent('MyInbox');
      this.store.setDashboardMeeting(true);
      this.$router.push('/Meetings');
    },

    handleEvents(events) {
      this.currentEvents = events;
    },

    eventRender(event, element, view) {
      if (view == 'timeGridDay') {
        element.find('.fc-title').append('<div class="hr-line-solid-no-margin"></div><span style="font-size: 10px">' + event.title + '</span></div>');
      }
    },
  },
};
</script>