import { defineStore } from 'pinia';

export const useAuthStore = defineStore('auth', {
  persist: true,
  state: () => {
    return {
      loggedInUser: {},
      dashboardMenuList: [],
      eboardUpdates: [],
      userLanguage: {},
      digitalSignatures: false,
      sessionTimeSeconds: 0,
      sessionTimeMinutes: 0,
      sessionTimeHours: 0,
      sessionTimeDays: 0,
      sessionExpired: false,
      resetSession: false,
      userActivityThrottlerIds: [],
      sessionCountdownTimeoutIds: [],
      resetSessionTimeoutIds: [],
      userActivityTimeoutIds: [],
      userQrImageUrl: '',
    }
  },
  getters: {
    getLoggedInUser() {
      return this.loggedInUser
    },
    getDashboardMenuList() {
      return this.dashboardMenuList
    },
    getEboardUpdates() {
      return this.eboardUpdates
    },
    getUserLanguage() {
      return this.userLanguage
    },
    getDigitalSignatures() {
      return this.digitalSignatures
    },
    getSessionTimeSeconds() {
      return this.sessionTimeSeconds
    },
    getSessionTimeMinutes() {
      return this.sessionTimeMinutes
    },
    getSessionTimeHours() {
      return this.sessionTimeHours
    },
    getSessionTimeDays() {
      return this.sessionTimeDays
    },
    getSessionExpired() {
      return this.sessionExpired
    },
    getResetSession() {
      return this.resetSession
    },
    getUserActivityThrottlerIds() {
      return this.userActivityThrottlerIds
    },
    getSessionCountdownTimeoutIds() {
      return this.sessionCountdownTimeoutIds
    },
    getResetSessionTimeoutIds() {
      return this.resetSessionTimeoutIds
    },
    getUserActivityTimeoutIds() {
      return this.userActivityTimeoutIds
    },
    getUserQrImageUrl() {
      return this.userQrImageUrl
    },
  },
  actions: {
    setLoggedInUser(val) {
      this.loggedInUser = val
    },
    setDashboardMenuList(val) {
      this.dashboardMenuList = val
    },
    setEboardUpdates(val) {
      this.eboardUpdates = val
    },
    setCompanyId(companyId) {
      this.loggedInUser.companyDetail.companyId = companyId
    },
    setCompanyName(companyName) {
      this.loggedInUser.companyDetail.companyName = companyName
    },
    setUserLanguage(userLanguage) {
      this.userLanguage = userLanguage
    },
    setDigitalSignatures(val) {
      this.digitalSignatures = val
    },
    setSessionTimeSeconds(val) {
      this.sessionTimeSeconds = val
    },
    setSessionTimeMinutes(val) {
      this.sessionTimeMinutes = val
    },
    setSessionTimeHours(val) {
      this.sessionTimeHours = val
    },
    setSessionTimeDays(val) {
      this.sessionTimeDays = val
    },
    setSessionExpired(val) {
      this.sessionExpired = val
    },
    setResetSession(val) {
      this.resetSession = val
    },
    setUserActivityThrottlerIds(ids) {
      this.userActivityThrottlerIds = ids
    },
    setSessionCountdownTimeoutIds(ids) {
      this.sessionCountdownTimeoutIds = ids
    },
    setResetSessionTimeoutIds(ids) {
      this.resetSessionTimeoutIds = ids
    },
    setUserActivityTimeoutIds(ids) {
      this.userActivityTimeoutIds = ids
    },
    setUserQrImageUrl(url) {
      this.userQrImageUrl = url
    },
  }
});
