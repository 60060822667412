<template>
  <div>
    <v-row justify="center" no-gutters>
      <v-col :cols="cols[0]" v-if="listType">
        <v-sheet>
          <StaticList
            :document-list="documentList" 
            :list-menu="menu" 
            :list-color="listColor" 
            @open_document="switchDocument" 
            @close_list="menu = false" 
            :list-width="listWidth"
            :parent_select_type="parent_select_type"
            :parent_pdf_type="parent_pdf_type"
            v-if="documentList.length > 0"
            :file-ids="briefcaseFiles"
            :briefcase-color="briefcaseColor"
          />
        </v-sheet>
      </v-col>

      <v-divider vertical class="mx-4" v-if="listType"></v-divider>

      <v-col :cols="listType ? cols[1] : 12">
        <v-sheet>
          <v-card width="100%">
            <v-card-title>
              <v-toolbar
                dark
                :color="viewerColor"
                class="text-white mx-2"
              >
                <v-toolbar-items>
                  <v-btn
                    icon
                    dark
                    @click="menu = true"
                    v-if="documentList.length > 0 && !listType"
                  >
                    <v-icon>mdi-menu</v-icon>
                  </v-btn>
                </v-toolbar-items>
                <v-toolbar-title>
                  <v-tooltip
                    location="top"
                    content-class="bg-red"
                  >
                    <template v-slot:activator="{ props }">
                      <span v-bind="props">{{ documentTitle }}</span>
                    </template>
                    <span>{{ documentTitle }}</span>
                  </v-tooltip>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn
                    icon
                    dark
                    @click="closeDialog"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
            </v-card-title>
            <v-card-text style="overflow-y: scroll;">
              <div class="text-center mx-2">
                <v-form v-model="form" ref="form">
                  <v-table
                    fixed-header
                    fixed-footer
                    height="80vh"
                  >
                    <tbody>
                      <tr>
                        <td>
                          <v-container>
                            <v-row>
                              <v-col cols="12" sm="12">
                                <v-text-field
                                  v-model="editedItem.title"
                                  label="Title"
                                  variant="outlined"
                                  :rules="[rules.required, rules.minimum]"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="12">
                                <v-textarea
                                  v-model="editedItem.description"
                                  label="Description (Optional)"
                                  variant="outlined"
                                  :rules="editedItem.description != '' ? [rules.minimum] : []"
                                ></v-textarea>
                              </v-col>
                              <v-col cols="12" sm="12">
                                <v-select
                                  v-model="editedItem.signature_type"
                                  :items="signatureTypes"
                                  item-title="label"
                                  item-value="value"
                                  label="Signature Type"
                                  persistent-hint
                                  return-object
                                  single-line
                                  variant="outlined"
                                ></v-select>
                              </v-col>
                              <v-col cols="12">
                                <v-autocomplete
                                  v-model="editedItem.selected"
                                  :model-value="editedItem.selected"
                                  :disabled="isUpdating"
                                  :items="users"
                                  chips
                                  closable-chips
                                  color="blue-grey-lighten-2"
                                  item-title="userName"
                                  item-value="userId"
                                  dense
                                  return-object
                                  label="Signatories"
                                  multiple
                                  variant="outlined"
                                  :rules="selectRules"
                                >
                                  <template v-slot:chip="{ props, item }">
                                    <v-chip
                                      v-bind="props"
                                      :prepend-avatar="item.raw.userImageURL ? item.raw.userImageURL : '/img/neutral-icon.jpeg'"
                                      :text="item.raw.userName"
                                    ></v-chip>
                                  </template>
    
                                  <template v-slot:item="{ props, item }">
                                    <v-list-item
                                      v-bind="props"
                                      :prepend-avatar="item?.raw?.userImageURL ? item.raw.userImageURL : '/img/neutral-icon.jpeg'"
                                      :title="item?.raw?.userName"
                                    ></v-list-item>
                                  </template>
    
                                  <template v-slot:prepend-item>
                                    <v-list-item ripple @click="toggleSignatories">
                                      <template v-slot:prepend="{ isActive }">
                                        <v-list-item-action start>
                                          <v-icon
                                            :color="editedItem.selected.length > 0 ? 'error' : ''"
                                            :model-value="isActive"
                                          >
                                            {{ iconSignatories }}
                                          </v-icon>
                                        </v-list-item-action>
                                      </template>
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          Select All
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-2"></v-divider>
                                  </template>
    
                                  <template v-slot:append-item>
                                    <v-divider class="mb-2"></v-divider>
                                    <v-list-item disabled>
                                      <template v-slot:prepend="{ isActive }">
                                        <v-list-item-action start>
                                          <v-list-item-avatar color="grey lighten-3" :model-value="isActive">
                                            <v-icon> mdi-account-multiple-check-outline </v-icon>
                                          </v-list-item-avatar>
                                        </v-list-item-action>
                                      </template>
    
                                      <v-list-item-content
                                        v-if="likesAllSignatories"
                                      >
                                        <v-list-item-title>
                                          All Users Selected to Sign This
                                          Document!
                                        </v-list-item-title>
                                      </v-list-item-content>
    
                                      <v-list-item-content
                                        v-else-if="likesSomeSignatories"
                                      >
                                        <v-list-item-title>
                                          Users Count
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                          {{ editedItem.selected.length }}
                                        </v-list-item-subtitle>
                                      </v-list-item-content>
    
                                      <v-list-item-content v-else>
                                        <v-list-item-title>
                                          Which Users are required to sign this
                                          document?
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                          Go ahead, make a selection above!
                                        </v-list-item-subtitle>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="12" v-if="editedItem.selected.length > 0 && editedItem?.signature_type?.value == 'workflow'">
                                <v-list style="border: 1px solid black;">
                                  <v-list-subheader inset>
                                    Drag and Drop Below to change Signing Order
                                  </v-list-subheader>

                                  <v-list-item-group
                                    v-model="editedItem.selected"
                                    active-class="pink--text"
                                    multiple
                                  >
                                    <draggable 
                                      v-model="editedItem.selected" 
                                      item-key="userId" 
                                      @change="checkMove"
                                    >
                                      <template #item="{ element }">
                                        <v-list-item :title="element.userName" class="text-left" :value="element.userId">
                                          <template v-slot:prepend>
                                            <v-avatar color="grey-lighten-1">
                                              <v-img :src="element.userImageURL ? element.userImageURL : '/img/neutral-icon.jpeg'" />
                                            </v-avatar>
                                          </template>
    
                                          <template v-slot:append>
                                            <v-icon
                                              class="float-right align-center"
                                              style="cursor: pointer;"
                                            >
                                              mdi-reorder-horizontal
                                            </v-icon>
                                          </template>
                                        </v-list-item>
                                      </template>
                                    </draggable>
                                  </v-list-item-group>
                                </v-list>
                              </v-col>
                              <v-col cols="12" sm="12">
                                <v-menu
                                  ref="menu_due_date"
                                  v-model="menu_due_date"
                                  :close-on-content-click="true"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ props }">
                                    <v-text-field
                                      v-model="editedItem.due_date"
                                      :value="computedDueDate"
                                      label="Due Date"
                                      persistent-hint
                                      append-icon="mdi-calendar"
                                      @click:append="menu_due_date = true"
                                      @click="updateValidation"
                                      v-bind="props"
                                      variant="outlined"
                                      dense
                                      color="grey"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    inline
                                    auto-apply
                                    :model-value="dueDateWatcher"
                                    @update:model-value="setDate"
                                  ></v-date-picker>
                                </v-menu>
                              </v-col>
                              <v-col cols="12" sm="12">
                                <v-switch
                                  v-model="editedItem.active"
                                  hide-details
                                  inset
                                  :label="editedItem.active ? 'Active' : 'Not Active'"
                                  color="info"
                                  :false-value="0"
                                  :true-value="1"
                                ></v-switch>
                              </v-col>
                              <v-col cols="12" sm="12" v-if="editedIndex > -1">
                                <v-hover>
                                  <template v-slot:default="{ isHovering, props }">
                                    <p 
                                      v-bind="props"
                                      :class="isHovering ? 'text-left text-error' : 'text-left text-success'"
                                      style="cursor: pointer;"
                                      @click="briefcaseDocument(editedItem.signatureFileInfo)"
                                    >
                                      <span class="input-group-addon">
                                        <v-progress-circular
                                          v-if="fileLoading[editedItem.signatureFileInfo.itemId]"
                                          indeterminate
                                          color="success"
                                          class="mr-2"
                                        ></v-progress-circular> 
                                        <v-icon
                                          v-else
                                          :color="briefcaseFiles.includes(editedItem.signatureFileInfo.itemId) ? 'error' : 'success'"
                                          class="mr-2"
                                          size="large"
                                          :icon="globalMethods.getDocumentIcon(editedItem.signatureFileInfo.itemExtension)"
                                        />
                                      </span>
                                      {{ editedItem.signatureFileInfo.itemName }} (Click to View)
                                    </p>
                                  </template>
                                </v-hover>
                              </v-col>
                            </v-row>
                          </v-container>
                        </td>
                      </tr>
                    </tbody>
                  </v-table>
                </v-form>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="error"
                class="ma-4"
                variant="outlined"
                @click="closeDialog"
              >
                Cancel
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="success"
                class="ma-4"
                variant="outlined"
                @click="generateSignature"
                :loading="loading"
                :disabled="editedIndex > -1 ? true : false"
              >
                {{ editedIndex == -1 ? 'Submit' : 'Update' }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-sheet>
      </v-col>
    </v-row>

    <div class="text-center">
      <DocumentList 
        :document-list="documentList" 
        :list-menu="menu" 
        :list-color="listColor" 
        @open_document="switchDocument" 
        @close_list="menu = false"
        :list-width="listWidth" 
        :parent_select_type="parent_select_type"
        :parent_pdf_type="parent_pdf_type"
        v-if="documentList.length > 0"
        :file-ids="briefcaseFiles"
        :briefcase-color="briefcaseColor"
      />
    </div>
  </div>
</template>

<script>
var moment = require("moment");
import axios from 'axios';
import { useIndexStore } from '../../stores';
import { useAuthStore } from '../../stores/auth';
import { useAlertStore } from '../../stores/alert';
import DocumentList from '../Cards/DocumentList.vue';
import StaticList from '../Cards/StaticList.vue';
import SlackNotificationChannel from '../repository/SlackNotificationChannel';
import UserData from '../repository/UserData';
import authHeader from '../repository/auth-header';
import draggable from 'vuedraggable';
import GlobalMethods from '../repository/GlobalMethods';

export default {
  name: "View03",

  components: { 
    DocumentList, 
    StaticList,
    draggable,
  },

  props: {
    documentUrl: {
      type: String,
      default: "",
    },

    documentType: {
      type: String,
      default: "",
    },

    documentTitle: {
      type: String,
      default: "",
    },

    listType: {
      type: Boolean,
      default: false,
    },

    viewerColor: {
      type: String,
      default: "rgb(227, 58, 58)"
    },

    documentList: {
      type: Array,
      default: () => [],
    },

    listColor: {
      type: String,
      default: "#e33333",
    },

    briefcaseColor: {
      type: String,
      default: "#e33333",
    },

    listWidth: {
      type: String,
      default: "50%",
    },

    parent_select_type: {
      type: Boolean,
      default: false,
    },

    parent_pdf_type: {
      type: Number,
      default: null,
    },

    briefcaseOnLoad: {
      type: Boolean,
      default: false,
    },

    briefcaseFiles: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },

    meeting() {
      return this.store.getCurrentMeeting;
    },

    selectRules() {
      return [
        this.editedItem.selected.length > 0 || "At least one user should be selected"
      ];
    },
    
    dialog_document_viewer: {
      get() {
        return this.store.getDialogDocumentViewer;
      },
      set(value) {
        this.store.setDialogDocumentViewer(value);
      },
    },

    session_overlay: {
      get() {
        return this.store.getSessionOverlay;
      },
      set(value) {
        this.store.setSessionOverlay(value);
      },
    },

    cols () {
      const { lg, sm } = this.$vuetify.display;
      return lg ? [2, 9] : sm ? [2, 9] : [5, 6];
    },

    computedDueDate() {
      const date = this.formatDate(this.editedItem.due_date);
      return moment(date).format('DD MMM YYYY');
    },

    likesAllSignatories() {
      return (this.editedItem.selected.length === this.users.length);
    },

    likesSomeSignatories() {
      return (this.editedItem.selected.length > 0 && !this.likesAllSignatories);
    },

    iconSignatories() {
      if (this.likesAllSignatories) return "mdi-close-box";
      if (this.likesSomeSignatories) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },

  },

  watch: {
    dueDateWatcher() {
      this.editedItem.due_date = this.formatDate(this.dueDateWatcher);
    },
  },

  data: (vm) => ({
    menu: false,
    auth: useAuthStore(),
    store: useIndexStore(),
    alert: useAlertStore(),
    editedIndex: -1,
    dueDateWatcher: new Date(Date.now() + (30 * 24 * 60 * 60 * 1000)).toISOString().substr(0, 10),
    menu_due_date: false, 
    moment: moment,
    loading: false,
    fileLoading: {},
    isUpdating: false,
    users: [],
    validationErrors: [],
    globalMethods: GlobalMethods,
    token: localStorage.getItem("token") ? JSON.stringify(localStorage.getItem("token")).slice(1, -1) : "",
    editedItem: {
      title: "Digital Signature Form for " + vm.documentTitle,
      description: "",
      active: 1,
      signatureFileInfo: {},
      selected: [],
      signature_type: {
        id: 1,
        label: 'Workflow',
        value: 'workflow'
      },
      due_date: vm.formatDate(new Date(Date.now() + (30 * 24 * 60 * 60 * 1000)).toISOString().substr(0, 10)),
    },
    defaultItem: {
      title: "",
      description: "",
      active: 1,
      signatureFileInfo: {},
      selected: [],
      signature_type: {
        id: 1,
        label: 'Workflow',
        value: 'workflow'
      },
      due_date: vm.formatDate(new Date(Date.now() + (30 * 24 * 60 * 60 * 1000)).toISOString().substr(0, 10)),
    },
    rules: {
      required: value => !!value || 'Required.',
      minimum: value => value.length >= 3 || 'Min 3 characters',
      counter: value => value.length <= 20 || 'Max 20 characters',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Invalid e-mail.'
      },
    },
    signatureTypes: [
      {
        id: 1,
        label: 'Workflow',
        value: 'workflow'
      },
      {
        id: 2,
        label: 'Independent',
        value: 'independent'
      },
    ],
  }),

  created() {
    this.getUsersForDocument();
  },

  methods: {
    validate() {
      return this.$refs.form.validate();
    },

    reset() {
      this.$refs.form.reset();
    },

    resetValidation() {
      return this.$refs.form.resetValidation();
    },

    setDate(date) {
      this.editedItem.due_date = this.formatDate(moment(date).format("YYYY-MM-DD"));
      this.menu_due_date = false;
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },

    reverseFormatDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("-");
      return `${year}-${month}-${day}`;
    },

    setDBDateFormat() {
      this.editedItem.due_date = this.reverseFormatDate(this.editedItem.due_date);
    },

    revertDBDateFormat() {
      this.editedItem.due_date = this.formatDate(this.editedItem.due_date);
    },

    updateValidation() {
      this.validationErrors = [];
      if (this.$refs.form) this.resetValidation();
    },

    openSignatureFile(document, url = null) {
      this.store.setBriefcaseUrl(url);
      this.$emit("signature_file", document);
    },

    briefcaseDocument(item) {
      if (item.itemExtension) {
        this.fileLoading[item.itemId] = true;
        this.globalMethods.downloadEbrDocument(item.itemUrl).then(async (response) => {
          if (response.status == 200) {
            let fileType = response.headers['content-type'];
            let fileData = new Blob([response.data], { type: fileType });
            let blob = window.URL.createObjectURL(fileData);
            this.openSignatureFile(item, blob);
            this.fileLoading[item.itemId] = false;
          } else {
            this.fileLoading[item.itemId] = false;
            console.log("Response: ", response);
          }
        }).catch((e) => {
          this.fileLoading[item.itemId] = false;
          console.log("Error: ", e);
        });
      } else {
        this.alert.showInfo("Briefcasing Folders is Under Development");
      }
    },

    switchDocument(document) {
      if (document.itemExtension == 'pdf') {
        this.$emit("switch_pdf", document);
      } else {
        this.$emit("switch_document", document);
      }
    },

    closeDialog() {
      this.dialog_document_viewer = false;
      this.$emit('close_document');
    },

    toggleSignatories() {
      this.$nextTick(() => {
        if (this.likesAllSignatories) {
          this.editedItem.selected = [];
        } else {
          this.editedItem.selected = this.users;
        }
      });
    },

    checkMove(evt) {
      var oldIndex = evt.moved.oldIndex;
      var newIndex = evt.moved.newIndex;
      var user = evt.moved.element;

      var newSelected = this.editedItem.selected.find(item => item.userId == user.userId);
      if (!newSelected) {
        return false;
      } else {
        var newSelectedIndex = this.editedItem.selected.indexOf(newSelected);
        newSelected.userSortOrder = newIndex;
        this.editedItem.selected.splice(newSelectedIndex, 1, newSelected);
      }

      var oldSelected = this.editedItem.selected[oldIndex];
      if (!oldSelected) {
        return false;
      } else {
        var oldSelectedIndex = this.editedItem.selected.indexOf(oldSelected);
        oldSelected.userSortOrder = oldIndex;
        this.editedItem.selected.splice(oldSelectedIndex, 1, oldSelected);
      }
    },

    getUsersForDocument() {
      const document_id = UserData.getDocumentId() ? UserData.getDocumentId() : this.store.getDocumentId;

      const formData = new FormData();
      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "getUsersForDocument");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      formData.append("itemId", document_id);

      this.store.setLoading(true);
      axios.post(UserData.getBaseUrl(), formData).then((response) => {
        this.users = response.data.usersList;
        this.getSignatureFile();
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    generateSignature() {
      this.validate().then((validator_response) => {
        this.valid = validator_response.valid;
        if (this.valid) {
          this.setDBDateFormat();
          this.loading = true;

          const document_id = UserData.getDocumentId() ? UserData.getDocumentId() : this.store.getDocumentId();
          const admin_url = process.env.NODE_ENV == 'production' ? UserData.getProductionAdminBaseUrl() : UserData.getLocalAdminBaseUrl();

          const selected_users = this.editedItem.selected;

          // check users
          try {
            if (typeof selected_users[0] !== "number" || typeof selected_users[selected_users.length - 1] !== "number") {
              this.editedItem.selected = selected_users.map(item => parseInt(item.userId));
            }
          } catch (error) {
            console.log(error);
          }

          const form = new FormData();
          form.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
          form.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
          form.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
          form.append("model", "generateSignature");
          form.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
          form.append("documentId", document_id);
          form.append("title", this.editedItem.title);
          form.append("description", this.editedItem.description);
          form.append("users", JSON.stringify(this.editedItem.selected));
          form.append("due_date", this.editedItem.due_date);
          form.append("active", this.editedItem.active);
          form.append("signature_type", this.editedItem?.signature_type?.value);
          form.append("base_url", window.location.origin);
          form.append("meeting_id", this.meeting.itemId);
          
          axios.post(admin_url + '/generate-signature-file', form, { headers: authHeader() }).then((response) => {
            this.revertDBDateFormat();
            if (response.status == 200) {
              let result = response.data;
              this.alert.showSuccess(result.message);
              this.getSignatureFile();
              this.loading = false;
            } else {
              this.loading = false;
              this.alert.showInfo(response.message);
              if (response.response.status == 403 && !this.session_overlay) {
                this.session_overlay = true;
              }
            }
          }).catch((err) => {
            this.revertDBDateFormat();
            this.loading = false;
            this.alert.showError(err.message);
            SlackNotificationChannel.errorMessage(err.message, 'generateSignature(400) in PSPDFKit.vue', Object.fromEntries(form));
          });
        }
      });
    },

    getSignatureFile() {
      this.loading = true;

      const document_id = UserData.getDocumentId() ? UserData.getDocumentId() : this.store.getDocumentId();
      const admin_url = process.env.NODE_ENV == 'production' ? UserData.getProductionAdminBaseUrl() : UserData.getLocalAdminBaseUrl();

      const form = new FormData();
      form.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      form.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      form.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      form.append("model", "getSignatureFile");
      form.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      form.append("documentId", document_id);
      
      axios.post(admin_url + '/signature-file', form, { headers: authHeader() }).then((response) => {
        if (response.status == 200) {
          let result = response.data;
          if (result.signatureFileId) {
            this.editedIndex = result.signatureFileId;
            this.editedItem.title = result.signatureFileTitle;
            this.editedItem.description = result.signatureFileDescription;

            if (result.userList.length > 0) {
              let data = result.userList.map(item => item.userId.toString());
              let arr = this.users.filter(item => data.includes(item.userId));
              let ordered = data.map(userId => arr.find(v => v.userId == userId));
              this.editedItem.selected = ordered;
            }

            this.editedItem.due_date = this.formatDate(moment.unix(result.signatureFileDueDate).format("YYYY-MM-DD"));
            this.editedItem.active = result.active;
            this.editedItem.signatureFileInfo = result.signatureFileInfo;
          } else {
            this.editedIndex = -1;
          }

          this.loading = false;
        } else {
          this.loading = false;
          if (response.response.status == 403 && !this.session_overlay) {
            this.session_overlay = true;
          }
        }
      }).catch((err) => {
        this.loading = false;
        SlackNotificationChannel.errorMessage(err.message, 'getSignatureFile(529) in PSPDFKit.vue', Object.fromEntries(form));
      });
    },
  },
}
</script>