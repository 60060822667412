<template>
  <div>
    <!-- OPTIONS MODAL -->
    <div>
      <v-row>
        <v-card min-width="335px" max-height="80vh" class="overflow-y-auto">
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-card-text style="width: 100%;">
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in languages"
                      :key="index"
                    >
                      <v-list-item-title
                        v-if="index == 0"
                        class="text-red"
                      >
                        {{ item.language }}
                      </v-list-item-title>
                      <v-list-item-title
                        v-else
                      >
                        <a
                          href="#"
                          :class="getLanguageClass(item)"
                          @click="handleClick(item)"
                        >
                          {{ item.language }}
                        </a>
                      </v-list-item-title>
                      <v-divider thickness="2px"></v-divider>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
        </v-card>
      </v-row>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from "../../stores/auth";
import { useIndexStore } from "../../stores/index";
import { useAlertStore } from "../../stores/alert";

export default {
  name: "OptionsMenu",
  props: {
    languages: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    valid: false,
    reply: "",
    show: false,
    items: [
      { 
        title: 'Options',
        action: 0, 
      },
      { 
        title: 'Change Password',
        action: 1, 
      },
      { 
        title: 'Logout',
        action: 2, 
      },
    ],
    opened: [],
    rules: {
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
      selectRules: [(v) => !!v || "Select is required"],
      textRules: [
        v => !!v || 'Field is required',
        v => (v && v.length >= 3) || 'Min 3 characters',
      ],
      characterRules: [
        v => (v && v.length >= 3) || 'Min 3 characters',
      ],
    },
    token: localStorage.getItem("token") ? JSON.stringify(localStorage.getItem("token")).slice(1, -1) : "",
    auth: useAuthStore(),
    store: useIndexStore(),
    alert: useAlertStore(),
  }),

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },

    eboardUpdates() {
      return this.auth.getEboardUpdates;
    },

    userLanguage() {
      return this.auth.getUserLanguage;
    },
  },
  
  methods: {
    handleClick(language) {
      this.$emit('language_selected', language);
    },

    getLanguageClass(language) {
      if (this.userLanguage.languageId == language.languageId) {
        return 'text-decoration-none text-success';
      } else {
        return 'text-decoration-none';
      }
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000000;
}

a:hover {
  color: #F44336;
}
</style>