<template>
  <!-- PROFILE -->
  <v-card style="padding: 1%; color: #e33333; height: auto;" min-width="400px" max-height="80vh" class="overflow-y-auto">
    <v-card-actions class="px-5">
      <div style="float: left; margin: 0px;">
        <v-img
          :src="user.userInfo.imageUrl !== '' ? user.userInfo.imageUrl : (gender == 'null' ? '/img/neutral-icon.jpeg' : (gender == 'M' ? '/img/male-icon.png' : '/img/female-icon.png'))"
          style="height: 85px; width: 85px; background-color: #fff; border: 1px solid black;"
        />
      </div>
      <div style="float: left; position: relative; margin: 0px;">
        <v-card-text>
          <p style="font-size: 21px; margin: 0px;">
            {{ user.userInfo.userFullName }}
          </p>
          <p style="font-size: 13px; margin: 0px;">
            {{ user.userInfo.userPhone }}
          </p>
          <p style="font-size: 13px; margin: 0px;">
            {{ user.userInfo.userEmail }}
          </p>
        </v-card-text>
      </div>
    </v-card-actions>
    <v-card-text v-if="dashboard">
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-card-text style="width: 100%;">
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-list>
                <v-list-item
                  v-for="(item, index) in items"
                  :key="index"
                >
                  <v-list-item-title>
                    <span
                      style="cursor: pointer;"
                      class="text-decoration-none"
                      @click="handleClick(item.action)"
                    >
                      {{ item.title }}
                    </span>

                    <v-switch
                      v-if="index == 0 || index == 1 || index == 2"
                      hide-details
                      inset
                      class="mt-n4 mb-n4 pa-1 float-right"
                      color="#F44336"
                      @click="handleClick(item.action)"
                      v-model="selected[index]"
                      :readonly="checkEnvironment"
                    ></v-switch>

                    <span 
                      v-if="index == 3"
                      style="color: #F44336; cursor: pointer;"
                      class="float-right"
                    >
                      Password
                    </span>

                    <span 
                      v-if="index == 7"
                      style="color: #F44336; cursor: pointer;"
                      class="float-right"
                    >
                      {{ session_time_hours }} h 
                      {{ session_time_minutes }} m
                      {{ session_time_seconds }} s
                    </span>
                  </v-list-item-title>
                  <v-divider thickness="2px"></v-divider>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { useAuthStore } from "../../stores/auth";
import { useIndexStore } from "../../stores/index";
import { useAlertStore } from "../../stores/alert";

export default {
  name: "UserProfile",

  props: {
    dashboard: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },

    gender() {
      return localStorage.getItem('admin_gender');
    },

    checkEnvironment() {
      return process.env.NODE_ENV == 'production' ? true : false;
    },

    session_time_seconds: {
      get() {
        return this.auth.getSessionTimeSeconds;
      },
      set(value) {
        this.auth.setSessionTimeSeconds(value);
      },
    },

    session_time_minutes: {
      get() {
        return this.auth.getSessionTimeMinutes;
      },
      set(value) {
        this.auth.setSessionTimeMinutes(value);
      },
    },

    session_time_hours: {
      get() {
        return this.auth.getSessionTimeHours;
      },
      set(value) {
        this.auth.setSessionTimeHours(value);
      },
    },

    session_time_days: {
      get() {
        return this.auth.getSessionTimeDays;
      },
      set(value) {
        this.auth.setSessionTimeDays(value);
      },
    },

    digital_signatures: {
      get() {
        return this.auth.getDigitalSignatures;
      },
      set(value) {
        this.auth.setDigitalSignatures(value);
      },
    },
  },

  data: () => ({
    valid: false,
    selected: {},
    items: [
      { 
        title: 'Notification',
        action: 1, 
      },
      { 
        title: 'Calendar Sync',
        action: 2, 
      },
      { 
        title: 'Digital Signature',
        action: 3, 
      },
      { 
        title: 'User Verification',
        action: 4, 
      },
      { 
        title: 'Customize Menus',
        action: 5, 
      },
      { 
        title: 'Change Applock PIN',
        action: 6, 
      },
      { 
        title: 'Change Password',
        action: 7, 
      },
      { 
        title: 'Session Timeout',
        action: 8, 
      },
    ],
    rules: {
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
      selectRules: [(v) => !!v || "Select is required"],
      textRules: [
        v => !!v || 'Field is required',
        v => (v && v.length >= 3) || 'Min 3 characters',
      ],
      characterRules: [
        v => (v && v.length >= 3) || 'Min 3 characters',
      ],
    },
    token: localStorage.getItem("token") ? JSON.stringify(localStorage.getItem("token")).slice(1, -1) : "",
    auth: useAuthStore(),
    store: useIndexStore(),
    alert: useAlertStore(),
  }),

  mounted() {
    this.selected[2] = this.auth.getDigitalSignatures;
  },

  methods: {
    changePassword() {
      this.store.setMenuOption(6);
      this.store.setDialogPassword(true);
    },

    dashboardMenus() {
      this.store.setMenuOption(4);
      this.store.setDialogMenus(true);
    },

    handleClick(action) {
      switch(action) {
        case 3:
          this.digital_signatures = !this.digital_signatures;
          break;
        case 5:
          this.dashboardMenus();
          break
        case 7:
          this.changePassword();
          break;
      }
    },
  },
}
</script>

<style scoped>
span {
  text-decoration: none;
  color: #000000;
}

span:hover {
  color: #F44336;
}
</style>