<template>
  <div>
    <!-- NAV-MENU -->
    <div
      class="nav-menu"
      style="height: 45px; width: 100%; padding: 0px; padding-top: 0.3%; text-align: center;"
    >
      <router-link to="/dashboard" style="text-decoration: none">
        <v-icon color="#fff" size="35" style="margin-left: 10px"
          >mdi-home</v-icon
        >
      </router-link>
      <p style="font-weight: bold; font-size: 24px; margin-left: 40%">
        License & Policies
      </p>
    </div>

    <!-- COMPONENT -->
    <div
      style="margin-top: 0.5%; margin-bottom: 0.5%; background-color: #f8f9f9; border-top: 5px solid #e33333; width: 98%; margin-left: 1%; margin-right: 1%;"
    >
      <div
        class="two"
        style="margin-top: 7px; position: absolute; background-color: #f5f5f5; width: 98%; height: 100vh; margin-right: 1%; z-index: 3;"
      >
        <iframe width="100%" height="100%" name="discussions" :src="license_url" sandbox="allow-scripts"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from "../../stores/auth";
import { useIndexStore } from "../../stores/index";

export default {
  name: "LicenseAndPolicies",

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },
  },

  data() {
    return {
      license_url: null,
      token: localStorage.getItem("token") ? JSON.stringify(localStorage.getItem("token")).slice(1, -1) : "",
      userPassword: localStorage.getItem("userPassword") ? JSON.stringify(localStorage.getItem("userPassword")).slice(1, -1) : "",
      store: useIndexStore(),
      auth: useAuthStore(),
    }
  },

  mounted() {
    this.getLicenseUrl();
  },

  methods: {
    getLicenseUrl() {
      this.license_url = this.user.licencePolicyUrlHtml;
    },
  }
}
</script>

<style scoped>
body {
  margin: 0;
  padding: 0;
}

.nav-menu {
  margin-top: 0px;
  background-color: #e33333;
  color: #fff;
  display: flex;
}

.nav-menu .btn {
  background-color: #e33333;
  border: none;
  padding: 0px;
  margin-right: 15px;
  color: white;
  outline: none;
}
</style>