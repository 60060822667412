import originalCrypto from "./originalCrypto";
import database from "./database";

const PBKDF2_SALT_BYTE_LENGTH = 16;
const DERIVE_KEY_ITERATIONS = 1000000;
const AES_GCM_IV_LENGTH = 12;

const textEncoder = new TextEncoder();
const textDecoder = new TextDecoder();

var CREDENTIAL_KEY = null;

class encAtRest {
  static getCredentialKey = () => {
    return CREDENTIAL_KEY;
  }

  static setCredentialKey = (credentialKey) => {
    CREDENTIAL_KEY = credentialKey;
  }

  static _randomBytes = (byteLength) => {
    return window.crypto.getRandomValues(new Uint8Array(byteLength));
  }
  
  static _stringToBytes = (text) => {
    return textEncoder.encode(text);
  }
  
  static _bytesToString = (utf8Array) => {
    return textDecoder.decode(utf8Array);
  }

  static _getSubtle = async () => {
    const subtle = global.crypto && global.crypto.subtle;
    if (!subtle) throw Error('Browser does not implement Web Crypto.');
    if (!subtle.importKey || !subtle.deriveKey || !subtle.decrypt || !subtle.encrypt) throw Error('Web Crypto on this browser does not implement required APIs.');
    if (originalCrypto.findTampering()) throw Error('Crypto functions have been tampered with.');
    return subtle;
  }

  static _generateCredentialKey = async (password, forPasswordChange) => {
    try {
      const subtle = await this._getSubtle();
      const credentialKeyData = await database.getKeyGenData();
  
      const generateDeriveKeySalt = forPasswordChange || !credentialKeyData.deriveKeySalt;
      if (generateDeriveKeySalt) { 
        credentialKeyData.deriveKeySalt = this._randomBytes(PBKDF2_SALT_BYTE_LENGTH);
        await database.putKeyGenData(credentialKeyData);
      }
  
      const salt = credentialKeyData.deriveKeySalt;
      const passwordUint8 = this._stringToBytes(password); 
      const algorithmParams = { name: 'PBKDF2', hash: 'SHA-256', salt, iterations: DERIVE_KEY_ITERATIONS };
      const baseKey = await subtle.importKey('raw', passwordUint8, 'PBKDF2', false, ['deriveKey']);
      const derivedParams = { name: 'AES-GCM', length: 128 };
      const credentialsKey = await subtle.deriveKey(algorithmParams, baseKey, derivedParams, false, ['decrypt', 'encrypt']);
      return credentialsKey;
    } catch(e) {
      if (e instanceof TypeError) {
        if (e.message.includes('deriveKeySalt')) {
          console.log(e.message);
        }
      } else {
        console.log(e);
      }
    }
  }

  static _encryptText = async (text, key) => {
    const subtle = await this._getSubtle();
    const iv = this._randomBytes(AES_GCM_IV_LENGTH);
    const algorithmParams = {name: 'AES-GCM', iv };
    const data = this._stringToBytes(text);
    const cipherText = await subtle.encrypt(algorithmParams, key, data);
    const fullMessage = new Uint8Array(AES_GCM_IV_LENGTH + cipherText.byteLength);
    fullMessage.set(iv);
    fullMessage.set(new Uint8Array(cipherText), AES_GCM_IV_LENGTH);
    return fullMessage;
  }

  static _decryptText = async (fullMessage, key) => {
    const subtle = await this._getSubtle();
    const iv = fullMessage.slice(0, AES_GCM_IV_LENGTH);
    const cipherText = fullMessage.slice(AES_GCM_IV_LENGTH);
    
    const algorithmParams = {name: 'AES-GCM', iv};
    const plainText = await subtle.decrypt(algorithmParams, key, cipherText);

    const text = this._bytesToString(new Uint8Array(plainText));
    return text;
  }

  static _encryptFile = async (blob, key) => {
    const subtle = await this._getSubtle();
    const iv = this._randomBytes(AES_GCM_IV_LENGTH);
    const algorithmParams = {name: 'AES-GCM', iv };
    let encryptKey = await subtle.generateKey({ name: "AES-GCM", length: 256 }, true, ["encrypt", "decrypt"]);
    const data = await blob.arrayBuffer();
    const cipherFile = await subtle.encrypt(algorithmParams, encryptKey, data);
    let exportedkey =  await subtle.exportKey("jwk", encryptKey);
    let ivdata = await this._encryptText(iv.toString(), key);
    let secretKey = exportedkey;
    let file = [new Blob([cipherFile]), ivdata, secretKey];
    return file;
  }

  static _decryptFile = async (file, type, key) => {
    const subtle = await this._getSubtle();
    let exportKey = file[2];
    let decryptKey = await subtle.importKey("jwk", exportKey, { name: "AES-GCM" }, true, ["encrypt", "decrypt"]);
    let ivdata = await this._decryptText(file[1], key);
    let iv = new Uint8Array(ivdata.split(','));
    const cipherText = await file[0].arrayBuffer();
    const algorithmParams = {name: 'AES-GCM', iv};
    const plainFile = await subtle.decrypt(algorithmParams, decryptKey, cipherText);
    let fileType = await this._decryptText(type, key);
    let result = new Blob([plainFile], { type: fileType });
    return result;
  }

  static loadStoreFromAppData = async (credentialKey) => {
    const appData = await database.getAppData();
    const sensitiveData = appData.sensitiveData === null ? '' : await this._decryptText(appData.sensitiveData, credentialKey);
    return { sensitiveData };
  }

  static saveStoreToAppData = async (credentialKey, store) => {
    const appData = await database.getAppData();  
    appData.id = store.id;
    appData.sensitiveData = await this._encryptFile(store.sensitiveData, credentialKey);
    appData.sensitiveType = await this._encryptText(store.sensitiveType, credentialKey);
    await database.putAppData(appData);
  }

  static generateCredentialKey = async (password) => {
    return this._generateCredentialKey(password, false);
  }
}

export default encAtRest;