<template>
  <div>
    <!-- PARTICIPANTS MODAL -->
    <div class="text-center">
      <v-dialog v-model="dialog_approval" max-width="950px">
        <v-card>
          <div
            style="border-top: 5px solid rgb(71, 183, 83);"
          >
            <v-card-title>
              <div style="height: 8%;">
                <v-card-title
                  class="headline grey lighten-2"
                  style="background-color: rgb(255, 255, 255); color: rgb(71, 183, 83);"
                >
                  <v-toolbar class="bg-white">
                    <v-toolbar-title>
                      <v-container fill-height fluid>
                        <v-row align="center">
                          <!-- Approval Details Header -->
                          <div
                            class="parent"
                            style="height: 45px; width: 100%; background-color: #fff"
                          >
                            <div class="left-buttons">
                              <v-icon
                                style="float: left; margin-top: 5px; background-color: rgb(71, 183, 83); color: #fff;"
                                size="25"
                                >mdi-calendar-check-outline</v-icon
                              >
                              <p style="float: left; font-weight: bold; font-size: 19px; text-align: left; margin-left: 10px; margin-top: 3px; color: rgb(71, 183, 83);">
                                Approvals
                              </p>
                            </div>
                          </div>
                        </v-row>
                      </v-container>
                    </v-toolbar-title>
                    <v-toolbar-items>
                      <v-btn
                        class="btn"
                        color="rgb(71, 183, 83)"
                        @click="closeDialogApproval"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-toolbar-items>
                  </v-toolbar>
                </v-card-title>
              </div>
            </v-card-title>
            <v-card-text>
              <div style="height: 92%; overflow: auto">
                <!-- Approvals Table -->
                <v-table 
                  v-if="Object.keys(current_approval).length > 0" 
                  fixed-header
                  height="100vh"
                >
                  <thead>
                    <tr style="color: #fff; font-weight: bolder;">
                      <th 
                        style="background-color: rgb(71, 183, 83); font-weight: bolder" 
                        class="text-left text-white"
                      >
                        Proposal
                      </th>
                      <th 
                        style="background-color: rgb(71, 183, 83); font-weight: bolder" 
                        class="text-left text-white"
                      >
                        Approval Name
                      </th>
                      <th 
                        style="background-color: rgb(71, 183, 83); font-weight: bolder" 
                        class="text-left text-white"
                      >
                        Applicant Name
                      </th>
                      <th 
                        style="background-color: rgb(71, 183, 83); font-weight: bolder" 
                        class="text-left text-white"
                      >
                        Amount
                      </th>
                      <th 
                        style="background-color: rgb(71, 183, 83); font-weight: bolder" 
                        class="text-left text-white"
                      >
                        Date Submitted
                      </th>
                      <th 
                        style="background-color: rgb(71, 183, 83); font-weight: bolder" 
                        class="text-left text-white"
                      >
                        Votes
                      </th>
                      <th 
                        style="background-color: rgb(71, 183, 83); font-weight: bolder" 
                        class="text-center text-white"
                      >
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    class="table-striped"
                  >
                    <tr
                      style="height: 100%;"
                    >
                      <td
                        style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; padding-top: 20px; padding-bottom: 20px;"
                      >
                        -
                      </td>
                      <td
                        style="overflow: hidden; text-overflow: ellipsis;"
                      >
                        {{ current_approval.approvalName }}
                      </td>
                      <td
                        style="overflow: hidden; text-overflow: ellipsis;"
                      >
                        {{ current_approval.applicantName }}
                      </td>
                      <td
                        style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                      >
                        {{ toMoneyFormat(current_approval.amount, current_approval.currency) }}
                      </td>
                      <td
                        style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                      >
                        {{ formatDate(current_approval.dateSubmitted) }}
                      </td>
                      <td
                        style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                      >
                        {{ current_approval.votes }}
                      </td>
                      <td
                        class="text-center"
                        style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                      >
                        <v-btn 
                          class="text-white" 
                          color="info"
                          block
                        >
                          Pending
                        </v-btn>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="7">
                        <v-card class="elevation-0">
                          <v-card-text>
                            <div
                              style="display: block; margin: 2%; border: 1px solid grey; overflow: hidden;"
                            >
                              <v-row class="pa-4">
                                <v-col cols="6" md="6" sm="6">
                                  <div
                                    class="top-left"
                                  >
                                    <p
                                      style="font-weight: bold; font-size: 19px; margin: 0px;"
                                    >
                                      Full Proposal Description:
                                    </p>
                                    <br>
                                    <p
                                      style="margin-top: 0px; height: auto; text-align: justify;"
                                    >
                                      {{ current_approval.description || 'No Description Found' }}
                                    </p>
                                  </div>
                                </v-col>

                                <v-col cols="6" md="6" sm="6">
                                  <v-row>
                                    <v-col cols="12" md="12" sm="12">
                                      <p style="font-weight: bold; font-size: 19px; margin: 0px;">
                                        Source Documents:
                                      </p>
                                      <div v-if="approvalDocs && approvalDocs.length > 0">
                                        <v-list density="compact" class="ml-n4">
                                          <v-list-item
                                            v-for="(item, index) in approvalDocs"
                                            :key="index"
                                            :value="item.itemName"
                                          >
                                            <template v-slot:prepend>
                                              <v-progress-circular
                                                v-if="approvalLoading[item.itemId]"
                                                indeterminate
                                                color="rgb(71, 183, 83)"
                                                class="mr-2"
                                              ></v-progress-circular> 
                                              <v-icon
                                                v-else
                                                color="rgb(71, 183, 83)"
                                                class="mr-2"
                                                size="large"
                                                :icon="globalMethods.getDocumentIcon(item.itemExtension)"
                                              />
                                            </template>
                                            <v-list-item-title class="text-wrap">
                                              <file-options
                                                :file="item"
                                                :file_color="'rgb(71, 183, 83)'"
                                                @open_pdf="setPdf(item)"
                                                :file_class="item.crossout == 0 ? '' : ' text-decoration-line-through'"
                                                v-if="item.itemExtension == 'pdf'"
                                                :select_type="true"
                                                :pdf_type="2"
                                              ></file-options>
                                              <span 
                                                v-else
                                                :class="item.crossout == 0 ? ' name' : ' name text-decoration-line-through'"
                                                @click="setPdf(item)"
                                              >
                                                {{ item.itemName }}
                                              </span>
                                            </v-list-item-title>
                                          </v-list-item>
                                        </v-list>
                                      </div>
                                      <div v-else>
                                        <br>
                                        <p style="margin-top: 0px; height: auto; text-align: justify;">
                                          No Documents Found
                                        </p>
                                      </div>
                                    </v-col>

                                    <v-col cols="12" md="12" sm="12">
                                      <p style="font-weight: bold; font-size: 19px; margin: 0px; margin-top: 10px;">
                                        Supplementary Documents:
                                      </p>
                                      <div v-if="approvalSuppDocs && approvalSuppDocs.length > 0">
                                        <v-list density="compact" class="ml-n4">
                                          <v-list-item
                                            v-for="(suppItem, index) in approvalSuppDocs"
                                            :key="index"
                                            :value="suppItem.itemName"
                                          >
                                            <template v-slot:prepend>
                                              <v-progress-circular
                                                v-if="approvalLoading[suppItem.itemId]"
                                                indeterminate
                                                color="rgb(71, 183, 83)"
                                                class="mr-2"
                                              ></v-progress-circular> 
                                              <v-icon
                                                v-else
                                                color="rgb(71, 183, 83)"
                                                class="mr-2"
                                                size="large"
                                                :icon="globalMethods.getDocumentIcon(suppItem.itemExtension)"
                                              />
                                            </template>
                                            <v-list-item-title class="text-wrap">
                                              <file-options
                                                :file="suppItem"
                                                :file_color="'rgb(71, 183, 83)'"
                                                @open_pdf="setPdf(suppItem)"
                                                :file_class="suppItem.crossout == 0 ? '' : ' text-decoration-line-through'"
                                                v-if="suppItem.itemExtension == 'pdf'"
                                                :select_type="true"
                                                :pdf_type="2"
                                              ></file-options>
                                              <span 
                                                v-else
                                                :class="suppItem.crossout == 0 ? ' name' : ' name text-decoration-line-through'"
                                                @click="setPdf(suppItem)"
                                              >
                                                {{ suppItem.itemName }}
                                              </span>
                                            </v-list-item-title>
                                          </v-list-item>
                                        </v-list>
                                      </div>
                                      <div v-else>
                                        <br>
                                        <p style="margin-top: 0px; height: auto; text-align: justify;">
                                          No Documents Found
                                        </p>
                                      </div>
                                    </v-col>
                                  </v-row>
                                </v-col>

                                <v-col cols="12" md="12" sm="12">
                                  <p style="margin: 0px; padding-left: 10px; font-weight: bold; font-size: 19px;">
                                    Actions
                                  </p>
                                  <br>
                                  <div class="modals">
                                    <!-- APPROVE MODAL -->
                                    <div class="approve" style="float: left;">
                                      <v-btn
                                        @click="store.setDialogApprove(true);"
                                        style="margin-right: 10px;"
                                        :disabled="current_approval.RCIRequired ? (current_approval.RCIRegistered ? (checkApprove ? true : false) : true) : (checkApprove ? true : false)"
                                        :color="current_approval.RCIRequired ? (current_approval.RCIRegistered ? (checkApprove ? 'transparent' : 'success') : 'transparent') : (checkApprove ? 'transparent' : 'success')"
                                        class="mb-3"
                                      >
                                        Approve
                                      </v-btn>
                                    </div>

                                    <!-- REJECT MODAL -->
                                    <div class="reject" style="float: left;">
                                      <v-btn
                                        @click="store.setDialogReject(true)"
                                        style="margin-right: 10px; color: #FFFFFF;"
                                        :disabled="current_approval.RCIRequired ? (current_approval.RCIRegistered ? (checkApprove ? true : false) : true) : (checkApprove ? true : false)"
                                        :color="current_approval.RCIRequired ? (current_approval.RCIRegistered ? (checkApprove ? 'transparent' : 'error') : 'transparent') : (checkApprove ? 'transparent' : 'error')"
                                        class="mb-3"
                                      >
                                        Reject
                                      </v-btn>
                                    </div>

                                    <!-- COMMENT MODAL -->
                                    <div class="comment" style="float: left;">
                                      <v-btn
                                        @click="store.setDialogComment(true)"
                                        style="margin-right: 10px"
                                        color="warning"
                                        class="mb-3"
                                      >
                                        Comment
                                      </v-btn>
                                    </div>

                                    <!-- RCI MODAL -->
                                    <div class="rci" style="float: left">
                                      <v-btn
                                        @click="store.setDialogRCI(true)"
                                        style="margin-right: 10px"
                                        :disabled="current_approval.RCIRegistered ? true : false"
                                        :color="current_approval.RCIRegistered ? 'transparent' : 'info'"
                                        class="mb-3"
                                      >
                                        RCI
                                      </v-btn>
                                    </div>
                                  </div>
                                </v-col>

                                <v-col cols="12" sm="12" md="12">
                                  <!-- APPROVER TABLE -->
                                  <div>
                                    <v-table>
                                      <thead>
                                        <tr style="background-color: rgb(71, 183, 83); color: #fff; font-weight: bolder;" class="approver-table">
                                          <td>Approver</td>
                                          <td>Action</td>
                                          <td>Comment</td>
                                          <td>Condition</td>
                                          <td>Date</td>
                                        </tr>
                                      </thead>
                                      <tbody v-if="approvalComments.length > 0">
                                        <tr
                                          v-for="(item, index) in approvalComments"
                                          v-bind:key="index"
                                          @click="replyComment(item)"
                                          style="cursor: pointer;"
                                        >
                                          <td>
                                            {{ item.approver }}
                                          </td>
                                          <td>
                                            {{ item.approvalActionName }}
                                          </td>
                                          <td>
                                            <div v-if="item.totalCommentCount > 0">
                                              {{ truncateText(item.approverComment, 50) }} 
                                              <span style="cursor: pointer;" class="text-red float-right font-weight-bold">(i)</span>
                                            </div>
                                            <div v-else>
                                              {{ truncateText(item.approverComment, 50) }}
                                            </div>
                                          </td>
                                          <td>
                                            {{ truncateText(item.approvalCondition, 50) }}
                                          </td>
                                          <td>
                                            {{ formatDate(item.approvalCommentDate) }}
                                          </td>
                                        </tr>
                                      </tbody>
                                      <tbody v-else>
                                        <tr>
                                          <td 
                                            colspan="5"
                                            class="text-center"
                                          >
                                            No Comments Found for this Approval
                                          </td>
                                        </tr>
                                      </tbody>
                                    </v-table>
                                  </div>
                                </v-col>
                              </v-row>
                            </div>
                          </v-card-text>
                        </v-card>
                      </td>
                    </tr>
                  </tbody>
                </v-table>

                <v-table v-else>
                  <thead>
                    <tr style="background-color: rgb(71, 183, 83); color: #fff; font-weight: bolder;">
                      <td class="text-left">Proposal</td>
                      <td class="text-left">Approval Name</td>
                      <td class="text-left">Applicant Name</td>
                      <td class="text-left">Amount</td>
                      <td class="text-left">Date Submitted</td>
                      <td class="text-left">Votes</td>
                      <td class="text-center">Status</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td 
                        colspan="7"
                        class="text-center"
                      >
                        <span>
                          No Approval Details Found
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </v-table>
              </div>
            </v-card-text>
            <v-card-actions class="grey" @click="closeDialogApproval">
              <v-btn 
                block
                color="rgb(71, 183, 83)"
              >
                Close
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-dialog>
    </div>

    <!-- REPLY COMMENTS MODAL -->
    <div class="text-center">
      <ReplyComment v-if="dialog_reply" :comment="comment" :replies="selected_replies" @add_comment="replyComment(comment)" />
    </div>

    <!-- APPROVE MODAL -->
    <div class="text-center">
      <ApproveApproval @approval_updated="approvalUpdated" />
    </div>

    <!-- REJECT MODAL -->
    <div class="text-center">
      <RejectApproval @approval_updated="approvalUpdated" />
    </div>

    <!-- COMMENTS MODAL -->
    <div class="text-center">
      <CommentApproval @approval_updated="approvalUpdated" />
    </div>

    <!-- RCI MODAL -->
    <div class="text-center">
      <RCI :type="2" @approval_updated="approvalUpdated" />
    </div>
  </div>
</template>

<script>
var moment = require("moment");
import { useAuthStore } from "../../stores/auth";
import { useIndexStore } from "../../stores/index";
import { useAlertStore } from "../../stores/alert";
import axios from "axios";
import UserData from "../repository/UserData";
import ReplyComment from "./ReplyComment.vue";
import ApproveApproval from "./ApproveApproval.vue";
import RejectApproval from "./RejectApproval.vue";
import CommentApproval from "./CommentApproval.vue";
import RCI from "./RCI.vue";
import FileOptions from '../Cards/FileOptions.vue';
import GlobalMethods from "../repository/GlobalMethods";

const LICENSE_KEY =
  "QvMr6j5706r3kYlOiVvJgEwu4fZnTL1fYz4t6paoBV5PekugaffzJnLtX5TczMS65OKAI1iIaSi06_l76UgF3kyKauJ9vaMZFuU3_oDplMFty6AhBhWPh80M7qoSa8xjtux3NqNrfqybICplLfHezwjhABIkPy6XxBMQ_uPU87_C5pMBXlc8A2PI_ShMjfhst6O_C0y9jyeMJ2DYudM3liFVsp90A0dOY51LM2Q3xV3jfcJ7K39FKpnKU_atpn1Ig8HXcmWdGQMjCXyquwVu9i2J850rGqBgFKJJFGixxnU3sZ__tA7Mb3aX8T2nXPlEnamtAhNf-DD1sEtqXHp5q5xxsFFuJDVh82tXKfd4oX8AkzWLd_-UVg_h_6ATML30e8fiQXSVcN0mmlZH5XYUv0jB5cHEyuIaGZac7gCNRn32pchJNpIwEdgHzWDRBQxxCR7F8b6z3Xs3yXJ6y9EhhrnKmjNYkMCdFJ0qNhI8zF8RhAbLA-uKtrzMy7hXLrlpuIHdGaCXMm7gA9iBpKiqwHIrX83rCFoVYUGM4BqNkim3HN8YJzZRDDI-ZHzdy7CcanSuvbe3NSxW5daeHdGVvg==";

export default {
  name: "ApprovalDetails",
  props: {
    meeting: {
      type: Object,
      default: () => {},
    },

    load: {
      type: Boolean,
      default: false,
    },

    briefcaseFiles: {
      type: Array,
      default: () => [],
    },

    approvalLoading: {
      type: Object,
      default: () => {},
    },
  },

  components: {
    ReplyComment,
    ApproveApproval,
    RejectApproval,
    CommentApproval,
    RCI,
    FileOptions,
  },

  data: () => ({
    pdf: "",
    LICENSE_KEY: LICENSE_KEY,
    baseUrl: "",
    valid: false,
    dialog: false,
    interestDisclosed: "",
    interestConflict: "",
    hasConflict: false,
    confirm: false,
    comments: "",
    conditions: "",
    password: "",
    approvalDocs: [],
    approvalSuppDocs: [],
    approvalComments: [],
    checkApprove: true,
    rules: {
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
      selectRules: [(v) => !!v || "Select is required"],
      textRules: [
        v => !!v || 'Field is required',
        v => (v && v.length >= 3) || 'Min 3 characters',
      ],
      characterRules: [
        v => (v && v.length >= 3) || 'Min 3 characters',
      ],
    },
    token: localStorage.getItem("token") ? JSON.stringify(localStorage.getItem("token")).slice(1, -1) : "",
    auth: useAuthStore(),
    store: useIndexStore(),
    alert: useAlertStore(),
    selected_replies: [],
    comment: {},
    globalMethods: GlobalMethods,
    loading: {},
  }),

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },

    dialog_approval() {
      return this.store.getDialogApproval;
    },

    dialog_reply() {
      return this.store.getDialogReply;
    },

    current_approval() {
      return this.store.getCurrentApproval;
    },

    current_agenda() {
      return this.store.getCurrentAgenda;
    },

    close_pdf() {
      return this.store.getClosePdf;
    },

    selected_comment: {
      get() {
        return this.store.getSelectedComment;
      },
      set(value) {
        this.store.setSelectedComment(value);
      },
    },
  },

  watch: { 
    load: function() {
      this.getApprovalDocuments();
    }
  },

  mounted() {
    this.store.setDialogReply(false);
    this.store.setDialogApprove(false);
    this.store.setDialogReject(false);
    this.store.setDialogComment(false);
    this.store.setDialogRCI(false);
  },

  methods: {
    formatDate(date) {
      return moment.unix(date).format("DD-MMM-YYYY");
    },

    truncateText(str, n) {
      return str.length > n ? str.substr(0, n - 1) + "..." : str;
    },

    toMoneyFormat(number, currency) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: currency,
      }).format(number);
    },

    validate() {
      return this.$refs.form.validate();
    },

    resetForm() {
      return this.$refs.form.reset();
    },

    resetValidation() {
      return this.$refs.form.resetValidation();
    },

    closePdf() {
      this.getApprovalDocuments();
      this.store.setClosePdf(!this.close_pdf);
    },

    setPdf(item) {
      this.$emit('approval_document', item);
      this.store.setClosePdf(true);
      this.store.setApprovalDocument(true);
    },

    closeDialogApproval() {
      this.store.setDialogApproval(false);
      document.querySelector('html').classList.remove('v-overlay-scroll-blocked');
    },

    getAttendanceStatus(status) {
      var index = 0;
      switch(status) {
        case "1":
          index = 1;
          break;
        case "2":
          index = 2;
          break;
        case "3":
          index = 3;
          break;
      }

      return index;
    },

    approvalUpdated() {
      this.$emit('approval_updated', this.current_agenda);
      this.store.setDialogApproval(false);
      this.getApprovalDocuments();
    },

    getApprovalDocuments() {
      const formData = new FormData();
      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "getApprovalDocuments");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      formData.append("approvalId", UserData.getApprovalId() ? UserData.getApprovalId() : this.current_approval.approvalId);

      axios.post(UserData.getBaseUrl(), formData).then((response) => {
        this.getApprovalComments();
        this.approvalDocs = response.data.documents;
        this.approvalSuppDocs = response.data.suppDocuments;
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    getApprovalComments() {
      const formData = new FormData();
      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "getApprovalComments");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      formData.append("approvalId", UserData.getApprovalId() ? UserData.getApprovalId() : this.store.getApprovalId);

      axios.post(UserData.getBaseUrl(), formData).then((response) => {
        this.getApprovalComment = response.data;
        this.approvalComments = this.getApprovalComment.comments;
        this.checkApprove = this.approvalComments.some(item => (this.user.userInfo.userFullName === item.approver && item.approvalActionType === "2" 
        || this.user.userInfo.userFullName === item.approver && item.approvalActionType === "3"));
        this.store.setDialogApproval(true);
        this.store.setClosePdf(false);
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    groupBy(arr, property) {
      return arr.reduce(function (memo, x) {
          if (!memo[x[property]]) { memo[x[property]] = []; }
          memo[x[property]].push(x);
          return memo;
      }, {});
    },

    replyComment(item) {
      const formData = new FormData();
      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "getApprovalsReplies");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      formData.append("approvalId", UserData.getApprovalId() ? UserData.getApprovalId() : this.current_approval.approvalId);
      formData.append("actionId", item.actionId);
      formData.append("from", 0);
      formData.append("count", 20);
      formData.append("isRefresh", false);

      axios.post(UserData.getBaseUrl(), formData).then((response) => {
        this.selected_comment = item;
        this.comment = item;
        this.selected_replies = response.data.commentList;
        this.store.setDialogReply(true);
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },
  },
};
</script>

<style scoped>
.table-striped a {
  text-decoration: none;
  color: #000;
}

.table-striped a:hover {
  color: rgb(71, 183, 83);
}

.table-striped a:active {
  color: rgb(71, 183, 83);
}

.table-striped:nth-child(even) {
  background-color: rgba(0, 0, 0, .05);
}

.table-striped:hover {
  background-color: rgba(255, 255, 255, 255) !important;
}

.approver-table:hover { 
  background-color: rgb(71, 183, 83) !important; 
}

.name {
  cursor: pointer !important;
}

.name:hover {
  color: #27ae60 !important;
}
</style>