<template>
  <div>
    <!-- ADD REPLY TO COMMENT MODAL -->
    <div class="text-center">
      <v-dialog 
        v-model="dialog_reply_comment"
        max-width="350px"
        scrollable
      >
        <v-row justify="center">
          <v-card
            style="padding: 3%; border-top: 5px solid rgb(71, 183, 83);"
          >
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="12" sm="12">
                    <v-toolbar color="rgb(255, 255, 255)">
                      <span class="text-ellipsis font-weight-bolder">
                        Reply to: {{ selected_comment['comment'] || selected_comment['approverComment'] }}
                      </span>
                    </v-toolbar>
                  </v-col>

                  <v-col cols="12" md="12" sm="12">
                    <v-textarea
                      type="text"
                      v-model="reply"
                      label="Comments"
                      variant="outlined"
                      :rules="rules.textRules"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="primary"
                  @click="closeDialogReplyComment"
                  variant="flat"
                  elevation="1"
                >
                  Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="warning"
                  @click="addReplyToComment();"
                  style="float: right"
                  variant="flat"
                  elevation="1"
                  :loading="store.loading"
                >
                  Add
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-row>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import UserData from "../repository/UserData";
import { useAuthStore } from "../../stores/auth";
import { useIndexStore } from "../../stores/index";
import { useAlertStore } from "../../stores/alert";

export default {
  name: "ReplyComment",
  props: {
    comment: {
      type: Object,
      default: () => {},
    },
    
    selected: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    valid: false,
    reply: "",
    show: false,
    current_comment: "",
    opened: [],
    rules: {
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
      selectRules: [(v) => !!v || "Select is required"],
      textRules: [
        v => !!v || 'Field is required',
        v => (v && v.length >= 3) || 'Min 3 characters',
      ],
      characterRules: [
        v => (v && v.length >= 3) || 'Min 3 characters',
      ],
    },
    token: localStorage.getItem("token") ? JSON.stringify(localStorage.getItem("token")).slice(1, -1) : "",
    auth: useAuthStore(),
    store: useIndexStore(),
    alert: useAlertStore(),
  }),

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },

    dialog_reply_comment: {
      get() {
        return this.store.getDialogReplyComment;
      },
      set(value) {
        this.store.setDialogReplyComment(value);
      }
    },

    selected_comment: {
      get() {
        return this.store.getSelectedComment;
      },
      set(value) {
        this.store.setSelectedComment(value);
      },
    },
  },
  
  methods: {
    validate() {
      return this.$refs.form.validate();
    },

    resetForm() {
      return this.$refs.form.reset();
    },

    resetValidation() {
      return this.$refs.form.resetValidation();
    },

    closeDialogReplyComment() {
      this.dialog_reply_comment = false;
      this.reset();
    },

    reset() {
      this.reply = "";
      document.querySelector('html').classList.remove('v-overlay-scroll-blocked');
    },

    addReplyToComment() {
      this.validate().then((validator_response) => {
        this.valid = validator_response.valid;
        if (this.valid) {
          const formData = new FormData();
          formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
          formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
          formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
          formData.append("model", "addApprovalsReplies");
          formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
          formData.append("approvalId", UserData.getApprovalId() ? UserData.getApprovalId() : this.store.getApprovalId);
          formData.append("comment", this.reply);
          formData.append("actionId", this.comment.actionId);

          if (this.selected_comment.parentId) {
            formData.append("replyId", this.selected_comment.commentId);
          }

          this.store.setLoading(true);
          axios.post(UserData.getBaseUrl(), formData).then((response) => {
            this.$emit('add_comment', this.selected);
            this.dialog_reply_comment = false;
            this.store.setLoading(false);
            document.querySelector('html').classList.remove('v-overlay-scroll-blocked');
            this.alert.showSuccess(response.data.message);
            this.reset();
          })
          .catch((e) => {
            console.log("Error", e);
            this.store.setLoading(false);
          });
        }
      });
    },
  },
};
</script>