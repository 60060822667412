<template>
  <Line
    :chart-options="options"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
    :options="options"
  />
</template>
    
<script>
import { Line } from "vue-chartjs";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
ChartJS.register( CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend );

export default {
  name: "LineChart",
  components: { 
    // eslint-disable-next-line
    Line 
  },

  props: {
    chartId: {
      type: String,
      default: "LineChart",
    },

    datasetIdKey: {
      type: String,
      default: "label",
    },

    chartData: {
      type: Object,
      default: () => {},
    },
    
    width: {
      type: Number,
      default: 400,
    },

    height: {
      type: Number,
      default: 400,
    },

    cssClasses: {
      default: "",
      type: String,
    },

    styles: {
      type: Object,
      default: () => {},
    },

    plugins: {
      type: Object,
      default: () => {},
    },
    
    options: {
      type: Object,
      default: () => {},
    },
  },

  data: (vm) => ({
    chartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      onClick(event, clickedElements) {
        if (clickedElements.length > 0) {
          vm.$emit("line_click", clickedElements[0].index);
        }
      },
      onHover: (event, chartElement) => {
        event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
      },
    },
  }),
};
</script>