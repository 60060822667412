<template>
  <div class="text-left">
    <v-menu
      v-model="menu"
      attach="menu-button"
      location="bottom"
      @click="closeDocumentList"
      transition="slide-x-transition"
      :width="listWidth"
    >
      <v-list 
        height="100vh"
        item-value="itemId"
      >
        <v-toolbar class="bg-white">
          <v-toolbar-title>
            <v-list-item-title>
              <span class="text-error">View Documents</span>
            </v-list-item-title>
          </v-toolbar-title>
          <v-toolbar-items>
            <v-btn
              class="btn"
              color="error lighten-1"
              @click="closeDocumentList"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list-item-group
          v-model="selected"
          multiple
          active-color="red"
        >
          <div 
            v-for="(item, index) in documentList.filter(item => item.itemType == 2)"
            :key="index"
          >
            <v-list-item
              :value="item.itemId"
              :onmouseover="globalMethods.getListColor(listColor)"
              onmouseout="this.style.color='#000000'"
              @click="switchDocument(item)"
              :class="{ 'bg-grey-lighten-2' : selected.includes(item.itemId) }"
            >
              <v-list-item-title class="text-wrap">
                {{ index + 1 + "." }}
                <span class="input-group-addon">
                  <v-icon
                    :color="fileIds.includes(item.itemId) ? briefcaseColor : listColor"
                    size="large"
                    :icon="globalMethods.getDocumentIcon(item.itemExtension)"
                  />
                </span>
                <file-options
                  :file="item"
                  :file_color="listColor"
                  @open_pdf="switchDocument(item)"
                  v-if="item.itemExtension == 'pdf'"
                  :select_type="parent_select_type"
                  :pdf_type="parent_pdf_type"
                ></file-options>
                <span 
                  v-else
                  @click="switchDocument(item)"
                >
                  {{ item.itemName }}
                </span>
              </v-list-item-title>
            </v-list-item>
            <v-divider
              v-if="index < documentList.length - 1"
              :key="index"
              class="my-0"
            ></v-divider>
          </div>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { useIndexStore } from '../../stores';
import GlobalMethods from '../repository/GlobalMethods';
import FileOptions from './FileOptions.vue';

export default {
  name: "DocumentList",

  props: {
    documentList: {
      type: Array,
      default: () => [],
    },

    listColor: {
      type: String,
      default: "#e33333",
    },

    briefcaseColor: {
      type: String,
      default: "#e33333",
    },

    listMenu: {
      type: Boolean,
      default: false,
    },

    listWidth: {
      type: String,
      default: '50%',
    },

    parent_select_type: {
      type: Boolean,
      default: false,
    },

    parent_pdf_type: {
      type: Number,
      default: null,
    },

    fileIds: {
      type: Array,
      default: () => [],
    },
  },

  components: {
    FileOptions,
  },

  data: () => ({
    menu: false,
    selected: [],
    globalMethods: GlobalMethods,
    store: useIndexStore(),
  }),

  mounted() {
    this.menu = this.listMenu;
    this.selected.splice(0, 1);
    this.selected.push(this.store.getDocumentId);
  },

  watch: {
    listMenu() {
      this.menu = this.listMenu;
    },
  },

  methods: {
    switchDocument(document) {
      this.$emit("open_document", document);
      this.selected.splice(0, 1);
      this.selected.push(document.itemId);
      this.closeDocumentList();
    },

    closeDocumentList() {
      this.menu = false;
      this.$emit("close_list");
    },
  },
}
</script>

<style scoped>
.selectedItem {
  background-color: blue !important;
}
</style>