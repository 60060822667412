<template>
  <div>
    <!-- SHARED ANNOTATIONS MODAL -->
    <div class="text-center">
      <v-card style="background-color: #f5f5f5; width: 100%;">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <div>
            <v-card
              class="mx-auto elevation-0"
              v-if="annotations_list.length > 0"
            >
              <v-table
                fixed-header
                fixed-footer
                class="text-left" 
                height="100vh"
              >
                <tbody
                  v-for="(item, index) in annotations_list" 
                  :key="index"
                  class="table-striped"
                >
                  <tr
                    style="height: 100%; cursor: pointer;"
                    @click="toggle(index, item)"
                  >
                    <td
                      style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                    >
                      <v-list class="bg-transparent">
                        <v-list-item
                          :value="item.topicId"
                        >
                          <template 
                            v-slot:append="{ isActive }"
                            v-if="tab == 'to' && opened.includes(index)"
                          >
                            <v-list-item-action>
                              <v-btn 
                                :model-value="isActive"
                                icon="mdi-delete-outline"
                                :value="item.topicId"
                                @click="deleteTopic(item.topicId)"
                                class="bg-transparent elevation-0 text-error"
                              />
                            </v-list-item-action>
                          </template>

                          <v-list-item-title>
                            <span class="text-ellipsis font-weight-bold float-left">
                              {{ item.topic }} 
                            </span>
                          </v-list-item-title>

                          <v-list-item-subtitle>
                            <span class="text-ellipsis float-left">
                              {{ item.creatorName }} - {{ formatDateAndTime(item.createdDate) }}
                            </span>
                          </v-list-item-subtitle>
                        </v-list-item>
                      </v-list>
                    </td>
                  </tr>
                  <tr v-if="tab == 'to' && opened.includes(index)">
                    <td
                      v-if="item.userList && item.userList.length > 0"
                      style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; border: 1px solid grey;"
                    >
                      <v-list>
                        <v-list-item
                          v-for="(user, index) in item.userList"
                          :key="index"
                          :value="user"
                        >
                          <v-list-item-title>
                            <span class="text-ellipsis font-weight-bold float-left">
                              {{ user }}
                            </span>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list> 
                    </td>

                    <td 
                      class="text-center"
                      style="border: 1px solid #000000;"
                      v-else
                    >
                      No users found for this topic
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </v-card>

            <v-card
              class="mx-auto elevation-0"
              v-else
            >
              <v-table 
                fixed-header
                fixed-footer
                class="text-left" 
                height="100vh"
              >
                <tbody>
                  <tr>
                    <td class="text-center">
                      <span>
                        No Shared Annotations Found
                      </span>
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </v-card>
          </div>
        </v-form>
      </v-card>
    </div>
  </div>
</template>

<script>
var moment = require("moment");
import axios from "axios";
import UserData from "../repository/UserData";
import authHeader from "../repository/auth-header";
import { useAuthStore } from "../../stores/auth";
import { useIndexStore } from "../../stores/index";
import { useAlertStore } from "../../stores/alert";

export default {
  name: "SharedAnnotations",
  props: {
    users: {
      type: Array,
      default: () => [],
    },

    annotations_list: {
      type: Array,
      default: () => [],
    },

    shared_annotations: {
      type: Boolean,
      default: false,
    },

    annotations: {
      type: Object,
      default: () => {},
    },

    tab: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    valid: false,
    dialog: false,
    opened: [],
    selected: [],
    selected_topics: [],
    interestDisclosed: "",
    interestConflict: "",
    hasConflict: false,
    confirm: false,
    loading: false,
    comments: "",
    conditions: "",
    password: "",
    token: localStorage.getItem("token") ? JSON.stringify(localStorage.getItem("token")).slice(1, -1) : "",
    auth: useAuthStore(),
    store: useIndexStore(),
    alert: useAlertStore(),
    annotationId: null,
  }),

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },
    
    dialog_users() {
      return this.store.getDialogUsers;
    },

    rules() {
      return [
        this.selected.length > 0 || "At least one item should be selected"
      ];
    },

    checked() {
      return this.alert.getChecked;
    },

    trigger: {
      get() {
        return this.store.getTrigger;
      },
      set(status) {
        this.store.setTrigger(status);
      },
    },

    from_annotation_ids: {
      get() {
        return this.store.getFromAnnotationIds;
      },
      set(ids) {
        this.store.setFromAnnotationIds(ids);
      },
    },

    selected_annotation: {
      get() {
        return this.store.getSelectedAnnotation;
      },
      set(value) {
        this.store.setSelectedAnnotation(value);
      },
    },
  },

  watch: {
    checked() {
      if (this.checked) {
        this.deleteTopicForDocument();
      }
    },
  },
  
  methods: {
    formatDate(date) {
      return moment.unix(date).format("DD MMM YYYY");
    },

    formatDateAndTime(date) {
      return moment.unix(date).format("DD MMM YYYY, hh:mm A");
    },

    async validate() {
      return this.$refs.form.validate();
    },

    resetForm() {
      return this.$refs.form.reset();
    },

    resetValidation() {
      return this.$refs.form.resetValidation();
    },

    closeDialogUsers() {
      this.store.setDialogUsers(false);
      document.querySelector('html').classList.remove('v-overlay-scroll-blocked');
    },

    deleteTopic(topicId) {
      this.alert.setChecked(null);
      this.alert.clearConfirm();
      this.annotationId = topicId;
      this.alert.showConfirm("Delete Topic", "Would you like to delete this topic?" + topicId, "Yes", "No");
    },

    deleteTopicForDocument() {
      this.validate().then((validator_response) => {
        this.valid = validator_response.valid;
        if (this.valid) {
          const form = new FormData();
          form.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
          form.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
          form.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
          form.append("model", "deleteTopicForDocument");
          form.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
          form.append("annotationId", this.annotationId);
          form.append("documentId", UserData.getDocumentId() ? UserData.getDocumentId() : this.store.getDocumentId);

          this.store.setLoading(true);
          axios.post((process.env.NODE_ENV == 'production' ? UserData.getProductionAdminBaseUrl() : UserData.getLocalAdminBaseUrl()) + '/delete-annotation', form, { headers: authHeader() }).then((response) => {
            if (response.status == 200) {
              this.$emit('update_list');
              this.alert.showSuccess("Topic Deleted Successfully");
              this.store.setLoading(false);
            } else {
              if (response.response.status == 403) {
                this.store.setLoading(false);
                this.$emit("session_timeout", true);
              }
            }
          })
          .catch((e) => {
            console.log("Error", e);
          });
        }
      });
    },

    toggle(i, annotation) {
      this.selected_annotation = annotation;
      const index = this.opened.indexOf(i);

      if (index > -1) {
        this.opened.splice(index, 1);
      } else {
        this.opened = [];
        this.opened.push(i);
      }
    },
  },
};
</script>

<style scoped>
.list-striped a {
  text-decoration: none;
  color: #000;
}

.list-striped a:hover {
  color: rgb(72, 61, 139);
}

.list-striped a:active {
  color: rgb(72, 61, 139);
}
</style>