<template>
  <div>
    <div v-if="store.getLoading">
      <v-progress-linear
        color="#27ae60"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
    </div>

    <div style="width: 100%;">
      <v-table 
        v-if="recentDocuments.recentDocumentsList && recentDocuments.recentDocumentsList.length > 0"
        height="250px"
        fixed-header
      >
        <thead>
          <tr>
            <th style="background-color: rgb(71, 183, 83); font-weight: bolder;" class="text-left text-white">No.</th>
            <th style="background-color: rgb(71, 183, 83); font-weight: bolder;" class="text-left text-white"></th>
            <th style="background-color: rgb(71, 183, 83); font-weight: bolder;" class="text-left text-white">Name</th>
            <th style="background-color: rgb(71, 183, 83); font-weight: bolder;" class="text-left text-white">Size</th>
            <th style="background-color: rgb(71, 183, 83); font-weight: bolder;" class="text-left text-white">Created On</th>
          </tr>
        </thead>
        <tbody
          v-for="(item, index) in recentDocuments.recentDocumentsList" 
          :key="index"
          class="table-striped"
        >
          <tr>
            <td style="overflow: hidden; text-overflow: ellipsis; padding-top: 20px; padding-bottom: 20px;">
              {{ index + 1 + "." }}
            </td>
            <td style="overflow: hidden; text-overflow: ellipsis;">
              <span class="input-group-addon">
                <v-progress-circular
                  v-if="loading[item.itemId]"
                  indeterminate
                  color="rgb(71, 183, 83)"
                ></v-progress-circular> 
                <v-icon
                  v-else
                  :color="fileIds.includes(item.itemId) ? 'rgb(227, 58, 58)' : 'rgb(71, 183, 83)'"
                  class="mr-2"
                  size="large"
                  :icon="globalMethods.getDocumentIcon(item.itemExtension)"
                />
              </span>
            </td>
            <td style="overflow: hidden; text-overflow: ellipsis;">
              <file-options
                :file="item"
                :file_color="'#27ae60'"
                v-if="item.itemExtension == 'pdf'"
                @open_pdf="getDocument(item)"
                @briefcase_pdf="briefcaseDocument(item)"
                :briefcase-files="fileIds"
                :select_type="true"
                :pdf_type="2"
              ></file-options>
              <span 
                v-else
                class="name"
                @click="getDocument(item)"
              >
                {{ item.itemName }}
              </span>
            </td>
            <td style="overflow: hidden; text-overflow: ellipsis;">
              {{ getPrettyBytes(item.itemSize) }}
            </td>
            <td style="overflow: hidden; text-overflow: ellipsis;">
              {{ formatDate(item.itemCreatedOn) }}
            </td>
          </tr>
        </tbody>
      </v-table>
      <v-table v-else>
        <thead>
          <tr>
            <th class="text-left text-white" style="background-color: rgb(71, 183, 83); font-weight: bolder;">No.</th>
            <th class="text-left text-white" style="background-color: rgb(71, 183, 83); font-weight: bolder;"></th>
            <th class="text-left text-white" style="background-color: rgb(71, 183, 83); font-weight: bolder;">Name</th>
            <th class="text-left text-white" style="background-color: rgb(71, 183, 83); font-weight: bolder;">Size</th>
            <th class="text-left text-white" style="background-color: rgb(71, 183, 83); font-weight: bolder;">Created On</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td 
              colspan="6"
              class="text-center"
            >
              <span>
                No Recent Documents Found
              </span>
            </td>
          </tr>
        </tbody>
      </v-table>
    </div>
  </div>
</template>

<script>
var moment = require("moment");
import prettyBytes from 'pretty-bytes';
import { useAuthStore } from "../../stores/auth";
import { useIndexStore } from "../../stores/index";
import FileOptions from '../Cards/FileOptions.vue';
import GlobalMethods from '../repository/GlobalMethods';

export default {
  components: { FileOptions },
  name: "MyInbox",

  props: {
    recentDocuments: {
      type: Array,
      default: () => [],
    },

    load: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    moment: moment,
    auth: useAuthStore(),
    store: useIndexStore(),
    globalMethods: GlobalMethods,
    loading: {},
    files: [],
    fileIds: [],
  }),

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },

    overlay_type: {
      get() {
        return this.store.getOverlayType;
      },
      set(value) {
        this.store.setOverlayType(value);
      },
    },

    session_overlay: {
      get() {
        return this.store.getSessionOverlay;
      },
      set(value) {
        this.store.setSessionOverlay(value);
      },
    },
  },

  watch: { 
    load() {
      // Trigger for MyInbox Component
      // Use to call any method inside MyInbox from Parent
    },
  },

  methods: {
    formatDate(date) {
      return moment.unix(date).format("DD-MMM-YYYY");
    },

    getPrettyBytes(value) {
      return prettyBytes(parseInt(value));
    },

    getDocument(item) {
      if (item.itemExtension == 'pdf') {
        this.briefcaseDocument(item);
      } else {
        this.openDocument(item);
      }
    },

    openPdf(item, url = null) {
      this.store.setBriefcaseUrl(url);
      this.$emit('open_pdf', item);
    },

    openDocument(item, url = null) {
      this.store.setBriefcaseUrl(url);
      this.$emit('open_document', item);
    },

    briefcaseDocument(item) {
      this.loading[item.itemId] = true;
      this.globalMethods.downloadEbrDocument(item.itemUrl).then(async (response) => {
        if (response.status == 200) {
          let fileType = response.headers['content-type'];
          let fileData = new Blob([response.data], { type: fileType });
          let blob = window.URL.createObjectURL(fileData);
          this.openPdf(item, blob);
          this.loading[item.itemId] = false;
        } else {
          this.loading[item.itemId] = false;
          console.log("Response: ", response);
        }
      }).catch((e) => {
        this.loading[item.itemId] = false;
        console.log("Error: ", e);
      });
    },
  },
}
</script>

<style scoped>
.name {
  cursor: pointer !important;
}

.name:hover {
  color: #27ae60 !important;
}
</style>
