import axios from "axios";
import UserData from './UserData';
import { useAuthStore } from '../../stores/auth';
import { useIndexStore } from '../../stores/index';
import { useAlertStore } from '../../stores/alert';
import encAtRest from "./encAtRest";
import indexedDatabase from "./database";
import Countdown from "./countdown";
import SlackNotificationChannel from "./SlackNotificationChannel";
import authHeader from "./auth-header";

const DB_NAME = "egovernance";
let DB_VERSION = localStorage.getItem('DB_VERSION');

let db = null;
let files = [];
let token = localStorage.getItem("token") ? JSON.stringify(localStorage.getItem("token")).slice(1, -1) : "";

class GlobalMethods {
  static reloadPage() {
    window.location.reload();
  }

  static getDocumentIcon(itemExtension) {
    let icon = '';

    switch (itemExtension) {
      case 'pdf':
        icon = 'mdi-file-pdf-box';
        break;
      case 'docx':
        icon = 'mdi-file-word-outline';
        break;
      case 'ppt':
        icon = 'mdi-file-powerpoint-outline';
        break;
      case 'pptx':
        icon = 'mdi-file-powerpoint-outline';
        break;
      case 'xlsx':
        icon = 'mdi-file-excel-outline';
        break;
      case 'png':
        icon = 'mdi-panorama-outline';
        break;
      default:
        icon = 'mdi-file-document-outline';
        break;
    }

    return icon;
  }

  static getDocumentExtension(itemExtension) {
    let extension = '';

    switch (itemExtension) {
      case 'pdf':
        extension = true;
        break;
      case 'docx':
        extension = true;
        break;
      case 'ppt':
        extension = true;
        break;
      case 'pptx':
        extension = true;
        break;
      case 'xlsx':
        extension = true;
        break;
      case 'No File Extension':
        extension = true;
        break;
      default:
        extension = false;
        break;
    }

    return extension;
  }

  static getListColor(color) {
    return "this.style.color='" + color + "'";
  }

  static downloadEbrDocument = async (documentUrl) => {
    return await new Promise((resolve, reject) => {
      axios.get(documentUrl, { responseType: 'blob' }).then((response) => {
        resolve(response);
      })
      .catch((e) => {
        reject("Error", e);
      });
    });
  }

  static getUser = () => {
    let loggedInUser = useAuthStore().getLoggedInUser;

    if (loggedInUser) {
      let formData = {
        userId: loggedInUser.userInfo?.userId,
        userName: loggedInUser.userInfo?.userName,
        companyId: loggedInUser.companyDetail?.companyId,
        companyCode: loggedInUser.companyDetail?.companyCode,
      };
  
      return formData;
    }
  }

  static getDb = async () => {
    return await new Promise((resolve, reject) => {
      if (!('indexedDB' in window)) {
        let message = "This browser doesn't support IndexedDB";
        SlackNotificationChannel.errorMessage(message, 'getDb(115) in GlobalMethods.js', this.getUser());
        return;
      }

      window.indexedDB.databases().then((response) => {
        let database = response.find(item => item.name == DB_NAME);
        if (typeof database !== 'undefined') {
          let version = database.version;
          localStorage.setItem('DB_VERSION', version);
        } else {
          localStorage.setItem('DB_VERSION', 1);
        }

        let wereStoresCreated = false;

        DB_VERSION = localStorage.getItem('DB_VERSION');
        let request = window.indexedDB.open(DB_NAME, DB_VERSION);

        request.onerror = e => {
          console.log('Error opening db', e);
          SlackNotificationChannel.errorMessage(e.message, 'getDb(135) in GlobalMethods.js', this.getUser());
          reject('Error');
        };

        request.onsuccess = e => {
          if (wereStoresCreated) indexedDatabase._populateStores(db, indexedDatabase.getSchema());
          resolve(e.target.result);
        };

        request.onupgradeneeded = e => {
          console.log('onupgradeneeded');
          db = e.target.result;
          wereStoresCreated = this.createObjectStores(db);
        };
      });
    });
  }

  static createObjectStores = async (db) => {
    let wereStoresCreated = false;

    if (!db.objectStoreNames.contains('KeyGen')) {
      let objectStore = db.createObjectStore("KeyGen", { keyPath: 'id' });
      console.log(objectStore);
      wereStoresCreated = true;
    }

    if (!db.objectStoreNames.contains('AppData')) {
      let objectStore = db.createObjectStore("AppData", { keyPath: 'id' });
      console.log(objectStore);
      wereStoresCreated = true;
    }

    return wereStoresCreated;
  }

  static getFilesFromDb = async (payloadDb) => {
    return await new Promise((resolve, reject) => {
      try {
        let trans = db != null ? db.transaction(['AppData'], 'readonly') : payloadDb.transaction(['AppData'], 'readonly');
        trans.oncomplete = e => {
          console.log('Transaction completed', e);
          resolve(files);
        };

        trans.onerror = e => {
          console.log('Error completing transaction', e);
          SlackNotificationChannel.errorMessage(e.message, 'getFilesFromDb(182) in GlobalMethods.js', this.getUser());
          reject('Error: ', e);
        };

        let store = trans.objectStore('AppData');

        files = [];
        store.openCursor().onsuccess = async (e) => {
          let cursor = e.target.result;
          if (cursor) {
            files.push({
              id: cursor.value.id,
              fileData: cursor.value.sensitiveData,
              fileType: cursor.value.sensitiveType,
            });

            cursor.continue();
          }
        };
      } catch (e) {
        if (e.message == "Failed to execute 'transaction' on 'IDBDatabase': One of the specified object stores was not found.") {
          indexedDatabase.deleteDatabase();
        }

        // Sometimes a derived key from a wrong password will cause Web Crypto to throw.
        SlackNotificationChannel.errorMessage(e.message, 'getFilesFromDb(207) in GlobalMethods.js', this.getUser());
        reject(e);
      }
    });
  }

  static getFileFromDb = async (itemId, payloadDb) => {
    return await new Promise((resolve, reject) => {
      let trans = db != null ? db.transaction(['AppData'], 'readonly') : payloadDb.transaction(['AppData'], 'readonly');
      trans.oncomplete = e => {
        console.log('Transaction completed', e);
        resolve(file);
      };

      trans.onerror = e => {
        console.log('Error completing transaction', e);
        SlackNotificationChannel.errorMessage(e.message, 'getFileFromDb(223) in GlobalMethods.js', this.getUser());
        reject('Error: ', e);
      };

      let store = trans.objectStore('AppData');
      let file = store.get(itemId);
    });
  }

  static addFile = async (id, data, type) => {
    return await new Promise((resolve, reject) => {
      if (encAtRest.getCredentialKey() == null) {
        console.log('Failed to save data due to unexpected error');
        reject({
          code: 500,
          message: 'Something went wrong'
        });
      } else {
        let file = {
          id: id,
          sensitiveData: data,
          sensitiveType: type,
        };

        console.log('About to add ' + JSON.stringify(file));
        this.addFileToDb(file);
        resolve();
      }
    });
  }

  static addFileToDb = async (file) => {
    return await new Promise((resolve) => {
      encAtRest.saveStoreToAppData(encAtRest.getCredentialKey(), file);
      resolve();
    });
  }

  static deleteFile = async (id, payloadDb) => {
    await this.deleteFileFromDb(id, payloadDb);
    files = await this.getFilesFromDb(payloadDb);
  }

  static deleteFileFromDb = async (id, payloadDb) => {
    return await new Promise((resolve, reject) => {
      let trans = db != null ? db.transaction(['AppData'], 'readwrite') : payloadDb.transaction(['AppData'], 'readwrite');
      trans.oncomplete = e => {
        console.log('Transaction completed', e);
        resolve();
      };

      trans.onerror = e => {
        console.log('Error completing transaction', e);
        SlackNotificationChannel.errorMessage(e.message, 'deleteFileFromDb(276) in GlobalMethods.js', this.getUser());
        reject('Error');
      };

      let store = trans.objectStore('AppData');
      store.delete(id);
    });
  }

  static deleteStore = async (id, payloadDb) => {
    await this.deleteStoreFromDb(id, payloadDb);
    files = await this.getFilesFromDb(payloadDb);
  }

  static deleteStoreFromDb = async (id, payloadDb) => {
    return await new Promise((resolve, reject) => {
      let trans = db != null ? db.transaction(['AppData'], 'readwrite') : payloadDb.transaction(['AppData'], 'readwrite');
      trans.oncomplete = e => {
        console.log('Transaction completed', e);
        resolve();
      };

      trans.onerror = e => {
        console.log('Error completing transaction', e);
        reject('Error');
      };

      let store = trans.objectStore(id);
      store.clear();
    });
  }

  static getPlanMilestones = async (planId) => {
    const auth = useAuthStore();

    const formData = new FormData();
    formData.append("userId", UserData.getUserId() ? UserData.getUserId() : auth.loggedInUser?.userInfo?.userId);
    formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : auth.loggedInUser?.companyDetail?.companyCode);
    formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : token);
    formData.append("model", "getPlanMilestoneList");
    formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : auth.loggedInUser?.companyDetail?.companyId);
    formData.append("planId", planId);

    return await new Promise((resolve, reject) => {
      axios.post(UserData.getBaseUrl(), formData).then((response) => {
        resolve(response);
      })
      .catch((e) => {
        reject("Error", e);
      });
    });
  }

  static loginAsAdmin = async (userPassword = null) => {
    const auth = useAuthStore();
    const formData = new FormData();
    
    formData.append("username", auth.loggedInUser.userInfo.userName);
    formData.append("password", UserData.getUserPassword() ? UserData.getUserPassword() : userPassword);
    formData.append("code", UserData.getCompanyCode() ? UserData.getCompanyCode() : auth.loggedInUser.companyDetail.companyCode);

    let admin_url = process.env.NODE_ENV == 'production' ? UserData.getProductionAdminBaseUrl() : UserData.getLocalAdminBaseUrl();

    return await new Promise((resolve, reject) => {
      axios.post(admin_url + '/auth/login', formData).then((response) => {
        if (response.data && response.data.code == 200) {
          resolve(response.data);
        } else {
          resolve(response);
        }
      })
      .catch(function (err) {
        reject(err);
      });
    });
  }

  static checkAdmin = async (userId = null) => {
    let admin_url = process.env.NODE_ENV == 'production' ? UserData.getProductionAdminBaseUrl() : UserData.getLocalAdminBaseUrl();

    return await new Promise((resolve, reject) => {
      axios.get(admin_url + '/auth/check-admin/' + userId, { headers: authHeader() }).then((response) => {
        if (response.data && response.data.code == 200) {
          resolve(response.data);
        } else {
          resolve(response);
        }
      })
      .catch(function (err) {
        reject(err);
      });
    });
  }

  static renewTokenLease = async (userPassword = null) => {
    const auth = useAuthStore();
    const formData = new FormData();

    formData.append("userId", UserData.getUserId() ? UserData.getUserId() : auth.loggedInUser?.userInfo?.userId);
    formData.append("userName", UserData.getUserName() ? UserData.getUserName() : auth.loggedInUser?.userInfo?.userName);
    formData.append("password", UserData.getUserPassword() ? UserData.getUserPassword() : userPassword);
    formData.append("code", UserData.getCompanyCode() ? UserData.getCompanyCode() : auth.loggedInUser?.companyDetail?.companyCode);

    let admin_url = process.env.NODE_ENV == 'production' ? UserData.getProductionAdminBaseUrl() : UserData.getLocalAdminBaseUrl();

    return await new Promise((resolve, reject) => {
      axios.post(admin_url + '/auth/renew-lease', formData, { headers: authHeader() }).then((response) => {
        if (response.data && response.data.code == 200) {
          resolve(response.data);
        } else {
          resolve(response);
        }
      })
      .catch(function (err) {
        reject(err);
      });
    });
  }

  static getCompanySessionTimeout = async (route = null) => {
    const auth = useAuthStore();
    const store = useIndexStore();

    let access_token = localStorage.getItem('admin_access_token');
    let refresh_token = localStorage.getItem('admin_refresh_token');

    if (route != '' && route != 'open-meeting') {
      const form = new FormData();
      form.append("userId", UserData.getUserId() ? UserData.getUserId() : auth.loggedInUser?.userInfo?.userId);
      form.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : auth.loggedInUser?.companyDetail?.companyCode);
      form.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : token);
      form.append("model", "getCompanySessionTimeout");
      form.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : auth.loggedInUser?.companyDetail?.companyId);
      
      return await new Promise((resolve, reject) => {
        if ((access_token != 'undefined') && (refresh_token != 'undefined')) {
          auth.setResetSession(true);
          axios.post((process.env.NODE_ENV == 'production' ? UserData.getProductionAdminBaseUrl() : UserData.getLocalAdminBaseUrl()) + '/session-timeout', form, { headers: authHeader() }).then((response) => {
            if (response.status == 200) {
              auth.setResetSession(false);
  
              if (response.data.data && response.data.data.length > 0) {
                localStorage.setItem('admin_session_expiry', response.data.data.token_expiry);
                localStorage.setItem('admin_session_timeout', response.data.data.token_expiry_Tminus);
              }
              
              resolve(response.data);
            } else {
              if (response.response.status == 403) {
                store.setSessionOverlay(true);
                reject(response);
              }
            }
          })
          .catch((e) => {
            console.log("Error", e);
            reject();
          });
        }
      });
    }
  }

  static setCompanySessionTimeout = async (token = null) => {
    localStorage.setItem('admin_access_token', token);

    const auth = useAuthStore();
    const store = useIndexStore();
    const alert = useAlertStore();

    const form = new FormData();
    form.append("userId", UserData.getUserId() ? UserData.getUserId() : auth.loggedInUser?.userInfo?.userId);
    form.append("userName", UserData.getUserName() ? UserData.getUserName() : auth.loggedInUser?.userInfo?.userName);
    form.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : auth.loggedInUser?.companyDetail?.companyCode);
    form.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : token);
    form.append("model", "setCompanySessionTimeout");
    form.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : auth.loggedInUser?.companyDetail?.companyId);
    
    return await new Promise((resolve, reject) => {
      if (token != null) {
        auth.setResetSession(true);
        axios.post((process.env.NODE_ENV == 'production' ? UserData.getProductionAdminBaseUrl() : UserData.getLocalAdminBaseUrl()) + '/set-session-timeout', form, { headers: authHeader() }).then((response) => {
          if (response.status == 200) {
            auth.setResetSession(false);
            
            if (response.data.data && Object.keys(response.data.data).length > 0) {
              localStorage.setItem('admin_session_expiry', response.data.data.token_expiry);
              localStorage.setItem('admin_session_timeout', response.data.data.token_expiry_Tminus);
              localStorage.setItem('admin_refresh_token', response.data.data.refresh_token);
              resolve(response.data);
            }
          } else {
            if (response.response.status == 403) {
              store.setSessionOverlay(true);
              reject(response);
            }

            if (response?.response?.status == 401) {
              let errorMessage = response?.response?.data?.message;
              alert.showError(errorMessage ?? 'Unauthorized!');
            }

            reject(response);
          }
        })
        .catch((e) => {
          console.log("Error", e);
          reject(e);
        });
      }
    });
  }

  static format = (t) => {
    return t < 10 ? '0' + t : t;
  }

  static getAdminSessionExpiry = () => {
    const currentExpiry = localStorage.getItem('admin_session_expiry');
    return new Date(currentExpiry);
  }

  static getAdminSessionTimeout = () => {
    const now = new Date();
    const sessionTimeout = localStorage.getItem('admin_session_timeout') ? localStorage.getItem('admin_session_timeout') : 100;
    const newExpiry = new Date().setTime(now.getTime() + (sessionTimeout * 60 * 1000));
    return new Date(newExpiry);
  }

  static render = (time) => {
    if (typeof time !== 'undefined') {
      Countdown.setSessionTimeoutCountdown(time, this.format);
    }
  }

  static complete = () => {
    // restart the countdown
    Countdown.setExpiredDate(this.getAdminSessionTimeout());
  }
}

export default GlobalMethods;