<template>
  <div>
    <!-- RCI MODAL -->
    <div class="text-center">
      <v-dialog v-model="dialog_rci" max-width="500px" scrollable>
        <v-row justify="center">
          <v-card
            style="padding: 3%"
          >
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-card-title>
                {{ formTitle }}
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="12" sm="12">
                    <v-textarea
                      type="text"
                      v-model="interestDisclosed"
                      placeholder="Interest disclosed"
                      variant="outlined"
                      :rules="rules.textRules"
                      clearable
                      rows="3"
                    />
                  </v-col>

                  <v-col cols="12" md="12" sm="12">
                    <v-textarea
                      type="text"
                      v-model="interestConflict"
                      placeholder="Nature of potential conflict and estimated value if known"
                      variant="outlined"
                      :rules="rules.textRules"
                      clearable
                      rows="3"
                    />
                  </v-col>

                  <v-col cols="12" md="12" sm="12">
                    <v-radio-group 
                      v-model="confirm"
                      :rules="rules.selectRules"
                    >
                      <v-radio value="true">
                        <template v-slot:label>
                          <span class="pt-2 text-wrap">Confirm accuracy of information</span>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>

                  <v-col cols="12" md="12" sm="12" v-if="rci_type == 2">
                    <v-row>
                      <v-col cols="8" md="8" sm="8">
                        <p class="text-body-1 pt-3">Do you have a conflict of interest?</p>
                      </v-col>

                      <v-col cols="4" md="4" sm="4">
                        <v-switch
                          v-model="hasConflict"
                          hide-details
                          inset
                          color="success"
                          class="float-right"
                        >
                          <template v-slot:label>
                            <span class="pt-2">{{ hasConflict ? 'Yes' : 'No' }}</span>
                          </template>
                        </v-switch>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="error"
                  @click="
                    store.setDialogRCI(false);
                    reset();
                  "
                  variant="flat"
                  elevation="1"
                >
                  Close
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="success"
                  @click="rci_type == 1 ? registerOfInterestNew() : registerOfInterest()"
                  style="float: right"
                  variant="flat"
                  elevation="1"
                  :loading="store.loading"
                >
                  Submit
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-row>
      </v-dialog>
    </div>
  </div>
</template>

<script>
var moment = require("moment");
import axios from "axios";
import UserData from "../repository/UserData";
import { useAuthStore } from "../../stores/auth";
import { useIndexStore } from "../../stores/index";
import { useAlertStore } from "../../stores/alert";

export default {
  name: "RCI",
  props: {
    type: {
      type: Number,
      default: 0,
    },
    meeting: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    valid: false,
    dialog: false,
    interestDisclosed: "",
    interestConflict: "",
    hasConflict: false,
    confirm: false,
    comments: "",
    conditions: "",
    password: "",
    isApproval: false,
    rules: {
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
      selectRules: [(v) => !!v || "Select is required"],
      textRules: [
        v => !!v || 'Field is required',
        v => (v && v.length >= 3) || 'Min 3 characters',
      ],
      characterRules: [
        v => (v && v.length >= 3) || 'Min 3 characters',
      ],
    },
    token: localStorage.getItem("token") ? JSON.stringify(localStorage.getItem("token")).slice(1, -1) : "",
    auth: useAuthStore(),
    store: useIndexStore(),
    alert: useAlertStore(),
  }),

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },

    formTitle() {
      return this.rci_type == 1 ? 'Register Conflict of Interest for Meeting' : 'Register Conflict of Interest for Approval';
    },
      
    dialog_rci() {
      return this.store.getDialogRCI;
    },

    rci_type() {
      return this.store.getRCIType;
    },

    current_approval() {
      return this.store.getCurrentApproval;
    },

    current_meeting() {
      return this.store.getCurrentMeeting;
    },
  },
  
  methods: {
    formatDate(date) {
      return moment.unix(date).format("DD MMM YYYY");
    },

    validate() {
      return this.$refs.form.validate();
    },

    resetForm() {
      return this.$refs.form.reset();
    },

    resetValidation() {
      return this.$refs.form.resetValidation();
    },

    reset() {
      this.comments = "";
      this.conditions = "";
      this.password = "";
      this.interestDisclosed = "";
      this.interestConflict = "";
      this.confirm = false;
      this.hasConflict = false;
      document.querySelector('html').classList.remove('v-overlay-scroll-blocked');
    },

    registerOfInterest() {
      this.validate().then((validator_response) => {
        this.valid = validator_response.valid;
        if (this.valid) {
          let approvalId = null;
          this.isApproval = this.rci_type == 2 ? true : false;
          if (this.isApproval) {
            approvalId = UserData.getApprovalId() ? UserData.getApprovalId() : this.current_approval.approvalId;
          }

          const formData = new FormData();
          formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
          formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
          formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
          formData.append("model", "registerOfInterestApproval");
          formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
          formData.append("approvalId", approvalId);
          formData.append("isApproval", this.isApproval);
          formData.append("interestDisclosed", this.interestDisclosed);
          formData.append("hasConflict", this.hasConflict);
          formData.append("interestConflict", this.interestConflict);
          
          this.store.setLoading(true);
          axios.post(UserData.getBaseUrl(), formData).then((response) => {
            this.alert.showSuccess(response.data.message);
            this.reset();
            this.store.setDialogRCI(false);
            this.store.setLoading(false);
            if (this.isApproval) {
              this.$emit('approval_updated');
            }
          })
          .catch((e) => {
            console.log("Error", e);
            this.store.setLoading(false);
          });
        }
      });
    },

    registerOfInterestNew() {
      this.validate().then((validator_response) => {
        this.valid = validator_response.valid;
        if (this.valid) {
          let meetingId = null;
          this.isMeeting = this.rci_type == 1 ? true : false;
          if (this.isMeeting) {
            meetingId = this.current_meeting.itemId;
          }

          const formData = new FormData();
          formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
          formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
          formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
          formData.append("model", "registerOfInterestNew");
          formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
          formData.append("meetingId", meetingId);
          formData.append("isMeeting", this.isMeeting);
          formData.append("interestDisclosed", this.interestDisclosed);
          formData.append("hasConflict", this.hasConflict);
          formData.append("interestConflict", this.interestConflict);
          formData.append("userName", UserData.getUserName() ? UserData.getUserName() : this.user.userInfo.userName);
          
          this.store.setLoading(true);
          axios.post(UserData.getBaseUrl(), formData).then((response) => {
            this.alert.showSuccess(response.data.message);
            this.reset();
            this.store.setDialogRCI(false);
            this.store.setLoading(false);
            if (this.isApproval) {
              this.$emit('approval_updated');
            }
          })
          .catch((e) => {
            console.log("Error", e);
            this.store.setLoading(false);
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.list-striped a {
  text-decoration: none;
  color: #000;
}

.list-striped a:hover {
  color: rgb(72, 61, 139);
}

.list-striped a:active {
  color: rgb(72, 61, 139);
}
</style>