<template>
  <div>
    <v-app>
      <!-- NAV-MENU -->
      <div
      class="nav-menu"
      style="height: 45px; width: 100%; padding: 0px; padding-top: 0.3%; text-align: center;"
    >
      <div class="left-buttons">
        <router-link to="/dashboard" style="text-decoration: none">
          <v-icon color="#fff" size="35" style="margin-left: 10px"
            >mdi-home</v-icon
          >
        </router-link>
      </div>
      <div class="filler"></div>
      <p style="font-weight: bold; font-size: 19px;">
        ELECTIONS
      </p>
      <div class="filler"></div>
      <div class="right-buttons">
        <v-icon 
          color="#ffffff" 
          class="mr-5"
          @click="reloadPage"
          icon="mdi-cached"
          size="35"
          style="cursor: pointer;"
        />
      </div>
    </div>

      <!-- COMPONENT -->
      <div class="component">
  
        <div>
          <v-card>
            <div class="d-flex flex-column">
           
                 <v-layout column style="height: 90vh;">       
                 <v-flex md12 style="overflow: unset"> 
                  <div v-if="store.getLoading">
                    <v-progress-linear
                      color="rgb(246, 203, 243)"
                      indeterminate
                      rounded
                      height="6"
                    ></v-progress-linear>
                  </div>
                  
                  <v-table fixed-header height="70vh" style="width:99vw;" >
                    <thead>
                      <tr
                        style="background-color: rgb(246, 203, 243); color: #fff; font-weight: bolder;"
                      >
                        <th
                          style="background-color: rgb(246, 203, 243); color: #fff; font-weight: bolder;"
                          class="text-left"
                        >
                          Position
                        </th>
                        <th
                          style="background-color: rgb(246, 203, 243); color: #fff; font-weight: bolder;"
                          class="text-left"
                        >
                          From
                        </th>
                        <th
                          style="background-color: rgb(246, 203, 243); color: #fff; font-weight: bolder;"
                          class="text-left"
                        >
                          To
                        </th>
                        <th
                          style="background-color: rgb(246, 203, 243); color: #fff; align-content: flex-end; font-weight: bolder;"
                          class="text-left"
                        >
                        Status
                        </th>
                      </tr>
                    </thead>
                    <tbody
                    v-for="item in elections"
                    :key="item.position"
                    class="table-striped"
                    @click="getSingleElection()"
                    >
                    <!-- <router-link to="/election"> -->
                    <tr style="height: 100%">
                        <td
                          style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; padding-top: 20px; padding-bottom: 20px;"
                        >
                          {{ item.position }}
                        </td>
                        <td
                          style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                        >
                          {{ item.from }}
                        </td>
                        <td
                          style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                        >
                          {{ item.to }}
                        </td>
                        <td
                          style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;align-self: flex-end;"
                        >
                        {{item.status}}
                        </td>
                      </tr>
                    <!-- </router-link> -->
                    </tbody>
                  </v-table>
                </v-flex>
                </v-layout>
                
            </div>
          </v-card>
        </div>
      </div>
    </v-app>
  </div>
</template>

<script>
import axios from "axios";
import UserData from "../../components/repository/UserData";
import { useIndexStore } from "../../stores/index";
import { useAuthStore } from "../../stores/auth";
import { useAlertStore } from "../../stores/alert";

export default {
  data() {
    return {
      sort: {
        field: "",
        desc: true,
        category: null
      },
      toggle_exclusive: undefined,
      tab: "option-1",
      bodyName: [],
      initialPlanList: [],
      planList:[],
      chartData: {},
      elections: [
          {
            position: 'ADC_Training',
            from: '04 Apr 2019',
            to: '14 Apr 2019',
            status: 'Stopped',
          },
          {
            position: 'claire test',
            from: '17 June 2019',
            to: '27 June 2019',
            status: 'Under Way',
          },
          {
            position: 'final test',
            from: '18 July 2019',
            to: '22 July 2019',
            status: 'Pending',
          },
          {
            position: 'ADC',
            from: '01 Aug 2019',
            to: '16 Aug 2019',
            status: 'Stopped',
          },
          {
            position: 'New Election',
            from: '04 Sept 2019',
            to: '24 Sept 2019',
            status: 'Pending',
          },
          {
            position: 'Certificate',
            from: '02 Oct 2019',
            to: '02 Nov 2019',
            status: 'Underway',
          },
        ],
      token: localStorage.getItem("token") ? JSON.stringify(localStorage.getItem("token")).slice(1, -1) : "", 
      auth: useAuthStore(),
      store: useIndexStore(),
      alert: useAlertStore(),

    };
  },
  components: { 
  },
  methods: {
    filterMeetings(category) {
      this.filterMeetingList('planCategory', category);
    },

    reloadPage() {
      window.location.reload();
    },

    filterMeetingList(field, category = null) {
      if (category != null) {
        this.sort.category = category;
        this.planList = this.initialPlanList.filter((plan) => plan.planCategory == category);
      } else {
        this.sort.category = null;
        this.planList = this.initialPlanList;
      }

      if (field == this.sort.field) {
        this.sort.desc = !this.sort.desc;
      } else {
        this.sort.field = field;
        this.sort.desc = true;
      }
    },

    getPlanList() {
      const formData = new FormData();
      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "getPlanList");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      formData.append("from", 0);
      formData.append("count", 1);

      this.store.setLoading(true);
      axios.post(UserData.getBaseUrl(), formData).then((response) => {
        this.getPlanList = response.data;
        this.planList = this.getPlanList.planList;
        this.initialPlanList = this.planList;
        this.getPlanListArray = this.planList[0];
        this.store.setLoading(false);

        this.planCode = this.planList.map(
          (item) => item.planCode
        );

        this.chartData.labels = [];
        this.planCode.forEach((label) => {
          this.chartData.labels.push(label);
        });

        this.chartData.datasets = [];
        this.chartData.datasets.push({
          label: 'Plans',
          data: this.planList.map((item) => item.percentage),
          borderColor: '#26acc2',
          borderWidth: 1,
          backgroundColor: '#26acc2',
          fill: false,
        });
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    getPlanMilestoneList(item) 
    {
      this.store.setCurrentPlan(item);
      this.$router.replace('/plan'); 
    },
    getSingleElection(){
      this.$router.replace('/election'); 
    },

    getPlanMilestoneAlert(){
      this.alert.showInfo("No Milestones Found");
    },
  },

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },
  },
  
  beforeMount() {
    this.getPlanList();
  },
};
</script>

<style scoped>
.nav-menu {
  margin-top: 0px;
  background-color: rgb(246, 203, 243);
  color: #fff;
  display: flex;
  margin-bottom: 1%;
  border: none;
  padding: 0px;
  margin-right: 15px;
  outline: none;
  justify-content: space-between;
}

.component {
  width: 99%;
  margin-left: 0.5%;
  margin-right: 0.5%;
  height: 100%;
}

.table-striped a {
  text-decoration: none;
  color: #000;
}
.table-striped{
cursor: pointer;
}

.table-striped a:hover {
  color: rgb(246, 203, 243);
}

.table-striped a:active {
  color: rgb(246, 203, 243);
}

.table-striped:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-striped:hover {
  background-color: rgba(255, 255, 255, 255) !important;
}

.vider {
  border-color: rgba(0, 0, 0, 0.12) !important;
}

</style>
