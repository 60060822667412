class UserData {

  static userFullName = "";
  static accessToken = "";

  constructor() {

  }

  static setAccessToken = (accessToken) => {
    UserData.accessToken = accessToken;
    localStorage.setItem('token', accessToken);
    localStorage.setItem('admin_access_token', accessToken);
  }

  static getAccessToken = () => {
    return UserData.accessToken;
  }

  static setUserName = (userName) => {
    UserData.userName = userName;
    localStorage.setItem('userName', userName);
  }

  static getUserName = () => {
    return UserData.userName;
  }

  static setUserPassword = (userPassword) => {
    UserData.userPassword = userPassword;
  }

  static getUserPassword = () => {
    return UserData.userPassword;
  }

  static setCompanyCode = (companyCode) => {
    UserData.companyCode = companyCode;
    localStorage.setItem('admin_company_code', companyCode);
  }

  static getCompanyCode = () => {
    return UserData.companyCode;
  }

  static setUserId = (userId) => {
    UserData.userId = userId;
    localStorage.setItem('userId', userId);
  }

  static getUserId = () => {
    return UserData.userId;
  }

  static setCompanyId = (companyId) => {
    UserData.companyId = companyId;
    localStorage.setItem('admin_company_id', companyId);
  }

  static getCompanyId = () => {
    return UserData.companyId;
  }

  static setCompanyName = (companyName) => {
    UserData.companyName = companyName;
  }

  static getCompanyName = () => {
    return UserData.companyName;
  }

  static setCompanyListId = (companyId) => {
    UserData.companyId = companyId;
  }

  static getCompanyListId = () => {
    return UserData.companyId;
  }

  static setDocumentId = (itemId) => {
    UserData.itemId = itemId;
  }

  static getDocumentId = () => {
    return UserData.itemId;
  }

  static setBodyId = (bodyId) => {
    UserData.bodyId = bodyId;
  }

  static getBodyId = () => {
    return UserData.bodyId;
  }

  static setCdsId = (cdsId) => {
    UserData.cdsId = cdsId;
  }

  static getCdsId = () => {
    return UserData.cdsId;
  }

  static setCdsBsId = (cdsBsid) => {
    UserData.cdsBsid = cdsBsid;
  }

  static getCdsBsId = () => {
    return UserData.cdsBsid;
  }

  static setApprovalId = (approvalId) => {
    UserData.approvalId = approvalId;
  }

  static getApprovalId = () => {
    return UserData.approvalId;
  }

  static setEvaluationId = (evaluationId) => {
    UserData.evaluationId = evaluationId;
  }

  static getEvaluationId = () => {
    return UserData.evaluationId;
  }

  static setEvaluateeId = (evaluateeId) => {
    UserData.evaluateeId = evaluateeId
  }

  static getEvaluateeId = () => {
    return UserData.evaluateeId;
  }

  static getUserData(itemId = 0, parentItemId = 0) {
    this.parentItemId = parentItemId;
    const formData = new FormData();
    formData.append('userId', "45");
    formData.append('companyCode', "010");
    formData.append('accessToken', "97f914eb1ceb1867e3824f647f7e589b");
    formData.append('model', "getMeetingPackFolder");
    formData.append('companyId', "2");
    formData.append('itemId', itemId);
    return formData;
  }

  static getBaseUrl = () => {                               
    // return "https://legacy.egovernance.io/api_v14/frontend/web/user/create" 
    // return "https://v3.egovernance.io/api_v14/frontend/web/user/create"
    // return "https://v3.egovernance.io/api_v19_6/frontend/web/user/create"
    // return "https://v3.egovernance.io/v21/user/create"
    return "https://v3.egovernance.io/user/create"
  }

  static getLocalAdminBaseUrl = () => {
    return "http://localhost/apis_zoom"
  }

  static getProductionAdminBaseUrl = () => {
    return "https://cb.egovernance.io"
  }

  static getOldBaseUrl = () => {
    return "https://v3.egovernance.io/api_v14/frontend/web/user/create"
  }

  static getLocalSaveAnnotationUrl = () => {
    return "http://localhost/apis_zoom/save-annotation"
  }

  static getProductionSaveAnnotationUrl = () => {
    return "https://cb.egovernance.io/save-annotation"
  }

  static getLocalAdminImportAnnotationUrl = () => {
    return "http://localhost/apis_zoom/import-annotation"
  }

  static getProductionAdminImportAnnotationUrl = () => {
    return "https://cb.egovernance.io/import-annotation"
  }

  static getProductionImportAnnotationUrl = () => {
    return "https://v3.egovernance.io/pspdfkit/importAnnotation.php"
  }

  static getLocalSignatureEndpoint = () => {
    return "http://localhost/apis_zoom/zoom/generate-signature"
  }

  static getProductionSignatureEndpoint = () => {
    return "https://cb.egovernance.io/zoom/generate-signature"
  }

  static getLocalZoomMeetings = () => {
    return "http://localhost/apis_zoom/meetings/zoom"
  }

  static getProductionZoomMeetings = () => {
    return "https://cb.egovernance.io/meetings/zoom"
  }

  static getLocalUserZakToken = () => {
    return "http://localhost/apis_zoom/zoom/zak-token"
  }

  static getProductionUserZakToken = () => {
    return "https://cb.egovernance.io/zoom/zak-token"
  }

  static getiOSAppUrl = () => {
    return "https://apps.apple.com/ke/app/blueion-egovernance/id994834805"
  }
}

export default UserData;