<template>
  <div>
    <!-- NAV-MENU -->
    <div
      class="nav-menu"
      style="height: 45px; width: 100%; padding: 0px; padding-top: 0.3%; text-align: center;"
    >
      <router-link to="/dashboard" style="text-decoration: none">
        <v-icon color="#fff" size="35" style="margin-left: 10px"
          >mdi-home</v-icon
        >
      </router-link>
      <v-spacer></v-spacer>
      <p style="font-weight: bold; font-size: 24px;">
        Links & News
      </p>
      <v-spacer></v-spacer>
      <v-icon 
        color="#ffffff" 
        class="mr-5"
        @click="getLinksAndNews"
        icon="mdi-cached"
        size="35"
      />
    </div>

    <!-- COMPONENT -->
    <div
      style="margin-top: 0.5%; margin-bottom: 0.5%; background-color: #f8f9f9; border-top: 5px solid #e33333; width: 98%; margin-left: 1%; margin-right: 1%;"
    >
      <div v-if="store.getLoading">
        <v-progress-linear
          color="#e33333"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </div>

      <div
        v-if="!closePDF"
        style="margin-top: 7px; position: absolute; background-color: #f5f5f5; width: 98%; height: 100vh; margin-right: 1%; z-index: 3;"
      >
        <v-table
          fixed-header
          height="100vh"
        >
          <thead>
            <tr>
              <th>
                <v-icon color="#e33333" icon="mdi-check-bold" />
                <span style="color: #e33333;">Links And News</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <div class="links">
              <v-list lines="two">
                <v-list-item-group
                  v-model="selected"
                  active-class="pink--text"
                  multiple
                >
                  <div v-if="links.length > 0">
                    <v-list-subheader class="bg-grey-lighten-2 font-weight-bold">
                      <span style="color: #e33333;">:Links</span>
                    </v-list-subheader>

                    <div
                      v-for="(link, index) in links"
                      :key="link.linkTitle"
                    >
                      <v-list-item
                        :value="link.linkTitle"
                        :href="link.linkUrl" 
                        target="_blank"
                        style="text-decoration: none;"
                      >
                        <v-list-item-title>{{ link.linkTitle }}</v-list-item-title>
                        <v-list-item-subtitle>{{ link.linkUrl }}</v-list-item-subtitle>
    
                        <template v-slot:append>
                          <span class="text-grey">{{ formatDateTime(link.linkDateTime) }}</span>
                        </template>
                      </v-list-item>
                      <v-divider
                        v-if="index < links.length - 1"
                        :key="index"
                        class="my-0"
                      ></v-divider>
                    </div>
                  </div>

                  <div v-if="news.length > 0">
                    <v-list-subheader class="bg-grey-lighten-2 font-weight-bold">
                      <span style="color: #e33333;">:News</span>
                    </v-list-subheader>

                    <div
                      v-for="(item, index) in news"
                      :key="item.newsTitle"
                    >
                      <v-list-item
                        :value="item.newsTitle"
                        style="text-decoration: none;"
                      > 
                        <v-list-item-title @click="openNewsDetails(item)">{{ item.newsTitle }}</v-list-item-title>
                        <v-list-item-subtitle @click="openNewsDetails(item)">
                          <tooltip
                            :data="item.newsDetail"
                            :location="'bottom'"
                            :styling="'bg-error font-weight-bold'"
                            :truncate="150"
                          />
                        </v-list-item-subtitle>
                        <v-list-item-action>
                          <span 
                            style="color: #e33333; cursor: pointer;" 
                            class="mr-4"
                            @click="openNewsImage(item)"
                          >
                            Image
                          </span>
                          <span 
                            style="color: #e33333; cursor: pointer;"
                            @click="openPdf(item)"
                          >
                            Document
                          </span>
                        </v-list-item-action>
    
                        <template v-slot:append>
                          <span class="text-grey">{{ formatDateTime(item.newsDateTime) }}</span>
                        </template>
                      </v-list-item>
                      <v-divider
                        v-if="index < news.length - 1"
                        :key="index"
                        class="my-0"
                      ></v-divider>
                    </div>
                  </div>
                </v-list-item-group>
              </v-list>
            </div>
          </tbody>
        </v-table>
      </div>

      <div class="text-center">
        <NewsDetails :title="newsTitle" :details="newsDetails" />
      </div>

      <div class="text-center">
        <NewsImage :title="newsTitle" :details="newsDetails" :image="newsImageUrl" />
      </div>

      <!-- PSPDFKIT MODAL -->
      <div
        v-if="closePDF"
        style="margin-top: 7px; position: absolute; background-color: #f5f5f5; width: 98%; height: 100vh; margin-right: 1%; z-index: 3;"
      >
        <v-card>
          <v-card-text>
            <v-main>
              <v-container fluid>
                <pspdfkit
                  :pdf-url="pdf"
                  :license-key="LICENSE_KEY"
                  :base-url="baseUrl"
                  @close_pdf="closePdf"
                />
              </v-container>
            </v-main>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <!-- END OF COMPONENT -->
  </div>
</template>

<script>
var moment = require("moment");
import axios from "axios";
import UserData from "../../components/repository/UserData";
import { useAuthStore } from "../../stores/auth";
import { useIndexStore } from "../../stores/index";
import Tooltip from '../../components/Cards/Tooltip.vue';
import NewsDetails from '../../components/Cards/NewsDetails.vue';
import NewsImage from "../../components/Cards/NewsImage.vue";
import PSPDFKit from '../../components/PSPDFKit.vue';

const LICENSE_KEY =
  "QvMr6j5706r3kYlOiVvJgEwu4fZnTL1fYz4t6paoBV5PekugaffzJnLtX5TczMS65OKAI1iIaSi06_l76UgF3kyKauJ9vaMZFuU3_oDplMFty6AhBhWPh80M7qoSa8xjtux3NqNrfqybICplLfHezwjhABIkPy6XxBMQ_uPU87_C5pMBXlc8A2PI_ShMjfhst6O_C0y9jyeMJ2DYudM3liFVsp90A0dOY51LM2Q3xV3jfcJ7K39FKpnKU_atpn1Ig8HXcmWdGQMjCXyquwVu9i2J850rGqBgFKJJFGixxnU3sZ__tA7Mb3aX8T2nXPlEnamtAhNf-DD1sEtqXHp5q5xxsFFuJDVh82tXKfd4oX8AkzWLd_-UVg_h_6ATML30e8fiQXSVcN0mmlZH5XYUv0jB5cHEyuIaGZac7gCNRn32pchJNpIwEdgHzWDRBQxxCR7F8b6z3Xs3yXJ6y9EhhrnKmjNYkMCdFJ0qNhI8zF8RhAbLA-uKtrzMy7hXLrlpuIHdGaCXMm7gA9iBpKiqwHIrX83rCFoVYUGM4BqNkim3HN8YJzZRDDI-ZHzdy7CcanSuvbe3NSxW5daeHdGVvg==";

export default {
  name: "LinksAndNews",

  components: {
    pspdfkit: PSPDFKit,
    tooltip: Tooltip,
    NewsDetails,
    NewsImage
},

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },
  },

  data() {
    return {
      moment: moment,
      selected: [],
      token: localStorage.getItem("token") ? JSON.stringify(localStorage.getItem("token")).slice(1, -1) : "",
      userPassword: localStorage.getItem("userPassword") ? JSON.stringify(localStorage.getItem("userPassword")).slice(1, -1) : "",
      store: useIndexStore(),
      auth: useAuthStore(),
      links: [],
      news: [],
      pdf: "example.pdf",
      LICENSE_KEY: LICENSE_KEY,
      baseUrl: "",
      closePDF: false,
      newsTitle: '',
      newsDetails: '',
      newsImageUrl: '',
    }
  },

  mounted() {
    this.getLinksAndNews();
  },

  methods: {
    formatDateTime(dateTime) {
      return moment.unix(dateTime).fromNow();
    },

    closePdf() {
      this.closePDF = !this.closePDF;
      this.store.setLoading(false);
    },

    openNewsDetails(item) {
      this.newsTitle = item.newsTitle;
      this.newsDetails = item.newsDetail;
      this.store.setDialogNews(true);
    },

    openNewsImage(item) {
      this.newsTitle = item.newsTitle;
      this.newsDetails = item.newsDetail;
      this.newsImageUrl = item.newsImageUrl;
      this.store.setDialogNewsImage(true);
    },

    getLinksAndNews() {
      const form = new FormData();
      form.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      form.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      form.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      form.append("model", "getLinksAndNews");
      form.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);

      this.store.setLoading(true);
      axios.post(UserData.getBaseUrl(), form).then((response) => {
        if (response.data.success == 1) {
          this.links = response.data.links;
          this.news = response.data.news;
          this.store.setLoading(false);
        }
      });
    },

    openPdf(item) {
      this.closePDF = !this.closePDF;
      this.pdf = item.newsDocumentUrl;
      return;
    },
  }
}
</script>

<style scoped>
body {
  margin: 0;
  padding: 0;
}

.nav-menu {
  margin-top: 0px;
  background-color: #e33333;
  color: #fff;
  display: flex;
}

.nav-menu .btn {
  background-color: #e33333;
  border: none;
  padding: 0px;
  margin-right: 15px;
  color: white;
  outline: none;
}

span:hover {
  color: #27ae60 !important;
}
</style>