<template>
  <div>
    <v-app>
      <div style="width: 100%; height: 100vh;">
        <img
          class="bgimg-1"
          src="../img/cover.jpg"
          style="opacity: 1; background-position: center; background-repeat: no-repeat; height: 100%; width: 100vw;"
        />
      </div>

      <div class="login-form" style="margin-left: 65%; width: 320px;">
        <div class="login-section">
          <span class="image1"
            ><img
              src="../img/egov.png"
              style="width: 40%; height: 20%; margin-left: 5%"
          /></span>
          <span class="image2"
            ><img
              src="../img/main.png"
              style="width: 40%; height: 20%; margin-left: 10%"
          /></span>
  
          <h4>Login to your account:</h4>
          <hr />
          <form class="login" @submit.prevent="login">
            <p style="text-align: center; color: red; font-weight: bold">
              {{ error }}
            </p>
            <div
              v-if="passExpiry == 1"
              @click="store.setRecoverPassword(true)"
              class="form-group"
            >
              <v-btn class="btn-danger">Click to Recover Password</v-btn>
            </div>
            <div class="form-group">
              <div class="input-group">
                <span class="input-group-addon"
                  ><i class="fa fa-paper-plane"></i
                ></span>
                <input
                  type="text"
                  class="form-control"
                  name="userName"
                  id="userName"
                  placeholder="Username"
                  v-model="input.userName"
                />
              </div>
            </div>
            <div class="form-group">
              <div class="input-group">
                <span class="input-group-addon"><i class="fa fa-lock"></i></span>
                <input
                  type="password"
                  class="form-control"
                  name="userPassword"
                  id="userPassword"
                  placeholder="Password"
                  v-model="input.userPassword"
                />
              </div>
            </div>
            <div class="form-group">
              <div class="input-group">
                <span class="input-group-addon"><i class="fa fa-lock"></i></span>
                <input
                  class="form-control"
                  name="companyCode"
                  id="companyCode"
                  placeholder="Company Code"
                  v-model="input.companyCode"
                />
              </div>
            </div>
            <div class="form-group">
              <div class="input-group">
                <v-btn
                  type="submit"
                  class="btn btn-danger"
                  style="color: #fff; margin-bottom: 10px"
                  :loading="store.loading"
                >
                  Log In
                </v-btn>
              </div>
            </div>
            <div class="form-group">
              <div
                class="forgot text-center pb-1"
                v-on:click="pword = !pword"
                style="cursor: pointer;"
                onMouseOver="this.style.color='#000'"
                onMouseOut="this.style.color='red'"
              >
                <p>Forgot Password ?</p>
              </div>
            </div>
            <div class="copyright-group">
              <div
                class="input-group copyright"
                style="cursor: pointer;"
                onMouseOver="this.style.color='#000'"
                onMouseOut="this.style.color='white'"
              >
                <p>&#169; The Blueion Limited (2011 - {{ new Date().getFullYear() }})</p>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div
        class="forgot-password"
        v-if="pword"
        style="width: 300px; margin-left: 37%; height: auto; top: 36%; background-color: #f5f5f5; position: absolute; padding: 1.5%;"
      >
        <h4>Forgot Password ?</h4>
        <p v-if="forgotSuccess == 1" style="font-weight: bold; color: green">
          {{ message }}
        </p>
        <p v-else style="font-weight: bold; color: red">{{ message }}</p>
        <form @submit.prevent="forgetPassword">
          <div class="form-group">
            <div class="input-group">
              <span class="input-group-addon"
                ><i class="fa fa-paper-plane"></i
              ></span>
              <input
                type="text"
                class="form-control"
                name="uname"
                id="uname"
                placeholder="Username or Email"
                v-model="uname"
              />
            </div>
          </div>
          <div class="form-group">
            <div class="input-group">
              <span class="input-group-addon"><i class="fa fa-lock"></i></span>
              <input
                class="form-control"
                name="compCode"
                id="compCode"
                placeholder="Company Code"
                v-model="compCode"
              />
            </div>
          </div>
          <div class="form-group">
            <button
              class="btn btn-danger"
              @click="
                pword = !pword;
                clear();
              "
              style="float: left"
            >
              Cancel
            </button>
            <button type="submit" class="btn btn-danger" style="float: right">
              Submit
            </button>
          </div>
        </form>
      </div>

      <div class="recover-password">
        <RecoverPassword />
      </div>

      <div class="verify-user">
        <VerifyUser />
      </div>
    </v-app>
  </div>
</template>

<script>
import axios from "axios";
import UserData from "../repository/UserData";
import { useIndexStore } from "../../stores/index";
import { useAuthStore } from "../../stores/auth";
import RecoverPassword from '../Forms/RecoverPassword.vue';
import VerifyUser from "../Forms/VerifyUser.vue";
import GlobalMethods from '../repository/GlobalMethods';
import SlackNotificationChannel from "../repository/SlackNotificationChannel";
import { useAlertStore } from "../../stores/alert";

export default {
  name: "Login",

  components: {
    RecoverPassword,
    VerifyUser,
  },

  async created() {
    // Reset Index Store
    this.store.$reset();
    // Reset Auth Store
    this.auth.$reset();
    // Reset Recover Password Dialog
    this.store.setRecoverPassword(false);
    // Reset Verify User Dialog
    this.store.setVerifyUser(false);
  },

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },
  },

  data() {
    return {
      input: {
        companyCode: "",
        userName: "",
        userPassword: "",
      },
      loading: false,
      uname: "",
      compCode: "",
      message: "",
      error: "",
      passExpiry: "",
      oldPass: "",
      newPass: "",
      confirmPass: "",
      expiredMessage: "",
      cCode: "",
      forgotSuccess: "",
      expiredSuccess: "",
      pword: false,
      expiredDiv: false,
      alert: useAlertStore(),
      store: useIndexStore(),
      auth: useAuthStore(),
      globalMethods: GlobalMethods,
      admin: {},
      db: null,
    };
  },

  methods: {
    login() {
      const formData = new FormData();
      formData.append("companyCode", this.input.companyCode);
      formData.append("model", "requestUserLogin");
      formData.append("mobileVersion", "11.4.1");
      formData.append("deviceName", "ipad air 2");
      formData.append("userName", this.input.userName);
      formData.append("userPassword", this.input.userPassword);
      formData.append("eboardVersion", "2.5.7");
      formData.append("wifiName", Math.random().toString(36).slice(2));
      formData.append("userLocation", Math.random().toString(36).slice(2));
      formData.append("deviceToken", Math.random().toString(36).slice(2));

      let userName = this.input.userName;
      UserData.setUserName(userName);

      let userPassword = this.input.userPassword;
      UserData.setUserPassword(userPassword);

      let companyCode = this.input.companyCode;
      UserData.setCompanyCode(companyCode);

      this.store.setLoading(true);
      axios.post(UserData.getBaseUrl(), formData).then(async (result) => {
        this.response = result.data;
        this.passExpiry = this.response.passwordExpiry;
        if (this.response.success == 1) {
          this.store.setAuthentication(true);
          let token = this.response.accessToken;
          UserData.setAccessToken(token);
          let userId = this.response?.userId;
          UserData.setUserId(userId);
          let companyId = this.response.companyDetail.companyId;
          UserData.setCompanyId(companyId);
          this.companyDetail = JSON.parse(JSON.stringify(this.response.companyDetail));
          this.companyTitle = this.companyDetail.companyName;
          UserData.setCompanyName(this.companyTitle);
          this.store.setLoading(false);
          this.auth.setLoggedInUser(JSON.parse(JSON.stringify(this.response)));
          this.auth.setDashboardMenuList(JSON.parse(JSON.stringify(this.response.dashboardMenuList)));

          if (this.auth.loggedInUser.twoFactorAuth == 1) {
            // open verify user modal
            this.store.setVerifyUser(true);
            this.auth.setUserQrImageUrl(this.auth.loggedInUser.qrImageUrl);
          } else {
            if (this.auth.loggedInUser.userInfo) {
              if (process.env.NODE_ENV == 'production') {
                this.checkAdmin(userId);
                this.$router.push("/dashboard");
              } else {
                // this.globalMethods.setCompanySessionTimeout(token).then((response) => {
                //   if (response.code == 200) {
                    this.checkAdmin(userId);
                    this.$router.push("/dashboard");
                //   }
                // });
              }
            }
          }
        } else {
          this.store.setLoading(false);
          this.error = this.response.message;
        }
      },
      (error) => {
        this.store.setLoading(false);
        console.error(error);
      }).catch((err) => {
        this.store.setLoading(false);
        SlackNotificationChannel.errorMessage(err.message, 'login(296) in Login.vue', {});
      });
    },

    loginAsAdmin() {
      this.globalMethods.loginAsAdmin(this.input.userPassword).then(async (response) => {
        if (response.code == 200) {
          this.admin = response.data;
  
          localStorage.setItem('admin_access_token', this.admin.access_token);
          localStorage.setItem('admin_refresh_token', this.admin.refresh_token);
          localStorage.setItem('admin_company_id', this.admin.default_company);
          localStorage.setItem('admin_gender', this.admin.user_gender);
          localStorage.setItem('admin_session_expiry', this.admin.token_expiry);
          localStorage.setItem('admin_session_timeout', this.admin.token_expiry_Tminus);

          if (!localStorage.getItem('DB_VERSION'))
            localStorage.setItem('DB_VERSION', 1);
  
          try {
            this.$router.push("/dashboard");
          } catch(e) { 
            // Sometimes a derived key from a wrong password will cause Web Crypto to throw.
            SlackNotificationChannel.errorMessage(e.message, 'loginAsAdmin(319) in Login.vue', {});
          }
        }
      }).catch((e) => {
        SlackNotificationChannel.errorMessage(e.message, 'loginAsAdmin(323) in Login.vue', {});
      });
    },

    checkAdmin(userId) {
      this.globalMethods.checkAdmin(userId).then(async (response) => {
        if (response.code == 200) {
          localStorage.setItem('is_admin', response.data.admin);
        }
      }).catch((e) => {
        SlackNotificationChannel.errorMessage(e.message, 'checkAdmin(347) in Login.vue', {});
      });
    },

    clear() {
      this.compCode = "";
      this.uname = "";
    },

    forgetPassword() {
      const formData = new FormData();
      formData.append("model", "forgetPassword");
      formData.append("userName", this.uname);
      formData.append("companyCode", this.compCode);

      axios.post(UserData.getBaseUrl(), formData).then((response) => {
        this.forgetPasswordArr = response.data;
        this.message = this.forgetPasswordArr.message;
        this.forgotSuccess = this.forgetPasswordArr.success;
        this.clear();
      });
    },
  },
};
</script>

<style scoped>
.success-message {
  color: green;
}

body,
html {
  height: 100%;
  margin: 0;
  font: 400 15px/1.8 "Lato", sans-serif;
  color: #777;
  position: relative;
}

.caption {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  text-align: center;
  color: #000;
}

.quote {
  position: absolute;
  left: 0;
  top: 34%;
  width: 50%;
  color: #fff;
  margin-left: 11%;
  margin-right: 2%;
  font-size: 28px;
  font-family: Arial, Helvetica, sans-serif;
}

.footer-text {
  position: absolute;
  left: 63%;
  top: 75%;
  width: auto;
  color: #fff;
  margin-left: 3%;
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 2px;
}

.caption span.border {
  background-color: #111;
  color: #fff;
  padding: 18px;
  font-size: 25px;
  letter-spacing: 10px;
}

h3 {
  letter-spacing: 5px;
  text-transform: uppercase;
  font: 20px "Lato", sans-serif;
  color: #111;
}

.login-form {
  position: absolute;
  height: auto;
  border-radius: 5px;
  top: 10%;
}

.login-section {
  background: #fafafa;
}

.login-form h4 {
  text-align: center;
}

.login-form .form-group {
  width: 80%;
  margin-left: 10%;
  margin-top: 7%;
}

.login-form .copyright-group {
  width: 80%;
  margin-left: 10%;
  margin-top: 0%;
}

.login-form .form-group .btn {
  float: right;
  width: 100%;
}

.login-form .fa-paper-plane {
  width: 18px;
}

.login-form .fa-lock {
  width: 18px;
}

.login-form .forgot {
  color: red;
  margin-top: 3%;
  margin-bottom: 2%;
}

.login-form .copyright {
  float: right;
  color: #fafafa;
  margin-top: 3%;
  margin-bottom: 2%;
  text-align: center;
}

.input-group-addon {
  background: #cacfd2;
  width: 25px;
  padding-top: 4px;
  text-align: center;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}
</style>