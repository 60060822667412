<template>
  <v-list>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-list-item
        v-for="(action, index) in actions"
        :key="index"
        :class="action.actionId == userAction ? 'text-success text-center action-list' : 'text-center action-list'"
        style="cursor: pointer;"
        @click="submitRSVP(action)"
      >
        <v-list-item-title>
          {{ action.actionName }}
        </v-list-item-title>
      </v-list-item>
    </v-form>
  </v-list>
</template>

<script>
var moment = require("moment");
import axios from "axios";
import UserData from "../repository/UserData";
import { useAuthStore } from "../../stores/auth";
import { useIndexStore } from "../../stores/index";
import { useAlertStore } from "../../stores/alert";

export default {
  name: "ReplyComment",
  emits: ['updateMeetings'],

  props: {
    actions: {
      type: Array,
      default: () => [],
    },
    meetingId: {
      type: String,
      default: "",
    },
    eventId: {
      type: String,
      default: "",
    },
    userAction: {
      type: Number,
      default: 0,
    },
  },

  data: () => ({
    valid: false,
    reply: "",
    show: false,
    dialog_comment: false,
    current_comment: "",
    reply_comment: {},
    opened: [],
    rules: {
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
      selectRules: [(v) => !!v || "Select is required"],
      textRules: [
        v => !!v || 'Field is required',
        v => (v && v.length >= 3) || 'Min 3 characters',
      ],
      characterRules: [
        v => (v && v.length >= 3) || 'Min 3 characters',
      ],
    },
    token: localStorage.getItem("token") ? JSON.stringify(localStorage.getItem("token")).slice(1, -1) : "",
    auth: useAuthStore(),
    store: useIndexStore(),
    alert: useAlertStore(),
  }),

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },
    dialog_rsvp() {
      return this.store.getDialogRSVP;
    },
  },
  
  methods: {
    formatDate(date) {
      return moment.unix(date).format("DD MMM YYYY");
    },

    formatDateAndTime(date) {
      return moment.unix(date).format("DD MMM YYYY, hh:mm A");
    },

    validate() {
      return this.$refs.form.validate();
    },

    resetForm() {
      return this.$refs.form.reset();
    },

    resetValidation() {
      return this.$refs.form.resetValidation();
    },

    reset() {
      this.reply = "";
      document.querySelector('html').classList.remove('v-overlay-scroll-blocked');
    },

    toggle(i) {
      const index = this.opened.indexOf(i);
      if (index > -1) {
        this.opened.splice(index, 1);
      } else {
        this.opened = [];
        this.opened.push(i);
      }
    },

    openReplyCommentDialog(comment) {
      this.dialog_comment = true;
      this.current_comment = comment['approverComment'];
      this.reply_comment = comment;
    },

    noRepliesFound() {
      this.alert.showInfo('No replies found');
    },

    submitRSVP(action) {
      this.validate().then((validator_response) => {
        this.valid = validator_response.valid;
        if (this.valid) {
          const formData = new FormData();
          formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
          formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
          formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
          formData.append("model", "sendEventAction");
          formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
          formData.append("meetingId", this.meetingId);
          formData.append("eventId", this.eventId);
          formData.append("eventType", 2);
          formData.append("actionId", action.actionId);

          this.store.setLoading(true);
          axios.post(UserData.getBaseUrl(), formData).then((response) => {
            if (response.data.success == 1) {
              this.$emit('updateMeetings');
              this.dialog_comment = false;
              this.store.setLoading(false);
              this.alert.showSuccess(response.data.message);
              this.store.setDialogRSVP(!this.dialog_rsvp);
              this.reset();
            }
          })
          .catch((e) => {
            console.log("Error", e);
            this.store.setLoading(false);
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.action-list:nth-child(odd) {
  background-color: rgba(86, 182, 229, .2);
}
.action-list:hover {
  background-color: rgba(255, 255, 255, 255) !important;
}
</style>