<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="dialog_news_image"
        fullscreen
        :scrim="false"
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-card-title>
            <v-toolbar
              dark
              color="rgb(227, 58, 58)"
              class="text-white mx-2"
            >
              <v-toolbar-title>{{ title }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn
                  icon
                  dark
                  @click="dialog_news_image = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
          </v-card-title>
          <v-card-text>
            <div class="text-center">
              <v-img
                class="bg-white"
                :aspect-ratio="1"
                :src="image"
                cover
              >
                <template v-slot:placeholder>
                  <div class="d-flex align-center justify-center fill-height">
                    <v-progress-circular
                      color="rgb(227, 58, 58)"
                      indeterminate
                    ></v-progress-circular>
                  </div>
                </template>
              </v-img>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { useIndexStore } from '../../stores';

export default {
  name: "NewsImage",

  props: {
    image: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
  },

  computed: {
    dialog_news_image: {
      get() {
        return this.store.getDialogNewsImage;
      },
      set(value) {
        this.store.setDialogNewsImage(value);
      },
    },
  },

  data: () => ({
    store: useIndexStore(),
  }),
}
</script>