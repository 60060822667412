<template>
  <div>
    <!-- VERIFY USER FORM -->
    <div
      class="verify-user"
      v-if="verify_user"
      style="width: 320px; margin-left: 37%; height: auto; background-color: #f5f5f5; position: absolute; padding: 1.5%;"
      :style="user_qr_image_url ? 'top: 10%' : 'top: 26%'"
    >
      <v-row justify="center">
        <v-card
          style="background-color: #f5f5f5; width: 100%;"
          elevation="0"
        >
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-card-title>
              <v-toolbar color="transparent">
                <v-toolbar-title class="grow">Verify User</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  icon
                  dark
                  @click="close"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" md="12" sm="12" class="pt-0" v-if="user_qr_image_url != ''">
                    <v-img
                      class="mx-auto"
                      :lazy-src="user_qr_image_url"
                      :src="user_qr_image_url"
                      @error="imageLoadError"
                    >
                      <template v-slot:placeholder>
                        <div class="d-flex align-center justify-center fill-height">
                          <v-progress-circular
                            color="grey-lighten-4"
                            indeterminate
                          ></v-progress-circular>
                        </div>
                      </template>
                    </v-img>
                  </v-col>
                  <v-col cols="12" md="12" sm="12" class="pt-0" v-if="user_qr_image_url == ''">
                    <v-skeleton-loader
                      class="mx-auto border"
                      max-width="300"
                      max-height="300"
                      type="image"
                    ></v-skeleton-loader>
                  </v-col>
                  <v-col cols="12" md="12" sm="12" class="pb-0">
                    <v-text-field
                      :rules="rules.textRules"
                      v-model="verificationCode"
                      type="password"
                      label="Verification Code"
                      placeholder="Enter Verification Code"
                      variant="outlined"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="error"
                @click="getVerificationImage()"
                variant="flat"
                elevation="1"
                :loading="store.loading"
              >
                Re-Scan
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="info"
                @click="verifyCode()"
                style="float: right;"
                variant="flat"
                elevation="1"
                :loading="store.loading"
              >
                Verify
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import UserData from "../repository/UserData";
import { useAuthStore } from "../../stores/auth";
import { useIndexStore } from "../../stores/index";
import { useAlertStore } from "../../stores/alert";
import GlobalMethods from "../repository/GlobalMethods";
import SlackNotificationChannel from "../repository/SlackNotificationChannel";

export default {
  name: "VerifyUser",
  data: () => ({
    verificationCode: '',
    rules: {
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
      selectRules: [(v) => !!v || "Select is required"],
      textRules: [
        v => !!v || 'Field is required',
        v => (v && v.length >= 3) || 'Min 3 characters',
      ],
      characterRules: [
        v => (v && v.length >= 3) || 'Min 3 characters',
      ],
    },
    token: localStorage.getItem("token") ? JSON.stringify(localStorage.getItem("token")).slice(1, -1) : "",
    auth: useAuthStore(),
    store: useIndexStore(),
    alert: useAlertStore(),
    globalMethods: GlobalMethods,
  }),

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },

    user_qr_image_url() {
      return this.auth.getUserQrImageUrl;
    },

    verify_user() {
      return this.store.getVerifyUser;
    },
  },
  
  methods: {
    validate() {
      return this.$refs.form.validate();
    },

    resetForm() {
      return this.$refs.form.reset();
    },

    resetValidation() {
      return this.$refs.form.resetValidation();
    },

    reset() {
      this.verificationCode = "";
      document.querySelector('html').classList.remove('v-overlay-scroll-blocked');
    },

    imageLoadError() {
      this.alert.showError('Image Failed to Load!');
    },

    clearExpired() {
      this.verificationCode = "";
    },

    verifyCode() {
      this.validate().then((validator_response) => {
        this.valid = validator_response.valid;
        if (this.valid) {
          const formData = new FormData();
          formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
          formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
          formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
          formData.append("accessToken", UserData.getAccessToken() ?  UserData.getAccessToken() : this.token);
          formData.append("model", "googleDeviceConfirmation");
          formData.append("authCode", this.verificationCode);

          this.store.setLoading(true);
          axios.post(UserData.getBaseUrl(), formData).then((response) => {
            if (response.data.success == 1) {
              this.alert.showSuccess(response.data.message);
              this.reset();
              this.close();
              this.store.setLoading(false);
              this.handleLogin();
            } else {
              this.store.setLoading(false); 
              this.alert.showInfo(response.data.message);
            }
          })
          .catch((e) => {
            console.log("Error", e);
            this.store.setLoading(false);
            this.alert.showError('Something Went Wrong');
          });
        }
      });
    },

    handleLogin() {
      if (this.auth.loggedInUser.userInfo) {
        if (process.env.NODE_ENV == 'production') {
          this.checkAdmin(this.auth.loggedInUser.userId);
          this.$router.push("/dashboard");
        } else {
          // this.globalMethods.setCompanySessionTimeout(this.token).then((response) => {
          //   if (response.code == 200) {
              this.checkAdmin(this.auth.loggedInUser.userId);
              this.$router.push("/dashboard");
          //   }
          // });
        }
      }
    },

    checkAdmin(userId) {
      this.globalMethods.checkAdmin(userId).then(async (response) => {
        if (response.code == 200) {
          localStorage.setItem('is_admin', response.data.admin);
        }
      }).catch((e) => {
        SlackNotificationChannel.errorMessage(e.message, 'checkAdmin(347) in Login.vue', {});
      });
    },

    close() {
      this.store.setVerifyUser(false);
    },

    getVerificationImage() {
      const formData = new FormData();
      formData.append("userName", UserData.getUserName() ? UserData.getUserName() : this.user.userInfo.userName);
      formData.append("userPassword", UserData.getUserPassword() ? UserData.getUserPassword() : '');
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("model", "googleDeviceConfirmationReset");

      this.store.setLoading(true);
      axios.post(UserData.getBaseUrl(), formData).then((response) => {
        if (response.data.success == 1) {
          this.auth.setUserQrImageUrl(response.data.qrImageUrl);
        } else {
          this.alert.showInfo(response.data.message);
        }

        this.store.setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        this.alert.showError('Something Went Wrong');
        this.store.setLoading(false);
      });
    },
  },
};
</script>