<template>
  <div>
    <!-- NAV-MENU -->
    <div
      class="nav-menu"
      style="
        height: 45px;
        width: 100%;
        padding: 0px;
        padding-top: 0.3%;
        text-align: center;
      "
    >
      <router-link to="/dashboard" style="text-decoration: none">
        <v-icon color="#fff" size="35" style="margin-left: 10px;"
          >mdi-home</v-icon
        >
      </router-link>
      <p style="font-weight: bold; font-size: 24px; margin-left: 40%;">
        Risk Management
      </p>
      <v-spacer></v-spacer>
      <router-link to="/dashboard" style="text-decoration: none">
        <v-icon color="#fff" size="35" style="margin-right: 10px;"
          >mdi-server</v-icon
        >
      </router-link>
      <v-icon color="#fff" size="35" style="margin-right: 10px;"
        >mdi-refresh</v-icon
      >
    </div>

    <!-- Cards for filtering data -->
    <v-row style="margin-top: 0.5%; margin-bottom: 0.5%; width: 98%; margin-left: 1%; margin-right: 1%;">
      <v-col v-for="(cardData, index) in cardDataList" :key="index" cols="12" md="3" sm="12">
        <v-card :variant="cardData.variant" @mouseenter="setHoveredCard(index)" @mouseleave="setHoveredCard(null)">
          <v-row>
            <!-- Left column for title and text -->
            <v-col cols="12" md="8" sm="8">
              <v-card-title>{{ cardData.title.toUpperCase() }}</v-card-title>
              <v-card-text>{{ cardData.content }}</v-card-text>
            </v-col>
            
            <!-- Right column for image -->
            <v-col cols="12" md="4" sm="4" class="d-flex align-center justify-center">
              <v-icon>{{ cardData.icon }}</v-icon>
            </v-col>

          </v-row>

          <v-row v-if="(cardData.dropdownitems.length > 0)">
            <v-select
              v-if="isHovered(index) || isDropdownOpen[index]"
              clearable
              :label="`Select for ${cardData.title}`"
              :items="cardData.dropdownitems.map(item => item.name)"
              v-model="selectedValues[index]"
              @click="isDropdownOpen[index] = true"
              @blur="isDropdownOpen[index] = false"
            ></v-select>
          </v-row>
                      
        </v-card>

      </v-col>
    </v-row>

    <v-row style="margin-top: 0.5%; margin-bottom: 0.5%; width: 98%; margin-left: 2%; margin-right: 1%;">
      <v-btn @click="toggleHeatmapTables" prepend-icon="mdi-table">
        {{ toggleHeatMapButtonText }}
      </v-btn>
    </v-row>


    <v-row  v-show="showHeatmapTable" style="margin-top: 0.5%; margin-bottom: 0.5%; width: 98%; margin-left: 1%; margin-right: 1%;">
      <v-col cols="12" md="9" sm="9">

        <!-- Table -->
        <v-table fixed-header
              height="100vh"
            >
              <thead>
                <tr>
                  <th style="background-color: rgb(199, 9, 229); font-weight: bolder;" class="text-left text-white">Risk</th>
                  <th style="background-color: rgb(199, 9, 229); font-weight: bolder;" class="text-left text-white">Owner</th>
                  <th style="background-color: rgb(199, 9, 229); font-weight: bolder;" class="text-left text-white">Identified On</th>
                  <th style="background-color: rgb(199, 9, 229); font-weight: bolder;" class="text-left text-white">Reviewed</th>
                  <th style="background-color: rgb(199, 9, 229); font-weight: bolder;" class="text-left text-white">Probability</th>
                  <th style="background-color: rgb(199, 9, 229); font-weight: bolder;" class="text-left text-white">Impact</th>
                  <th style="background-color: rgb(199, 9, 229); font-weight: bolder;" class="text-left text-white">Priority</th>
                  <th style="background-color: rgb(199, 9, 229); font-weight: bolder;" class="text-left text-white">Documents</th>
                  <th style="background-color: rgb(199, 9, 229); font-weight: bolder;" class="text-left text-white">Mitigation</th>
                </tr>
              </thead>
              <tbody 
                v-for="item in filteredRisks" 
                :key="item.riskId"
                class="table-striped"
                >
                <tr
                  style="cursor: pointer;"
                >
                  <td
                    style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                     {{ item.riskName }}
                  </td>
                  <td
                    style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                  >
                    {{ item.owner }}
                  </td>
                  <td
                    style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                  >
                    {{ item.identifiedOn}}
                  </td>
                  <td
                    style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                  >
                   {{ item.reviewed }}
                  </td>
                  <td
                    style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                  >
                    {{ item.probability }}
                  </td>
                  <td
                    style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                  >
                    <!-- {{ item.impact }} -->
                    <span v-if="item.impact === 0">Low</span>
                    <span v-else-if="item.impact === 1">Medium</span>
                    <span v-else-if="item.impact === 2">High</span>
                    <span v-else-if="item.impact === 3">Extreme</span>
                    <span v-else>Unknown</span>
                  </td>
                  <td
                    style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                  >
                    {{ item.priority }}
                  </td>
                  <td
                    style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                  >
                    {{ item.documents }}
                  </td>
                  <td
                    style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                  >
                    {{ item.mitigation }}
                  </td>
                </tr>
              </tbody>
        </v-table>

        
 
      </v-col>
      <v-col cols="12" md="3" sm="3">
          <v-card
            class="mx-auto"
            max-width="300"
          >
            <v-list density="compact">
              <v-list-subheader>CATEGORIES</v-list-subheader>
      
              <v-list-item
                v-for="(item, i) in riskCategoryItems"
                :key="i"
                :value="item"
                color="primary"
                @click="selectRiskCategory(item.riskCategoryId)"
              >
                <v-list-item-title>{{ item.riskCategoryName }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
      </v-col>
    </v-row>

    <v-row  v-show="!showHeatmapTable" style="margin-top: 0.5%; margin-bottom: 0.5%; width: 98%; margin-left: 1%; margin-right: 1%;">

      <v-col cols="12" md="9" sm="9">
        <v-table fixed-header id="container"
            >
        </v-table>
      </v-col>
   

      <v-col cols="12" md="3" sm="3">
           <v-card
            class="mx-auto"
            max-width="300"
          >
            <v-list density="compact">
              <v-list-subheader>Selected Risk(s)</v-list-subheader>
              <v-divider></v-divider>
      
              <v-list-item
                v-for="(item, i) in selectedRiskList"
                :key="i"
                :value="item"
                color="primary"
              >
                <v-list-item>{{ i+1}}. {{ item.riskName }}</v-list-item>
                <v-divider></v-divider>
              </v-list-item>

            </v-list>
          </v-card>
      </v-col>
    </v-row>



    <!-- END OF COMPONENT -->
  </div>
</template>

<script>
var moment = require("moment");
import axios from "axios";
// import UserData from "../../components/repository/UserData";
import { useAuthStore } from "../../stores/auth";
import { useIndexStore } from "../../stores/index";
import prettyBytes from 'pretty-bytes';
import anychart from 'anychart';


export default {
  data: () => ({
    moment: moment,
    test: true,
    itemSubArrayStaticData: [],
    itemSubArrayDynamicData: [],
    resourceDirectors: [],
    itemName: "",

    sort: {
      field: "",
      desc: true,
      code: null,
    },

    list: {
      view: 1,
    },

    navigationPath: [
      {
        itemName: "...",
        localUrl: "0",
      },
    ],

    currentParent: "",
    parentItemId: "",

    userdata: {
      rootUrl:
        "https://v3.egovernance.io/api_v13/frontend/web/index.php/user/create",
    },

    closePDF: false,
    pdf: "",
    token: localStorage.getItem("token") ? JSON.stringify(localStorage.getItem("token")).slice(1, -1) : "",
    auth: useAuthStore(),
    store: useIndexStore(),
    baseUrl: "",
    errorMsg: "",

    cardDataList: [
      { 
        id:1,
        title: "All Risks", 
        variant: "tonal", 
        content: "1800",
        icon: "mdi-asterisk",
        dropdownitems: []
      },
      { 
        id:2,
        title: "Projects", 
        variant: "tonal", 
        content: "2800",
        icon: "mdi-cog-box",
        dropdownitems: [
          {
            id:1,
            name:"Project 1"
          },
          {
            id:2,
            name:"Project 2"
          }
        ]
      },
      { 
        id:3,
        title: "Business Units", 
        variant: "tonal", 
        content: "3800",
        icon: "mdi-store",
        dropdownitems: [
          {
            id:1,
            name:"STL Vision"
          },
          {
            id:2,
            name:"Training Environment"
          }
        ] 
      },
      { 
        id:4,
        title: "Compliance", 
        variant: "tonal", 
        content: "4800",
        icon: "mdi-check-circle",
        dropdownitems: [
          {
            id:1,
            name:"KRA"
          }
        ] 
      },
    ],

    selectedRiskCategoryId: 1, // Default to "All" category
    riskCategoryItems: [
      { riskCategoryId:1, riskCategoryName: 'All'},
      { riskCategoryId:2, riskCategoryName: 'Operational Risks'},
      { riskCategoryId:3, riskCategoryName: 'Budget Risk'},
      { riskCategoryId:4, riskCategoryName: 'Information Secury Risk'},
    ],

    hoveredCard: null,
    isDropdownOpen: {}, // Keep track of dropdown states
    selectedValues: {},
    showHeatmapTable: true,
    riskData: [],
    selectedRiskList:[]


  }),

  methods: {
    formatDate(date) {
      return moment.unix(date).format("DD-MMM-YYYY");
    },

    reloadPage() {
      window.location.reload();
    },

    getPrettyBytes(value) {
      return prettyBytes(parseInt(value));
    },

    addRisk(item) {
      console.log(item);
    },

    toggleHeatmapTables() {
      this.showHeatmapTable = !this.showHeatmapTable;
    },

    loadChart(riskDataArr) {
      const self = this;
      anychart.onDocumentReady(function () {
        
        // Create Heat Map
        var chart = anychart.heatMap(getData(riskDataArr));

        // Sets selection mode for single selection
        chart.interactivity().selectionMode('none');

        // Enable and configure scrollers
        chart.xScroller().enabled(true);
        chart.xZoom().setToPointsCount(8);
        chart.yScroller().enabled(true);
        chart.yZoom().setToPointsCount(8);

        // Variable with list of labels
        var namesList = ['Low', 'Medium', 'High', 'Extreme'];

        // Sets adjust chart labels
        chart
          .labels()
          .enabled(true)
          .minFontSize(14)
          .format(function () {
            var heatValue = this.heat;
            var xValue = this.x;
            var yValue = this.y;
            // var heatValue = this.heat;
            var matchingData = getData(riskDataArr).find(function(dataPoint) {
              return dataPoint.x === xValue && dataPoint.y === yValue;
            });

            if (matchingData && matchingData.riskName) {
                riskNameLeval = namesList[heatValue] + ' (!)';
                return riskNameLeval;
            }else{
                var riskNameLeval = namesList[heatValue] || 'Unknown Risk';
                return riskNameLeval;
            }
        });

        // Sets Axes
        chart.yAxis().stroke(null);
        chart.yAxis().labels().padding([0, 15, 0, 0]);
        chart.yAxis().ticks(false);
        chart.xAxis().stroke(null);
        chart.xAxis().ticks(false);

        chart
          .tooltip()
          .useHtml(true)
          .titleFormat(function () {
            var heatValue = this.heat;
            var riskNameState = namesList[heatValue] || 'Unknown Risk';
            return '<b>' + riskNameState + '</b>  Risk';
          })
          .format(function () {
            var xValue = this.x;
            var yValue = this.y;
            // var heatValue = this.heat;
            var matchingData = getData(riskDataArr).find(function(dataPoint) {
              return dataPoint.x === xValue && dataPoint.y === yValue;
            });

            // Check if a matching data point was found
            if (matchingData && matchingData.riskName) {

              var riskName = matchingData.riskName || 'No risk Name';
              var riskCategory = matchingData.riskCategory || 'No risk Category';
              var project = matchingData.project || 'No project';
              var businessUnit = matchingData.businessUnit || 'STL';
              var compliance = matchingData.compliance || 'KRA';
              var owner = matchingData.owner || 'No owner';
              var dateIdentified = matchingData.dateIdentified || 'No dateIdentified';

              return (
                '<span style="color: #CECECE">Likelihood: </span>' + xValue + '<br/>' +
                '<span style="color: #CECECE">Consequence: </span>' + yValue + '<br/>' +
                '<span style="color: #CECECE">Risk Name: </span>' + riskName + '<br/>' +
                '<span style="color: #CECECE">Risk Category: </span>' + riskCategory + '<br/>' +
                '<span style="color: #CECECE">Project: </span>' + project + '<br/>' +
                '<span style="color: #CECECE">Business Unit: </span>' + businessUnit + '<br/>' +
                '<span style="color: #CECECE">Compliance: </span>' + compliance + '<br/>' +
                '<span style="color: #CECECE">Owner: </span>' + owner + '<br/>' +
                '<span style="color: #CECECE">Risk Identified On: </span>' + dateIdentified + '<br/>'
              );
            } else {
              // Handle the case where no matching data point was found
              return '<span style="color: #CECECE">No <b>Risk</b> found for this point</span>';
            }
          });
          

        // Handle the click event on the chart
        chart.listen('pointClick', function (e) {
          // Check if a matching data point was clicked
          var xValue = e.iterator.get('x');
          var yValue = e.iterator.get('y');
          var heatValue = e.iterator.get('heat');

          var riskDataAll = riskDataArr.risks;

          var matchingData = riskDataAll.filter(function (dataPoint) {
            return xValue === dataPoint.probability && yValue === dataPoint.priority && heatValue === dataPoint.impact;
          });

          // Convert reactive array to plain array of objects
          const plainMatchingRiskData = JSON.parse(JSON.stringify(matchingData));

          // Set the matching data as the selectedRiskList
          self.selectedRiskList = plainMatchingRiskData;
          
        });


        // Set container id for the chart
        chart.container('container');

        // Initiate chart drawing
        chart.draw();
      });

      /**
       * [Risk management as from data json Info]
       *  
       *  PRIORITY
       *    e.g - Extreme, Major, Moderate, Minor, Insignificant
       *  IMPACT
       *    e.g - Low, Medium, High, Extreme
       *  PROBABILITY
       *    e.g - Rare, Unlikely, Possible, Likely, Almost Certain
       * 
       */

      function getData(riskDataArr) {
        var data = [   
          {
            x: 'Rare',
            y: 'Extreme',
            heat: 0,
            fill: '#90caf9',
            dateIdentified: '',
            riskName: '',
            riskCategory: '',
            project: '',
            businessUnit: '',
            compliance:'',
            owner: ''
          },
          {
            x: 'Unlikely',
            y: 'Extreme',
            heat: 1,
            fill: '#ffb74d',
            dateIdentified: '',
            riskName: '',
            riskCategory: '',
            project: '',
            businessUnit: '',
            compliance:'',
            owner: ''
          },
          {
            x: 'Possible',
            y: 'Extreme',
            heat: 1,
            fill: '#ffb74d',
            dateIdentified: '',
            riskName: '',
            riskCategory: '',
            project: '',
            businessUnit: '',
            compliance:'',
            owner: ''
          },
          {
            x: 'Likely',
            y: 'Extreme',
            heat: 2,
            fill: '#ef6c00',
            riskName: '',
            riskCategory: '',
            project: '',
            businessUnit: '',
            compliance:'',
            owner: ''
          },
          {
            x: 'Almost\nCertain',
            y: 'Extreme',
            heat: 3,
            fill: '#d84315',
            riskName: '',
            riskCategory: '',
            project: '',
            businessUnit: '',
            compliance:'',
            owner: ''
          }, 
          //major
          {
            x: 'Rare',
            y: 'Major',
            heat: 0,
            fill: '#90caf9',
            dateIdentified: '',
            riskName: '',
            riskCategory: '',
            project: '',
            businessUnit: '',
            compliance:'',
            owner: ''
          },
          {
            x: 'Unlikely',
            y: 'Major',
            heat: 1,
            fill: '#ffb74d',
            dateIdentified: '',
            riskName: '',
            riskCategory: '',
            project: '',
            businessUnit: '',
            compliance:'',
            owner: ''
          },
          {
            x: 'Possible',
            y: 'Major',
            heat: 1,
            fill: '#ffb74d',
            dateIdentified: '',
            riskName: '',
            riskCategory: '',
            project: '',
            businessUnit: '',
            compliance:'',
            owner: ''
          },
          {
            x: 'Likely',
            y: 'Major',
            heat: 2,
            fill: '#ef6c00',
            riskName: '',
            riskCategory: '',
            project: '',
            businessUnit: '',
            compliance:'',
            owner: ''
          },
          {
            x: 'Almost\nCertain',
            y: 'Major',
            heat: 2,
            fill: '#ef6c00',
            riskName: '',
            riskCategory: '',
            project: '',
            businessUnit: '',
            compliance:'',
            owner: ''
          },
          //moderate
          {
            x: 'Rare',
            y: 'Moderate',
            heat: 0,
            fill: '#90caf9',
            dateIdentified: '',
            riskName: '',
            riskCategory: '',
            project: '',
            businessUnit: '',
            compliance:'',
            owner: ''
          },
          {
            x: 'Unlikely',
            y: 'Moderate',
            heat: 0,
            fill: '#90caf9',
            dateIdentified: '',
            riskName: '',
            riskCategory: '',
            project: '',
            businessUnit: '',
            compliance:'',
            owner: ''
          },
          {
            x: 'Possible',
            y: 'Moderate',
            heat: 1,
            fill: '#ffb74d',
            riskName: '',
            riskCategory: '',
            project: '',
            businessUnit: '',
            compliance:'',
            owner: ''
          },
          {
            x: 'Likely',
            y: 'Moderate',
            heat: 1,
            fill: '#ffb74d',
            riskName: '',
            riskCategory: '',
            project: '',
            businessUnit: '',
            compliance:'',
            owner: ''
          },
          {
            x: 'Almost\nCertain',
            y: 'Moderate',
            heat: 1,
            fill: '#ffb74d',
            riskName: '',
            riskCategory: '',
            project: '',
            businessUnit: '',
            compliance:'',
            owner: ''
          },
          //minor
          {
            x: 'Rare',
            y: 'Minor',
            heat: 0,
            fill: '#90caf9',
            dateIdentified: '',
            riskName: '',
            riskCategory: '',
            project: '',
            businessUnit: '',
            compliance:'',
            owner: ''
          },
          {
            x: 'Unlikely',
            y: 'Minor',
            heat: 0,
            fill: '#90caf9',
            dateIdentified: '',
            riskName: '',
            riskCategory: '',
            project: '',
            businessUnit: '',
            compliance:'',
            owner: ''
          },
          {
            x: 'Possible',
            y: 'Minor',
            heat: 0,
            fill: '#90caf9',
            dateIdentified: '',
            riskName: '',
            riskCategory: '',
            project: '',
            businessUnit: '',
            compliance:'',
            owner: ''
          },
          {
            x: 'Likely',
            y: 'Minor',
            heat: 1,
            fill: '#ffb74d',
            riskName: '',
            riskCategory: '',
            project: '',
            businessUnit: '',
            compliance:'',
            owner: ''
          },
          {
            x: 'Almost\nCertain',
            y: 'Minor',
            heat: 1,
            fill: '#ffb74d',
            riskName: '',
            riskCategory: '',
            project: '',
            businessUnit: '',
            compliance:'',
            owner: ''
          },
          //Insignificant
          {
            x: 'Rare',
            y: 'Insignificant',
            heat: 0,
            fill: '#90caf9',
            dateIdentified: '',
            riskName: '',
            riskCategory: '',
            project: '',
            businessUnit: '',
            compliance:'',
            owner: ''
          },
          {
            x: 'Unlikely',
            y: 'Insignificant',
            heat: 0,
            fill: '#90caf9',
            dateIdentified: '',
            riskName: '',
            riskCategory: '',
            project: '',
            businessUnit: '',
            compliance:'',
            owner: ''
          },
          {
            x: 'Possible',
            y: 'Insignificant',
            heat: 0,
            fill: '#90caf9',
            dateIdentified: '',
            riskName: '',
            riskCategory: '',
            project: '',
            businessUnit: '',
            compliance:'',
            owner: ''
          },
          {
            x: 'Likely',
            y: 'Insignificant',
            heat: 0,
            fill: '#90caf9',
            dateIdentified: '',
            riskName: '',
            riskCategory: '',
            project: '',
            businessUnit: '',
            compliance:'',
            owner: ''
          },
          {
            x: 'Almost\nCertain',
            y: 'Insignificant',
            heat: 0,
            fill: '#90caf9',
            dateIdentified: '',
            riskName: '',
            riskCategory: '',
            project: '',
            businessUnit: '',
            compliance:'',
            owner: ''
          },
          
        ];


        //order low, medium, high
        // var color = ['#90caf9','ffb74d','#ef6c00'];    
        var riskCountMap = {};

        // Iterate through the riskData array and replace matching objects in the data array
        for (var i = 0; i < riskDataArr.risks.length; i++) {
          var risk = riskDataArr.risks[i];

          var matchingIndex = data.findIndex(item => item.x === risk.probability && item.y === risk.priority);

          if (matchingIndex !== -1) {
                console.log("Impact Level:", risk.impact);
                var fill
                switch (risk.impact) {
                  case 0:
                    fill = '#90caf9';
                    break;
                  case 1:
                    fill = '#ffb74d';
                    break
                  case 2:
                    fill = '#ef6c00';
                    break;
                  case 4:
                    fill = '#ff0000';
                    break;
                  default:
                    // Handle the default case if needed
                    fill = '#90caf9';
                    break;
                }


            // Check if the riskCountMap has an entry for the (x, y) pair
            var riskKey = risk.probability + '-' + risk.priority;
            if (riskCountMap[riskKey] === undefined) {
              riskCountMap[riskKey] = 1; // Initialize the count to 1
            } else {
              riskCountMap[riskKey]++; // Increment the count for the (x, y) pair
            }

            // Append the count to the risk name
            var count = riskCountMap[riskKey];
            var riskNameWithCount = risk.riskName + ' (Total Risks ' + count + ')';
                    
            // Replace the matching object with the risk data object
            data[matchingIndex] = {
              x: risk.probability,
              y: risk.priority,
              heat: risk.impact,
              fill: fill,
              dateIdentified: risk.identifiedOn,
              riskName: riskNameWithCount,
              riskCategory: 'Category A', // You can set the category as needed
              project: 'Units', // You can set the project as needed
              businessUnit: 'STL', // You can set the business unit as needed
              compliance: 'KRA', // You can set the compliance as needed
              owner: risk.owner,
            };
            
          }
        }

        // Return the full data array with all objects
        return data;

      }
    },

    setHoveredCard(index) {
      this.hoveredCard = index;
    },

    isHovered(index) {
      return this.hoveredCard === index;
    },

    selectRiskCategory(riskCategoryId) {
      this.selectedRiskCategoryId = riskCategoryId;
    },

    fetchRiskData() {
      /**
       *  NOTE: This will help user choose (priority risk) and (probability of risk happening).
       *  HEAT OF 0
       *  ===============
       *  (PRIORITY - PROBABILITY)
       * 
       *  Extreme - Rare = heat 0
       * 
       *  Major - Rare = heat 0
       * 
       *  Moderate - Rare = heat 0
       *  Moderate - Unlikely = heat 0
       * 
       *  Minor - Rare = heat 0
       *  Minor - Unlikely = heat 0
       *  Minor - Possible = heat 0
       * 
       *  Insignificant - Rare = heat 0
       *  Insignificant - Unlikely = heat 0
       *  Insignificant - Possible = heat 0
       *  Insignificant - Likely = heat 0
       *  Insignificant - Almost Certain = heat 0
       * 
       *  HEAT OF 1
       *  ===============
       *  Extreme - Unlikely = heat 1
       *  Extreme - Possible = heat 1
       *  
       *  Major - Unlikely = heat 1
       *  Major - Possible = heat 1
       * 
       *  Moderate - Possible = heat 1
       *  Moderate - Likely = heat 1
       *  Moderate - Almost Certain = heat 1
       * 
       *  Minor - Likely = heat 1
       *  Minor - Almost Certain = heat 1
       * 
       * 
       *  HEAT OF 2
       *  ===============
       *  Extreme - Likely = heat 2
       *  Major - Likely = heat 2
       *  Major - Almost Certain = heat 2
       * 
       *  HEAT OF 3
       *  ===============
       *  Extreme - Almost Certain = heat 3
       *
       * @param   {[type]}  /assets/json-APIs/getRisks.json  [/assets/json-APIs/getRisks.json - this will corespond to api endpoint]
       *
       * @return  {[type]}                                   [return fetches all risk data using axios ]
       *       // Fetch your risk data using axios 
       */

      axios.get('/assets/json-APIs/getRisks.json')
        .then(response => {
          this.riskData = response.data;
          this.loadChart(this.riskData); // Call the function after the data is loaded
        })
        .catch(error => {
          console.error('Error fetching risk data:', error);
        });
    },

  },

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },
    
    toggleHeatMapButtonText() {
      return this.showHeatmapTable ? "Show HeatMap" : "Show Table";
    },
    
    filteredRisks() {
      if (this.selectedRiskCategoryId === 1) {
        // "All" category selected, return all risks
        return this.riskData.risks;
      } else {
        // Filter risks based on the selected category
        return this.riskData.risks.filter(item => item.riskCategoryId === this.selectedRiskCategoryId);
      }
    
    },
  },

  created(){
  },

  beforeMount() {
  },

  mounted() {
    this.fetchRiskData();
  },
};


</script>

<style scoped>
  html,
  body,
  #container {
    width: 100%;
    /* height: 100%; */
    height: 500px;
    margin: 0;
    padding: 0;
  }

  .nav-menu {
    margin-top: 0px;
    background-color: rgb(199, 9, 229);
    color: #fff;
    display: flex;
    margin-bottom: 1%;
  }

  .component {
    width: 99%;
    margin-left: 0.5%;
    margin-right: 0.5%;
    height: 900px;
  }

  .left {
    width: 20%;
    margin-right: 1%;
    float: left;
    height: 100%;
  }

  .left-top {
    width: 100%;
    height: 49%;
    margin-bottom: 4%;
    background-color: #f8f9f9;
    border-top: 5px solid rgb(199, 9, 229);
    overflow: auto;
  }

  .left-bottom {
    width: 100%;
    height: 49%;
    background-color: #f8f9f9;
    border-top: 5px solid rgb(199, 9, 229);
    overflow: auto;
  }

  .left a {
    color: #000;
    text-decoration: none;
  }

  .left a:hover {
    color: rgb(199, 9, 229);
  }

  .right {
    width: 79%;
    float: left;
    background-color: #f8f9f9;
    height: 100%;
    border-top: 5px solid rgb(199, 9, 229);
    overflow: auto;
  }

  .parent {
    display: flex;
    width: 100%;
  }

  .filler {
    flex-grow: 1;
  }

  .left-buttons {
    display: inline;
  }

  .right-buttons {
    display: inline;
  }

  button:focus {
    outline: 0 !important;
    border: 0 !important;
    box-shadow: none !important;
  }
  .table-striped a {
    text-decoration: none;
    color: #000;
  }

  .table-striped a:hover {
    color: rgb(199, 9, 229);
  }

  .table-striped a:active {
    color: rgb(199, 9, 229);
  }

  .table-striped:nth-child(even) {
    background-color: rgba(0, 0, 0, .05);
  }

  .table-striped:hover {
    background-color: rgba(255, 255, 255, 255) !important;
  }

  .resources-table:hover { 
    background-color: rgb(199, 9, 229) !important; 
  }
</style>