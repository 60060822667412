<template>
  <div>
    <!-- PARTICIPANTS MODAL -->
    <div class="text-center">
      <v-dialog v-model="dialog_participants" max-width="500px">
        <v-card>
          <div
            style="border-top: 5px solid rgb(86, 182, 229);"
          >
            <v-card-title>
              <div style="height: 8%;">
                <v-card-title
                  class="headline grey lighten-2"
                  style="
                    background-color: rgb(255, 255, 255);
                    color: rgb(86, 182, 229);
                  "
                >
                  <v-container fill-height fluid>
                    <v-row align="center">
                      Participants
                    </v-row>
                  </v-container>
                </v-card-title>
              </div>
            </v-card-title>
            <v-card-text>
              <div style="height: 92%; overflow: auto">
                <v-card class="grey lighten-2" style="padding-bottom: 20px" v-if="participants.length > 0">
                  <div
                    style="overflow: auto"  
                  >
                    <v-list
                      subheader
                      two-line
                    >
                      <v-list-item-group
                        v-model="selected"
                        active-class="pink--text"
                        multiple
                      >
                        <div
                          v-for="(item, index) in participants"
                          :key="index"
                        >
                          <v-list-item>
                            <v-list-item-content>
                              <v-row align="center" class="spacer">
                                <v-col cols="12" md="12" sm="12">
                                  <v-badge
                                    :content="item.userName"
                                    inline
                                    color="transparent"
                                  >
                                    <v-avatar 
                                      size="40"
                                      :content="item.userName"
                                    >
                                      <v-img :src="item.userImageURL ? item.userImageURL : '/img/male-icon.png'"></v-img>
                                    </v-avatar>
                                  </v-badge>
                                </v-col>
                              </v-row>
                              <v-row align="center" class="spacer mt-2">
                                <v-col cols="12" md="12" sm="12">
                                  <v-table>
                                    <tbody>
                                      <tr style="color: rgb(86, 182, 229); border: 1px rgb(86, 182, 229) solid;">
                                        <td
                                          :style="'border-left: 1px solid rgb(86, 182, 229); ' + (key == getAttendanceStatus(item.attendanceStatus) ? 'background-color: rgb(86, 182, 229);' : '')"
                                          v-for="(option, key, index) in options"
                                          :key="index"
                                          :class="key == getAttendanceStatus(item.attendanceStatus) ? 'text-left text-white' : 'text-left'"
                                        >
                                          {{ option }}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </v-table>
                                </v-col>
                              </v-row>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider
                            v-if="index < participants.length - 1"
                            :key="index"
                          ></v-divider>
                        </div>
                      </v-list-item-group>
                    </v-list>
                  </div>
                </v-card>
                <v-card class="grey lighten-2" style="padding-bottom: 20px" v-else>
                  <v-card-text
                    class="ml-1"
                    style="
                      color: rgb(72, 61, 139);
                      font-size: 19px;
                      font-weight: bold;
                    "
                  >
                    No meeting participants found
                  </v-card-text>
                </v-card>
              </div>
            </v-card-text>
            <v-card-actions class="grey" @click="closeDialogParticipants">
              <v-btn 
                block
                color="rgb(86, 182, 229)"
              >
                Close
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
var moment = require("moment");
import { useAuthStore } from "../../stores/auth";
import { useIndexStore } from "../../stores/index";
import { useAlertStore } from "../../stores/alert";

export default {
  name: "MeetingParticipants",
  props: {
    meeting: {
      type: Object,
      default: () => {},
    },
    participants: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    valid: false,
    dialog: false,
    interestDisclosed: "",
    interestConflict: "",
    hasConflict: false,
    confirm: false,
    comments: "",
    conditions: "",
    password: "",
    rules: {
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
      selectRules: [(v) => !!v || "Select is required"],
      textRules: [
        v => !!v || 'Field is required',
        v => (v && v.length >= 3) || 'Min 3 characters',
      ],
      characterRules: [
        v => (v && v.length >= 3) || 'Min 3 characters',
      ],
    },
    token: localStorage.getItem("token") ? JSON.stringify(localStorage.getItem("token")).slice(1, -1) : "",
    auth: useAuthStore(),
    store: useIndexStore(),
    alert: useAlertStore(),
  }),

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },
    
    dialog_participants: {
      get: function () {
        return this.store.getDialogParticipants;
      },
      set: function (value) {
        this.store.setDialogParticipants(value);
      }
    },
  },
  
  methods: {
    formatDate(date) {
      return moment.unix(date).format("DD MMM YYYY");
    },

    validate() {
      return this.$refs.form.validate();
    },

    resetForm() {
      return this.$refs.form.reset();
    },

    resetValidation() {
      return this.$refs.form.resetValidation();
    },

    closeDialogParticipants() {
      this.store.setDialogParticipants(false);
      document.querySelector('html').classList.remove('v-overlay-scroll-blocked');
    },

    getAttendanceStatus(status) {
      var index = 0;
      switch(status) {
        case "1":
          index = 1;
          break;
        case "2":
          index = 2;
          break;
        case "3":
          index = 3;
          break;
      }

      return index;
    },
  },
};
</script>

<style scoped>
.list-striped a {
  text-decoration: none;
  color: #000;
}

.list-striped a:hover {
  color: rgb(72, 61, 139);
}

.list-striped a:active {
  color: rgb(72, 61, 139);
}
</style>