<template>
  <div>
    <!-- AGENDA COMMENTS MODAL -->
    <div class="text-center">
      <v-dialog 
        v-model="dialog_agenda_comments"
        max-width="950px"
        style='z-index: 2;'
      >
        <v-row justify="center">
          <v-card style="border-top: 5px solid rgb(86, 182, 229);" min-width="500px">
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-table
                fixed-header
                fixed-footer
                height="80vh"
              >
                <thead>
                  <tr>
                    <th>
                      <v-card-title style="width: 100%;">
                        <v-toolbar class="bg-white">
                          <v-icon
                            style="float: left; background-color: rgb(86, 182, 229); color: #fff;"
                            class="float-left mr-2"
                            @click="store.setDialogAgendaComments(false)"
                            >mdi-chevron-left</v-icon
                          >
                          <v-toolbar-title 
                            class="ml-n1"
                            color="rgb(86, 182, 229)"
                          >
                            Agenda Comments
                          </v-toolbar-title>
                          <v-icon
                            color="rgb(86, 182, 229)"
                            class="float-right"
                            @click="$emit('add_comment', comment)"
                            >mdi-cached</v-icon
                          >
                        </v-toolbar>
                      </v-card-title>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <v-card-text style="width: 100%;">
                        <v-row>
                          <v-col cols="12" md="12" sm="12">
                            <v-toolbar color="rgb(255, 255, 255)">
                              <span class="text-ellipsis font-weight-bolder" style="color: rgb(86, 182, 229);">
                                {{ agenda['itemName'] }}
                              </span>
                            </v-toolbar>
                            
                            <v-card
                              class="mx-auto elevation-0"
                              v-if="Object.keys(comments).length > 0"
                            >
                              <!-- Agenda Table -->
                              <v-table height="100vh">
                                <tbody
                                  v-for="(item, key, index) in comments" 
                                  :key="index"
                                  class="table-striped"
                                >
                                  <tr
                                    style="height: 100%;"
                                  >
                                    <td
                                      style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                                    >
                                      <v-list class="bg-transparent">
                                        <v-list-item
                                          active-color="rgb(86, 182, 229)"
                                        >
                                          <template v-slot:append v-if="item.userId == user.userInfo.userId">
                                            <v-icon
                                              style="float: right; background-color: rgba(0, 0, 0, .05); color: rgb(86, 182, 229);"
                                              size="x-large"
                                              >mdi-comment-multiple-outline</v-icon
                                            >
                                          </template>
        
                                          <template v-slot:prepend v-if="item.userId != user.userInfo.userId">
                                            <v-icon
                                              style="float: left; background-color: rgba(0, 0, 0, .05); color: rgb(86, 182, 229);"
                                              size="x-large"
                                              >mdi-comment-multiple</v-icon
                                            >
                                          </template>
        
                                          <v-list-item-title>
                                            <span :class="item.userId == user.userInfo.userId ? 'text-ellipsis font-weight-bold float-right' : 'text-ellipsis font-weight-bold float-left'">
                                              {{ item.comment }}
                                            </span>
                                          </v-list-item-title>
        
                                          <v-list-item-subtitle>
                                            <span :class="item.userId == user.userInfo.userId ? 'text-ellipsis float-right' : 'text-ellipsis float-left'">
                                              {{ item.userName }} - {{ formatDateAndTime(item.commentDate) }}
                                            </span>
                                          </v-list-item-subtitle>
                                        </v-list-item>
                                      </v-list>
                                    </td>
                                  </tr>
                                </tbody>
                              </v-table>
                            </v-card>
        
                            <v-card
                              class="mx-auto elevation-0"
                              v-else
                            >
                              <v-table class="bg-grey-lighten-5" height="100vh">
                                <tbody>
                                  <tr>
                                    <td class="text-center">
                                      <span>
                                        No comments found for this agenda
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </v-table>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td class="bg-white">
                      <v-textarea
                        class="mt-4"
                        label="Type your comments here"
                        rows="1"
                        variant="outlined"
                        v-model="comment"
                        :rules="rules.textRules"
                      >
                        <template v-slot:append>
                          <v-btn 
                            block
                            color="rgb(86, 182, 229)"
                            class="text-white mt-n2"
                            @click="addAgendaComments"
                            :loading="store.getLoading"
                          >
                            Add
                          </v-btn>
                        </template>
                      </v-textarea>
                    </td>
                  </tr>
                </tfoot>
              </v-table>
            </v-form>
          </v-card>
        </v-row>
      </v-dialog>
    </div>
  </div>
</template>

<script>
var moment = require("moment");
import axios from "axios";
import UserData from "../repository/UserData";
import { useAuthStore } from "../../stores/auth";
import { useIndexStore } from "../../stores/index";
import { useAlertStore } from "../../stores/alert";

export default {
  name: "AgendaComments",
  props: {
    agenda: {
      type: Object,
      default: () => {},
    },
    comments: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    valid: false,
    comment: "",
    reply: "",
    show: false,
    dialog_comment: false,
    current_comment: "",
    reply_comment: {},
    opened: [],
    rules: {
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
      selectRules: [(v) => !!v || "Select is required"],
      textRules: [
        v => !!v || 'Field is required',
        v => (v && v.length >= 3) || 'Min 3 characters',
      ],
      characterRules: [
        v => (v && v.length >= 3) || 'Min 3 characters',
      ],
    },
    token: localStorage.getItem("token") ? JSON.stringify(localStorage.getItem("token")).slice(1, -1) : "",
    auth: useAuthStore(),
    store: useIndexStore(),
    alert: useAlertStore(),
  }),

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },

    dialog_agenda_comments() {
      return this.store.getDialogAgendaComments;
    },

    meeting() {
      return this.store.getCurrentMeeting;
    },
  },
  
  methods: {
    formatDate(date) {
      return moment.unix(date).format("DD MMM YYYY");
    },

    formatDateAndTime(date) {
      return moment.unix(date).format("DD MMM YYYY, hh:mm A");
    },

    validate() {
      return this.$refs.form.validate();
    },

    resetForm() {
      return this.$refs.form.reset();
    },

    resetValidation() {
      return this.$refs.form.resetValidation();
    },

    reset() {
      this.reply = "";
      this.comment = "";
      this.resetValidation();
      document.querySelector('html').classList.remove('v-overlay-scroll-blocked');
    },

    noRepliesFound() {
      this.alert.showInfo('No replies found');
    },

    addAgendaComments() {
      this.validate().then((validator_response) => {
        this.valid = validator_response.valid;
        if (this.valid) {
          const formData = new FormData();
          formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
          formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
          formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
          formData.append("model", "addCommentForAgenda");
          formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
          formData.append("agendaId", this.agenda.itemId);
          formData.append("meetingId", this.meeting.itemId);
          formData.append("agenda_comment", this.comment);

          this.store.setLoading(true);
          axios.post(UserData.getBaseUrl(), formData).then((response) => {
            this.$emit('add_comment', this.comment);
            this.store.setLoading(false);
            this.alert.showSuccess(response.data.message);
            this.reset();
          })
          .catch((e) => {
            console.log("Error", e);
            this.store.setLoading(false);
          });
        }
      });
    },
  },
};
</script>