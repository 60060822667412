<template>
  <div>
    <!-- CONFERENCE MODAL -->
    <v-row justify="center">
      <v-dialog v-model="dialog_conference" max-width="70%" scrollable="false">
        <v-card style="border-top: 5px solid #795548;">
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-toolbar>
              <v-toolbar-title>
                Conference
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn
                  class="btn"
                  color="brown lighten-1"
                  @click="getUpcomingMeetings"
                  :loading="store.getLoading"
                >
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>

                <v-btn
                  class="btn"
                  color="error lighten-1"
                  @click="close"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <div class="d-flex flex-row">
              <v-tabs
                v-model="tab"
                direction="vertical"
                color="brown lighten-1"
              >
                <v-tab value="option-1">
                  Upcoming Meetings
                </v-tab>
                <v-tab value="option-2">
                  Join Meeting
                </v-tab>
              </v-tabs>
              <v-card-text>
                <v-window v-model="tab">
                  <v-window-item value="option-1">
                    <div v-if="upcomingMeetings.length > 0">
                      <v-card
                        flat
                        scrollable
                        v-for="(item, index) in upcomingMeetings"
                        :key="index"
                        style="display: flex; border-bottom: 1px solid rgb(220, 220, 220); width: 100%;"
                      >
                        <v-card-text>
                          <span style="display: inline;">
                            <p style="font-size: 20px;">{{ item.conferenceTitle }}</p>
                            <p style="font-size: 20px;">Meeting Number - {{ item.conferenceId }}</p>
                            <p style="font-size: 20px;">Created By - {{ item.creatorName }}</p>
                            <p style="font-size: 20px; color: grey;">{{ formatDate(item.conferenceStart) }}</p>
                          </span>
                        </v-card-text>
                        <v-card-actions>
                          <span style="display: inline;">
                            <p style="font-size: 20px;">
                              <v-btn                                    
                                class="btn text-white"
                                color="success"
                                block
                                variant="flat"
                                elevation="1"
                                :loading="store.loading"
                                @click="startMeeting(item)"
                              >
                                <span v-if="item.isHost == 1">Start</span>
                                <span v-else>Join</span>
                              </v-btn>
                            </p>
                            <p style="font-size: 20px;"><br></p>
                            <p style="font-size: 20px;"><br></p>
                            <p 
                              style="font-size: 20px; color: grey; cursor: pointer;"
                              @click="showPassword[item.conferenceId] = !showPassword[item.conferenceId]"
                            >
                              <span v-if="showPassword[item.conferenceId]">
                                {{ item.password }}
                              </span>
                              <span v-else class="text-info">
                                Show Password
                              </span>
                            </p>
                          </span>
                        </v-card-actions>
                      </v-card>
                    </div>
                    <div v-else> 
                      <v-card 
                        flat
                        style="display: flex; width: 100%;"
                      >
                        <v-card-text>
                          <p>
                            No Upcoming Meetings Found
                          </p>
                        </v-card-text>
                      </v-card>
                    </div>
                  </v-window-item>
                  <v-window-item value="option-2">
                    <div> 
                      <v-card 
                        flat
                        style="width: 100%;"
                      >
                        <v-card-text>
                          <v-container fluid>
                            <v-row>
                              <v-col cols="12" md="12" sm="12">
                                <v-text-field
                                  v-model="meeting_number"
                                  :rules="rules.textRules"
                                  label="Meeting Number"
                                  variant="outlined"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="12" sm="12">
                                <v-text-field
                                  v-model="meeting_password"
                                  :rules="rules.passwordRules"
                                  label="Password"
                                  variant="outlined"
                                  type="password"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="12" sm="12">
                                <v-btn                                    
                                  class="text-brown-lighten-1"
                                  block
                                  variant="outlined"
                                  elevation="1"
                                  :loading="store.loading"
                                  @click="joinMeeting"
                                >
                                  Join
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                      </v-card>
                    </div>
                  </v-window-item>
                </v-window>
              </v-card-text>
            </div>
          </v-form>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
var moment = require("moment");
import axios from "axios";
import UserData from "../repository/UserData";
import { useAuthStore } from "../../stores/auth";
import { useIndexStore } from "../../stores/index";
import { useAlertStore } from "../../stores/alert";

export default {
  name: "Conference",
  props: {
    type: {
      type: String,
      default: "0",
    },
    meeting: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    tab: 'option-1',
    upcomingMeetings: [],
    valid: false,
    meeting_number: "",
    meeting_password: "",
    admin_url: process.env.NODE_ENV == 'production' ? UserData.getProductionAdminBaseUrl() : UserData.getLocalAdminBaseUrl(), 
    showPassword: {},
    rules: {
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
      selectRules: [(v) => !!v || "Select is required"],
      textRules: [
        (v) => !!v || "Field is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
      characterRules: [(v) => (v && v.length >= 3) || "Min 3 characters"],
    },
    token: localStorage.getItem("token") ? JSON.stringify(localStorage.getItem("token")).slice(1, -1) : "",
    auth: useAuthStore(),
    store: useIndexStore(),
    alert: useAlertStore(),
  }),

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },

    dialog_conference() {
      return this.store.getDialogConference;
    },
  },

  created() {
    this.getUpcomingMeetings();
  },

  methods: {
    formatDate(date) {
      return moment(date).format("DD MMM YYYY, h:mm a");
    },

    setCurrentMeeting(meeting) {
      this.store.setCurrentMeeting(meeting);
    },

    validate() {
      return this.$refs.form.validate();
    },

    resetForm() {
      return this.$refs.form.reset();
    },

    resetValidation() {
      return this.$refs.form.resetValidation();
    },

    getUpcomingMeetings() {
      const formData = new FormData();

      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "getConferenceList");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);

      axios.post(UserData.getBaseUrl(), formData).then((response) => {
        if (response.data && response.data.conferenceList && response.data.conferenceList.length > 0) {
          this.upcomingMeetings = response.data.conferenceList;

          this.upcomingMeetings.forEach((item) => {
            var key = item.conferenceId;
            this.showPassword[key] = false;
          });
        }
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    close() {
      this.store.setDialogConference(false);
      document.querySelector("html").classList.remove("v-overlay-scroll-blocked");
    },

    reset() {
      this.meeting_number = "";
      this.meeting_password = "";
      document.querySelector("html").classList.remove("v-overlay-scroll-blocked");
    },

    startMeeting(meeting) {
      if (meeting.password !== "" && meeting.conferenceId !== "") {
        this.store.setCurrentConference(meeting);
        this.$router.push('/initiate-meeting/' + (meeting.conferenceId || meeting.id));
      }
    },

    joinMeeting() {
      this.validate().then((validator_response) => {
        this.valid = validator_response.valid;
        if (this.valid) {
          this.store.setLoading(true);
          axios.get(this.admin_url + '/meetings/zoom/' + this.meeting_number).then((response) => {
            if (response.data && response.data.password && response.data.password == this.meeting_password) {
              this.reset();
              this.store.setLoading(false);
              this.store.setDialogConference(false);
              this.startMeeting(response.data);
            } else {
              this.store.setLoading(false);
              this.store.setDialogConference(false);
              if (response.data && response.data.code == 3001) {
                this.alert.showInfo(response.data.message);
              } else {
                this.alert.showError('Invalid Meeting Password');
              }
            }
          })
          .catch((e) => {
            console.log("Error", e);
            this.store.setLoading(false);
          });
        }
      });
    },
  },
};
</script>