<template>
  <div>
    <!-- CHANGE PASSWORD MODAL -->
    <div class="text-center" style='z-index: 1;'>
      <v-dialog 
        v-model="recover_password" 
        max-width="500px"
        scrollable
      >
        <v-row justify="center">
          <v-card
            style="padding: 3%; background-color: #f5f5f5; width: 100%;"
          >
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-card-title>
                <span>
                  Expired Password ?
                </span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="12" sm="12">
                      <v-text-field
                        :rules="rules.passwordRules"
                        v-model="old_password"
                        type="password"
                        label="Old Password"
                        variant="outlined"
                      >
                        <template v-slot:prepend>
                          <v-icon icon="mdi-lock" size="large">
                          </v-icon>
                        </template>
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" md="12" sm="12">
                      <v-text-field
                        :rules="rules.passwordRules"
                        v-model="new_password"
                        type="password"
                        label="New Password"
                        variant="outlined"
                      >
                        <template v-slot:prepend>
                          <v-icon icon="mdi-lock" size="large">
                          </v-icon>
                        </template>
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" md="12" sm="12">
                      <v-text-field
                        :rules="rules.passwordRules"
                        v-model="confirm_password"
                        type="password"
                        label="Confirm Password"
                        variant="outlined"
                      >
                        <template v-slot:prepend>
                          <v-icon icon="mdi-lock" size="large">
                          </v-icon>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="error"
                  @click="
                    store.setRecoverPassword(false);
                    clearExpired();
                  "
                  variant="flat"
                  elevation="1"
                >
                  Close
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="info"
                  @click="
                    recoverPassword();
                  "
                  style="float: right"
                  variant="flat"
                  elevation="1"
                  :loading="store.loading"
                >
                  Submit
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-row>
      </v-dialog>
    </div>
  </div>
</template>

<script>
var moment = require("moment");
import axios from "axios";
import UserData from "../repository/UserData";
import { useAuthStore } from "../../stores/auth";
import { useIndexStore } from "../../stores/index";
import { useAlertStore } from "../../stores/alert";

export default {
  name: "RecoverPassword",
  props: {
    type: {
      type: Number,
      default: 0,
    },
    meeting: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    valid: false,
    dialog: false,
    old_password: "",
    new_password: "",
    confirm_password: "",
    rules: {
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
      selectRules: [(v) => !!v || "Select is required"],
      textRules: [
        v => !!v || 'Field is required',
        v => (v && v.length >= 3) || 'Min 3 characters',
      ],
      characterRules: [
        v => (v && v.length >= 3) || 'Min 3 characters',
      ],
    },
    token: localStorage.getItem("token") ? JSON.stringify(localStorage.getItem("token")).slice(1, -1) : "",
    auth: useAuthStore(),
    store: useIndexStore(),
    alert: useAlertStore(),
  }),

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },

    recover_password() {
      return this.store.getRecoverPassword;
    },
  },
  
  methods: {
    formatDate(date) {
      return moment.unix(date).format("DD MMM YYYY");
    },

    validate() {
      return this.$refs.form.validate();
    },

    resetForm() {
      return this.$refs.form.reset();
    },

    resetValidation() {
      return this.$refs.form.resetValidation();
    },

    reset() {
      this.comments = "";
      this.conditions = "";
      this.password = "";
      this.interestDisclosed = "";
      this.interestConflict = "";
      this.confirm = false;
      this.hasConflict = false;
      document.querySelector('html').classList.remove('v-overlay-scroll-blocked');
    },

    clearExpired() {
      this.oldPass = "";
      this.newPass = "";
      this.confirmPass = "";
    },

    recoverPassword() {
      this.validate().then((validator_response) => {
        this.valid = validator_response.valid;
        if (this.valid) {
          const formData = new FormData();
          formData.append("oldPassword", this.old_password);
          formData.append("newPassword", this.new_password);
          formData.append("confirmPassword", this.confirm_password);
          formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
          formData.append("userName", UserData.getUserName() ? UserData.getUserName() : this.user.userInfo.userName);
          formData.append("model", "changePassword");
          formData.append("passwordExpiry", 1);

          this.store.setLoading(true);
          axios.post(UserData.getBaseUrl(), formData).then((response) => {
            this.expiredPasswordArr = response.data;
            this.expiredMessage = this.expiredPasswordArr.message;
            this.expiredSuccess = this.expiredPasswordArr.success;

            if (this.expiredSuccess == 1) {
              UserData.setUserPassword(this.new_password);
              this.alert.showSuccess(this.expiredMessage);
              this.clearExpired();
              this.store.setRecoverPassword(false);
              this.store.setLoading(false);
            } else {
              this.store.setRecoverPassword(false);
              this.store.setLoading(false); 
              this.alert.showError(this.expiredMessage);
            }
          })
          .catch((e) => {
            console.log("Error", e);
            this.store.setLoading(false);
          });
        }
      });
    },
  },
};
</script>