<template>
  <div>
    <!-- NAV-MENU -->
    <div
      class="nav-menu"
      style="
        height: 45px;
        width: 100%;
        padding: 0px;
        padding-top: 0.3%;
        text-align: center;
      "
    >
      <div class="left-buttons">
        <router-link to="/dashboard" style="text-decoration: none">
          <v-icon color="#fff" size="35" style="margin-left: 10px"
            >mdi-home</v-icon
          >
        </router-link>
      </div>
      <div class="filler"></div>
      <h4 style="font-weight: bold; font-size: 19px">ELECTIONS</h4>
      <div class="filler"></div>
      <div class="right-buttons">
        <v-icon
          color="#ffffff"
          class="mr-5"
          @click="reloadPage"
          icon="mdi-cached"
          size="35"
          style="cursor: pointer"
        />
      </div>
    </div>

    <!-- COMPONENT -->
    <div
      class="component"
      style="
        margin-top: 0.5%;
        margin-bottom: 0.5%;
        background-color: #f8f9f9;
        border-top: 5px solid#f6cbf3;
        width: 98%;
        margin-left: 1%;
        margin-right: 1%;
      "
    >
      <div
        class="parent"
        style="
          height: 45px;
          width: 100%;
          margin-top: 7px;
          background-color: #fff;
        "
      >
        <div class="left-buttons">
          <router-link to="/Elections">
            <v-icon
              style="
                float: left;
                margin-left: 10px;
                background-color: #f6cbf3;
                color: #fff;
              "
              size="25"
              >mdi-chevron-left</v-icon
            >
          </router-link>
          <p
            style="
              float: left;
              font-weight: bold;
              font-size: 15px;
              text-align: left;
              margin-left: 10px;
              width: 250px;
              color: #f6cbf3;
            "
          >
            {{ this.current_plan.planName }}
          </p>
        </div>
      </div>
      <div>
        <v-layout column style="height: 90vh">
          <v-flex md12 style="overflow: unset">
            <div v-if="store.getLoading">
              <v-progress-linear
                color="rgb(246, 203, 243)"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
            </div>
            <!-- Table -->
            <v-table fixed-header height="70vh" style="width: 99vw">
              <thead>
                <tr
                  style="
                    background-color: #f6cbf3;
                    color: #fff;
                    font-weight: bolder;
                  "
                >
                  <th
                    style="
                      background-color: #f6cbf3;
                      color: #fff;
                      font-weight: bolder;
                    "
                    class="text-left"
                  >
                    Position
                  </th>
                  <th
                    style="
                      background-color: #f6cbf3;
                      color: #fff;
                      font-weight: bolder;
                    "
                    class="text-left"
                  >
                    Area
                  </th>
                  <th
                    style="
                      background-color: #f6cbf3;
                      color: #fff;
                      font-weight: bolder;
                    "
                    class="text-left"
                  >
                    Actions
                  </th>
                  <th
                    style="
                      background-color: #f6cbf3;
                      color: #fff;
                      font-weight: bolder;
                    "
                    class="text-center"
                  ></th>
                </tr>
              </thead>
              <tbody
                v-for="item in election"
                :key="item.milestoneCode"
                class="table-striped"
              >
                <tr
                  style="height: 100%"
                  @click="
                    toggle(item.milestoneId);
                    dialog1 = true;
                  "
                >
                  <td
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      padding-top: 20px;
                      padding-bottom: 20px;
                    "
                  >
                    {{ item.position }}
                  </td>
                  <td
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    "
                  >
                    {{ item.area }}
                  </td>
                  <td
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    "
                  >
                    {{ item.actions }}
                  </td>

                  <td
                    class="text-center"
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      text-decoration: underline;
                      color: red;
                    "
                  >
                    <v-row justify="center">
                      <v-btn variant="text" dark @click.stop="dialog = true">
                        RESULT
                      </v-btn>

                      <v-dialog v-model="dialog" max-width="80%">
                        <v-card>
                          <v-card-text>
                            <div
                              class="graph"
                              style="
                                height: 300px;
                                width: 99%;
                                background-color: white;
                                margin-left: 10px;
                                margin-right: 10px;
                                margin-bottom: 15px;
                              "
                            >
                              <doughnut-graph
                                :chartData="chartData"
                                style="height: 300px"
                              />
                            </div>
                            <div>
                              <h6>Total nuber of registered votes:- 200</h6>
                              <h6>Total number of cast votes:- 150</h6>
                            </div>
                            <v-table
                              fixed-header
                              height="70vh"
                              style="width: 100%"
                            >
                              <thead>
                                <tr
                                  style="
                                    background-color: #f6cbf3;
                                    color: #fff;
                                    font-weight: bolder;
                                  "
                                >
                                  <th
                                    style="
                                      background-color: #f6cbf3;
                                      color: #fff;
                                      font-weight: bolder;
                                    "
                                    class="text-left"
                                  >
                                    Position
                                  </th>
                                  <th
                                    style="
                                      background-color: #f6cbf3;
                                      color: #fff;
                                      font-weight: bolder;
                                    "
                                    class="text-left"
                                  >
                                    Area
                                  </th>
                                  <th
                                    style="
                                      background-color: #f6cbf3;
                                      color: #fff;
                                      font-weight: bolder;
                                    "
                                    class="text-left"
                                  >
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tbody
                                v-for="item in planMilestones"
                                :key="item.milestoneCode"
                                class="table-striped"
                              >
                                <tr
                                  style="height: 100%"
                                  @click="toggle(item.milestoneId)"
                                >
                                  <td
                                    style="
                                      overflow: hidden;
                                      text-overflow: ellipsis;
                                      white-space: nowrap;
                                      padding-top: 20px;
                                      padding-bottom: 20px;
                                    "
                                  >
                                    {{ item.milestoneCode }}
                                  </td>
                                  <td
                                    style="
                                      overflow: hidden;
                                      text-overflow: ellipsis;
                                      white-space: nowrap;
                                    "
                                  >
                                    {{ item.milestoneName }}
                                  </td>
                                  <td
                                    style="
                                      overflow: hidden;
                                      text-overflow: ellipsis;
                                      white-space: nowrap;
                                    "
                                  >
                                    {{ formatDate(item.plannedDate) }}
                                  </td>
                                </tr>
                              </tbody>
                            </v-table>
                          </v-card-text>
                        </v-card>
                      </v-dialog>
                    </v-row>
                  </td>

                  <v-dialog v-model="dialog1" max-width="80%">
                    <v-card>
                      <v-card-title>
                        <div  class="popup-menu">     
                        <h4  style="color:red; justify-self:center; " >Administrator</h4>
                        <!-- <v-text-field  
                          v-model="search"
                          prepend-inner-icon="mdi-magnify"
                          label="Search"
                          hide-details 
                          variant="solo"
                          class="searchBar"
                         /> -->
                      </div>
                      </v-card-title>
                      <v-card-text>
                        <v-table
                              :search="search"
                              fixed-header
                              height="60vh"
                              style="width: 100%"
                            > 
                              <thead>
                                <tr
                                  style="
                                    background-color: #f6cbf3;
                                    color: #fff;
                                    font-weight: bolder;
                                  "
                                >
                                  <th
                                    style="
                                      background-color: red;
                                      color: #fff;
                                      font-weight: bolder;
                                    "
                                    class="text-left"
                                  >
                                    Candidate
                                  </th>
                                  <th
                                    style="
                                      background-color: red;
                                      color: #fff;
                                      font-weight: bolder;
                                    "
                                    class="text-left"
                                  >
                                    Bio
                                  </th>
                                  <th
                                    style="
                                      background-color: red;
                                      color: #fff;
                                      font-weight: bolder;
                                    "
                                    class="text-left"
                                  >
                                    Select
                                  </th>
                                </tr>
                              </thead>
                              <tbody
                                class="striped"
                              >
                                <tr
                                  style="height: 150px"
                                 
                                >
                                  <td
                                    style="
                                      overflow: hidden;
                                      text-overflow: ellipsis;
                                      white-space: nowrap;
                                      padding-top: 20px;
                                      padding-bottom: 20px;
                                    "
                                  >
                                    
                                  </td>
                                  <td
                                    style="
                                      overflow: hidden;
                                      text-overflow: ellipsis;
                                      white-space: nowrap;
                                    "
                                  >
                                  Administartor STL
                                  </td>
                                  <td
                                    style="
                                      overflow: hidden;
                                      text-overflow: ellipsis;
                                      white-space: nowrap;
                                    "
                                  >
                                  <v-radio-group v-model="column" column>
                              <v-radio  style="color:red;" color="red" value="radio-1"></v-radio>
                        </v-radio-group>
                                  </td>
                                </tr>
                                <tr
                                  style="height: 100px"
                                 
                                >
                                  <td
                                    style="
                                      overflow: hidden;
                                      text-overflow: ellipsis;
                                      white-space: nowrap;
                                      padding-top: 20px;
                                      padding-bottom: 20px;
                                    "
                                  >
                                    
                                  </td>
                                  <td
                                    style="
                                      overflow: hidden;
                                      text-overflow: ellipsis;
                                      white-space: nowrap;
                                    "
                                  >
                                  Jyoti Mukherjee
                                  </td>
                                  <td
                                    style="
                                      overflow: hidden;
                                      text-overflow: ellipsis;
                                      white-space: nowrap;
                                    "
                                  >
                                  <v-radio-group v-model="column" column>
                          <v-radio  style="color:red;" color="red" value="radio-2"></v-radio> 
                        </v-radio-group>
                                  </td>
                                </tr>
                              </tbody>
                            </v-table>
                      </v-card-text>
                      <v-card-actions style=" display: flex; justify-content: center;">
                        <v-btn variant="tonal" style="background:red; color:white; font-weight:bold;">SUBMIT</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </tr>
              </tbody>
            </v-table>
          </v-flex>
        </v-layout>
      </div>
    </div>
  </div>
</template>
  
  <script>
var moment = require("moment");
import Doughnut from "../../components/DonChart.vue";
import UserData from "../../components/repository/UserData";
import { useAuthStore } from "../../stores/auth";
import { useIndexStore } from "../../stores/index";
import { useAlertStore } from "../../stores/alert";
import axios from "axios";
const LICENSE_KEY =
  "QvMr6j5706r3kYlOiVvJgEwu4fZnTL1fYz4t6paoBV5PekugaffzJnLtX5TczMS65OKAI1iIaSi06_l76UgF3kyKauJ9vaMZFuU3_oDplMFty6AhBhWPh80M7qoSa8xjtux3NqNrfqybICplLfHezwjhABIkPy6XxBMQ_uPU87_C5pMBXlc8A2PI_ShMjfhst6O_C0y9jyeMJ2DYudM3liFVsp90A0dOY51LM2Q3xV3jfcJ7K39FKpnKU_atpn1Ig8HXcmWdGQMjCXyquwVu9i2J850rGqBgFKJJFGixxnU3sZ__tA7Mb3aX8T2nXPlEnamtAhNf-DD1sEtqXHp5q5xxsFFuJDVh82tXKfd4oX8AkzWLd_-UVg_h_6ATML30e8fiQXSVcN0mmlZH5XYUv0jB5cHEyuIaGZac7gCNRn32pchJNpIwEdgHzWDRBQxxCR7F8b6z3Xs3yXJ6y9EhhrnKmjNYkMCdFJ0qNhI8zF8RhAbLA-uKtrzMy7hXLrlpuIHdGaCXMm7gA9iBpKiqwHIrX83rCFoVYUGM4BqNkim3HN8YJzZRDDI-ZHzdy7CcanSuvbe3NSxW5daeHdGVvg==";

export default {
  data() {
    return {
      search: '',
      opened: [],
      column: null,
      dialog: false,
      dialog1: false,
      tab: "option-1",
      planMilestones: [],
      closePDF: false,
      pdf: "",
      LICENSE_KEY: LICENSE_KEY,
      baseUrl: "",
      token: localStorage.getItem("token")
        ? JSON.stringify(localStorage.getItem("token")).slice(1, -1)
        : "",
      auth: useAuthStore(),
      store: useIndexStore(),
      alert: useAlertStore(),
      chartData: {
        labels: ["VueJs", "EmberJs", "ReactJs", "AngularJs", "ChartJs"],
        datasets: [
          {
            backgroundColor: [
              "#41B883",
              "#E46651",
              "#00D8FF",
              "#DD1B16",
              "#EDF40D",
            ],
            data: [40, 20, 80, 10, 33],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
      election: [
        {
          position: "Chairman",
          area: "STL Vision",
          actions: "Vote",
        },
        {
          position: "Administrator",
          area: "STL Vision",
          actions: "Vote",
        },
        {
          position: "President",
          area: "STL Vision",
          actions: "Vote",
        },
        {
          position: "Governor",
          area: "STL Vision",
          actions: "Vote",
        },
        {
          position: "Secretory",
          area: "STL Vision",
          actions: "Vote",
        },
      ],

    };
  },
  components: {
    "doughnut-graph": Doughnut,
  },
  methods: {
    formatDate(date) {
      return moment.unix(date).format("DD-MMM-YYYY");
    },

    reloadPage() {
      window.location.reload();
    },
    toggle(id) {
      const index = this.opened.indexOf(id);
      if (index > -1) {
        this.opened.splice(index, 1);
      } else {
        this.opened = [];
        this.opened.push(id);
      }
    },
    milestoneDocuments() {
      this.alert.showInfo("No Milestones Documents Found");
    },

    getPlanMilestoneList() {
      const formData = new FormData();
      formData.append(
        "userId",
        UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId
      );
      formData.append(
        "companyCode",
        UserData.getCompanyCode()
          ? UserData.getCompanyCode()
          : this.user.companyDetail.companyCode
      );
      formData.append(
        "accessToken",
        UserData.getAccessToken() ? UserData.getAccessToken() : this.token
      );
      formData.append("model", "getPlanMilestoneList");
      formData.append(
        "companyId",
        UserData.getCompanyId()
          ? UserData.getCompanyId()
          : this.user.companyDetail.companyId
      );
      formData.append("planId", this.current_plan.planId);

      this.store.setLoading(true);
      axios
        .post(UserData.getBaseUrl(), formData)
        .then((response) => {
          this.getPlanMilestoneList = response.data;
          this.planMilestones = this.getPlanMilestoneList.planMilestones;
          this.getPlanListArray = this.planMilestones[0];

          this.store.setLoading(false);

          this.plannedDate = this.planMilestones.map((item) =>
            this.formatDate(item.plannedDate)
          );
        })
        .catch((e) => {
          console.log("Error", e);
        });
    },
  },
  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },

    current_plan() {
      return this.store.getCurrentPlan;
    },
    planDocuments() {
      console.log("docs");
      console.log(this.current_plan.planDocuments[0]);
      return this.current_plan.planDocuments[0];
    },
  },
  beforeMount() {
    this.getPlanMilestoneList();
  },
};
</script>
  
<style scoped>
.nav-menu {
  margin-top: 0px;
  background-color: rgb(246, 203, 243);
  color: #fff;
  display: flex;
  margin-bottom: 1%;
  border: none;
  padding: 0px;
  margin-right: 15px;
  outline: none;
  justify-content: space-between;
}
.popup-menu {
  margin-top: 0px;
  display: flex;
  margin-bottom: 0;
  border: none;
  padding: 0px;
  margin-right: 15px;
  outline: none;
  justify-content: space-between;
}
.searchBar{
  background: lightgray !important;
}

.component {
  width: 99%;
  margin-left: 0.5%;
  margin-right: 0.5%;
  height: 900px;
}

p {
  text-decoration: none;
  color: #000000;
}

p:hover {
  color: #f6cbf3 !important;
}

.table-striped a {
  text-decoration: none;
  color: #000;
}

.table-striped a:hover {
  color: #f6cbf3;
}

.table-striped a:active {
  color: #f6cbf3;
}

.table-striped:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-striped:hover {
  background-color: rgba(255, 255, 255, 255) !important;
}
</style>
  