<template>
  <div>
    <v-card>
      <v-card-title>
        <v-slide-group
          fixed-tabs
          v-if="dashboardComponent === 'Tasks'"
        >
          <v-slide-group-item>
            <v-btn
              :style="'margin-left: 20px; margin-right: 20px; font-weight: bold; cursor: pointer; ' 
              + (dashboardComponent === 'MyInbox' ? 'background-color: #27ae60;' : 'background-color: #9e9e9e;')"
              @click="swapComponent('MyInbox')"
              class="ma-2 pa-2 v-tab text-white"
              rounded
              role="tab"
              block
            >
              Notifications
            </v-btn>
          </v-slide-group-item>
          <v-slide-group-item>
            <v-btn
              :style="'margin-left: 20px; margin-right: 20px; font-weight: bold; cursor: pointer; ' 
              + (dashboardComponent === 'Tasks' ? 'background-color: #27ae60;' : 'background-color: #9e9e9e;')"
              @click="swapComponent('Tasks')"
              class="ma-2 pa-2 v-tab text-white"
              rounded
              role="tab"
              block
            >
              Tasks
            </v-btn>
          </v-slide-group-item>
        </v-slide-group>
      </v-card-title>
      <v-card-text>
        <v-list
          subheader
          three-line
          class="bg-transparent"
          v-if="taskList && taskList.length > 0"
        >
          <v-list-item-group
            v-model="selected"
            active-class="pink--text"
            multiple
          >
            <div
              v-for="(task, index) in taskList"
              :key="index"
            >
              <v-list-item 
                :value="task.taskId" 
                @click="selectTask(task)"
              >
                <template v-slot:prepend="{ isActive }">
                  <v-list-item-action start>
                    <v-icon
                      color="#E74343"
                      style="margin-left: 20px;"
                      :model-value="isActive"
                      size="x-large"
                    >
                      mdi-radiobox-marked</v-icon
                    >
                  </v-list-item-action>
                </template>
    
                <v-list-item-content>
                  <v-row align="center" class="spacer">
                    <v-col cols="12" md="12" sm="12">
                      <v-list-item-title class="text-h6 text-wrap">
                        {{ task.taskTitle }}
                      </v-list-item-title>
    
                      <v-list-item-subtitle class="text-wrap">
                        Due on {{ formatDate(task.taskDueDate) }}
                      </v-list-item-subtitle>
    
                      <v-list-item-subtitle class="text-wrap">
                        {{ task.companyName }}
                      </v-list-item-subtitle>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                v-if="index < taskList.length - 1"
                :key="index"
              ></v-divider>
            </div>
          </v-list-item-group>
        </v-list>
    
        <div style="width: 100%;" v-else>
          <div
            class="w-100 p-4 d-flex align-items-center justify-content-center"
          >
            <v-table 
              color="transparent"
              style="width: 100%; background-color: transparent;"
            >
              <tbody>
                <tr>
                  <td 
                    colspan="5"
                    class="text-center"
                  >
                    <span>
                      No Tasks Found
                    </span>
                  </td>
                </tr>
              </tbody>
            </v-table>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
var moment = require("moment");
import { useIndexStore } from "../../stores";

export default {
  name: 'Tasks',

  props: {
    taskList: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    dashboardComponent() {
      return this.store.getDashboardComponent;
    },
  },

  data: () => ({
    tasks: [],
    selected: [],
    moment: moment,
    store: useIndexStore(),
  }),

  methods: {
    formatDate(date) {
      return moment.unix(date).format("DD-MMM-YYYY");
    },

    selectTask(task) {
      this.$emit('open_task', task);
    },

    swapComponent(component) {
      this.store.setDashboardComponent(component);
    },
  },
}
</script>