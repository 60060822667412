<template>
  <div :style="currentRouteName !== 'dashboard' ? '' : ''">
    <!-- NAV-MENU -->
    <div
      class="nav-menu"
      style="height: 45px; width: 100%; padding: 0px; padding-top: 0.3%; text-align: center;"
      v-if="currentRouteName !== 'dashboard'"
    >
      <router-link to="/dashboard" style="text-decoration: none;">
        <v-icon color="#fff" size="35" style="margin-left: 10px;"
          >mdi-home</v-icon
        >
      </router-link>
      <v-spacer></v-spacer>
      <p style="font-weight: bold; font-size: 24px; color: #ffffff;">MEETINGS</p>
      <v-spacer></v-spacer>
      <v-icon 
        color="#ffffff" 
        class="mr-5"
        @click="reloadPage"
        icon="mdi-cached"
        size="35"
      />
    </div>

    <!-- COMPONENT -->
    <div :class="currentRouteName !== 'dashboard' ? 'component' : 'component-meetings'">
      <div 
        class="left" 
        style="color: #000000"
        v-if="currentRouteName !== 'dashboard'"
      >
        <div class="left-details">
          <v-list 
            density="compact"
            style="background-color: #f8f9f9"
          >
            <v-list-item-title 
              style="color: rgb(86, 182, 229); padding: 15px;" 
              v-if="!open_pdf"
            >
              <v-icon style="margin-right: 10px" color="rgb(86, 182, 229)"
                >mdi-briefcase</v-icon
              >
              Meetings For
            </v-list-item-title>
            <v-list-item-title 
              style="color: rgb(86, 182, 229); padding: 15px;" 
              v-else
            >
              {{ meetingCategory ? meetingCategory : 'Meetings' }}
            </v-list-item-title>
            <v-list-item-content>
              <v-card
                class="mx-auto"
                v-if="!open_pdf"
              >
                <v-list density="compact">
                  <v-list-item
                    v-for="(item, index) in itemArrayMeetingCategories.categoryList"
                    :key="index"
                    :value="item"
                    active-color="rgb(86, 182, 229)"
                    @click="loadMeetingsForCategory(item)"
                  >
                    <v-list-item-title class="mt-3 mb-n5">
                      <a href="#" @click="loadMeetingsForCategory(item)">
                        {{ item.itemName }}
                      </a>
                      <span class="float-right">
                        {{ getUpcomingMeetingsForCategory(item.itemId) || 0 }}
                      </span>
                      <v-divider thickness="2px"></v-divider>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
              <v-card 
                class="mx-auto pa-auto"
                height="100vh"
                style="overflow-y: scroll;"
                v-else
              >
                <v-table
                  fixed-footer
                  height="100vh"
                >
                  <tbody>
                    <tr>
                      <td>
                        <!-- Components Switch -->
                        <component 
                          :is="currentComponent" 
                          :category-meetings="sortedData2"
                        />
                        <!-- End of components switch -->
                      </td>
                    </tr>
                  </tbody>
                  <tfoot v-if="currentRouteName !== 'dashboard'">
                    <tr>
                      <td>
                        <!-- FOOTER -->
                        <div
                          class="nav-menu"
                          @click="getMattersArisingReport"
                          style="width: 98%; margin: 1%; border: 1px solid #f2f2f2; height: auto; display: flex; padding: 1%; padding-bottom: 0px;"
                        >
                          <v-icon color="#fff" class="mr-2 mt-1"
                            >mdi-file-pdf-box</v-icon
                          >
                          <p style="font-weight: bold; color: #ffffff;" class="mt-1">Matters Arising Report</p>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </v-table>
              </v-card>
            </v-list-item-content>
          </v-list>
        </div>
      </div>

      <div :class="currentRouteName !== 'dashboard' ? 'right' : 'right-meetings'">
        <v-table
          fixed-header
          fixed-footer
          height="100%"
          :class="currentRouteName !== 'dashboard' ? '' : 'bg-transparent'"
        >
          <thead v-if="!open_pdf">
            <tr v-if="currentRouteName !== 'dashboard'">
              <th>
                <div 
                  class="parent"
                  style="height: 62px; width: 100%; background-color: #fff;"
                >
                  <div class="left-buttons mt-3 mb-n5">{{ meetingCategory ? meetingCategory : 'Meetings' }}</div>
                  <div class="filler">
                    <div class="dropdown-table float-right" style="display: inline; margin-top: 5px;">
                      <v-menu>
                        <template v-slot:activator="{ props }">
                          <v-btn 
                            variant="text" 
                            v-bind="props"
                            class="btn"
                            :style="getFilterStyle(9)"
                          >
                            {{ sort.desc ? 'Descending' : 'Ascending' }}
                            <v-icon>mdi-swap-vertical-bold</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item style="display: block">
                            <v-list-item-title
                              style="padding: 12px; font-size: 16px; cursor: pointer"
                              @click="sort.desc = true"
                              >Descending
                            </v-list-item-title>

                            <v-list-item-title
                              style="padding: 12px; font-size: 16px; cursor: pointer"
                              @click="sort.desc = false"
                              >Ascending
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </div>
                  <div class="dropdown-table float-right" style="display: inline; margin-top: 5px;">
                    <v-menu>
                      <template v-slot:activator="{ props }">
                        <v-btn 
                          variant="text" 
                          v-bind="props"
                          class="btn"
                          :style="getFilterStyle(9)"
                        >
                          {{ filterName }}
                          <span v-if="sort.status == 9"
                            >({{ sort.desc ? "desc" : "asc" }})</span
                          >
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item style="display: block">
                          <v-list-item-title
                            style="padding: 12px; font-size: 16px; cursor: pointer"
                            @click="doSort('itemName', 9, 'Name')"
                            >Name
                            <span v-if="sort.field == 'itemName'"
                              >({{ sort.desc ? "desc" : "asc" }})</span
                            >
                          </v-list-item-title>

                          <v-list-item-title
                            style="padding: 12px; font-size: 16px; cursor: pointer"
                            @click="doSort('meetingStatusId', 9, 'Status')"
                            >Status
                            <span v-if="sort.field == 'meetingStatusId'"
                              >({{ sort.desc ? "desc" : "asc" }})</span
                            >
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>

                  <div class="right-buttons" style="display: inline; margin-top: 5px;">
                    <div style="display: inline; margin-right: 10px;">
                      <v-btn
                        style="padding: 0px;"
                        class="btn"
                        variant="text"
                        v-on:click="doSort('meetingDate', 10)"
                        :style="getFilterStyle(10)"
                      >
                        Date
                        <span v-if="sort.field == 'meetingDate' && sort.status == 7"
                          >({{ sort.desc ? "desc" : "asc" }})</span
                        >
                      </v-btn>
                    </div>

                    <div class="dropdown-table" style="display: inline;">
                      <MeetingStatus 
                        :meeting-status="meetingStatus" 
                        :status-name="getStatusName" 
                        :filter-style="getFilterStyle(sort.status)" 
                        :meeting-sort="sort"
                        @filter_list="filterMeetings"
                      />
                    </div>
                  </div>
                </div>
              </th>
            </tr>

            <tr v-else>
              <th style="background-color: transparent;">
                <div
                  class="col-right-top mt-n7"
                  style="width: 100%; height: 35%;"
                >
                  <div class="input-group">
                    <p 
                      :style="'padding: 12px; font-size: 16px; padding: 12px; font-weight: bold; cursor: pointer; ' + (currentRouteName === 'dashboard' ? 'color: #27ae60;' : 'color: #9e9e9e;')"
                    >
                      Meetings
                    </p>
                    <v-spacer></v-spacer>
                    <div class="dropdown-table">
                      <MeetingStatus 
                        :meeting-status="meetingStatus" 
                        :status-name="getStatusName" 
                        :filter-style="getFilterStyle(sort.status)" 
                        :meeting-sort="sort"
                        @filter_list="filterMeetings"
                      />
                    </div>
                  </div>
                </div>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <!-- Components Switch -->
                <component 
                  :is="currentComponent" 
                  :category-meetings="sortedData2"
                />
                <!-- End of components switch -->
              </td>
            </tr>
          </tbody>

          <tfoot v-if="currentRouteName !== 'dashboard'">
            <tr>
              <td>
                <!-- FOOTER -->
                <div
                  class="nav-menu ml-3"
                  @click="getMattersArisingReport"
                  style="height: 45px; width: 98%; padding: 0px; padding-top: 0.3%; padding-left: 40%; cursor: pointer;"
                >
                  <v-icon color="#fff" class="mr-2 mt-1"
                    >mdi-file-pdf-box</v-icon
                  >
                  <p style="font-weight: bold; font-size: 19px; color: #ffffff;">Matters Arising Report</p>
                </div>
              </td>
            </tr>
          </tfoot>
        </v-table>
      </div>

      <!-- RCI MODAL -->
      <div class="text-center">
        <RCI :type="1" />
      </div>
    </div>
    <!-- END OF COMPONENT -->
  </div>
</template>
<script>
var moment = require("moment");
import axios from "axios";
import UserData from "./repository/UserData";
import View01 from "./Swap/View01.vue";
import View02 from "./Swap/View02.vue";
import { useAuthStore } from "../stores/auth";
import { useIndexStore } from "../stores/index";
import MeetingStatus from './MeetingStatus.vue';
import RCI from "./Forms/RCI.vue";

export default {
  data: () => ({
    moment: moment,
    sort: {
      field: "",
      desc: true,
      status: null,
    },
    allMeetings: [],
    itemArrayMeetingCategories: [],
    meetingsForCategory: [],
    allMeetingsForCategory: [],
    meetingStatus: [],
    token: localStorage.getItem("token") ? JSON.stringify(localStorage.getItem("token")).slice(1, -1) : "",
    auth: useAuthStore(),
    store: useIndexStore(),
    currentCategory: {},
    meetingCategory: '',
    padless: false,
    filterName: "Name",
    variant: 'fixed',
    firstComponent: 'view-01',
    secondComponent: 'view-02',
    currentTab: "Home",
    tabs: ["Home", "Posts", "Archive"],
    lastPath: null,
  }),

  components: {
    View01,
    View02,
    MeetingStatus,
    RCI,
  },

  computed: {
    currentTabComponent() {
      return "tab-" + this.currentTab.toLowerCase();
    },
    
    currentComponent() {
      return this.store.getCurrentComponent;
    },

    open_pdf() {
      return this.store.getOpenPdf;
    },

    localAttrs() {
      const attrs = {}

      if (this.variant === 'default') {
        attrs.absolute = false
        attrs.fixed = false
      } else {
        attrs[this.variant] = true
      }
      return attrs
    },

    user() {
      return this.auth.getLoggedInUser;
    },

    currentRouteName() {
      return this.$route.name;
    },

    companyId() {
      return this.auth.getCompanyId;
    },

    meeting() {
      return this.store.getCurrentMeeting;
    },

    getStatusName() {
      var name = '';
      var status = this.sort.status;

      if (status != null && status != 9 && status != 10) {
        let status = {};
        status = this.meetingStatus.filter((status) => status.meetingStatusId == parseInt(this.sort.status))[0];
        name = status.meetingStatusName;
      } else {
        name = 'All';
      }

      return name;
    },

    sortedData2() {
      if (!this.sort.field) {
        return this.meetingsForCategory;
      }

      return this.meetingsForCategory.concat().sort((a, b) => {
        return this.checkSortField(a, b);
      });
    },

    dashboardComponent() {
      return this.store.getDashboardComponent;
    },

    dashboardMeeting() {
      return this.store.getDashboardMeeting;
    },

    dialog_rsvp() {
      return this.store.getDialogRSVP;
    },
  },

  created() {
    this.lastPath = this.$router.options.history.state.back;
    if (this.dashboardMeeting && this.currentComponent !== 'view-02') {
      this.store.setCurrentComponent('view-02');
    } else {
      this.store.setCurrentComponent('view-01');
    }
    
    this.getMeetingCategories();
  },

  watch: {
    dialog_rsvp() {
      this.onUpdateMeetings();
    },

    companyId() {
      if (this.currentRouteName == 'dashboard') {
        this.onUpdateMeetings();
      }
    },
  },

  methods: {
    checkSortField(a, b) {
      switch (this.sort.field) {
        case 'itemName':
          if (this.sort.desc) {
            return a[this.sort.field].toLowerCase().trim() > b[this.sort.field].toLowerCase().trim() ? -1 : 1;
          } else {
            return a[this.sort.field].toLowerCase().trim() > b[this.sort.field].toLowerCase().trim() ? 1 : -1;
          }
        default: 
          if (this.sort.desc) {
            return a[this.sort.field] > b[this.sort.field] ? -1 : 1;
          } else {
            return a[this.sort.field] > b[this.sort.field] ? 1 : -1;
          }
      }
    },

    compareDates(a, b) {
      return moment(a).isBefore(b);
    },

    onUpdateMeetings() {
      this.refreshMeetingsForCategory();
    },

    reloadPage() {
      window.location.reload();
    },

    doSort(field, status = null, name = null) {
      if (status != null) {
        this.sort.status = status;
      }

      if (name != null) {
        this.filterName = name;
      }

      if (field == this.sort.field) {
        this.sort.desc = !this.sort.desc;
      } else {
        this.sort.field = field;
        this.sort.desc = true;
      }
    },

    filterMeetings(status_id) {
      this.filterMeetingList('meetingStatusId', status_id);
    },

    filterMeetingList(field, status = null) {
      if (status != 0) {
        this.sort.status = status;
        this.meetingsForCategory = this.allMeetingsForCategory.filter((meeting) => meeting.meetingStatusId == status);
      } else {
        this.sort.status = 0;
        this.meetingsForCategory = this.allMeetingsForCategory;
      }

      if (field == this.sort.field) {
        this.sort.desc = !this.sort.desc;
      } else {
        this.sort.field = field;
        this.sort.desc = true;
      }
    },

    getMattersArisingReport() {
      this.$router.push('/Matters%20Arising%20Report');
    },

    getMeetingCategories() {
      this.sort.field = "";
      this.sort.status = null;
      this.sort.desc = true;

      axios.post(UserData.getBaseUrl(), this.getMeetingCategoriesList()).then((response) => {
        this.itemArrayMeetingCategories = response.data.data;

        if (response.data.data && response.data.data.categoryList.length > 0) {
          response.data.data.categoryList.find((category) => {
            if (category.itemId == -999) {
              this.loadMeetingsForCategory(category);
            }
          });
        }

        if (response.data && response.data.meetingStatus.length > 0) {
          this.meetingStatus = response.data.meetingStatus;
          this.meetingStatus.unshift({
            meetingStatusColor: "#000000",
            meetingStatusId: "0",
            meetingStatusName: "All"
          });
        }
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    getMeetingCategoriesList() {
      const formData = new FormData();

      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "getMeetingCategories");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);

      return formData;
    },

    loadMeetingsForCategory(item) {
      this.currentCategory = item;
      this.store.setLoading(true);
      this.store.setOpenPdf(false);

      if (typeof this.meeting != 'undefined') {
        if (this.dashboardMeeting && this.currentComponent !== 'view-02') {
          this.store.setCurrentComponent('view-02');
          this.store.setDashboardMeeting(false);
        } else {
          this.store.setCurrentComponent('view-01');
        }
      } else {
        this.store.setCurrentComponent('view-01');
      }

      axios.post(UserData.getBaseUrl(), this.getMeetingsForCategory(item.itemId)).then((response) => {
        if (response.data.data && response.data.data.meetingList && response.data.data.meetingList.length > 0) {
          this.meetingsForCategory = response.data.data.meetingList;
          this.allMeetingsForCategory = this.meetingsForCategory;
          if (item.itemId == -999) {
            this.allMeetings = response.data.data.meetingList;
          }
        }

        this.meetingCategory = item.itemName;
        this.store.setLoading(false);
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    refreshMeetingsForCategory() {
      this.store.setLoading(true);
      axios.post(UserData.getBaseUrl(), this.getMeetingsForCategory(this.currentCategory.itemId)).then((response) => {
        if (response.data.data && response.data.data.meetingList && response.data.data.meetingList.length > 0) {
          this.meetingsForCategory = response.data.data.meetingList;
          this.allMeetingsForCategory = this.meetingsForCategory;
          if (this.currentCategory.itemId == -999) {
            this.allMeetings = response.data.data.meetingList;
          }

          if (typeof this.meeting != 'undefined') {
            let current = this.allMeetingsForCategory.find(item => item.itemId == this.meeting.itemId);
            this.store.setCurrentMeeting(current);
          }
          
          this.store.setLoading(false);
        }
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    getMeetingsForCategory(meetingCategoryId = 0) {
      const formData = new FormData();

      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "getMeetingForCategory");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      formData.append("meetingCategoryId", meetingCategoryId);

      return formData;
    },

    getUpcomingMeetingsForCategory(meetingCategoryId) {
      var upcomingMeetings = [];
      var upcomingMeetingsCount = 0;
      
      if (this.meetingsForCategory.length > 0) {
        if (meetingCategoryId !== -999) {
          upcomingMeetings = this.allMeetings.filter(item => (item.meetingCategoryId == meetingCategoryId && item.meetingStatusId == 1));
          upcomingMeetingsCount += upcomingMeetings.length;
        } else {
          upcomingMeetings = this.allMeetings.filter(item => item.meetingStatusId == 1);
          upcomingMeetingsCount += upcomingMeetings.length;
        }
      }

      return upcomingMeetingsCount || '';
    },

    swapComponent(component) {
      this.store.setDashboardComponent(component);
    },

    getFilterStyle(status) {
      if (this.sort.status === status) {
        return 'color: rgb(86, 182, 229); font-weight: bold; border: none;';
      } 
    },
  },
};
</script>

<style scoped>
.nav-menu {
  margin-top: 0px;
  background-color: rgb(86, 182, 229);
  color: #fff;
  display: flex;
  margin-bottom: 1%;
}

.component {
  width: 99%;
  margin-left: 0.5%;
  margin-right: 0.5%;
  height: 900px;
}

.component-meetings {
  width: 99%;
  margin-left: 0.5%;
  margin-right: 0.5%;
  height: 250px;
}

.left {
  width: 20%;
  margin-right: 1%;
  float: left;
  height: 100%;
  background-color: #f8f9f9;
  border-top: 5px solid rgb(86, 182, 229);
}

.left a {
  color: #000;
  text-decoration: none;
}

.left a:hover {
  color: rgb(86, 182, 229);
}

.right {
  width: 79%;
  float: left;
  height: 100%;
  background-color: #f8f9f9;
  border-top: 5px solid rgb(86, 182, 229);
  position: relative;
}

.right-meetings {
  width: 100%;
  float: left;
  height: 100%;
  background-color: #f8f9f9;
  position: relative;
}

.right {
  width: 79%;
  float: left;
  height: 100%;
  background-color: #f8f9f9;
  border-top: 5px solid rgb(86, 182, 229);
  position: relative;
}

.right-meetings {
  width: 100%;
  float: left;
  height: 100%;
  background-color: #f8f9f9;
  position: relative;
}

.parent {
  display: flex;
  width: 100%;
  background-color: #fff;
  color: rgb(86, 182, 229);
}

.filler {
  flex-grow: 1;
}

.left-buttons {
  display: inline;
  font-size: 22px;
  padding-left: 15px;
}

.right-buttons {
  display: inline;
}

button:focus {
  outline: 0 !important;
  border: 0 !important;
  box-shadow: none !important;
}
</style>
