<template>
  <div>
    <v-app>
      <!-- NAV-MENU -->
      <div
        class="nav-menu"
        style="height: 45px; width: 100%; padding: 0px; padding-top: 0.3%; text-align: center;"
      >
        <router-link to="/dashboard" style="text-decoration: none;">
          <v-icon color="#fff" size="35" style="margin-left: 10px;"
            >mdi-home</v-icon
          >
        </router-link>
        <v-spacer></v-spacer>
        <p style="font-weight: bold; font-size: 24px;">MATTERS ARISING REPORT</p>
        <v-spacer></v-spacer>
        <v-icon 
          color="#ffffff" 
          class="mr-5"
          @click="getMattersArisingReportList"
          icon="mdi-cached"
          size="35"
        />
      </div>

      <!-- COMPONENT -->
      <div class="component">
        <div class="left" style="color: #000000">
          <div v-if="store.getLoading">
            <v-progress-linear
            color="red"
            indeterminate
            rounded
            height="6"
            ></v-progress-linear>
          </div>
          <div class="left-top">
            <v-list 
              density="compact"
              style="background-color: #f8f9f9"
            >
              <v-table fixed-header height="100vh">
                <thead>
                  <tr>
                    <th>
                      <v-list-item-title style="color: rgb(227, 58, 58); padding: 15px;">
                        <v-icon style="margin-right: 10px" color="rgb(227, 58, 58)"
                          >mdi-view-list</v-icon
                        >
                        Meetings
                      </v-list-item-title>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <v-list-item-content>
                        <v-card elevation="0">
                          <v-list density="compact">
                            <v-list-item
                              v-for="(item, index) in itemArrayMeetingData.meetingList"
                              :key="index"
                              :value="item"
                              active-color="rgb(227, 58, 58)"
                              @click="loadMattersArisingForMeeting(item)"
                            >
                              <v-list-item-title class="text-wrap mt-3 mb-n5">
                                <a href="#" @click="loadMattersArisingForMeeting(item)">
                                  {{ item.meetingName }}
                                  <p class="text-subtitle-2 text-grey lighten-1">
                                    Due on {{ formatDate(item.meetingDate) }}
                                  </p>
                                </a>
                                <v-divider thickness="2px"></v-divider>
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-card>
                      </v-list-item-content>
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </v-list>
          </div>
        </div>

        <div class="right">
          <!-- Header 1 -->
          <div
            class="parent"
            style="height: 45px; width: 100%; background-color: #fff"
          >
            <div 
              class="left-buttons"
              @click="store.setDialogFilter(true)"
            >
              <span>
                <v-icon
                  class="pa-4"
                  style="float: left; margin-top: 5px; margin-left: 10px; background-color: rgb(227, 58, 58); color: #fff;"
                  >mdi-filter-menu</v-icon
                >
              </span>
              <p
                class="pt-1"
                style="float: left; font-weight: bold; font-size: 24px; text-align: left; color: rgb(227, 58, 58); margin-left: 10px;"
              >
                Filter
              </p>
            </div>
            <div class="filler"></div>
            <div class="right-buttons" style="display: inline">
              <div class="dropdown-table" style="display: inline">
                <v-menu>
                  <template v-slot:activator="{ props }">
                    <v-btn 
                      variant="text" 
                      v-bind="props"
                      class="btn"
                      :style="getFilterStyle(9)"
                    >
                      {{ sort.desc ? 'Descending' : 'Ascending' }}
                      <v-icon>mdi-swap-vertical-bold</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item style="display: block">
                      <v-list-item-title
                        style="padding: 12px; font-size: 16px; cursor: pointer"
                        @click="sort.desc = true"
                        >Descending
                      </v-list-item-title>

                      <v-list-item-title
                        style="padding: 12px; font-size: 16px; cursor: pointer"
                        @click="sort.desc = false"
                        >Ascending
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
            <div class="dropdown-table float-right" style="display: inline;">
              <v-menu>
                <template v-slot:activator="{ props }">
                  <v-btn 
                    color="#000" 
                    variant="text" 
                    v-bind="props"
                    class="btn"
                    :style="getFilterStyle(9)"
                  >
                    {{ filterName }}
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item style="display: block">
                    <v-list-item-title
                      style="padding: 12px; font-size: 16px; cursor: pointer"
                      v-on:click="doSort('mattersAgenda', 9, 'Agenda')"
                      >Agenda
                      <span v-if="sort.field == 'mattersAgenda'"
                        >({{ sort.desc ? "desc" : "asc" }})</span
                      >
                    </v-list-item-title>

                    <v-list-item-title
                      style="padding: 12px; font-size: 16px; cursor: pointer"
                      v-on:click="doSort('statusId', 2, 'Completed')"
                      >Completed
                      <span v-if="sort.field == 'statusId' && sort.code == 2"
                        >({{ sort.desc ? "desc" : "asc" }})</span
                      >
                    </v-list-item-title>

                    <v-list-item-title
                      style="padding: 12px; font-size: 16px; cursor: pointer"
                      v-on:click="doSort('assignedTo', 9, 'Assigned To')"
                      >Assigned To
                      <span v-if="sort.field == 'assignedTo'"
                        >({{ sort.desc ? "desc" : "asc" }})</span
                      >
                    </v-list-item-title>

                    <v-list-item-title
                      style="padding: 12px; font-size: 16px; cursor: pointer"
                      v-on:click="doSort('dueDate', 9, 'Due Date')"
                      >Due Date
                      <span v-if="sort.field == 'dueDate'"
                        >({{ sort.desc ? "desc" : "asc" }})</span
                      >
                    </v-list-item-title>

                    <v-list-item-title
                      style="padding: 12px; font-size: 16px; cursor: pointer"
                      v-on:click="doSort('statusId', 9, 'Status')"
                      >Status
                      <span v-if="sort.field == 'statusId'"
                        >({{ sort.desc ? "desc" : "asc" }})</span
                      >
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <div class="right-buttons" style="display: inline;">
              <div style="display: inline; margin-right: 10px;">
                <v-btn
                  class="btn"
                  :style="getFilterStyle(0)"
                  variant="text"
                  @click="filterEvaluationList(0)"
                >
                  All
                </v-btn>

                <v-btn
                  class="btn"
                  :style="getFilterStyle(3)"
                  variant="text"
                  @click="filterEvaluationList(3)"
                >
                  Cancelled
                </v-btn>

                <v-btn
                  class="btn"
                  :style="getFilterStyle(1)"
                  variant="text"
                  @click="filterEvaluationList(1)"
                >
                  Pending
                </v-btn>

                <v-btn
                  class="btn"
                  :style="getFilterStyle(2)"
                  variant="text"
                  @click="filterEvaluationList(2)"
                >
                  Completed
                </v-btn>
              </div>
            </div>
          </div>

          <!-- Header 2 -->
          <div v-if="store.getLoading">
            <v-progress-linear
            color="red"
            indeterminate
            rounded
            height="6"
            ></v-progress-linear>
          </div>
          <div
            class="parent"
            style="height: 55px; width: 100%; background-color: #f1f1f1;"
          >
            <p
              style="font-weight: bold; font-size: 24px; margin-left: 10px; float: left; "
            >
              {{ joinNames() }}
            </p>
          </div>

          <!-- Table -->
          <v-table v-if="sortedData2.length > 0" fixed-header height="100vh">
            <thead>
              <tr style="background-color: rgb(227, 58, 58); color: #fff; font-weight: bolder;">
                <th style="background-color: rgb(227, 58, 58); color: #fff; font-weight: bolder;" class="text-left">No.</th>
                <th style="background-color: rgb(227, 58, 58); color: #fff; font-weight: bolder;" class="text-left">Agenda</th>
                <th style="background-color: rgb(227, 58, 58); color: #fff; font-weight: bolder;" class="text-left">Action Recommended</th>
                <th style="background-color: rgb(227, 58, 58); color: #fff; font-weight: bolder;" class="text-left">Action Undertaken</th>
                <th style="background-color: rgb(227, 58, 58); color: #fff; font-weight: bolder;" class="text-left">Assigned To</th>
                <th style="background-color: rgb(227, 58, 58); color: #fff; font-weight: bolder;" class="text-left">Due Date</th>
                <th style="background-color: rgb(227, 58, 58); color: #fff; font-weight: bolder;" class="text-left">Completed</th>
                <th style="background-color: rgb(227, 58, 58); color: #fff; font-weight: bolder;" class="text-left">Status</th>
              </tr>
            </thead>
            <tbody
              v-for="(item, index) in sortedData2" 
              :key="item.itemId"
              class="table-striped"
            >
              <tr 
                @click="toggle(item.mattersArisingId)"
                style="cursor: pointer;"
              >
                <td
                  style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; padding-top: 20px; padding-bottom: 20px;"
                >
                  {{ index + 1 + "." }}
                </td>
                <td
                  style="overflow: hidden; text-overflow: ellipsis;"
                  @click="openMattersComments(item)"
                >
                  <tooltip
                    :data="item.mattersAgenda"
                    :location="'bottom'"
                    :styling="'bg-red font-weight-bold'"
                  />
                </td>
                <td
                  style="overflow: hidden; text-overflow: ellipsis;"
                  @click="openMattersComments(item)"
                >
                  <tooltip
                    :data="item.actionRecommended"
                    :location="'bottom'"
                    :styling="'bg-red font-weight-bold'"
                  />
                </td>
                <td
                  style="overflow: hidden; text-overflow: ellipsis;"
                >
                  <div v-if="item.totalCommentCount > 0">
                    <tooltip
                      :data="item.actionTaken"
                      :location="'bottom'"
                      :styling="'bg-red font-weight-bold'"
                    />
                    <span 
                      style="cursor: pointer;" class="text-red float-right font-weight-bold"
                      @click="openMattersComments(item)"
                    >
                      (i)
                    </span>
                  </div>
                  <div v-else>
                    <tooltip
                      :data="item.actionTaken"
                      :location="'bottom'"
                      :styling="'bg-red font-weight-bold'"
                    />
                  </div>
                </td>
                <td
                  style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                >
                  {{ item.assignedTo }}
                </td>
                <td
                  style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                >
                  {{ formatDate(item.dueDate) }}
                </td>
                <td
                  style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                >
                  {{ (item.completionPercent).toFixed(2) }} %
                </td>
                <td
                  style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                >
                  <v-btn 
                    class="text-white" 
                    :color="getStatusColor(item.statusId)"
                    block
                  >
                    {{ item.status }}
                  </v-btn>
                </td>
              </tr>
              <tr v-if="opened.includes(item.mattersArisingId)">
                <td colspan="8">
                  <v-card class="elevation-0">
                    <v-card-text>
                      <div
                        style="display: block; margin: 2%; border: 1px solid grey; overflow: hidden;"
                      >
                        <v-row class="pa-4">
                          <v-col cols="12" md="12" sm="12">
                            <div
                              class="top-left"
                            >
                              <p
                                style="font-weight: bold; font-size: 19px; margin: 0px;"
                              >
                                Minutes:
                              </p>
                              <br>
                              <p
                                style="margin-top: 0px; height: auto; text-align: justify;"
                              >
                                {{ item.minutesText || 'No Minutes Found' }}
                              </p>
                            </div>
                          </v-col>

                          <v-col cols="12" md="12" sm="12">
                            <div
                              class="top-left"
                            >
                              <p
                                style="font-weight: bold; font-size: 19px; margin: 0px;"
                              >
                                Reason:
                              </p>
                              <br>
                              <p
                                style="margin-top: 0px; height: auto; text-align: justify;"
                              >
                                {{ item.mattersReason || 'No Reason Found' }}
                              </p>
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                    </v-card-text>
                  </v-card>
                </td>
              </tr>
            </tbody>
          </v-table>

          <v-table fixed-header v-else>
            <thead>
              <tr style="background-color: rgb(227, 58, 58); color: #fff; font-weight: bolder;">
                <td class="text-left">No.</td>
                <td class="text-left">Agenda</td>
                <td class="text-left">Action Recommended</td>
                <td class="text-left">Action Undertaken</td>
                <td class="text-left">Assigned To</td>
                <td class="text-left">Due Date</td>
                <td class="text-left">Completed</td>
                <td class="text-left">Status</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td 
                  colspan="7"
                  class="text-center"
                >
                  <span>
                    No Data Found
                  </span>
                </td>
              </tr>
            </tbody>
          </v-table>
        </div>

        <!-- MATTERS ARISING FILTER MODAL -->
        <div class="text-center">
          <MattersArisingFilter :assignees="meetingAssignees" @filter_list="filterMattersArisingList" @update_meeting="updateMeetingName" />
        </div>

        <!-- MATTERS ARISING COMMENTS MODAL -->
        <div class="text-center">
          <MattersArisingComments v-if="dialog_matters_comments" :matter="current_matter" :comments="matters_comments" @add_comment="getMattersComments" />
        </div>

        <!-- PSPDFKIT MODAL -->
        <div
          v-if="closePDF"
          style="position: absolute; background-color: #f5f5f5; width: 100%; height: 100%; padding: 1%; overflow: hidden; margin-right: 0;"
        >
          <v-card>
            <v-card-text>
              <v-main>
                <v-container fluid>
                  <pspdfkit
                    :pdf-url="pdf"
                    :license-key="LICENSE_KEY"
                    :base-url="baseUrl"
                    @close_pdf="closePdf"
                  />
                </v-container>
              </v-main>
            </v-card-text>
          </v-card>
        </div>
      </div>
      <!-- END OF COMPONENT -->
    </v-app>
  </div>
</template>

<script>
var moment = require("moment");
import axios from "axios";
import UserData from "./repository/UserData";
import PSPDFKit from "./PSPDFKit.vue";
import { useAuthStore } from "../stores/auth";
import { useIndexStore } from "../stores/index";
import MattersArisingFilter from './Forms/MattersArisingFilter.vue';
import Tooltip from './Cards/Tooltip.vue';
import MattersArisingComments from './Forms/MattersArisingComments.vue';

const LICENSE_KEY =
  "QvMr6j5706r3kYlOiVvJgEwu4fZnTL1fYz4t6paoBV5PekugaffzJnLtX5TczMS65OKAI1iIaSi06_l76UgF3kyKauJ9vaMZFuU3_oDplMFty6AhBhWPh80M7qoSa8xjtux3NqNrfqybICplLfHezwjhABIkPy6XxBMQ_uPU87_C5pMBXlc8A2PI_ShMjfhst6O_C0y9jyeMJ2DYudM3liFVsp90A0dOY51LM2Q3xV3jfcJ7K39FKpnKU_atpn1Ig8HXcmWdGQMjCXyquwVu9i2J850rGqBgFKJJFGixxnU3sZ__tA7Mb3aX8T2nXPlEnamtAhNf-DD1sEtqXHp5q5xxsFFuJDVh82tXKfd4oX8AkzWLd_-UVg_h_6ATML30e8fiQXSVcN0mmlZH5XYUv0jB5cHEyuIaGZac7gCNRn32pchJNpIwEdgHzWDRBQxxCR7F8b6z3Xs3yXJ6y9EhhrnKmjNYkMCdFJ0qNhI8zF8RhAbLA-uKtrzMy7hXLrlpuIHdGaCXMm7gA9iBpKiqwHIrX83rCFoVYUGM4BqNkim3HN8YJzZRDDI-ZHzdy7CcanSuvbe3NSxW5daeHdGVvg==";

export default {
  data: () => ({
    moment: moment,
    itemArrayMeetingData: [],
    mattersArisingListForMeeting: [],
    initialMattersArisingList: [],
    itemName: "",
    meetingAssignees: [],
    matters_comments: [],

    items: [
      { title: "Name" },
      { title: "Size" },
      { title: "Modified On" },
      { title: "Submitted By" },
    ],

    sort: {
      field: "",
      desc: true,
      code: null,
    },

    navigationPath: [
      {
        itemName: "...",
        localUrl: "0",
      },
    ],

    currentParent: "",
    parentItemId: "",

    userdata: {
      rootUrl:
        "https://v3.egovernance.io/api_v13/frontend/web/index.php/user/create",
    },

    closePDF: false,
    pdf: "",
    LICENSE_KEY: LICENSE_KEY,
    token: localStorage.getItem("token") ? JSON.stringify(localStorage.getItem("token")).slice(1, -1) : "",
    auth: useAuthStore(),
    store: useIndexStore(),
    baseUrl: "",
    errorMsg: "",
    opened: [],
    filterName: "Due Date",
  }),

  methods: {
    formatDate(date) {
      return moment.unix(date).format("DD-MMM-YYYY");
    },

    truncateText(str, n) {
      return str.length > n ? str.substr(0, n - 1) + "..." : str;
    },

    reloadPage() {
      window.location.reload();
    },

    closePdf() {
      this.closePDF = !this.closePDF;
    },

    getStatusColor(id) {
      switch(parseInt(id)) {
        case 0:
          return 'info';
        case 2:
          return 'success';
        default:
          return 'warning';
      }
    },

    toggle(id) {
      const index = this.opened.indexOf(id);
      if (index > -1) {
        this.opened.splice(index, 1);
      } else {
        this.opened = [];
        this.opened.push(id);
      }
    },

    getBoardMembers() {
      const formData = new FormData();
      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "getBoardMembers");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);

      this.store.setLoading(true);
      axios.post(UserData.getBaseUrl(), formData).then((response) => {
        this.meetingAssignees = response.data.boardMemberList;
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    getMattersArisingReportList() {
      axios.post(UserData.getBaseUrl(), this.getMattersMeetingList()).then((response) => {
        this.itemArrayMeetingData = response.data;
        this.loadMattersArisingForMeeting(this.itemArrayMeetingData.meetingList[0]);
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    loadMattersArisingForMeeting(item) {
      axios.post(UserData.getBaseUrl(), this.getMattersArisingForMeeting(item.meetingId)).then((response) => {
        this.mattersArisingListForMeeting = response.data.mattersArisingList;
        this.initialMattersArisingList = response.data.mattersArisingList;
        this.navigationPath = [];
        this.navigationPath.push({
          itemName: item.meetingName,
          itemId: item.meetingId,
        });

        this.store.setCurrentMeeting(item);
        this.store.setLoading(false);
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    getMattersMeetingList() {
      const formData = new FormData();

      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "getMattersMeetingList");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      this.store.setLoading(true);

      return formData;
    },

    getMattersArisingForMeeting(meetingId = 0) {
      const formData = new FormData();

      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "getMattersArisingForMeeting");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      formData.append("meetingId", meetingId);
      this.store.setLoading(true);

      return formData;
    },

    getResources(item) {
      if (item.itemExtension == "pdf") {
        this.closePDF = !this.closePDF;
        this.pdf = item.itemUrl;
        UserData.setDocumentId(item.itemId);
        this.store.setDocumentId(item.itemId);
        return;
      }
      if (item.itemExtension == "docx") {
        window.open(item.itemUrl);
      }
      if (item.itemExtension == "pptx") {
        window.open(item.itemUrl);
      }
      if (item.itemExtension == "xlsx") {
        window.open(item.itemUrl);
      }

      axios.post(UserData.getBaseUrl(), this.getUserData(item.itemId, item.itemParentId)).then((response) => {
        this.getResourcePackFolder = response.data;
        this.mattersArisingListForMeeting = this.getResourcePackFolder.data.itemSubArray;
        this.navigationPath.push({
          itemName: this.getResourcePackFolder.data.itemName,
          itemId: this.parentItemId,
        });
      });
    },

    loadItemFromHistory() {
      let currentTop = this.navigationPath.pop();
      axios.post(UserData.getBaseUrl(), this.getUserData(currentTop.itemId)).then((response) => {
        this.getSubMeetingPackFolder = response.data;
        this.mattersArisingListForMeeting = this.getSubMeetingPackFolder.data.itemSubArray;
      });
    },

    joinNames() {
      return [
        ...this.navigationPath.map((value) => {
          return value.itemName;
        }),
      ].join("/");
    },

    swapComponent(component) {
      this.currentComponent = component;
    },

    openMattersComments(item) {
      this.store.setCurrentMatter(item);
      this.getMattersComments();
    },

    getMattersComments() {
      const formData = new FormData();
      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "getCommentsForMattersArising");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      formData.append("mattersArisingId", this.current_matter.mattersArisingId);
      formData.append("from", 0);
      formData.append("count", 10);
      formData.append("isRefresh", 0);

      this.matters_comments = [];
      axios.post(UserData.getBaseUrl(), formData).then((response) => {
        this.matters_comments = response.data.commentList;
        this.store.setDialogMattersComments(true);
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    filterMattersArisingList(mattersArising) {
      this.initialMattersArisingList = mattersArising;
      this.mattersArisingListForMeeting = mattersArising;
    },

    updateMeetingName(details) {
      let userName = '';
      if (details.boardMemberId != 0) {
        userName = this.meetingAssignees.find(item => item.boardMemberId == details.boardMemberId).boardMemberName;
      } else {
        userName = 'All Users';
      }

      let meetingName = userName + ' (' + details.fromDate + ' - ' + details.toDate + ')';

      this.navigationPath = [];
      this.navigationPath.push({
        itemName: meetingName,
        itemId: 0,
      });
    },

    doSort(field, code = 0, name = null) {
      if (code !== 0) {
        this.sort.code = code;
      }

      if (name != null) {
        this.filterName = name;
      }

      if (field == this.sort.field) {
        this.sort.desc = !this.sort.desc;
      } else {
        this.sort.field = field;
        this.sort.desc = true;
      }
    },

    getUniqueListBy(arr, key) {
      return [...new Map(arr.map(item => [item[key], item])).values()]
    },

    filterEvaluationList(code) {
      if (code !== 0) {
        this.sort.code = code;
        var sorted_matters = [];
        sorted_matters = this.initialMattersArisingList.filter((matter) => matter.statusId == code);
        this.mattersArisingListForMeeting = this.getUniqueListBy(sorted_matters, 'mattersArisingId');
      } else {
        this.sort.code = 0;
        this.mattersArisingListForMeeting = this.getUniqueListBy(this.initialMattersArisingList, 'mattersArisingId');
      }
    },

    getFilterStyle(code) {
      if (this.sort.code === code) {
        return 'color: rgb(227, 58, 58); font-weight: bold;';
      } 
    },

    checkSortField(a, b) {
      switch (this.sort.field) {
        case 'statusId':
          if (this.sort.desc) {
            if (parseInt(a[this.sort.field]) == parseInt(b[this.sort.field])) {
              return b['mattersArisingId'] - a['mattersArisingId'];
            }

            return parseInt(a[this.sort.field]) > parseInt(b[this.sort.field]) ? -1 : 1;
          } else {
            if (parseInt(a[this.sort.field]) == parseInt(b[this.sort.field])) {
              return a['mattersArisingId'] - b['mattersArisingId'];
            }

            return parseInt(a[this.sort.field]) > parseInt(b[this.sort.field]) ? 1 : -1;
          }
        default: 
          if (this.sort.desc) {
            return a[this.sort.field] > b[this.sort.field] ? -1 : 1;
          } else {
            return a[this.sort.field] > b[this.sort.field] ? 1 : -1;
          }
      }
    },
  },

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },

    sortedData2() {
      if (!this.sort.field) {
        return this.mattersArisingListForMeeting;
      }
      return this.mattersArisingListForMeeting.concat().sort((a, b) => {
        if (this.sort.desc) {
          return a[this.sort.field] > b[this.sort.field] ? -1 : 1;
        } else {
          return a[this.sort.field] > b[this.sort.field] ? 1 : -1;
        }
      });
    },

    meeting() {
      return this.store.getCurrentMeeting;
    },

    dialog_matters_comments() {
      return this.store.getDialogMattersComments;
    },

    current_matter() {
      return this.store.getCurrentMatter;
    },

    overlay_type: {
      get() {
        return this.store.getOverlayType;
      },
      set(value) {
        this.store.setOverlayType(value);
      },
    },

    session_overlay: {
      get() {
        return this.store.getSessionOverlay;
      },
      set(value) {
        this.store.setSessionOverlay(value);
      },
    },
  },

  beforeMount() {
    this.store.setDialogMattersComments(false);
    this.getBoardMembers();
    this.getMattersArisingReportList();
  },

  components: {
    pspdfkit: PSPDFKit,
    MattersArisingFilter,
    tooltip: Tooltip,
    MattersArisingComments,
  },
};
</script>

<style scoped>
.nav-menu {
  margin-top: 0px;
  background-color: rgb(227, 58, 58);
  color: #fff;
  display: flex;
  margin-bottom: 1%;
}

.component {
  width: 99%;
  margin-left: 0.5%;
  margin-right: 0.5%;
  height: 900px;
}

.left {
  width: 20%;
  margin-right: 1%;
  float: left;
  height: 100%;
}

.left-top {
  width: 100%;
  margin-bottom: 4%;
  background-color: #f8f9f9;
  border-top: 5px solid rgb(227, 58, 58);
  overflow: auto;
}

.left-bottom {
  width: 100%;
  height: 49%;
  background-color: #f8f9f9;
  border-top: 5px solid rgb(227, 58, 58);
  overflow: auto;
}

.left a {
  color: #000;
  text-decoration: none;
}

.left a:hover {
  color: rgb(227, 58, 58);
}

.right {
  width: 79%;
  float: left;
  background-color: #f8f9f9;
  height: 100%;
  border-top: 5px solid rgb(227, 58, 58);
  overflow: auto;
}

.parent {
  display: flex;
  width: 100%;
}

.filler {
  flex-grow: 1;
}

.left-buttons {
  display: inline;
  cursor: pointer;
}

.right-buttons {
  display: inline;
}

button:focus {
  outline: 0 !important;
  border: 0 !important;
  box-shadow: none !important;
}

.table-striped a {
  text-decoration: none;
  color: #000;
}

.table-striped a:hover {
  color: rgb(227, 58, 58);
}
</style>
