<template>
  <div>
    <!-- NAV-MENU -->
    <div
      class="nav-menu"
      style=" height: 45px; width: 100%; padding: 0px; padding-top: 0.3%; text-align: center;"
    >
      <div class="left-buttons">
        <router-link to="/dashboard" style="text-decoration: none">
          <v-icon color="#fff" size="35" style="margin-left: 10px"
            >mdi-home</v-icon
          >
        </router-link>
      </div>
      <div class="filler"></div>
      <h4 style="font-weight: bold; font-size: 19px">PLANS</h4>
      <div class="filler"></div>
      <div class="right-buttons">
        <v-icon
          color="#ffffff"
          class="mr-5"
          @click="reloadPage"
          icon="mdi-cached"
          size="35"
          style="cursor: pointer"
        />
      </div>
    </div>

    <!-- COMPONENT -->
    <div
      class="component"
      style=" margin-top: 0.5%; margin-bottom: 0.5%; background-color: #f8f9f9; border-top: 5px solid #26acc2; width: 98%; margin-left: 1%; margin-right: 1%;"
    >
      <div class="content" v-if="!closePDF && !closeDocument">
        <div
          class="parent"
          style=" height: 45px; width: 100%; margin-top: 7px; background-color: #fff;"
        >
          <div class="left-buttons">
            <router-link to="/plans">
              <v-icon
                style=" float: left; margin-left: 10px; background-color: #26acc2; color: #fff; "
                size="25"
                >mdi-chevron-left</v-icon
              >
            </router-link>
            <p
              style=" float: left; font-weight: bold; font-size: 15px; text-align: left; margin-left: 10px; width: 250px; color: #26acc2;"
            >
              {{ this.current_plan.planName }}
            </p>
          </div>
        </div>

        <div class="graph bg-white mx-4 my-4">
          <line-graph
            :height="100"
            :chartData="chartData"
            :options = "options"
          />
        </div>

        <div>
          <v-card
            style=" width: 99%; height: 150px; border: 1px solid #26acc2; margin-bottom: 10px;"
          >
            <v-card-title style="color: #26acc2; background: #f3f1f1"
              >Plan Documents</v-card-title
            >
            <v-card-text
              v-if="current_plan.planDocuments.length <= 0"
              class="mt-3"
            >
              <p>No Plan Documents Found</p>
            </v-card-text>
            <v-card-text v-else>
              <v-list 
                density="compact" 
                class="ml-n4 overflow-y-auto"
                style="height: 100px; overflow-y: auto;"
              >
                <v-list-item
                  v-for="(item, index) in current_plan.planDocuments"
                  :key="index"
                  :value="item.itemName"
                >
                  <template v-slot:prepend>
                    <v-progress-circular
                      v-if="loading[item.itemId]"
                      indeterminate
                      color="rgb(38, 172, 194)"
                      class="mr-2"
                    ></v-progress-circular> 
                    <v-icon
                      v-else
                      :color="fileIds.includes(item.itemId) ? 'rgb(227, 58, 58)' : 'rgb(38, 172, 194)'"
                      class="mr-2"
                      size="large"
                      :icon="globalMethods.getDocumentIcon(item.itemExtension)"
                    />
                  </template>
                  <v-list-item-title class="text-wrap">
                    <file-options
                      :file="item"
                      :file_color="'rgb(38, 172, 194)'"
                      @open_pdf="getDocument(item)"
                      v-if="item.itemExtension == 'pdf'"
                      :select_type="true"
                      :pdf_type="2"
                    ></file-options>
                    <span 
                      class="name"
                      v-else
                      @click="getDocument(item)"
                    >
                      {{ item.itemName }}
                    </span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </div>
        <div>
          <v-layout column style="height: 90vh">
            <v-flex md12 style="overflow: unset">
              <div v-if="store.getLoading">
                <v-progress-linear
                  color="rgb(38, 172, 194)"
                  indeterminate
                  rounded
                  height="6"
                ></v-progress-linear>
              </div>
              <!-- Table -->
              <v-table fixed-header height="70vh" style="width: 99vw">
                <thead>
                  <tr
                    style=" background-color: #26acc2; color: #fff; font-weight: bolder; "
                  >
                    <th
                      style=" background-color: #26acc2; color: #fff; font-weight: bolder;"
                      class="text-left"
                    >
                      Code
                    </th>
                    <th
                      style=" background-color: #26acc2; color: #fff; font-weight: bolder; "
                      class="text-left"
                    >
                      Milestone
                    </th>
                    <th
                      style=" background-color: #26acc2; color: #fff; font-weight: bolder; "
                      class="text-left"
                    >
                      Planned Date
                    </th>
                    <th
                      style=" background-color: #26acc2; color: #fff; font-weight: bolder; "
                      class="text-center"
                    >
                      Actual Date
                    </th>
                    <th
                      style=" background-color: #26acc2; color: #fff; font-weight: bolder; "
                      class="text-center"
                    >
                      Status
                    </th>
                    <th
                      style=" background-color: #26acc2; color: #fff; font-weight: bolder;"
                      class="text-center"
                    >
                      Project %
                    </th>
                  </tr>
                </thead>
                <tbody
                  v-for="item in planMilestones"
                  :key="item.milestoneCode"
                  class="table-striped"
                >
                  <tr
                    style="height: 100%; cursor: pointer"
                    @click="toggle(item)"
                  >
                    <td
                      style=" overflow: hidden; text-overflow: ellipsis; white-space: nowrap; padding-top: 20px; padding-bottom: 20px;"
                    >
                      {{ item.milestoneCode }}
                    </td>
                    <td
                      style=" overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                    >
                      {{ item.milestoneName }}
                    </td>
                    <td
                      style=" overflow: hidden; text-overflow: ellipsis; white-space: nowrap; "
                    >
                      {{ formatDate(item.plannedDate) }}
                    </td>

                    <td
                      class="text-center"
                      style=" overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                    >
                      {{ formatDate(item.actualDate) }}
                    </td>
                    <td
                      style=" overflow: hidden; text-overflow: ellipsis; white-space: nowrap; "
                      class="text-center"
                    >
                      {{ item.percentageCompleted }}%
                    </td>
                    <td
                      style=" overflow: hidden; text-overflow: ellipsis; white-space: nowrap; "
                      class="text-center"
                    >
                      {{ item.percentage }}%
                    </td>
                  </tr>
                  <tr v-if="opened.includes(item.milestoneId)">
                    <td colspan="6">
                      <v-card class="elevation-0">
                        <v-card-text
                          class="elevation-3"
                          style="background: #fffefe"
                        >
                          <v-list density="compact" class="ml-n4">
                            <v-list-item
                              v-for="(item, index) in item.milestoneDocuments"
                              :key="index"
                              :value="item.itemName"
                            >
                              <template v-slot:prepend>
                                <v-progress-circular
                                  v-if="loading[item.itemId]"
                                  indeterminate
                                  color="rgb(38, 172, 194)"
                                  class="mr-2"
                                ></v-progress-circular> 
                                <v-icon
                                  v-else
                                  :color="fileIds.includes(item.itemId) ? 'rgb(227, 58, 58)' : 'rgb(38, 172, 194)'"
                                  class="mr-2"
                                  size="large"
                                  :icon="globalMethods.getDocumentIcon(item.itemExtension)"
                                />
                              </template>
                              <v-list-item-title class="text-wrap">
                                <file-options
                                  :file="item"
                                  :file_color="'rgb(38, 172, 194)'"
                                  @open_pdf="getDocument(item)"
                                  v-if="item.itemExtension == 'pdf'"
                                  :select_type="true"
                                  :pdf_type="2"
                                ></file-options>
                                <span 
                                  class="name"
                                  v-else
                                  @click="getDocument(item)"
                                >
                                  {{ item.itemName }}
                                </span>
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-card-text>
                      </v-card>
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </v-flex>
          </v-layout>
        </div>
      </div>

      <!-- PSPDFKIT / OPEN DOCUMENT MODAL -->
      <div
        v-if="closePDF || closeDocument"
        style=" position: absolute; background-color: #f5f5f5; width: 100%; height: 100%; padding: 1%; overflow: hidden; margin-right: 0;"
      >
        <v-card>
          <v-card-text>
            <v-main>
              <v-container fluid>
                <component
                  :pdf-url="current_pdf.itemUrl"
                  :license-key="LICENSE_KEY"
                  :base-url="baseUrl"
                  @close_pdf="closePdf"
                  :is="document_component"
                  :document-url="current_document.itemUrl"
                  :document-type="current_document.itemExtension"
                  :document-title="current_document.itemName"
                  :viewer-color="'rgb(38, 172, 194)'"
                  @close_document="documentClosed"
                  :document-list="documentList"
                  :list-color="'rgb(38, 172, 194)'"
                  @open_pdf="openDocument"
                  @open_document="openDocument"
                  @switch_pdf="switchDocument"
                  @switch_document="switchDocument"
                  :parent_select_type="true"
                  :parent_pdf_type="2"
                  :list-width="'25%'"
                  :briefcase-files="fileIds"
                  @briefcase_document="briefcaseDocument"
                />
              </v-container>
            </v-main>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <!-- END OF COMPONENT -->
  </div>
</template>

<script>
var moment = require("moment");
import Line from "../components/LineChart.vue";
import UserData from "../components/repository/UserData";
import PSPDFKit from "../components/PSPDFKit.vue";
import { useAuthStore } from "../stores/auth";
import { useIndexStore } from "../stores/index";
import { useAlertStore } from "../stores/alert";
import FileOptions from "./Cards/FileOptions.vue";
import axios from "axios";
import GlobalMethods from "./repository/GlobalMethods";
import DocumentViewer from './Cards/DocumentViewer.vue';

const LICENSE_KEY =
  "QvMr6j5706r3kYlOiVvJgEwu4fZnTL1fYz4t6paoBV5PekugaffzJnLtX5TczMS65OKAI1iIaSi06_l76UgF3kyKauJ9vaMZFuU3_oDplMFty6AhBhWPh80M7qoSa8xjtux3NqNrfqybICplLfHezwjhABIkPy6XxBMQ_uPU87_C5pMBXlc8A2PI_ShMjfhst6O_C0y9jyeMJ2DYudM3liFVsp90A0dOY51LM2Q3xV3jfcJ7K39FKpnKU_atpn1Ig8HXcmWdGQMjCXyquwVu9i2J850rGqBgFKJJFGixxnU3sZ__tA7Mb3aX8T2nXPlEnamtAhNf-DD1sEtqXHp5q5xxsFFuJDVh82tXKfd4oX8AkzWLd_-UVg_h_6ATML30e8fiQXSVcN0mmlZH5XYUv0jB5cHEyuIaGZac7gCNRn32pchJNpIwEdgHzWDRBQxxCR7F8b6z3Xs3yXJ6y9EhhrnKmjNYkMCdFJ0qNhI8zF8RhAbLA-uKtrzMy7hXLrlpuIHdGaCXMm7gA9iBpKiqwHIrX83rCFoVYUGM4BqNkim3HN8YJzZRDDI-ZHzdy7CcanSuvbe3NSxW5daeHdGVvg==";

export default {
  data() {
    return {
      opened: [],
      tab: "option-1",
      chartData: {},
      options: {},
      planMilestones: [],
      initialMileList:[],
      closePDF: false,
      closeDocument: false,
      pdf: "",
      LICENSE_KEY: LICENSE_KEY,
      baseUrl: "",
      token: localStorage.getItem("token") ? JSON.stringify(localStorage.getItem("token")).slice(1, -1) : "",
      auth: useAuthStore(),
      store: useIndexStore(),
      alert: useAlertStore(),
      globalMethods: GlobalMethods,
      documentList: [],
      files: [],
      fileIds: [],
      loading: {},
    };
  },

  components: {
    "line-graph": Line,
    pspdfkit: PSPDFKit,
    FileOptions,
    DocumentViewer,
  },

  methods: {
    formatDate(date) {
      return moment.unix(date).format("DD-MMM-YYYY");
    },

    reloadPage() {
      window.location.reload();
    },

    clickedLine(index) {
      let milestone = this.planMilestones[index];
      this.toggle(milestone);
    },

    toggle(item) {
      if (item.milestoneDocuments.length > 0) {
        this.documentList = item.milestoneDocuments;
        var id = item.milestoneId;
        const index = this.opened.indexOf(id);

        if (index > -1) {
          this.opened.splice(index, 1);
        } else {
          this.opened = [];
          this.opened.push(id);
        }
      } else {
        this.milestoneDocuments();
      }
    },

    milestoneDocuments() {
      this.alert.showInfo("No Milestones Documents Found");
    },

    briefcaseDocument(item) {
      if (item.itemExtension) {
        this.loading[item.itemId] = true;
        this.globalMethods.downloadEbrDocument(item.itemUrl).then(async (response) => {
          if (response.status == 200) {
            let fileType = response.headers['content-type'];
            let fileData = new Blob([response.data], { type: fileType });
            let blob = window.URL.createObjectURL(fileData);
            this.openDocument(item, blob);
            this.loading[item.itemId] = false;
          } else {
            this.loading[item.itemId] = false;
            console.log("Response: ", response);
          }
        }).catch((e) => {
          this.loading[item.itemId] = false;
          console.log("Error: ", e);
        });
      } else {
        this.alert.showInfo("Briefcasing Folders is Under Development");
      }
    },

    switchDocument(item) {
      this.store.setBriefcaseUrl(null);
      this.openDocument(item);
    },

    getDocument(item) {
      if (item.itemExtension == "pdf") {
        this.briefcaseDocument(item);
      } else {
        this.openDocument(item);
      }
    },

    openDocument(item, url = null) {
      if (item.itemExtension == "pdf") {
        this.openPdf(item, url);
      } else {
        this.store.setDialogDocumentViewer(true);
        this.closePDF = false;
        this.closeDocument = true;
        this.store.setCurrentDocument(item);
        this.store.setDocumentComponent("document-viewer");

        UserData.setDocumentId(item.itemId);
      }
    },

    openPdf(item, url = null) {
      this.store.setBriefcaseUrl(url);
      this.store.setDialogDocumentViewer(false);
      this.closeDocument = false;
      this.closePDF = true;
      this.store.setCurrentPdf(item);
      this.store.setCurrentDocument(item);
      this.store.setDocumentComponent('pspdfkit');

      UserData.setDocumentId(item.itemId);
      this.store.setDocumentId(item.itemId);
    },

    getPlanMilestoneList() {
      const formData = new FormData();
      formData.append( "userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append( "companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append( "accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "getPlanMilestoneList");
      formData.append( "companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      formData.append("planId", this.current_plan.planId);

      this.store.setLoading(true);
      axios.post(UserData.getBaseUrl(), formData).then((response) => {
        this.getPlanMilestoneList = response.data;
        this.planMilestones = this.getPlanMilestoneList.planMilestones;
        this.initialMileList = this.planMilestones;
        this.getPlanListArray = this.planMilestones[0];

        this.store.setLoading(false);

        this.plannedDate = this.planMilestones.map((item) =>
          this.formatDate(item.plannedDate)
        );

        this.chartData.labels = [];
        this.plannedDate.forEach((label) => {
          this.chartData.labels.push(label);
        });

        var sum = 0;

        this.chartData.datasets = [];
        this.chartData.datasets.push({
          label: "Actual",
          data: this.planMilestones.map((item) => {
            let index = this.planMilestones.indexOf(item);
            if (index != 0) {
              return sum += parseInt(item.percentage); 
            } else {
              sum = parseInt(item.percentage);
              return sum;
            }
          }),
          borderColor: "#26acc2",
          borderWidth: 2,
          backgroundColor: "white",
          fill: true,
        });

        this.chartData.datasets.push({
          label: "Planned",
          data: this.planMilestones.map((item) => {
            let index = this.planMilestones.indexOf(item);
            if (index != 0) {
              return sum += parseInt(item.percentageCompleted); 
            } else {
              sum = parseInt(item.percentageCompleted);
              return sum;
            }
          }),
          borderColor: "red",
          borderWidth: 2,
          backgroundColor: "white",
          fill: true,
        });

        this.options = { 
          onClick : (e, clickedElements) => {
            let index = clickedElements[0].index;
            let plan = this.initialMileList[index];
            this.toggle(plan);
          },
          
          onHover: (event, chartElement) => {
            event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
          },
        };
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    closePdf() {
      this.closePDF = !this.closePDF;
      this.store.setLoading(false);
      this.store.setCurrentPdf({});
    },

    documentClosed() {
      this.closeDocument = !this.closeDocument;
      this.store.setDialogDocumentViewer(false);
      this.store.setCurrentDocument({});
    },
  },

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },

    current_plan() {
      return this.store.getCurrentPlan;
    },

    planDocuments() {
      return this.current_plan.planDocuments[0];
    },

    current_pdf() {
      return this.store.getCurrentPdf;
    },

    current_document() {
      return this.store.getCurrentDocument;
    },

    document_component() {
      return this.store.getDocumentComponent;
    },

    overlay_type: {
      get() {
        return this.store.getOverlayType;
      },
      set(value) {
        this.store.setOverlayType(value);
      },
    },

    session_overlay: {
      get() {
        return this.store.getSessionOverlay;
      },
      set(value) {
        this.store.setSessionOverlay(value);
      },
    },
  },

  beforeMount() {
    this.documentList = this.current_plan.planDocuments;
    this.getPlanMilestoneList();
  },
};
</script>

<style scoped>
.nav-menu {
  margin-top: 0px;
  background-color: rgb(38, 172, 194);
  color: #fff;
  display: flex;
  margin-bottom: 1%;
  border: none;
  padding: 0px;
  margin-right: 15px;
  outline: none;
  justify-content: space-between;
}

.component {
  width: 99%;
  margin-left: 0.5%;
  margin-right: 0.5%;
  height: 900px;
}

p {
  text-decoration: none;
  color: #000000;
}

p:hover {
  color: #26acc2 !important;
}

.table-striped a {
  text-decoration: none;
  color: #000;
}

.table-striped a:hover {
  color: rgb(38, 172, 194);
}

.table-striped a:active {
  color: #26acc2;
}

.table-striped:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-striped:hover {
  background-color: rgba(255, 255, 255, 255) !important;
}

.name {
  cursor: pointer;
}

.name:hover {
  color: rgb(38, 172, 194);
}
</style>
