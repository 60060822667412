import PSPDFKit from "pspdfkit";
import { useAuthStore } from "../stores/auth";
import { useIndexStore } from "../stores/index";

export async function attachListeners(instance, buttons, toolbarItems) {
  instance.addEventListener("document.change", async () => {
    // Enable the "Reset" button if the document is signed
    const annotations = await instance.getAnnotations(0);
    updateToolbarItems(annotations, true, instance, buttons, toolbarItems);
  });

  instance.addEventListener("annotations.create", async (createdAnnotations) => {
    // const signatures = await instance.getSignaturesInfo();
    // const isDocumentSigned = signatures.status !== "not_signed";

    // if (isDocumentSigned) {
    //   // Bailing out since we just need to handle the scenario before a digital signature
    //   // has been placed.
    //   return;
    // }

    // const annotation = createdAnnotations.get(0);

    // if (annotation.isSignature) {
    //   // Position the created signature over signature form field
    //   // and enable the "Finish Signing" button.
    //   positionSignature(annotation, instance);
    //   // positionText(instance);
    //   // positionImage(instance);
    //   await instance.ensureChangesSaved(annotation);
    //   updateToolbarItems(createdAnnotations, true, instance, buttons, toolbarItems);
    // }
  });

  instance.addEventListener("annotations.delete", (deletedAnnotations) => {
    // Disable the "Finish Signing" button if a signature has been deleted
    updateToolbarItems(deletedAnnotations, false, instance, buttons, toolbarItems);
  });

  const annotations = await instance.getAnnotations(0);
  updateToolbarItems(annotations, true, instance, buttons, toolbarItems);
  return instance;
}

export async function positionSignatureElement(instance, text) {
  const results = await instance.search(text);

  const annotations = results.map((result) => {
    let boundingBox = PSPDFKit.Geometry.Rect.union(result.rectsOnPage);

    const bottom = boundingBox.bottom;
    const height = boundingBox.height;
    const left = boundingBox.left + 200;
    const right = boundingBox.right;
    const top = boundingBox.top + -10;
    const width = boundingBox.width;

    // appropiate rect for the space to fit the annotation in
    const signingSpace = new PSPDFKit.Geometry.Rect({
      height: height * 2,
      left: left,
      top: top,
      width: width * 2,
    });
    
    const widget = new PSPDFKit.Annotations.WidgetAnnotation({
      id: PSPDFKit.generateInstantId(),
      pageIndex: result.pageIndex,
      boundingBox: signingSpace,
      formFieldName: text,
    });

    const formField = new PSPDFKit.FormFields.SignatureFormField({
      name: text,
      annotationIds: new PSPDFKit.Immutable.List([widget.id]),
    });

    return [widget, formField];
  });

  await instance.create(annotations);
}

// Checks what toolbar items need to be enabled/disabled at a given time.
// When the document doesn't have any electronic signature yet, we want to
// disable the "Finish signing" button. Once a signature is placed,
// the electronic signature button should be disabled and the "Finish signing"
// should be enabled instead.
// Once the document has been signed, we show the "Reset" button.
async function updateToolbarItems(annotations, disableFinishIfNoAnnotations, instance, buttons, toolbarItems) {
  const auth = useAuthStore();
  const store = useIndexStore();
  // const signatures = await instance.getSignaturesInfo();
  const { resetButton, closeButton, finishButton } = buttons;
  const hasSignatureAnnotation = annotations.some((annotation) => annotation.isSignature);

  // When the document is loaded and when a signature annotation is
  // created or deleted, we need to enable or disable the signing custom
  // toolbar item accordingly. The "disableFinishIfNoAnnotations" boolean
  // will determine which disable state we'll update the toolbar button with.
  const shouldDisableFinishBtn = disableFinishIfNoAnnotations ? !hasSignatureAnnotation : hasSignatureAnnotation;

  const notSignedBtns = [{ type: "signature", disabled: !shouldDisableFinishBtn }, { ...finishButton, disabled: shouldDisableFinishBtn }, closeButton];
  const signedBtns = [{ type: "signature", disabled: true }, resetButton, closeButton];

  if (!auth.getDigitalSignatures || !store.getSignatureDocument) {
    // remove signature buttons
    notSignedBtns.splice(0, 2);
    signedBtns.splice(0, 2);
  }

  // const additionalButtons = signatures.status === "not_signed" ? notSignedBtns : signedBtns;
  const additionalButtons = notSignedBtns;

  instance.setToolbarItems([...toolbarItems, ...additionalButtons]);
}

// Helper function to properly place the signature annotation
// added by the user to the corresponding spot on the document.
// Based on https://pspdfkit.com/guides/web/current/knowledge-base/override-ink-signature-dialog/
function positionSignature(annotation, instance) {
  // appropiate rect for the space to fit the annotation in
  const signingSpace = new PSPDFKit.Geometry.Rect({
    width: 150,
    height: 40,
    left: 306.000,
    top: 512.097,
  });

  const newSize = fitIn(
    {
      width: annotation.boundingBox.width,
      height: annotation.boundingBox.height,
    },
    {
      width: signingSpace.width,
      height: signingSpace.height,
    }
  );

  const resizeRatio = newSize.width / annotation.boundingBox.width;
  const newLeft = signingSpace.left + signingSpace.width / 2 - newSize.width / 2;
  const newTop = signingSpace.top + signingSpace.height / 2 - newSize.height / 2;

  const newBoundingBox = new PSPDFKit.Geometry.Rect({
    left: newLeft,
    top: newTop,
    width: newSize.width,
    height: newSize.height,
  });

  if (annotation.lines) {
    const newLines = annotation.lines.map((line) => {
      return line.map((point) => {
        return new PSPDFKit.Geometry.DrawingPoint({
          x: newLeft + (point.x - annotation.boundingBox.left) * resizeRatio,
          y: newTop + (point.y - annotation.boundingBox.top) * resizeRatio,
        });
      });
    });

    instance.update(annotation.set("boundingBox", newBoundingBox).set("lines", newLines).set("lineWidth", annotation.lineWidth * resizeRatio));
  } else {
    instance.update(annotation.set("boundingBox", newBoundingBox));
  }
}

// Helper function to properly place the user text annotation
// added by the user to the corresponding spot on the document.
async function positionText(instance) {
  // appropiate rect for the space to fit the annotation in
  const textSpace = new PSPDFKit.Geometry.Rect({
    width: 120,
    height: 40,
    left: 480,
    top: 690,
  });

  var textAnnotation = new PSPDFKit.Annotations.TextAnnotation({
    pageIndex: 0,
    boundingBox: textSpace,
    text: { format: "plain", value : "Digitally Signed by Gilbert Were on 08/02/2023 1:58 PM" },
    isBold: true,
    fontSize: 12,
  });

  const newSize = fitIn(
    {
      width: textAnnotation.boundingBox.width,
      height: textAnnotation.boundingBox.height,
    },
    {
      width: textSpace.width,
      height: textSpace.height,
    }
  );
  
  const newLeft = textSpace.left + textSpace.width / 2 - newSize.width / 2;
  const newTop = textSpace.top + textSpace.height / 2 - newSize.height / 2;

  const newTextBoundingBox = new PSPDFKit.Geometry.Rect({
    left: newLeft,
    top: newTop,
    width: newSize.width,
    height: newSize.height,
  });

  await instance.create(textAnnotation.set("boundingBox", newTextBoundingBox));
}

// Helper function to properly place the user image annotation
// added by the user to the corresponding spot on the document.
async function positionImage(instance) {
  // appropiate rect for the space to fit the annotation in
  const imageSpace = new PSPDFKit.Geometry.Rect({
    width: 40,
    height: 40,
    left: 320,
    top: 690,
  });

  const request = await fetch("/img/neutral-icon.jpeg");
  const blob = await request.blob();
  const imageAttachmentId = await instance.createAttachment(blob);

  var imageAnnotation = new PSPDFKit.Annotations.ImageAnnotation({
    pageIndex: 0,
    contentType: "image/jpeg",
    imageAttachmentId,
    description: "User Digital Image",
    boundingBox: imageSpace,
    blendMode: 'darken',
  });

  const newSize = fitIn(
    {
      width: imageAnnotation.boundingBox.width,
      height: imageAnnotation.boundingBox.height,
    },
    {
      width: imageSpace.width,
      height: imageSpace.height,
    }
  );

  const newLeft = imageSpace.left + imageSpace.width / 2 - newSize.width / 2;
  const newTop = imageSpace.top + imageSpace.height / 2 - newSize.height / 2;

  const newTextBoundingBox = new PSPDFKit.Geometry.Rect({
    left: newLeft,
    top: newTop,
    width: newSize.width,
    height: newSize.height,
  });

  await instance.create(imageAnnotation.set("boundingBox", newTextBoundingBox));
}

function fitIn(size, containerSize) {
  const { width, height } = size;

  const widthRatio = containerSize.width / width;
  const heightRatio = containerSize.height / height;

  const ratio = Math.min(widthRatio, heightRatio);

  return {
    width: width * ratio,
    height: height * ratio,
  };
}

export const initialToolbarItems = [
  { type: "sidebar-thumbnails" },
  { type: "sidebar-bookmarks" },
  { type: "zoom-in" },
  { type: "zoom-out" },
  { type: "spacer" },
];