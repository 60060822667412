<template>
  <div>
    <!-- MATTERS ARISING FILTER MODAL -->
    <div class="text-center">
      <v-dialog v-model="dialog_filter" max-width="550px" scrollable style="z-index: 900;">
        <v-row justify="center">
          <v-card style="padding: 3%; background-color: #f5f5f5; width: 100%;">
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-card-actions>
                <v-card-title>
                  Filter
                </v-card-title>
                <v-spacer></v-spacer>
                <v-btn
                  variant="text"
                  @click="reset"
                  class="text-capitalize"
                  color="info"
                  style="font-size: 1.25rem;"
                >
                  Reset
                </v-btn>
              </v-card-actions>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="12" sm="12">
                      <v-autocomplete
                        label="Select User"
                        :items="assignees"
                        clearable
                        variant="outlined"
                        item-title="boardMemberName"
                        item-value="boardMemberId"
                        v-model="boardMemberId"
                      >
                        <template v-slot:prepend>
                          <span>Assigned To</span>
                        </template>
                      </v-autocomplete>
                    </v-col>

                    <v-col cols="6" md="6" sm="6">
                      <v-menu
                        v-model="fromMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ props }">
                          <v-text-field
                            v-model="fromDate"
                            :value="computedFromDate"
                            label="Select Date"
                            variant="outlined"
                            readonly
                            v-bind="props"
                            clearable
                          >
                            <template v-slot:prepend>
                              <span>From</span>
                            </template>
                          </v-text-field>
                        </template>
                        <Datepicker 
                          v-model="fromDateWatcher" 
                          placeholder="Select Date" 
                          required
                          inline="true"
                        />
                      </v-menu>
                    </v-col>

                    <v-col cols="6" md="6" sm="6">
                      <v-menu
                        v-model="toMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ props }">
                          <v-text-field
                            v-model="toDate"
                            :value="computedToDate"
                            label="Select Date"
                            variant="outlined"
                            readonly
                            v-bind="props"
                            clearable
                          >
                            <template v-slot:prepend>
                              <span>To</span>
                            </template>
                          </v-text-field>
                        </template>
                        <Datepicker 
                          v-model="toDateWatcher" 
                          @click="toMenu = false"
                          placeholder="Select Date" 
                          required
                          inline="true"
                        />
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="error"
                  @click="close"
                  variant="flat"
                  elevation="1"
                >
                  Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="info"
                  style="float: right"
                  variant="flat"
                  elevation="1"
                  :loading="store.loading"
                  @click="filterMattersArisingReport"
                >
                  Apply
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-row>
      </v-dialog>
    </div>
  </div>
</template>

<script>
var moment = require("moment");
import axios from "axios";
import UserData from "../repository/UserData";
import { useAuthStore } from "../../stores/auth";
import { useIndexStore } from "../../stores/index";
import { useAlertStore } from "../../stores/alert";
import Datepicker from 'vuejs3-datepicker';

export default {
  name: "MattersArisingFilter",
  props: {
    type: {
      type: Number,
      default: 0,
    },
    assignees: {
      type: Array,
      default: () => [],
    },
  },

  components: {
    Datepicker,
  },

  data: () => ({
    valid: false,
    boardMemberId: null,
    fromMenu: false,
    fromDate: null,
    fromDateWatcher: new Date().toISOString().substring(0, 10),
    toMenu: false,
    toDate: null,
    toDateWatcher: new Date().toISOString().substring(0, 10),
    rules: {
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
      selectRules: [(v) => !!v || "Select is required"],
      textRules: [
        v => !!v || 'Field is required',
        v => (v && v.length >= 3) || 'Min 3 characters',
      ],
      characterRules: [
        v => (v && v.length >= 3) || 'Min 3 characters',
      ],
    },
    token: localStorage.getItem("token") ? JSON.stringify(localStorage.getItem("token")).slice(1, -1) : "",
    auth: useAuthStore(),
    store: useIndexStore(),
    alert: useAlertStore(),
  }),

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },

    dialog_filter: {
      get() {
        return this.store.getDialogFilter;
      },
      set(value) {
        this.store.setDialogFilter(value);
      }
    },

    computedFromDate() {
      if (this.fromDate != null) {
        return this.formatDate(this.fromDate);
      } else {
        return '';
      }
    },

    computedToDate() {
      if (this.toDate != null) {
        return this.formatDate(this.toDate);
      } else {
        return '';
      }
    },
  },
  
  watch: {
    fromDateWatcher() {
      this.fromDate = this.formatDate(this.fromDateWatcher);
      this.fromMenu = false;
    },

    toDateWatcher() {
      this.toDate = this.formatDate(this.toDateWatcher);
      this.toMenu = false;
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD MMM YYYY");
    },

    validate() {
      return this.$refs.form.validate();
    },

    resetForm() {
      return this.$refs.form.reset();
    },

    resetValidation() {
      return this.$refs.form.resetValidation();
    },

    close() {
      this.reset();
      this.store.setDialogFilter(false);
      document.querySelector('html').classList.remove('v-overlay-scroll-blocked');
    },

    reset() {
      this.boardMemberId = null;
      this.fromDate = null;
      this.toDate = null;
    },

    filterMattersArisingReport() {
      if (!this.boardMemberId && !this.fromDate && !this.toDate) {
        this.alert.showInfo('Please select a date or user');
        setTimeout(() => {
          this.store.setDialogFilter(true);
        }, 1100);
      } else {
        this.validate().then((validator_response) => {
          this.valid = validator_response.valid;
  
          if (this.valid) {
            const formData = new FormData();
            formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
            formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
            formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
            formData.append("model", "applyFilterToMattersArising");
            formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
            formData.append("filterFromDate", this.fromDate != null ? moment(this.fromDate).format("X") : moment().subtract(30, 'y').format("X"));
            formData.append("filterToDate", this.toDate != null ? moment(this.toDate).format("X") : moment().format("X"));
            if (this.boardMemberId != null)
              formData.append("filterByUserId", this.boardMemberId);
  
            this.store.setLoading(true);
            axios.post(UserData.getBaseUrl(), formData).then((response) => {
              let mattersArisingList = [];
              if (response.data.success == 1) {
                mattersArisingList = response.data.mattersArisingList;
                this.$emit('filter_list', mattersArisingList);
              } else {
                this.$emit('filter_list', mattersArisingList);
              }

              let details = {};
              details.fromDate = this.fromDate != null ? this.fromDate : moment().subtract(30, 'y').format("DD MMM YYYY");
              details.toDate = this.toDate != null ? this.toDate : moment().format("DD MMM YYYY");
              details.boardMemberId = this.boardMemberId != null ? this.boardMemberId : 0;
              this.$emit('update_meeting', details);
  
              this.alert.showSuccess(response.data.message);
              this.store.setDialogFilter(false);
              this.store.setLoading(false);
              this.reset();
            })
            .catch((e) => {
              console.log("Error", e);
              this.store.setLoading(false);
            });
          }
        });
      }
    },
  },
};
</script>