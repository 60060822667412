<template>
  <v-menu 
    :close-on-content-click="true" 
    width="30%" 
    :disabled="select_type"
  >
    <template v-slot:activator="{ props }">
      <span :class="'name' + file_class" v-bind="props" @click="select_type ? openPdf(pdf_type) : () => {}">{{ file.itemName }}</span>
    </template>
    <v-list class="mt-3">
      <v-list-item-group
        v-model="selected"
        active-class="pink--text"
        multiple
      >
        <div
          v-for="(item, index) in getItems"
          :key="index"
        >
          <v-list-item
            :value="index"
            @click="openPdf(item.id)"
            :onmouseover="getFileColor"
            onmouseout="this.style.color='#000000'"
          >
            <v-row>
              <v-col cols="6" md="6" sm="6">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-col>
            </v-row>
          </v-list-item>
          <v-divider
            v-if="index < getItems.length - 1"
            :key="index"
            class="my-0"
          ></v-divider>
        </div>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import { useIndexStore } from '../../stores';

export default {
  name: "FileOptions",

  props: {
    file: {
      type: Object,
      default: () => {},
    },

    file_color: {
      type: String,
      default: "#e33333",
    },

    file_class: {
      type: String,
      default: "",
    },

    select_type: {
      type: Boolean,
      default: false,
    },

    pdf_type: {
      type: Number,
      default: null,
    },

    briefcaseFiles: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    getFileColor() {
      return "this.style.color='" + this.file_color + "'";
    },

    getItems() {
      var items = [];
      if (this.briefcaseFiles.length > 0 && this.briefcaseFiles.includes(this.file.itemId)) {
        items = this.items.slice().splice(0, 2);
      } else {
        items = this.items;
      }

      return items;
    },
  },

  data: () => ({
    options: false,
    selected: [],
    store: useIndexStore(),
    items: [
      {
        id: 1,
        title: "Original",
      },
      {
        id: 2,
        title: "Annotate",
      },
      {
        id: 3,
        title: "Briefcase",
      },
    ],
  }),

  methods: {
    openPdf(id) {
      switch (id) {
        case 2:
          this.$emit('open_pdf', id);
          this.store.setPdfType(true);
          break;
        case 3:
          this.$emit('briefcase_pdf', id);
          break;
        default:
          this.$emit('open_pdf', id);
          this.store.setPdfType(false);
          break;
      }
    },
  },
}
</script>

<style scoped>
.name {
  cursor: pointer !important;
}

.name:hover {
  color: v-bind('file_color');
}

.v-list-item:hover {
  color: v-bind('file_color');
}
</style>