
import { useAuthStore } from '../../stores/auth';
import GlobalMethods from './GlobalMethods';

class Countdown {
  
  static sessionTimeoutCountdown = {};
  static timeRemaining = 0;
  static intervalId = null;

  constructor(expiredDate) {
    Countdown.setExpiredDate(expiredDate);
  }

  static setSessionTimeoutCountdown = (sessionTimeoutCountdown, format) => {
    this.sessionTimeoutCountdown.days = format(sessionTimeoutCountdown.days);
    this.sessionTimeoutCountdown.hours = format(sessionTimeoutCountdown.hours);
    this.sessionTimeoutCountdown.minutes = format(sessionTimeoutCountdown.minutes);
    this.sessionTimeoutCountdown.seconds = format(sessionTimeoutCountdown.seconds);

    const auth = useAuthStore();
    auth.setSessionTimeDays(this.sessionTimeoutCountdown.days);
    auth.setSessionTimeHours(this.sessionTimeoutCountdown.hours);
    auth.setSessionTimeMinutes(this.sessionTimeoutCountdown.minutes);
    auth.setSessionTimeSeconds(this.sessionTimeoutCountdown.seconds);
  }

  static setExpiredDate = (expiredDate, restart = false) => {
    // get the current time
    const currentTime = new Date().getTime();

    // calculate the remaining time 
    this.timeRemaining = expiredDate.getTime() - currentTime;

    // should the countdown completes or start
    this.timeRemaining > 0 ? this.start(restart) : this.complete();
  }

  static complete = () => {
    if (typeof GlobalMethods.complete === 'function') {
      GlobalMethods.complete();
    }
  }

  static getTime = () => {
    return {
      days: Math.floor(this.timeRemaining / 1000 / 60 / 60 / 24),
      hours: Math.floor(this.timeRemaining / 1000 / 60 / 60) % 24,
      minutes: Math.floor(this.timeRemaining / 1000 / 60) % 60,
      seconds: Math.floor(this.timeRemaining / 1000) % 60
    };
  }

  static update = () => {
    if (typeof GlobalMethods.render === 'function') {
      GlobalMethods.render(this.getTime());
    }
  }

  static start = () => {
    // update the countdown
    this.update();

    // clear the interval
    Countdown.intervalId && clearInterval(Countdown.intervalId);

    // setup a timer
    Countdown.intervalId = setInterval(() => {
      // update the timer 
      this.timeRemaining -= 1000;
      
      Countdown.setSessionTimeoutCountdown(this.getTime(), GlobalMethods.format);
      if (this.timeRemaining < 0) {
        // call the callback
        this.complete();
      }
    }, 1000); 
  }
}

export default Countdown;