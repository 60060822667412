<template>
  <div>
    <v-app>
      <!-- NAV-MENU -->
      <div
        class="nav-menu"
        style="height: 45px; width: 100%; padding: 0px; padding-top: 0.3%; text-align: center;"
      >
        <div class="left-buttons">
          <router-link to="/dashboard" style="text-decoration: none">
            <v-icon color="#fff" size="35" style="margin-left: 10px"
              >mdi-home</v-icon
            >
          </router-link>
        </div>
        <div class="filler"></div>
        <p style="font-weight: bold; font-size: 24px;">
          EVALUATION
        </p>
        <div class="filler"></div>
        <div class="right-buttons">
          <v-icon 
            color="#ffffff" 
            class="mr-4"
            @click="reloadPage"
            icon="mdi-cached"
            size="35"
            style="cursor: pointer;"
          />
          <v-menu 
            location="bottom" 
            :close-on-content-click="false"
            transition="slide-x-transition"
          >
            <template v-slot:activator="{ props }">
              <v-avatar rounded="0" size="35" class="mr-5">
                <v-img
                  :src="user.userInfo.imageUrl !== '' ? user.userInfo.imageUrl : (gender == 'null' ? '/img/neutral-icon.jpeg' : (gender == 'M' ? '/img/male-icon.png' : '/img/female-icon.png'))"
                  style="height: 85px; width: 85px; background-color: #fff; border: 1px solid #27ae60; cursor: pointer;"
                  v-bind="props"
                />
              </v-avatar>
            </template>

            <div class="mt-2 mr-n16">
              <UserProfile :dashboard="false" />
            </div>
          </v-menu>
        </div>
      </div>

      <!-- COMPONENT -->
      <div
        style="margin-top: 0.5%; margin-bottom: 0.5%; background-color: #f8f9f9; border-top: 5px solid rgb(72, 61, 139); width: 98%; margin-left: 1%; margin-right: 1%;"
      >
        <div
          class="two"
          v-if="two"
          style="margin-top: 7px; position: absolute; background-color: #f5f5f5; width: 98%; height: 100vh; margin-right: 1%; z-index: 3;"
        >
          <div style="display: flex; margin-left: 1%; margin-bottom: 10px;">
            <v-icon
              @click="
                two = !two;
                categories = [];
                evaluateeName = '';
                comm = '';
                categoryId = 0;
                evaluationId = '';
                getEvaluationList();
              "
              style="color: #fff; background-color: rgb(72, 61, 139)"
              >mdi-chevron-left</v-icon
            >
            <h4 style="margin-left: 10px; color: rgb(72, 61, 139);">
              {{ evalName }} {{ " => " + evaluateeName }}
            </h4>
          </div>

          <div>
            <div
              v-if="left1"
              class="left1"
              style="float: left; width: 11%; height: 700px; overflow: auto; margin-left: 1%; margin-right: 1%; padding-bottom: 90px; text-align: center;"
            >
              <div>
                <v-list density="compact">
                  <v-list-item
                    v-for="(item, index) in evaluatees"
                    :key="index"
                    :value="item"
                    active-color="rgb(72, 61, 139)"
                  >
                    <v-list-item-content>
                      <v-card
                        @click="
                          questionArr = [];
                          showReview = false;
                          setEvaluateeId(item.userId);
                          setEvaluateeName(item.userName);
                          getEvaluationDetail();
                          comments = false;
                          commentsDialog = false;
                        "
                        style="width: 100%; height: auto; padding: 5px; margin: 7px; cursor: pointer;"
                      >
                        <img
                          v-if="item.userImageURL"
                          :src="item.userImageURL"
                          style="height: 90px; width: 80px; margin-top: 7px; border: 1px solid #000; border-radius: 50%;"
                        />
                        <img
                          v-else
                          src="/img/neutral-icon.jpeg"
                          style="height: 90px; width: 80px; margin-top: 7px; border: 1px solid #000; border-radius: 50%;"
                        />
                        <p class="text-wrap">
                          {{ item.userName }}
                        </p>
                      </v-card>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>
            </div>
            
            <div
              class="right1 category"
              :style="left1 ? 'float: left; width: 86%; height: 700px; overflow: auto; background-color: #f5f5f5; margin-right: 1%;' : 'float: left; width: 100%; overflow: auto; background-color: #f5f5f5; margin-right: 1%;'"
            >
              <div class="main__contentReview" v-if="showReview">
                <div
                  v-for="(category, index) in categories2"
                  v-bind:key="category.categoryId"
                  :class="index == (categories2.length - 1) ? 'review_category' : ''"
                >
                  <p
                    style="font-size: 17px; font-weight: bold; margin-top: 20px;"
                  >
                    {{ category.categoryName }}
                  </p>
                  <div 
                    class="review_cointainer" 
                    v-if="category.questions.length > 0"
                  >
                    <v-card
                      flat
                      v-for="(question, index) in category.questions"
                      v-bind:key="question.questionId"
                      class="question_row"
                      style="display: flex; border-bottom: 1px solid rgb(220, 220, 220);"
                    >
                      <v-card-text style="font-size: 19px;" :class="question.isOpenEnded ? 'w-75' : ''">
                        <span class="text-wrap">
                          {{ index + 1 + "." }} {{ question.questionName }}
                        </span>
                      </v-card-text>

                      <div class="question__rate" v-if="!question.isOpenEnded">
                        <div
                          v-bind:key="n"
                          v-for="n in 5"
                          class="rating_circle"
                          v-bind:class="{
                            ['rating_circle__active']:
                              n == question.userRate,
                          }"
                        >
                          {{ n }}
                        </div>
                      </div>

                      <div class="w-25" v-else>
                        <span class="text-wrap" style="font-size: 19px; color: rgb(72, 61, 139);">
                          {{ question.answer }}
                        </span>
                      </div>

                      <v-card-actions v-if="activeCategory.isComplete == 0 || (activeCategory.isComplete == 1 && question.questionComment != '')">
                        <v-btn 
                          icon="mdi-forum" 
                          color="rgb(72, 61, 139)" 
                          variant="outlined"
                          @click="checkCanCommentQuestion(question)"
                          :disabled="question.questionComment == ''"
                        />
                      </v-card-actions>
                    </v-card>
                  </div>
                  <div class="review_cointainer" v-else>
                    <v-card 
                      flat
                      style="display: flex; border-bottom: 1px solid rgb(220, 220, 220);"
                    >
                      <v-card-text style="font-size: 19px;">
                        <span class="text-wrap">
                          No questions found
                        </span>
                      </v-card-text>
                    </v-card>
                  </div>
                </div>
                <div
                  class="save__continue"
                >
                  <v-btn
                    :loading="loading"
                    :disabled="loading"
                    block
                    size="large"
                    color="grey"
                    @click="isLastQuiz && isLastEvaluated ? checkSubmitEvaluation() : nextCategory()"
                  >
                    {{ isLastQuiz && isLastEvaluated ? 'Submit Evaluation' : 'Save and Continue' }}  
                  </v-btn>
                </div>
              </div>

              <div v-else>
                <v-card v-if="categories1.length > 0">
                  <v-card-title
                    v-if="setsttId == 3"
                    style="color: red; font-weight: bold; font-size: 19px;"
                  >
                    Evaluation Completed
                  </v-card-title>

                  <v-tabs
                    show-arrows
                    slider-color="rgb(72, 61, 139)"
                    grow
                    v-model="tab"
                    background-color="rgb(220, 220, 220)"
                    color="rgb(72, 61, 139)"
                    style="font-weight: bolder"
                    v-if="categories1.length > 0"
                  >
                    <div
                      v-for="(category, i) in categories1"
                      :key="category.categoryId"
                    >
                      <v-tab
                        v-if="category.isComplete == 0"
                        :value="'tab-' + i"
                        @click="setActiveCategory(category, i)"
                      >
                        <p style="margin-top: 10px">
                          {{ category.categoryName }}
                        </p>
                      </v-tab>
                      <v-tab
                        v-else
                        @click="checkTabStatus(i, category)"
                        :value="'tab-' + i"
                      >
                        <p style="margin-top: 10px">
                          {{ category.categoryName }}
                        </p>
                      </v-tab>
                    </div>
                  </v-tabs>
                  
                  <v-card-text>
                    <v-window v-model="tab">
                      <v-window-item
                        v-for="(category, i) in categories1"
                        :key="category.categoryId"
                        :value="'tab-' + i"
                      >
                        <v-card v-if="setsttId == 1 || setsttId == 7">
                          <div>
                            <v-form
                              ref="form"
                              v-model="valid"
                              lazy-validation
                            >
                              <div v-if="category.questions.length > 0">
                                <v-card
                                  flat
                                  v-for="(question, index) in category.questions"
                                  :key="question.questionId"
                                  style="display: flex; border-bottom: 1px solid rgb(220, 220, 220);"
                                >
                                  <v-card-text style="font-size: 19px;" :class="question.isOpenEnded ? 'w-75' : ''">
                                    <span class="text-wrap">
                                      {{ index + 1 + "." }} {{ question.questionName }}
                                    </span>
                                  </v-card-text>
    
                                  <div class="question__rate" v-if="!question.isOpenEnded">
                                    <div
                                      v-bind:key="n"
                                      v-for="n in 5"
                                      class="rating_circle"
                                      @click="answerSelected(question, activeCategory, n)"
                                      v-bind:class="{
                                        ['rating_circle__active'] :
                                          n == question.userRate,
                                      }"
                                    >
                                      {{ n }}
                                    </div>
                                  </div>
    
                                  <div class="w-25" v-else>
                                    <v-textarea
                                      v-model="question.answer"
                                      :label="question.answer === '' ? 'Type your Answer Here' : ''"
                                      variant="outlined"
                                      :rules="answerRules"
                                      v-if="category.isComplete == 0"
                                      hide-details
                                      class="my-2"
                                      rows="1"
                                    ></v-textarea>

                                    <v-card-text 
                                      style="font-size: 19px; color: rgb(72, 61, 139);"  
                                      v-else
                                    >
                                      <span class="text-wrap">
                                        {{ question.answer }}
                                      </span>
                                    </v-card-text>
                                  </div>

                                  <v-card-actions v-if="activeCategory.isComplete == 0 || (activeCategory.isComplete == 1 && question.questionComment != '')">
                                    <v-btn 
                                      icon="mdi-forum" 
                                      color="rgb(72, 61, 139)" 
                                      variant="outlined"
                                      @click="checkCanCommentQuestion(question)"
                                    />
                                  </v-card-actions>
                                </v-card>
                              </div>
    
                              <div v-else>
                                <v-card
                                  class="text-center pa-4"
                                  flat
                                  style="border-bottom: 1px solid rgb(220, 220, 220); font-size: 19px;">
                                  <span>
                                    No Questions Found For This Category
                                  </span>
                                </v-card>                        
                              </div>
    
                              <div v-if="category.isComplete == 0">
                                <v-btn
                                  :loading="loading"
                                  :disabled="loading"
                                  block
                                  size="large"
                                  color="grey"
                                  @click="isLastQuiz && isLastEvaluated ? reviewEvaluation(category) : nextCategory(category)"
                                >
                                  {{ isLastQuiz && isLastEvaluated ? 'Review' : 'Save and Continue' }}  
                                </v-btn>
                              </div> 
    
                              <div v-else></div>
                            </v-form>
                          </div>
                        </v-card>
                        <v-card v-else>
                          <div v-if="category.questions.length > 0">
                            <v-card
                              flat
                              v-for="(question, index) in category.questions"
                              v-bind:key="question.questionId"
                              style="display: flex; border-bottom: 1px solid rgb(220, 220, 220);"
                            >
                              <v-card-text style="font-size: 19px">
                                <span class="text-wrap">
                                  {{ index + 1 + "." }} {{ question.questionName }}
                                </span>
                              </v-card-text>

                              <div class="question__rate">
                                <div
                                  v-bind:key="n"
                                  v-for="n in 5"
                                  class="rating_circle"
                                  style="border-radius: 50%; pointer-events: none;"
                                  v-on:click="
                                    () => {
                                      answerSelected(question, activeCategory, n);
                                    }
                                  "
                                  v-bind:class="{
                                    ['rating_circle__active']:
                                      n == question.userRate,
                                  }"
                                >
                                  {{ n }}
                                </div>
                              </div>
                            </v-card>
                          </div>

                          <div v-else>
                            <v-card
                              flat
                              style="display: flex; border-bottom: 1px solid rgb(220, 220, 220);"
                            >
                              <p class="text-center">
                                No Questions Found For This Category
                              </p>
                            </v-card> 
                          </div>
                        </v-card>
                      </v-window-item>
                    </v-window>
                  </v-card-text>
                </v-card>

                <v-card v-else>
                  <v-card-text v-if="store.getLoading">
                    <v-container
                      fill-height
                      fluid
                      class="lighten-5 text-center"
                      style="position: relative;"
                    >
                      <!-- Loader  -->
                      <v-row align="center" justify="center">
                        <v-col>
                          <v-progress-circular
                            indeterminate
                            color="rgb(72, 61, 139)"
                            class="my-3"
                          ></v-progress-circular>
                          <p caption>Loading... Please wait</p>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-text v-else>
                    <p class="text-center">
                      No Questions Found For This Evaluation
                    </p>
                  </v-card-text>
                </v-card>
              </div>
            </div>
          </div>

          <!-- FOOTER -->
          <div
            style="display: flex; margin-left: 1%; margin-right: 1%; color: rgb(72, 61, 139); margin-top: 15px; bottom: 0; position: fixed; height: 90px; width: 99%;"
          >
            <v-footer
              v-bind="localAttrs"
              :padless="padless"
            >
              <!-- SHOW/HIDE USERS -->
              <v-card
                @click="left1 = !left1"
                style="width: 19%; margin-right: 1.5%; color: rgb(72, 61, 139); text-align: center; cursor: pointer; padding-top: 7px;"
              >
                <div v-if="!left1">
                  <v-icon size="45" color="rgb(72,61,139)"
                    >mdi-eye-outline</v-icon
                  >
                  <h6>Show Users</h6>
                </div>
                <div v-else>
                  <v-icon size="45" color="rgb(72,61,139)"
                    >mdi-eye-off-outline</v-icon
                  >
                  <h6>Hide Users</h6>
                </div>
              </v-card>

              <!-- COMMENTS -->
              <v-menu 
                v-model="comments"
                location="top"
                transition="slide-x-transition"
              >
                <template v-slot:activator="{ props }">
                  <v-card
                    v-bind="props"
                    @click="checkCanComment"
                    style="width: 19%; margin-right: 1.5%; color: rgb(72, 61, 139); text-align: center; cursor: pointer; padding-top: 7px;"
                  >
                    <v-icon size="45" color="rgb(72,61,139)">mdi-forum</v-icon>
                    <h6>Evaluation Comments</h6>
                  </v-card>
                </template>

                <div class="mb-6" v-if="comments">
                  <EvaluationComments 
                    :chairman="isChairman" 
                    :evaluation="evaluationDetail" 
                    :category="activeCategory"
                    :load="trigger"
                    @comments_opened="commentsOpened" 
                  />
                </div>
              </v-menu>

              <div class="text-center">
                <EvaluationCommentsDialog
                  :chairman="isChairman" 
                  :evaluation="evaluationDetail" 
                  :category="activeCategory"
                  :load="trigger"
                  @comments_opened="commentsOpened" 
                />
              </div>

              <div class="text-center">
                <EvaluationQuestionsCommentsDialog
                  :chairman="isChairman" 
                  :evaluation="evaluationDetail" 
                  :category="activeCategory"
                  :load="trigger"
                  @question_comments_opened="questionCommentsOpened" 
                  :selected-question="selectedQuestion"
                  @submit_question_comments="submitQuestionComments"
                  :show-review="showReview"
                />
              </div>

              <!-- SCALES -->
              <v-dialog v-model="dialog" persistent max-width="290">
                <template v-slot:activator="{ props }">
                  <v-card
                    v-bind="props"
                    style="width: 19%; margin-right: 1.5%; color: rgb(72, 61, 139); text-align: center; cursor: pointer; padding-top: 7px;"
                  >
                    <v-icon size="45" color="rgb(72,61,139)">mdi-numeric</v-icon>
                    <h6>Scales</h6>
                  </v-card>
                </template>
                <v-card style="text-align: center">
                  <v-card-title
                    class="headline grey--text darken-1 justify-center"
                    style="font-weight: bold; font-size: 24px;"
                    >Scale Information</v-card-title
                  >
                  <v-card-text
                    style="font-size: 17px"
                  >
                    <v-list
                      v-for="(item, index) in scaleInfo"
                      :key="index"
                    >
                      <v-list-item density="compact">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item.scale }} {{ " - " }} {{ item.description }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn dark color="rgb(72,61,139)" @click="dialog = false"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <!-- REPORTS -->
              <v-card
                @click="getEvaluationReport"
                style="width: 19%; margin-right: 1.5%; color: rgb(72, 61, 139); text-align: center; cursor: pointer; padding-top: 7px;"
              >
                <v-icon size="45" color="rgb(72,61,139)"
                  >mdi-file-document</v-icon
                >
                <h6>Reports</h6>
              </v-card>

              <!-- GUIDELINES -->
              <v-dialog v-model="dialog2" persistent max-width="450px">
                <template v-slot:activator="{ props }">
                  <v-card
                    v-bind="props"
                    style="width: 19%; margin-right: 1.5%; color: rgb(72, 61, 139); text-align: center; cursor: pointer; padding-top: 7px;"
                  >
                    <v-icon size="45" color="rgb(72,61,139)"
                      >mdi-glass-stange</v-icon
                    >
                    <h6>Guidelines</h6>
                  </v-card>
                </template>
                <v-card style="text-align: center">
                  <v-card-title
                    class="headline grey--text darken-1"
                    style="font-weight: bold; font-size: 24px"
                  >
                    Evaluation Guidelines</v-card-title
                  >
                  <v-card-text style="font-size: 17px; text-align: justify">
                    <p v-if="guidelines.length == 0" style="color: red">
                      This evaluation has no guidelines
                    </p>
                    <p v-else>{{ guidelines }}</p>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn dark color="rgb(72,61,139)" @click="dialog2 = false"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <!-- QUESTIONS -->
              <v-dialog v-model="dialog3" persistent max-width="900px">
                <template v-slot:activator="{ props }">
                  <v-card
                    v-bind="props"
                    style="width: 19%; color: rgb(72, 61, 139); text-align: center; cursor: pointer; padding-top: 7px;"
                  >
                    <v-icon size="45" color="rgb(72,61,139)"
                      >mdi-format-list-text</v-icon
                    >
                    <h6>Questions</h6>
                  </v-card>
                </template>

                <div
                  style="height: 700px; border-top: 5px solid rgb(72, 61, 139);"
                >
                  <div style="height: 8%;">
                    <v-card-title
                      class="headline grey lighten-2"
                      style="background-color: rgb(255, 255, 255); color: rgb(72, 61, 139); margin-bottom: 15px; padding-bottom: 15px;"
                    >
                      <v-toolbar class="bg-white">
                        <v-toolbar-title
                          style="color: rgb(72, 61, 139);"
                        >
                          Category Wise Questions List
                        </v-toolbar-title>
                        <v-toolbar-items>
                          <v-btn
                            class="btn"
                            color="rgb(72, 61, 139)"
                            @click="dialog3 = false;"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </v-toolbar-items>
                      </v-toolbar>

                      <v-container fill-height fluid>
                        <v-row align="center">
                          <button
                            class="btn"
                            v-on:click="dialog3 = false"
                            style="background-color: rgb(72, 61, 139);; float: left; margin: 5px;"
                          >
                            <v-icon color="white" size="x-large">mdi-close</v-icon>
                          </button>
                          Category wise questions list
                        </v-row>
                      </v-container>
                    </v-card-title>
                  </div>
                  <div style="height: 92%; overflow: auto">
                    <v-card class="grey lighten-2" style="padding-bottom: 20px" v-if="categories.length > 0">
                      <div
                        style="overflow: auto"
                        v-for="(item, index) in categories"
                        :key="index"
                      >
                        <v-card-text
                          class="ml-3"
                          style="color: rgb(72, 61, 139); font-size: 19px; font-weight: bold;"
                          >{{ item.categoryName }}</v-card-text
                        >
                        <div
                          v-for="(item, index) in item.questions"
                          :key="index"
                          style="background-color: #fff; width: 96%; margin-left: 2%; margin-right: 2%;"
                        >
                          <div style="display: flex">
                            <v-card-text style="">{{
                              item.questionName
                            }}</v-card-text>
                          </div>
                          <v-divider style="margin: 0px"></v-divider>
                        </div>
                      </div>
                    </v-card>
                    <v-card class="grey lighten-2" style="padding-bottom: 20px" v-else>
                      <v-card-text
                        style="color: rgb(72, 61, 139); font-size: 19px; font-weight: bold;"
                      >
                        No category wise questions found
                      </v-card-text>
                    </v-card>
                  </div>
                </div>
              </v-dialog>
            </v-footer>
          </div>
          <!-- END OF FOOTER -->
        </div>

        <div class="one">
          <div
            class="parent"
            style="height: 45px; width: 100%; margin-top: 7px; background-color: #fff;"
          >
            <div class="left-buttons">
              <p
                style="float: left; font-weight: bold; font-size: 24px; text-align: left; margin-left: 10px; width: 250px;"
              >
                Evaluation
              </p>
            </div>
            <div class="filler">
              <div class="dropdown-table float-right" style="display: inline;">
                <v-menu>
                  <template v-slot:activator="{ props }">
                    <v-btn 
                      variant="text" 
                      v-bind="props"
                      class="btn"
                      :style="getFilterStyle(9)"
                    >
                      {{ sort.desc ? 'Descending' : 'Ascending' }}
                      <v-icon>mdi-swap-vertical-bold</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item style="display: block">
                      <v-list-item-title
                        style="padding: 12px; font-size: 16px; cursor: pointer"
                        @click="sort.desc = true"
                        >Descending
                      </v-list-item-title>

                      <v-list-item-title
                        style="padding: 12px; font-size: 16px; cursor: pointer"
                        @click="sort.desc = false"
                        >Ascending
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
            <div class="dropdown-table float-right" style="display: inline;">
              <v-menu>
                <template v-slot:activator="{ props }">
                  <v-btn 
                    color="#000" 
                    variant="text" 
                    v-bind="props"
                    class="btn"
                    :style="getFilterStyle(9)"
                  >
                    {{ filterName }}
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item style="display: block">
                    <v-list-item-title
                      style="padding: 12px; font-size: 16px; cursor: pointer"
                      v-on:click="doSort('evaluationName', 9, 'Name')"
                      >Name
                      <span v-if="sort.field == 'evaluationName'"
                        >({{ sort.desc ? "desc" : "asc" }})</span
                      >
                    </v-list-item-title>

                    <v-list-item-title
                      style="padding: 12px; font-size: 16px; cursor: pointer"
                      v-on:click="doSort('dateCreated', 9, 'Created On')"
                      >Created On
                      <span v-if="sort.field == 'dateCreated'"
                        >({{ sort.desc ? "desc" : "asc" }})</span
                      >
                    </v-list-item-title>

                    <v-list-item-title
                      style="padding: 12px; font-size: 16px; cursor: pointer"
                      v-on:click="doSort('dateStarted', 9, 'Start Date')"
                      >Start Date
                      <span v-if="sort.field == 'dateStarted'"
                        >({{ sort.desc ? "desc" : "asc" }})</span
                      >
                    </v-list-item-title>

                    <v-list-item-title
                      style="padding: 12px; font-size: 16px; cursor: pointer"
                      v-on:click="doSort('dateEnding', 9, 'End Date')"
                      >End Date
                      <span v-if="sort.field == 'dateEnding'"
                        >({{ sort.desc ? "desc" : "asc" }})</span
                      >
                    </v-list-item-title>

                    <v-list-item-title
                      style="padding: 12px; font-size: 16px; cursor: pointer"
                      v-on:click="doSort('donePercent', 9, '% Done')"
                      >% Done
                      <span v-if="sort.field == 'donePercent'"
                        >({{ sort.desc ? "desc" : "asc" }})</span
                      >
                    </v-list-item-title>

                    <v-list-item-title
                      style="padding: 12px; font-size: 16px; cursor: pointer"
                      v-on:click="doSort('status_code', 9, 'Status')"
                      >Status
                      <span v-if="sort.field == 'status_code'"
                        >({{ sort.desc ? "desc" : "asc" }})</span
                      >
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <div class="right-buttons" style="display: inline;">
              <div style="display: inline; margin-right: 10px;">
                <v-btn
                  class="btn"
                  :style="getFilterStyle(0)"
                  variant="text"
                  @click="filterEvaluationList(0)"
                >
                  All
                </v-btn>

                <v-btn
                  class="btn"
                  :style="getFilterStyle(1)"
                  variant="text"
                  @click="filterEvaluationList(1)"
                >
                  Open
                </v-btn>

                <v-btn
                  class="btn"
                  :style="getFilterStyle(7)"
                  variant="text"
                  @click="filterEvaluationList(7)"
                >
                  Ongoing
                </v-btn>

                <v-btn
                  class="btn"
                  :style="getFilterStyle(3)"
                  variant="text"
                  @click="filterEvaluationList(3)"
                >
                  Completed
                </v-btn>
              </div>
            </div>
          </div>

          <div v-if="store.getLoading">
            <v-progress-linear
              color="rgb(72, 61, 139)"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </div>

          <div class="blackish" style="width: 100%; margin-right: 1%;">
            <!-- Table -->
            <v-table 
              v-if="sortedData2.length > 0"
              fixed-header
              height="100vh"
            >
              <thead>
                <tr>
                  <th style="background-color: rgb(72, 61, 139); font-weight: bolder;" class="text-left text-white">Name</th>
                  <th style="background-color: rgb(72, 61, 139); font-weight: bolder;" class="text-left text-white">From</th>
                  <th style="background-color: rgb(72, 61, 139); font-weight: bolder;" class="text-left text-white">To</th>
                  <th style="background-color: rgb(72, 61, 139); font-weight: bolder;" class="text-left text-white">Created On</th>
                  <th style="background-color: rgb(72, 61, 139); font-weight: bolder;" class="text-left text-white">% Done</th>
                  <th style="background-color: rgb(72, 61, 139); font-weight: bolder;" class="text-left text-white">Status</th>
                </tr>
              </thead>
              <tbody
                v-for="item in sortedData2" 
                :key="item.evaluationId"
                class="table-striped"
              >
                <tr
                  style="cursor: pointer;"
                  @click="
                    questionArr = [];
                    setEvaluationId(item.evaluationId);
                    setSttId(item.stt_id);
                    setEvalName(item.evaluationName);
                    setGuidelines(item.evaluationGuidelines);
                    setScales(item.scaleInformation);
                    setCanComment(item.canComment);
                    setIsChairman(item.isChairman);
                    setEvaluationDetails(item);
                  "
                >
                  <td
                    style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                  >
                    <a>
                      {{ item.evaluationName }}
                    </a>
                  </td>
                  <td
                    style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                  >
                    {{ formatDate(item.dateStarted) }}
                  </td>
                  <td
                    style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                  >
                    {{ formatDate(item.dateEnding) }}
                  </td>
                  <td
                    style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                  >
                    {{ formatDate(item.dateCreated) }}
                  </td>
                  <td
                    style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                  >
                    {{ item.donePercent.toFixed(2) }} %
                  </td>
                  <td
                    style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                  >
                    <v-btn 
                      block
                      color="primary" 
                      v-if="item.stt_id == 1"
                    >
                      Open
                    </v-btn>
                    <v-btn 
                      block
                      color="warning" 
                      v-else-if="item.stt_id == 7"
                    >
                      Ongoing
                    </v-btn>
                    <v-btn 
                      block
                      color="error" 
                      v-else-if="item.stt_id == 3"
                    >
                      Completed 
                    </v-btn>
                    <v-btn 
                      block
                      color="info" 
                      v-else
                    >
                      Not Set
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </v-table>

            <v-table v-else>
              <thead>
                <tr style="background-color: rgb(72, 61, 139); color: #fff; font-weight: bolder;">
                  <td class="text-left">Name</td>
                  <td class="text-left">From</td>
                  <td class="text-left">To</td>
                  <td class="text-left">Created On</td>
                  <td class="text-left">% Done</td>
                  <td class="text-left">Status</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td 
                    colspan="7"
                    class="text-center"
                  >
                    <span>
                      No Evaluations Found
                    </span>
                  </td>
                </tr>
              </tbody>
            </v-table>
          </div>
        </div>
      </div>
      <!-- END OF COMPONENT -->
    </v-app>
  </div>
</template>

<script>
var moment = require("moment");
import axios from "axios";
import UserData from "./repository/UserData";
import { useAuthStore } from "../stores/auth";
import { useIndexStore } from "../stores/index";
import { useAlertStore } from "../stores/alert";
import UserProfile from './Cards/UserProfile.vue';
import EvaluationComments from './Cards/EvaluationComments.vue';
import EvaluationCommentsDialog from "./Forms/EvaluationCommentsDialog.vue";
import EvaluationQuestionsCommentsDialog from "./Forms/EvaluationQuestionsCommentsDialog.vue";

const LICENSE_KEY =
  "QvMr6j5706r3kYlOiVvJgEwu4fZnTL1fYz4t6paoBV5PekugaffzJnLtX5TczMS65OKAI1iIaSi06_l76UgF3kyKauJ9vaMZFuU3_oDplMFty6AhBhWPh80M7qoSa8xjtux3NqNrfqybICplLfHezwjhABIkPy6XxBMQ_uPU87_C5pMBXlc8A2PI_ShMjfhst6O_C0y9jyeMJ2DYudM3liFVsp90A0dOY51LM2Q3xV3jfcJ7K39FKpnKU_atpn1Ig8HXcmWdGQMjCXyquwVu9i2J850rGqBgFKJJFGixxnU3sZ__tA7Mb3aX8T2nXPlEnamtAhNf-DD1sEtqXHp5q5xxsFFuJDVh82tXKfd4oX8AkzWLd_-UVg_h_6ATML30e8fiQXSVcN0mmlZH5XYUv0jB5cHEyuIaGZac7gCNRn32pchJNpIwEdgHzWDRBQxxCR7F8b6z3Xs3yXJ6y9EhhrnKmjNYkMCdFJ0qNhI8zF8RhAbLA-uKtrzMy7hXLrlpuIHdGaCXMm7gA9iBpKiqwHIrX83rCFoVYUGM4BqNkim3HN8YJzZRDDI-ZHzdy7CcanSuvbe3NSxW5daeHdGVvg==";

export default {
  data: () => ({
    moment: moment,
    getRecentDocuments: [],
    itemSubArray: [],
    itemSubArray2: [],
    itemSubArray3: [],
    evaluations: [],
    evaluationList: [],
    evaluatees: [],
    evaluationDetail: [],
    categories: [],
    questions: [],
    buttonLoop: [],
    scaleInformation: [],
    questionArr: [],
    scaleInfo: [],
    ansLength: "",
    quesLength: "",
    valSelect: "",
    questionX: "",
    stt_id: "",
    setsttId: "",
    evaluateeName: "",
    evalName: "",
    guidelines: "",
    canComment: "",
    isChairman: "",
    categoryId: 0,
    commentsTextarea: "",
    comm: "",
    commentMessage: "",
    evaluateeId: "",
    selected: [],
    padless: false,
    variant: 'fixed',
    left1: true,
    evaluationId: "",
    success: "",
    navigationPath: [
      {
        itemName: "...",
        localUrl: "0",
      },
    ],
    parentItemId: "",

    currentComponent: null,

    dynamicComponent: {
      template: `<p>Wheee</p>`,
    },

    sort: {
      field: "",
      desc: true,
      code: null,
    },

    loading: false,
    answer: "",
    valid: false,
    answerRules: [
      v => !!v || 'Answer is required',
      v => (v && v.length >= 3) || 'Answer must be greater than 3 characters',
    ],

    LICENSE_KEY: LICENSE_KEY,
    baseUrl: "",
    errorMsg: "",

    two: false,
    closePDF: false,
    tab: null,
    tabs: {},
    dialog: false,
    dialog2: false,
    dialog3: false,
    dialog4: false,
    dialogg: false,
    comments: false,
    commentsDialog: false,
    commentsDialog2: false,
    commentsDialog3: false,

    categories1: [],
    categories2: [],
    activeCategory: {},
    showReview: false,
    filterName: "Created On",

    token: localStorage.getItem("token") ? JSON.stringify(localStorage.getItem("token")).slice(1, -1) : "",
    store: useIndexStore(),
    auth: useAuthStore(),
    alert: useAlertStore(),
    trigger: false,
    selectedQuestion: {},
  }),

  methods: {
    formatDate(date) {
      return moment.unix(date).format("DD-MMM-YYYY");
    },

    validate () {
      if (typeof this.activeCategory !== 'undefined') {
        var currentCategoryIndex = this.categories1.findIndex((category) => {
          return this.activeCategory.categoryId == category.categoryId;
        });

        if (this.$refs.form[currentCategoryIndex]) {
          return this.$refs.form[currentCategoryIndex].validate();
        } else {
          return this.$refs.form[0].validate();
        }
      }
    },

    reset () {
      return this.$refs.form[0].reset();
    },

    resetValidation () {
      return this.$refs.form[0].resetValidation();
    },

    reloadPage() {
      window.location.reload();
    },

    cancel() {
      this.commentsTextarea = "";
      this.commentMessage = "";
      document.querySelector('html').classList.remove('v-overlay-scroll-blocked');
    },

    commentsOpened(status) {
      this.comments = status;
    },

    questionCommentsOpened(status) {
      this.dialog_evaluation_question_comments = status;
      this.$nextTick(() => {
        this.selectedQuestion = Object.assign({}, {});
      });
    },

    submitQuestionComments(selectedQuestion) {
      let currentCategoryIndex = -1;
      currentCategoryIndex = this.categories1.findIndex((item) => item.categoryId == this.activeCategory?.categoryId);
      if (currentCategoryIndex > -1) {
        let selectedQuestionIndex = -1;
        selectedQuestionIndex = this.categories1[currentCategoryIndex]?.questions.findIndex((item) => item.questionId == selectedQuestion.question?.questionId);
        if (selectedQuestionIndex > -1) {
          this.categories1[currentCategoryIndex].questions[selectedQuestionIndex].questionComment = selectedQuestion.comments;
        }
      }
    },

    checkCanComment() {
      if (this.canComment == 1) {
        if (this.comm != '') {
          this.comments = true;
        } else {
          this.alert.showInfo("Select an evaluatee to enable comments");
        }
      } else {
        this.comments = false;
        this.alert.showInfo("Comments not allowed for this evaluation");
      }
    },

    checkCanCommentQuestion(question = null) {
      if (this.canComment == 1) {
        if (question != null) {
          this.selectedQuestion = question;
          this.dialog_evaluation_question_comments = true;
        } else {
          this.alert.showInfo("Select an evaluatee to enable comments");
        }
      } else {
        this.dialog_evaluation_question_comments = false;
        this.alert.showInfo("Comments not allowed for this evaluation");
      }
    },

    checkCategory(category) {
      if (typeof category !== 'undefined') {
        var status = false;
        var answered = 0;

        category.questions.map((item) => {
          if (!item.isOpenEnded) {
            if (parseInt(item.userRate) > 0) {
              answered++;
            }
          } else {
            if (item.userRate !== "") {
              answered++;
            }
          }
        });

        if (answered == category.questions.length) {
          if (category.isComplete == 1) {
            status = false;
          } else {
            status = true;
          }
        } else {
          status = false;
        }
        
        return status;
      }
    },

    checkTabStatus() {
      if (typeof this.activeCategory !== 'undefined') {
        var currentCategoryIndex = this.categories1.findIndex((category) => {
          return this.activeCategory.categoryId == category.categoryId;
        });
      }

      if (this.checkCategory(this.activeCategory)) {
        this.tabs[currentCategoryIndex] = true;
      }
    },

    getEvaluationList() {
      const formData = new FormData();
      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "getEvaluationList");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      this.store.setLoading(true);

      axios.post(UserData.getBaseUrl(), formData).then((response) => {
        this.getEvaluationListArr = response.data;
        this.evaluations = this.getEvaluationListArr.evaluationList;
        this.filterEvaluationList(1);
        this.store.setLoading(false);
        this.doInitialSort('dateCreated');
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    setEvaluateeId(item) {
      this.evaluateeId = UserData.setEvaluateeId(item);
      this.comm = this.evaluateeId;
      this.store.setEvaluateeId(item);
    },

    setEvaluationDetails(item) {
      this.evaluatees = [];
      this.evaluatees = item.evaluatees;
      this.scaleInformation = [];
      this.scaleInformation = item.scaleInformation;

      // Get First Evaluatee In Evaluation
      var firstEvaluatee = this.evaluatees[0];
      this.setEvaluateeId(firstEvaluatee['userId']);
      this.setEvaluateeName(firstEvaluatee['userName']);
      this.getEvaluationDetail();

      if (this.showReview) {
        this.showReview = !this.showReview;
      }

      if (item.stt_id != 3) {
        this.two = !this.two;
      } else {
        this.getEvaluationReport();
      }
    },

    setEvaluationId(item) {
      this.evaluationId = UserData.setEvaluationId(item);
      this.store.setEvaluationId(item);
    },

    setSttId(item) {
      this.setsttId = item;
    },

    setEvalName(item) {
      this.evalName = item;
    },

    setEvaluateeName(item) {
      this.evaluateeName = item;
    },

    setGuidelines(item) {
      this.guidelines = item;
    },

    setScales(item) {
      this.scaleInfo = item.sort((a, b) => a.scale > b.scale ? 1 : -1);
    },

    setCanComment(item) {
      this.canComment = item;
    },

    setIsChairman(item) {
      this.isChairman = item;
    },

    setCategoryId(item) {
      this.categoryId = item;
    },

    getEvaluationDetail(update = false) {
      const formData = new FormData();
      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "getEvaluationDetail");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      formData.append("evaluationId", UserData.getEvaluationId() ? UserData.getEvaluationId() : this.store.getEvaluationId);
      formData.append("evaluateeId", UserData.getEvaluateeId() ? UserData.getEvaluateeId() : this.store.getEvaluateeId);
      this.alert.setChecked(null);

      if (!update) {
        this.categories = [];
        this.categories1 = [];
      }

      this.store.setLoading(true);
      axios.post(UserData.getBaseUrl(), formData).then((response) => {
        this.getEvaluationDetailArray = response.data;
        this.evaluationDetail = this.getEvaluationDetailArray.evaluationDetail;

        this.categories =
          this.getEvaluationDetailArray.evaluationDetail.categories.map(
            (category) => {
              return {
                ...category,
                questions: [
                  ...category.questions.map((question) => {
                    return {
                      ...question,
                      buttonLoop: [
                        { val: "1" },
                        { val: "2" },
                        { val: "3" },
                        { val: "4" },
                        { val: "5" },
                      ],
                    };
                  }),
                ],
              };
            }
          );

        const categories1 =
          this.getEvaluationDetailArray.evaluationDetail.categories.map(
            (category) => {
              if (category.isComplete) {
                return {
                  ...category,
                  questions: category.questions.map((quiz) => {
                    return {
                      ...quiz,
                      userRate: quiz.score,
                    };
                  }),
                };
              } else {
                return {
                  ...category,
                  questions: category.questions.map((quiz) => {
                    return {
                      ...quiz,
                      userRate: null,
                    };
                  }),
                };
              }
            }
          );

        this.categories1 = categories1;

        if (!update && this.categories1.length > 0) {
          this.activeCategory = categories1.length > 0 ? categories1[0] : null;

          this.categories1.forEach((category, i) => {
            if (this.checkCategory(category)) {
              this.tabs[i] = true;
            } else {
              this.tabs[i] = false;
            }
          });

          this.store.setEvaluatedStatus(false);
          if ((this.activeCategory.questions.find(question => parseInt(question.score)) && this.activeCategory.questions.find(question => parseInt(question.score) > 0).score) > 0) {
            this.store.setEvaluatedStatus(true);
          }

          this.$nextTick(() => { this.tab = 'tab-' + 0 });
          this.store.setLoading(false);
        }
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    saveAndContinue(category) {
      category.questions.map((question) => {
        var choice = { 
          answer: question.userRate || question.answer, 
          question: question.questionId, 
        };

        var index = this.questionArr.findIndex((c) => {
          if (c.question == choice.question) {
            return true;
          }
          return false;
        });

        if (index == -1) {
          this.questionArr.push(choice);
        } else {
          this.questionArr[index] = { ...choice };
        }
      });

      this.updateAllQuestions(category);
    },

    checkUnanswered(item) {
      var ans = this.questionArr.map((ans) => ans.answer);
      this.ansLength = ans.length;
      this.quesLength = item.questions.length;
      ans.length = 0;
    },

    updateAllQuestions(category) {
      var evaluations = [];
      evaluations.push({
        answer: this.questionArr.map((ans) => ans.answer).toString(),
        userId: UserData.getEvaluateeId() ? UserData.getEvaluateeId() : this.store.getEvaluateeId,
        evaluationId: UserData.getEvaluationId() ? UserData.getEvaluationId() : this.store.getEvaluationId,
        question: this.questionArr.map((ques) => ques.question).toString(),
      });

      const formData = new FormData();
      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "updateAllQuestions");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      formData.append("evaluations", JSON.stringify(evaluations));

      this.loading = true;
      axios.post(UserData.getBaseUrl(), formData).then((response) => {
        if (response.status == 200) {
          this.alert.showSuccess(response.data.message);
          var currentIndex = this.categories.findIndex((c) => {
            if (c.categoryId == category.categoryId) {
              return true;
            }
            return false;
          });
          
          this.questionArr = [];

          var currentCategoryIndex = this.categories1.findIndex((category) => {
            return this.activeCategory.categoryId == category.categoryId;
          });

          if (!this.isLastQuiz) {
            this.activeCategory = this.categories1[currentCategoryIndex + 1];
            this.setActiveCategory(this.activeCategory);
          }

          if (!this.isLastQuiz) {
            this.showReview = false;
          } else {
            this.store.setEvaluatedStatus(false);
            if (
              this.categories1[currentCategoryIndex].questions.length > 0 &&
              (((this.categories1[currentCategoryIndex].questions.find(question => parseInt(question.score))) && 
              ((this.categories1[currentCategoryIndex].questions.find(question => parseInt(question.score) > 0).score) > 0)) ||
              ((this.categories1[currentCategoryIndex].questions.find(question => question.isOpenEnded ?? question.answer !== '')) && 
              (((this.categories1[currentCategoryIndex].questions.find(question => question.isOpenEnded ?? question.answer !== '').answer) !== '')))) 
            ) {
              this.store.setEvaluatedStatus(true);
            }
          }
          
          this.loading = false;
          this.$nextTick(() => { this.tab = 'tab-' + (currentIndex + 1) });
          this.getEvaluationDetail(true);
        } else {
          this.alert.showError(response.data.message);
        }
      });
    },

    checkSubmitEvaluation() {
      if (this.canComment == 1) {
        this.alert.clearConfirm();
        this.alert.showConfirm("Evaluation Comments", "Would you like to add evaluation comments?", "Yes", "No");
      } else {
        this.submitEvaluation();
      }
    },  

    submitEvaluation() {
      const formData = new FormData();
      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "submitEvaluation");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      formData.append("evaluationId", UserData.getEvaluationId() ? UserData.getEvaluationId() : this.store.getEvaluationId);
      formData.append("evaluateeId", UserData.getEvaluateeId() ? UserData.getEvaluateeId() : this.store.getEvaluateeId);
      
      this.loading = true;
      axios.post(UserData.getBaseUrl(), formData).then((response) => {
        if (response.status == 200) {
          this.alert.showSuccess(response.data.message);
          
          if (this.checked) {
            this.trigger = !this.trigger;
            this.store.setCommentsType({ id: 1, title: 'Comment on Evaluation' });
            setTimeout(() => {
              this.store.setLoading(false);
              this.store.setDialogEvaluationComments(true);
            }, 1500);
          }
          
          this.getEvaluationDetail(false);
          this.loading = false;
          this.showReview = false;
        } else {
          this.alert.showError(response.data.message);
        }
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    getEvaluationReport() {
      const formData = new FormData();
      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "getEvaluationReport");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      formData.append("evaluationId", UserData.getEvaluationId() ? UserData.getEvaluationId() : this.store.getEvaluationId);
      formData.append("evaluateeId", UserData.getEvaluateeId() ? UserData.getEvaluateeId() : this.store.getEvaluateeId);

      axios.post(UserData.getBaseUrl(), formData).then((response) => {
        this.getEvaluationReportArr = response.data;
        this.evaluationUrl = this.getEvaluationReportArr.evaluationUrl;
        window.open(this.evaluationUrl);
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    joinNames() {
      return [
        ...this.navigationPath.map((value) => {
          return value.itemName;
        }),
      ].join("/");
    },

    sortBySize() {
      this.itemSubArray2.itemSize.sort(function (a, b) {
        return a - b;
      });
    },

    doSort(field, code = 0, name = null) {
      if (code !== 0) {
        this.sort.code = code;
      }

      if (name != null) {
        this.filterName = name;
      }

      if (field == this.sort.field) {
        this.sort.desc = !this.sort.desc;
      } else {
        this.sort.field = field;
        this.sort.desc = true;
      }
    },

    doInitialSort(field, code = 0) {
      if (code !== 0) {
        this.sort.code = code;
      }

      if (field == this.sort.field) {
        this.sort.desc = true;
      } else {
        this.sort.field = field;
        this.sort.desc = true;
      }
    },

    getUniqueListBy(arr, key) {
      return [...new Map(arr.map(item => [item[key], item])).values()]
    },

    filterEvaluationList(code) {
      if (code !== 0) {
        this.sort.code = code;
        var sorted_evaluations = [];
        sorted_evaluations = this.evaluations.filter((evaluation) => evaluation.status_code == code);
        this.evaluationList = this.getUniqueListBy(sorted_evaluations, 'evaluationId');
      } else {
        this.sort.code = 0;
        this.evaluationList = this.getUniqueListBy(this.evaluations, 'evaluationId');
      }
    },

    getFilterStyle(code) {
      if (this.sort.code === code) {
        return 'color: rgb(72, 61, 139); font-weight: bold;';
      } 
    },

    openPDF: function openPDF(e) {
      this.pdf = window.URL.createObjectURL(e.target.files[0]);
    },

    swapComponent: function (component) {
      this.currentComponent = component;
    },

    reviewEvaluation(category) {
      this.validate().then((validator_response) => {
        this.valid = validator_response.valid;
        if (this.valid) {
          this.setCategoryId(category.categoryId);
          if (this.checkUnansweredQuestions(this.activeCategory)) {
            this.categories2 = [];
            if (this.isLastQuiz) {
              this.categories2 = this.categories1;
            } else {
              this.categories2.push(category);
            }

            
            this.submitPerCategory(this.activeCategory);
            this.showReview = true;
          }
        }
      });
    },

    submitPerCategory(category) {
      if (this.checkUnansweredQuestions(category)) {
        if (category.questions.length > 0) {
          this.saveAndContinue(category);
        }
      }
    },

    getSubmitText(category) {
      let status = false;
      if (category.categoryId == this.activeCategory.categoryId) {
        var answered = 0;
        category.questions.map((item) => {
          if (parseInt(item.userRate) > 0) {
            answered++;
          }
        });

        if (answered == category.questions.length) {
          if (category.isComplete == 1) {
            status = false;
          } else {
            status = true;
          }
        } else {
          status = false;
        }
      }
      
      return status;
    },

    checkUnansweredQuestions(category) {
      var status = false;
      if (category.categoryId == this.activeCategory.categoryId) {
        var answered = 0;
        category.questions.map((item) => {
          if (!item.isOpenEnded) {
            if (parseInt(item.userRate) > 0) {
              answered++;
            }
          } else {
            if (item.answer !== "") {
              answered++;
            }
          }
        });
        
        if (answered == category.questions.length) {
          if (category.isComplete == 1) {
            status = false;
          } else {
            status = true;
          }
        } else {
          this.alert.showInfo('Please Answer All Questions');
          status = false;
        }

        return status;
      }
    },

    setActiveCategory(category, index = null) {
      if (typeof category !== 'undefined' && typeof this.activeCategory !== 'undefined') {
        var currentCategoryIndex = this.categories1.findIndex((category) => {
          return this.activeCategory.categoryId == category.categoryId;
        });

        this.store.setEvaluatedStatus(false);
        if (this.categories1[currentCategoryIndex].questions.length > 0 &&
          (((this.categories1[currentCategoryIndex].questions.find(question => parseInt(question.score))) && 
          ((this.categories1[currentCategoryIndex].questions.find(question => parseInt(question.score) > 0).score) > 0)) ||
          ((this.categories1[currentCategoryIndex].questions.find(question => question.isOpenEnded ?? question.answer !== '')) && 
          (((this.categories1[currentCategoryIndex].questions.find(question => question.isOpenEnded ?? question.answer !== '').answer) !== ''))))  
        ) {
          this.store.setEvaluatedStatus(true);
        }

        if (index <= currentCategoryIndex || (this.evaluated && (index == (currentCategoryIndex + 1)))) {
          this.activeCategory = category;
        } else {
          if (this.categories1[currentCategoryIndex].questions.length > 0 &&
            (((this.categories1[index - 1].questions.find(question => parseInt(question.score))) && 
            ((this.categories1[index - 1].questions.find(question => parseInt(question.score) > 0).score) > 0)) ||
            (((this.categories1[index - 1].questions.find(question => question.isOpenEnded ?? question.answer !== '')) && 
            ((this.categories1[index - 1].questions.find(question => question.isOpenEnded ?? question.answer !== '').answer) !== ''))))
          ) {
            this.activeCategory = category;
          } else {
            this.alert.showInfo('Please Answer All Questions');
            this.$nextTick(() => { this.tab = 'tab-' + currentCategoryIndex });
          }
        }
      }
    },

    nextCategory(category) {
      if (this.activeCategory.questions.length > 0) {
        this.validate().then((validator_response) => {
          this.valid = validator_response.valid;
          if (this.valid) {
            if (this.checkUnansweredQuestions(category)) {
              this.submitPerCategory(category);
            }
          }
        });
      }
    },

    answerSelected(question, activeCategory, index) {
      if (activeCategory.isComplete == 0) {
        var activeCategoryCopy = activeCategory;
        var updatedQuiz = activeCategory.questions.map((quiz) => {
          if (question.questionId == quiz.questionId) {
            return {
              ...quiz,
              userRate: index,
            };
          } else {
            return {
              ...quiz,
            };
          }
        });

        activeCategoryCopy.questions = updatedQuiz;
        this.activeCategory = activeCategoryCopy;
        var categories1 = this.categories1.map((category) => {
          if (this.activeCategory.categoryId == category.categoryId) {
            return {
              ...activeCategory,
            };
          } else {
            return {
              ...category,
            };
          }
        });

        this.categories1 = categories1;
        this.checkTabStatus();
      }
    },

    checkSortField(a, b) {
      switch (this.sort.field) {
        case 'status_code':
          if (this.sort.desc) {
            if (parseInt(a[this.sort.field]) == parseInt(b[this.sort.field])) {
              return b['dateCreated'] - a['dateCreated'];
            }

            return parseInt(a[this.sort.field]) > parseInt(b[this.sort.field]) ? -1 : 1;
          } else {
            if (parseInt(a[this.sort.field]) == parseInt(b[this.sort.field])) {
              return a['dateCreated'] - b['dateCreated'];
            }

            return parseInt(a[this.sort.field]) > parseInt(b[this.sort.field]) ? 1 : -1;
          }
        default: 
          if (this.sort.desc) {
            return a[this.sort.field] > b[this.sort.field] ? -1 : 1;
          } else {
            return a[this.sort.field] > b[this.sort.field] ? 1 : -1;
          }
      }
    },
  },

  watch: {
    checked() {
      this.submitEvaluation();
    },
  },

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },

    evaluated() {
      return this.store.getEvaluatedStatus;
    },

    checked() {
      return this.alert.getChecked;
    },

    localAttrs () {
      const attrs = {}

      if (this.variant === 'default') {
        attrs.absolute = false
        attrs.fixed = false
      } else {
        attrs[this.variant] = true
      }

      return attrs
    },

    sortedData2() {
      if (!this.sort.field) {
        return this.evaluationList;
      }

      return this.evaluationList.concat().sort((a, b) => {
        return this.checkSortField(a, b);
      });
    },

    evalArray() {
      var evalArray = [];
      Object.keys(this.evaluatees).forEach((i) => {
        Object.keys(this.evaluatees[i]).forEach((j) => {
          evalArray.push(this.evaluatees[i][j]);
        });
      });

      return evalArray;
    },

    isLastQuiz() {
      if (!this.showReview && typeof this.activeCategory !== 'undefined') {
        var currentIndex = this.categories1.findIndex((category) => {
          return this.activeCategory.categoryId == category.categoryId;
        });

        return currentIndex == this.categories1.length - 1;
      } else {
        return true;
      }
    },

    isLastEvaluated() {
      if (!this.showReview && typeof this.activeCategory !== 'undefined') {
        var currentIndex = this.categories1.findIndex((category) => {
          return this.activeCategory.categoryId == category.categoryId;
        });

        var count = 0;
        this.categories1[currentIndex].questions.forEach((question) => {
          if (!question.isOpenEnded) {
            if (parseInt(question.userRate) > 0) 
              count++;
          } else {
            if (question.answer !== "")
              count++;
          }
        });

        if (count == this.categories1[currentIndex].questions.length) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },

    currentProgress() {
      var index =
        this.categories1.findIndex((category) => {
          return this.activeCategory.categoryId == category.categoryId;
        }) + 1;
      return index + " of " + this.categories1.length;
    },

    gender() {
      return localStorage.getItem('admin_gender');
    },

    dialog_evaluation_question_comments: {
      get() {
        return this.store.getDialogEvaluationQuestionComments;
      },
      set(value) {
        this.store.setDialogEvaluationQuestionComments(value);
      },
    },
  },

  beforeMount() {
    this.getEvaluationList();
    this.store.setDialogEvaluationComments(false);
    this.dialog_evaluation_question_comments = false;
  },

  components: {
    EvaluationComments,
    EvaluationCommentsDialog,
    EvaluationQuestionsCommentsDialog,
    UserProfile,
  },
};
</script>

<style scoped>
body {
  margin: 0;
  padding: 0;
}

.nav-menu {
  margin-top: 0px;
  background-color: rgb(72, 61, 139);
  color: #fff;
  display: flex;
}

.nav-menu .btn {
  background-color: rgb(72, 61, 139);
  border: none;
  padding: 0px;
  margin-right: 15px;
  color: white;
  outline: none;
}

.col-left {
  align-content: center;
  z-index: -1;
}

.col-left .left-menu {
  background-color: #f5f5f5;
  align-content: center;
  margin-bottom: 10px;
  font-size: 20px;
}

.col-right {
  float: left;
  width: 73%;
  overflow: hidden;
  height: auto;
  margin-top: 1%;
  position: relative;
  z-index: -1;
}

.col-right th {
  padding: 5px;
}

.col-right td {
  padding: 3px;
}

.col-right-top {
  height: 230px;
  background-color: #f5f5f5;
  margin-bottom: 15px;
  padding: 10px;
  border-top: 5px solid rgb(72, 61, 139);
}

.col-right-bottom {
  height: 430px;
  background-color: #ffffff;
}

.col-right-bottom-left {
  background-color: #f5f5f5;
}

.col-right-bottom-right {
  width: 59%;
  height: 430px;
  background-color: #f5f5f5;
  float: left;
  padding: 1%;
}

.col-right-top .btn {
  padding: 3px;
  width: 100px;
}

.menu-row-icon-six {
  margin: 0px;
  padding: 0px;
  color: rgb(72, 61, 139);
}

table.scroll {
  width: 100%;
  border-spacing: 0;
}

tbody tr td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

tbody td:last-child,
thead th:last-child {
  border-right: none;
}

button:focus {
  outline: 0 !important;
  border: 0 !important;
  box-shadow: none !important;
}

a {
  text-decoration: none;
  color: #000000;
}

a:hover {
  color: rgb(72, 61, 139);
}

.parent {
  display: flex;
  width: 100%;
}

.filler {
  flex-grow: 1;
}

.left-buttons {
  display: inline;
}

.right-buttons {
  display: inline;
}

.user_row {
  display: flex;
}

.user_row > div {
  flex: 1;
  text-align: center;
}

.review_cointainer {
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.save__continue {
  display: flex;
  cursor: pointer;
  border: 1px solid rgb(72, 61, 139);
  background-color: grey;
  width: 100%;
  justify-content: center;
  color: #55138e;
}

.main__contentReview {
  padding-bottom: 90px;
}

.question_cointainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.question_row {
  border-bottom: 1px solid grey;
  height: 15%;
  display: flex;
  align-items: center;
}

.question {
  flex: 0.7;
}

.question__rate {
  flex: 0.3;
  display: flex;
}

.progress__count_section {
  height: 8%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: grey;
  width: 30%;
}

.category {
  color: grey;
  padding-bottom: 90px;
}

.review_category {
  color: grey;
  padding-bottom: 90px;
}

.category_active {
  color: #55138e;
}

.category__list {
  color: #000;
  display: flex;
  align-items: center;

  height: 10%;
  width: 100%;
}

.rating_circle {
  width: 50px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 10px;
  height: 50px;
}

.rating_circle:hover {
  background-color: grey !important;
}

.rating_circle__active {
  background-color: #55138e !important;
  color: #f5f5f5 !important;
}

.table-striped a:hover {
  color: rgb(72, 61, 139);
}

.table-striped a:active {
  color: rgb(72, 61, 139);
}

.table-striped:nth-child(even) {
  background-color: rgba(0, 0, 0, .05);
}

.table-striped:hover {
  background-color: rgba(255, 255, 255, 255) !important;
}

.resources-table:hover { 
  background-color: rgb(72, 61, 139) !important; 
}
</style>