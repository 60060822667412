<template>
  <div>
    <!-- NAV-MENU -->
    <div
      class="nav-menu"
      style="height: 45px; width: 100%; padding: 0px; padding-top: 0.3%; text-align: center;"
    >
      <div class="left-buttons">
        <router-link to="/dashboard" style="text-decoration: none">
          <v-icon color="#fff" size="35" style="margin-left: 10px"
            >mdi-home</v-icon
          >
        </router-link>
      </div>
      <div class="filler"></div>
      <p style="font-weight: bold; font-size: 19px;">
        MEETING PACKS
      </p>
      <div class="filler"></div>
      <div class="right-buttons">
        <v-icon 
          color="#ffffff" 
          class="mr-5"
          @click="reloadPage"
          icon="mdi-cached"
          size="35"
          style="cursor: pointer;"
        />
      </div>
    </div>  

    <!-- COMPONENT -->
    <div
      style="margin-top: 0.5%; margin-bottom: 0.5%; background-color: #f8f9f9; border-top: 5px solid rgb(86, 182, 229); width: 98%; margin-left: 1%; margin-right: 1%; height: 900px;"
    >
      <div
        class="parent"
        v-if="!closePDF && !closeDocument"
        style="height: 45px; width: 100%; margin-top: 7px; background-color: #fff;"
      >
        <div class="left-buttons">
          <router-link to="">
            <v-icon
              v-on:click="loadItemFromHistory()"
              style="float: left; margin-left: 10px; background-color: rgb(86, 182, 229); color: #fff;"
              size="25"
              >mdi-chevron-left</v-icon
            >
          </router-link>
          <p
            style="float: left; font-weight: bold; font-size: 19px; text-align: left; margin-left: 10px; width: 250px;"
          >
            MEETING PACKS
          </p>
        </div>
        <div class="filler">
          <div class="dropdown-table float-right" style="display: inline; margin-top: 5px;">
            <v-menu>
              <template v-slot:activator="{ props }">
                <v-btn 
                  variant="text" 
                  v-bind="props"
                  class="btn"
                  :style="getFilterStyle(9)"
                >
                  {{ sort.desc ? 'Descending' : 'Ascending' }}
                  <v-icon>mdi-swap-vertical-bold</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item style="display: block;">
                  <v-list-item-title
                    style="padding: 12px; font-size: 16px; cursor: pointer;"
                    @click="sort.desc = true"
                    >Descending
                  </v-list-item-title>

                  <v-list-item-title
                    style="padding: 12px; font-size: 16px; cursor: pointer;"
                    @click="sort.desc = false"
                    >Ascending
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
        <div class="dropdown-table float-right" style="display: inline; margin-top: 5px;">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn 
                variant="text" 
                v-bind="props"
                class="btn"
                :style="getFilterStyle(9)"
              >
                {{ filterName }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item style="display: block">
                <v-list-item-title
                  style="padding: 12px; font-size: 16px; cursor: pointer;"
                  @click="doSort('itemName', 9, 'Name')"
                  >Name
                  <span v-if="sort.field == 'itemName'"
                    >({{ sort.desc ? "desc" : "asc" }})</span
                  >
                </v-list-item-title>

                <v-list-item-title
                  style="padding: 12px; font-size: 16px; cursor: pointer;"
                  @click="doSort('itemSize', 9, 'Size')"
                  >Size
                  <span v-if="sort.field == 'itemSize'"
                    >({{ sort.desc ? "desc" : "asc" }})</span
                  >
                </v-list-item-title>

                <v-list-item-title
                  style="padding: 12px; font-size: 16px; cursor: pointer;"
                  @click="doSort('itemLastUpdatedOn', 9, 'Modified On')"
                  >Modified On
                  <span v-if="sort.field == 'itemLastUpdatedOn'"
                    >({{ sort.desc ? "desc" : "asc" }})</span
                  >
                </v-list-item-title>

                <v-list-item-title
                  style="padding: 12px; font-size: 16px; cursor: pointer;"
                  @click="doSort('itemUploadedBy', 9, 'Submitted By')"
                  >Submitted By
                  <span v-if="sort.field == 'itemUploadedBy'"
                    >({{ sort.desc ? "desc" : "asc" }})</span
                  >
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div class="right-buttons" style="display: inline; margin-top: 5px;">
          <div style="display: inline; margin-right: 10px">
            <v-btn
              style="padding: 0px"
              class="btn"
              variant="text"
              @click="changeListView(1)"
              :style="getFilterStyle(1)"
            >
              List
            </v-btn>
          </div>
          <div style="display: inline; margin-right: 10px">
            <v-btn
              style="padding: 0px"
              class="btn"
              variant="text"
              @click="changeListView(2)"
              :style="getFilterStyle(2)"
            >
              Grid
            </v-btn>
          </div>
        </div>
      </div>

      <v-table
        fixed-header
        fixed-footer
        height="100vh"
        v-if="!closePDF && !closeDocument"
      >
        <thead>
          <tr>
            <th>
              <div
                class="parent"
                style="height: 55px; line-height: 55px; width: 100%; background-color: #f1f1f1;"
                v-if="!closePDF"
              >
                <p
                  style="font-weight: bold; font-size: 19px; margin-left: 10px; float: left; color: rgb(86, 182, 229);"
                >
                  {{ joinNames() }}
                </p>
              </div>

              <div v-if="store.getLoading && !closePDF">
                <v-progress-linear
                  color="rgb(86, 182, 229)"
                  indeterminate
                  rounded
                  height="6"
                ></v-progress-linear>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <!-- List View -->
              <div v-if="list.view != 2">
                <!-- Table -->
                <v-table 
                  v-if="sortedData2.length > 0 && !closePDF"
                  fixed-header
                  height="100vh"
                >
                  <thead>
                    <tr>
                      <th style="background-color: rgb(86, 182, 229); font-weight: bolder;" class="text-left text-white">No.</th>
                      <th style="background-color: rgb(86, 182, 229); font-weight: bolder;" class="text-left text-white"></th>
                      <th style="background-color: rgb(86, 182, 229); font-weight: bolder;" class="text-left text-white">Name</th>
                      <th style="background-color: rgb(86, 182, 229); font-weight: bolder;" class="text-left text-white">Size</th>
                      <th style="background-color: rgb(86, 182, 229); font-weight: bolder;" class="text-left text-white">Modified On</th>
                      <th style="background-color: rgb(86, 182, 229); font-weight: bolder;" class="text-left text-white">Submitted By</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="(item, index) in sortedData2" 
                    :key="item.itemId"
                    class="table-striped"
                  >
                    <tr>
                      <td
                        style="overflow: hidden; text-overflow: ellipsis; padding-top: 20px; padding-bottom: 20px;"
                      >
                        {{ index + 1 + "." }}
                      </td>
                      <td
                        style="overflow: hidden; text-overflow: ellipsis;"
                      >
                        <span class="input-group-addon">
                          <v-progress-circular
                            v-if="loading[item.itemId]"
                            indeterminate
                            color="rgb(86, 182, 229)"
                          ></v-progress-circular> 
                          <v-icon
                            v-else-if="item.itemExtension"
                            :color="fileIds.includes(item.itemId) ? 'rgb(227, 58, 58)' : 'rgb(86, 182, 229)'"
                            class="mr-2"
                            size="large"
                            :icon="globalMethods.getDocumentIcon(item.itemExtension)"
                          />
                          <v-icon
                            v-else
                            color="rgb(86, 182, 229)"
                            style="margin-right: 5px"
                          >
                            mdi-folder-open</v-icon
                          >
                        </span>
                      </td>
                      <td
                        style="overflow: hidden; text-overflow: ellipsis;"
                      >
                        <file-options
                          v-if="item.itemExtension == 'pdf'"
                          :file="item"
                          :file_color="'rgb(86, 182, 229)'"
                          @open_pdf="getMeetingPack(item)"
                          @briefcase_pdf="briefcaseDocument(item)"
                          :briefcase-files="fileIds"
                          :file_class="' text-wrap'"
                          :select_type="true"
                          :pdf_type="2"
                        ></file-options>
                        <a 
                          v-else
                          href="#"
                          @click="getMeetingPack(item)"
                        >
                          {{ item.itemName }}
                        </a>
                      </td>
                      <td
                        v-if="item.itemSize == 0"
                        style="overflow: hidden; text-overflow: ellipsis;"
                      >
                        {{ "0.00 KB" }}
                      </td>
                      <td
                        v-else
                        style="overflow: hidden; text-overflow: ellipsis;"
                      >
                        {{ getPrettyBytes(item.itemSize) }}
                      </td>
                      <td
                        style="overflow: hidden; text-overflow: ellipsis;"
                      >
                        {{ formatDate(item.itemLastUpdatedOn) }}
                      </td>
                      <td
                        style="overflow: hidden; text-overflow: ellipsis;"
                      >
                        {{ item.itemUploadedBy || '-' }}
                      </td>
                    </tr>
                  </tbody>
                </v-table>
        
                <v-table 
                  v-else
                  height="100vh"
                >
                  <thead>
                    <tr>
                      <th style="background-color: rgb(86, 182, 229); font-weight: bolder;" class="text-left text-white">No.</th>
                      <th style="background-color: rgb(86, 182, 229); font-weight: bolder;" class="text-left text-white"></th>
                      <th style="background-color: rgb(86, 182, 229); font-weight: bolder;" class="text-left text-white">Name</th>
                      <th style="background-color: rgb(86, 182, 229); font-weight: bolder;" class="text-left text-white">Size</th>
                      <th style="background-color: rgb(86, 182, 229); font-weight: bolder;" class="text-left text-white">Modified On</th>
                      <th style="background-color: rgb(86, 182, 229); font-weight: bolder;" class="text-left text-white">Submitted By</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td 
                        colspan="7"
                        class="text-center"
                      >
                        <span>
                          No Meeting Packs Found
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </v-table>
              </div>
              <!-- Grid View -->
              <div v-else class="table-striped">
                <v-card class="mx-auto pa-4" v-if="sortedData2.length > 0">
                  <v-row no-gutters>
                    <v-col 
                      cols="3" md="3" sm="3" align="center"
                      v-for="item in sortedData2" 
                      :key="item.itemId"  
                      @click="getMeetingPack(item)"
                      style="cursor: pointer;"
                    >
                      <v-card-title>
                        <v-progress-circular
                          v-if="loading[item.itemId]"
                          indeterminate
                          color="rgb(86, 182, 229)"
                        ></v-progress-circular> 
                        <v-icon
                          v-else-if="item.itemExtension"
                          :color="fileIds.includes(item.itemId) ? 'rgb(227, 58, 58)' : 'rgb(86, 182, 229)'"
                          class="mr-2"
                          size="large"
                          :icon="globalMethods.getDocumentIcon(item.itemExtension)"
                        />
                        <v-icon
                          v-else
                          color="rgb(86, 182, 229)"
                          style="margin-right: 5px"
                        >
                          mdi-folder-open</v-icon
                        >
                      </v-card-title>

                      <v-card-text>
                        <a href="#" class="text-wrap">
                          {{ item.itemName }}
                        </a>
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-card>

                <v-card class="mx-auto pa-4" v-else>
                  <v-row no-gutters>
                    <v-col cols="12" md="12" sm="12" align="center">
                      <v-card-text>
                        <span class="text-center">
                          No Meeting Packs Found
                        </span>
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-card>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>
              <!-- FOOTER -->
              <div
                v-if="!closePDF"
                class="nav-menu"
                @click="getMattersArisingReport"
                style="height: 45px; padding: 0px; padding-top: 0.3%; padding-left: 40%; cursor: pointer;"
              >
                <v-icon color="#fff" class="mr-2 mt-1"
                  >mdi-file-pdf-box</v-icon
                >
                <p style="font-weight: bold; font-size: 19px; color: #ffffff;">Matters Arising Report</p>
              </div>
            </td>
          </tr>
        </tfoot>
      </v-table>

      <!-- PSPDFKIT / OPEN DOCUMENT MODAL -->
      <div
        v-if="closePDF || closeDocument"
        style="position: absolute; background-color: #f5f5f5; width: 100%; height: 100%; padding: 1%; overflow: hidden; margin-right: 0;"
      >
        <v-card>
          <v-card-text>
            <v-main>
              <v-container fluid>
                <component
                  :pdf-url="current_pdf.itemUrl"
                  :license-key="LICENSE_KEY"
                  :base-url="baseUrl"
                  @close_pdf="closePdf"
                  :is="document_component"
                  :document-url="current_document.itemUrl"
                  :document-type="current_document.itemExtension"
                  :document-title="current_document.itemName"
                  :viewer-color="'rgb(86, 182, 229)'" 
                  @close_document="documentClosed"
                  :document-list="sortedData2"
                  :list-type="true"
                  :list-color="'rgb(86, 182, 229)'"
                  @open_pdf="getMeetingPack"
                  @open_document="getMeetingPack"
                  @switch_pdf="switchDocument"
                  @switch_document="switchDocument"
                  :parent_select_type="true"
                  :parent_pdf_type="pdf_type ? 2 : 1"
                  :list-width="'100%'"
                  :briefcase-files="fileIds"
                  :briefcase-on-load="true"
                  @briefcase_document="briefcaseDocument"
                />
              </v-container>
            </v-main>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <!-- END OF COMPONENT -->

  </div>
</template>

<script>
var moment = require("moment");
import axios from "axios";
import UserData from "./repository/UserData";
import PSPDFKit from "../components/PSPDFKit.vue";
import { useAuthStore } from "../stores/auth";
import { useIndexStore } from "../stores/index";
import { useAlertStore } from '../stores/alert';
import prettyBytes from 'pretty-bytes';
import FileOptions from "./Cards/FileOptions.vue";
import GlobalMethods from './repository/GlobalMethods';
import DocumentViewer from './Cards/DocumentViewer.vue';

const LICENSE_KEY =
  "QvMr6j5706r3kYlOiVvJgEwu4fZnTL1fYz4t6paoBV5PekugaffzJnLtX5TczMS65OKAI1iIaSi06_l76UgF3kyKauJ9vaMZFuU3_oDplMFty6AhBhWPh80M7qoSa8xjtux3NqNrfqybICplLfHezwjhABIkPy6XxBMQ_uPU87_C5pMBXlc8A2PI_ShMjfhst6O_C0y9jyeMJ2DYudM3liFVsp90A0dOY51LM2Q3xV3jfcJ7K39FKpnKU_atpn1Ig8HXcmWdGQMjCXyquwVu9i2J850rGqBgFKJJFGixxnU3sZ__tA7Mb3aX8T2nXPlEnamtAhNf-DD1sEtqXHp5q5xxsFFuJDVh82tXKfd4oX8AkzWLd_-UVg_h_6ATML30e8fiQXSVcN0mmlZH5XYUv0jB5cHEyuIaGZac7gCNRn32pchJNpIwEdgHzWDRBQxxCR7F8b6z3Xs3yXJ6y9EhhrnKmjNYkMCdFJ0qNhI8zF8RhAbLA-uKtrzMy7hXLrlpuIHdGaCXMm7gA9iBpKiqwHIrX83rCFoVYUGM4BqNkim3HN8YJzZRDDI-ZHzdy7CcanSuvbe3NSxW5daeHdGVvg==";

export default {
  data: () => ({
    moment: moment,
    test: true,
    getRecentDocuments: [],
    itemSubArray: [],
    itemSubArray2: [],
    itemSubArray3: [],
    filterName: "Name",

    files: [],
    fileIds: [],
    db: null,
    globalMethods: GlobalMethods,
    loading: {},

    navigationPath: [
      {
        itemName: "...",
        localUrl: "0",
      },
    ],

    parentItemId: "",

    items2: [
      { title: "Name" },
      { title: "Size" },
      { title: "Modified On" },
      { title: "Submitted By" },
    ],

    currentComponent: null,

    componentsArray: ["comp1", "comp2"],

    dynamicComponent: {
      template: `<p>Wheee</p>`,
    },

    sort: {
      field: "",
      desc: true,
      status: null,
    },

    list: {
      view: 1,
    },

    items: [
      { id: 1, name: "Person 1", leave: 123.45 },
      { id: 2, name: "John Smith", leave: 13.45 },
      { id: 3, name: "Bill Smith", leave: 23.45 },
      { id: 4, name: "John Doe", leave: 133.53 },
    ],

    load: {
      userId: "45",
      companyCode: "010",
      accessToken: "97f914eb1ceb1867e3824f647f7e589b",
      model: "getMeetingPackFolder",
      companyId: "2",
      itemId: "0",
    },

    type: "",
    title: "",
    pdf: "example.pdf",
    LICENSE_KEY: LICENSE_KEY,
    baseUrl: "",
    errorMsg: "",
    token: localStorage.getItem("token") ? JSON.stringify(localStorage.getItem("token")).slice(1, -1) : "",
    store: useIndexStore(),
    auth: useAuthStore(),
    alert: useAlertStore(),
    closePDF: false,
    closeDocument: false,
  }),

  methods: {
    formatDate(date) {
      return moment.unix(date).format("DD-MMM-YYYY");
    },

    reloadPage() {
      window.location.reload();
    },

    getPrettyBytes(value) {
      return prettyBytes(parseInt(value));
    },

    getMattersArisingReport() {
      this.$router.push('/Matters%20Arising%20Report');
    },

    closePdf() {
      this.store.setLoading(false);
      this.closePDF = !this.closePDF;
      this.shareDocument = false;
      this.store.setCurrentPdf({});
    },

    documentClosed() {
      this.closeDocument = !this.closeDocument;
      this.store.setDialogDocumentViewer(false);
      this.store.setCurrentDocument({});
    },

    getMeetingPackFolder() {
      let baseUrl = UserData.getBaseUrl();
      this.store.setLoading(true);
      axios.post(baseUrl, this.getUserData()).then((response) => {
        this.getMeetingPackFolder = response.data;
        this.itemSubArray = this.getMeetingPackFolder.data.itemSubArray;
        this.parentUrl = baseUrl;
        this.navigationPath.push({
          itemName: this.getMeetingPackFolder.data.itemName,
          itemId: this.parentItemId,
        });

        this.store.setLoading(false);
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    getMeetingPack(item) {
      if (item.itemExtension == "pdf") {
        this.briefcaseDocument(item);
      } else {
        let baseUrl = UserData.getBaseUrl();
        if (item.itemId == -999) {
          this.itemSubArray = item.itemSubArray;
          this.parentUrl = baseUrl;
          this.navigationPath.push({
            itemName: item.itemName,
            itemId: item.itemParentId,
          });
        } else {
          if (this.globalMethods.getDocumentExtension(item.itemExtension)) {
            this.openDocument(item);
          } else {
            this.loading[item.itemId] = true;
            axios.post(baseUrl, this.getUserData(item.itemId, item.itemParentId)).then((response) => {
              if (response.data.success == 1) {
                this.getMeetingPackFolder = response.data;
                this.loading[item.itemId] = false;
                this.itemSubArray = this.getMeetingPackFolder.data.itemSubArray;
                this.parentUrl = baseUrl;
                this.navigationPath.push({
                  itemName: this.getMeetingPackFolder.data.itemName,
                  itemId: this.parentItemId,
                });
              }
            })
            .catch((e) => {
              console.log("Error", e);
            });
          }
        }
      }
    },

    closeViewer(item) {
      if (item.itemExtension == "pdf") {
        this.closeDocument = false;
        this.closePDF = true;
      } else {
        this.closePDF = false;
        this.closeDocument = true;
      }
    },

    openPdf(item, url = null) {
      this.closeViewer(item);
      this.store.setBriefcaseUrl(url);
      this.store.setDialogDocumentViewer(false);
      this.store.setDocumentComponent('pspdfkit');
      this.store.setCurrentPdf(item);
      this.store.setCurrentDocument(item);
      UserData.setDocumentId(item.itemId);
      this.store.setDocumentId(item.itemId);
    },

    openDocument(item) {
      if (item.itemExtension != 'No File Extension') {
        this.closeViewer(item);
        this.store.setDialogDocumentViewer(true);
        this.store.setDocumentComponent('document-viewer');
        this.store.setCurrentDocument(item);
        UserData.setDocumentId(item.itemId);
        this.store.setDocumentId(item.itemId);
      } else {
        if (item.itemSize > 0) {
          this.alert.showError(item.itemExtension);
        } else {
          this.alert.showError('Error Opening File');
        }
      }
    },

    switchDocument(item) {
      this.store.setBriefcaseUrl(null);

      if (item.itemExtension == "pdf") {
        this.openPdf(item);
      } else {
        this.openDocument(item);
      }
    },

    briefcaseDocument(item) {
      if (item.itemExtension) {
        this.loading[item.itemId] = true;
        this.globalMethods.downloadEbrDocument(item.itemUrl).then(async (response) => {
          if (response.status == 200) {
            let fileType = response.headers['content-type'];
            let fileData = new Blob([response.data], { type: fileType });
            let blob = window.URL.createObjectURL(fileData);
            this.openPdf(item, blob);
            this.loading[item.itemId] = false;
          } else {
            this.loading[item.itemId] = false;
            console.log("Response: ", response);
          }
        }).catch((e) => {
          this.loading[item.itemId] = false;
          console.log("Error: ", e);
        });
      } else {
        this.alert.showInfo("Briefcasing Folders is Under Development");
      }
    },

    getUserData(itemId = 0, parentItemId = 0) {
      this.parentItemId = parentItemId;
      const formData = new FormData();
      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "getMeetingPackFolder");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      formData.append("itemId", itemId);
      formData.append("itemParentId", itemId);
      formData.append("isArchive", 0);
      return formData;
    },

    loadItemFromHistory() {
      let currentTop = this.navigationPath.pop();
      axios.post(UserData.getBaseUrl(), this.getUserData(currentTop.itemId)).then((response) => {
        this.getSubMeetingPackFolder = response.data;
        this.itemSubArray = this.getSubMeetingPackFolder.data.itemSubArray;
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    joinNames() {
      return [
        ...this.navigationPath.map((value) => {
          return value.itemName;
        }),
      ].join("/");
    },

    sortBySize() {
      this.itemSubArray2.itemSize.sort(function (a, b) {
        return a - b;
      });
    },

    doSort(field, status = null, name = null) {
      if (status != null) {
        this.sort.status = status;
      }

      if (name != null) {
        this.filterName = name;
      }

      if (field == this.sort.field) {
        this.sort.desc = !this.sort.desc;
      } else {
        this.sort.field = field;
        this.sort.desc = true;
      }
    },

    openPDF: function openPDF(e) {
      this.pdf = window.URL.createObjectURL(e.target.files[0]);
    },

    swapComponent: function (component) {
      this.currentComponent = component;
    },

    checkSortField(a, b) {
      if (this.sort.field == 'itemSize') {
        if (this.sort.desc) {
          return parseFloat(a[this.sort.field]) > parseFloat(b[this.sort.field]) ? -1 : 1;
        } else {
          return parseFloat(a[this.sort.field]) > parseFloat(b[this.sort.field]) ? 1 : -1;
        }
      } else {
        if (this.sort.desc) {
          return a[this.sort.field] > b[this.sort.field] ? -1 : 1;
        } else {
          return a[this.sort.field] > b[this.sort.field] ? 1 : -1;
        }
      }
    },

    changeListView(status) {
      this.list.view = status;
      this.sort.status = status;
    },

    getFilterStyle(status) {
      if (this.sort.status === status) {
        return 'color: rgb(86, 182, 229); font-weight: bold; border: none;';
      } 
    },
  },

  watch: {
    checked() {
      // Trigger to call any function within Briefcase component
    },
  },

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },

    sortedData() {
      if (!this.sort.field) {
        return this.items;
      }

      return this.items.concat().sort((a, b) => {
        return this.checkSortField(a, b);
      });
    },

    sortedData2() {
      if (!this.sort.field) {
        return this.itemSubArray;
      }

      return this.itemSubArray.concat().sort((a, b) => {
        return this.checkSortField(a, b);
      });
    },

    current_pdf() {
      return this.store.getCurrentPdf;
    },

    current_document() {
      return this.store.getCurrentDocument;
    },

    document_component() {
      return this.store.getDocumentComponent;
    },

    checked() {
      return this.alert.getChecked;
    },

    overlay_type: {
      get() {
        return this.store.getOverlayType;
      },
      set(value) {
        this.store.setOverlayType(value);
      },
    },

    session_overlay: {
      get() {
        return this.store.getSessionOverlay;
      },
      set(value) {
        this.store.setSessionOverlay(value);
      },
    },

    pdf_type() {
      return this.store.getPdfType;
    },
  },

  beforeMount() {
    this.getMeetingPackFolder();
  },

  mounted() {
    const getRecentDocuments = JSON.parse(localStorage.getItem("getRecentDocuments"));
    if (getRecentDocuments) {
      this.getRecentDocuments = getRecentDocuments;
    }
  },

  components: {
    pspdfkit: PSPDFKit,
    FileOptions,
    DocumentViewer,
  },
};
</script>

<style scoped>
body {
  margin: 0;
  padding: 0;
}

.nav-menu {
  margin-top: 0px;
  background-color: rgb(86, 182, 229);
  color: #fff;
  display: flex;
}

.nav-menu .btn {
  background-color: rgb(86, 182, 229);
  border: none;
  padding: 0px;
  margin-right: 15px;
  color: white;
  outline: none;
}

.col-left {
  align-content: center;
  z-index: -1;
}

.col-left .left-menu {
  background-color: #f5f5f5;
  align-content: center;
  margin-bottom: 10px;
  font-size: 20px;
}

.col-right {
  float: left;
  width: 73%;
  overflow: hidden;
  height: auto;
  margin-top: 1%;
  position: relative;
  z-index: -1;
}

.col-right th {
  padding: 5px;
}

.col-right td {
  padding: 3px;
}

.col-right-top {
  height: 230px;
  background-color: #f5f5f5;
  margin-bottom: 15px;
  padding: 10px;
  border-top: 5px solid rgb(86, 182, 229);
}

.col-right-bottom {
  height: 430px;
  background-color: #ffffff;
}

.col-right-bottom-left {
  background-color: #f5f5f5;
}

.col-right-bottom-right {
  width: 59%;
  height: 430px;
  background-color: #f5f5f5;
  float: left;
  padding: 1%;
}

.col-right-top .btn {
  padding: 3px;
  width: 100px;
}

.menu-row-icon-six {
  margin: 0px;
  padding: 0px;
  color: rgb(86, 182, 229);
}

table.scroll {
  width: 100%;
  border-spacing: 0;
}

tbody tr td {
  overflow: hidden;
  text-overflow: ellipsis;
}

tbody td:last-child,
thead th:last-child {
  border-right: none;
}

button:focus {
  outline: 0 !important;
  border: 0 !important;
  box-shadow: none !important;
}

a {
  text-decoration: none;
  color: #000000;
}

a:hover {
  color: rgb(86, 182, 229);
}

.parent {
  display: flex;
  width: 100%;
}

.filler {
  flex-grow: 1;
}

.left-buttons {
  display: inline;
}

.right-buttons {
  display: inline;
}

.user_row {
  display: flex;
}

.user_row > div {
  flex: 1;
  text-align: center;
}

.table-striped a {
  text-decoration: none;
  color: #000;
}

.table-striped a:hover {
  color: rgb(86, 182, 229);
}

.table-striped a:active {
  color: rgb(86, 182, 229);
}

.table-striped:nth-child(even) {
  background-color: rgba(0, 0, 0, .05);
}

.table-striped:hover {
  background-color: rgba(255, 255, 255, 255) !important;
}
</style>