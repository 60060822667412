<template>
  <!-- APPROVALS -->
  <v-card style="color: rgb(71, 183, 83);" min-width="450px">
    <v-card-text>
      <v-text-field
        label="Search"
        append-inner-icon="mdi-magnify"
        v-model="search"
        @input="$emit('search_approvals', search)"
        variant="outlined"
      ></v-text-field>
    </v-card-text>
  </v-card>
</template>

<script>
import { useAuthStore } from "../../stores/auth";
import { useIndexStore } from "../../stores/index";
import { useAlertStore } from "../../stores/alert";

export default {
  name: "ApprovalSearch",

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },
  },

  data: () => ({
    auth: useAuthStore(),
    store: useIndexStore(),
    alert: useAlertStore(),
    search: "",
  }),
}
</script>