import { createWebHistory, createRouter } from 'vue-router';
import Login from '../components/Auth/Login';
import Dashboard from '../components/Auth/Dashboard';
import Briefcase from '../components/Briefcase';
import MeetingPacks from '../components/MeetingPacks';
import Meetings from '../components/Meetings';
import Resources from '../components/Resources';
import Approvals from '../components/Approvals';
import Compliance from '../components/Compliance';
import Evaluation from '../components/Evaluation';
import Discussion from '../views/Pages/Discussion.vue';
import Plans from '../components/Plans';
import MattersArisingReport from '../components/MattersArisingReport';
import Elections from '../views/Pages/Elections';
import SingleElection from '../views/Pages/SingleElection';
import Conference from '../components/Forms/Conference';
import RiskManagement from '../views/Pages/RiskManagement';
import Procurement from '../views/Pages/Procurement';
import Plan from '../components/Plan';
import LineChart from '../components/LineChart.vue';
import Archives from '../views/Pages/Archives';
import BoardDirectory from '../views/Pages/BoardDirectory';
import LicenseAndPolicies from '../views/Pages/LicenseAndPolicies';
import LinksAndNews from '../views/Pages/LinksAndNews';
import Knowledgeware from '../views/Pages/Knowledgeware';
import Feedback from '../views/Pages/Feedback';

const routes = [
  {
    path: '/initiate-meeting/:meeting_id',
    component: () => import('../views/Pages/InitiateMeeting.vue'),
    props: true,
  },
  {
    path: '/open-meeting',
    component: () => import('../views/Pages/OpenMeeting.vue'),
    props: true,
  },
  {
    path: '/',
    component: () => import('../views/Layout/Main'),
    children: [
      {
        path: '/',
        component: Login,
        name: 'login',
      },
      {
        path: '/dashboard',
        component: Dashboard,
        name: 'dashboard'
      },
      {
        path: '/License%20And%20Policies',
        component: LicenseAndPolicies,
        name: 'License And Policies'
      },
      {
        path: '/Links%20And%20News',
        component: LinksAndNews,
        name: 'Links And News'
      },
      {
        path: '/Knowledgeware',
        component: Knowledgeware,
        name: 'Knowledgeware'
      },
      {
        path: '/Feedback',
        component: Feedback,
        name: 'Feedback'
      },
      {
        path: '/Briefcase',
        component: Briefcase,
        name: 'Briefcase'
      },
      {
        path: '/Matters%20Arising%20Report',
        component: MattersArisingReport,
        name: 'Matters Arising Report'
      },
      {
        path: '/Meeting%20Packs',
        component: MeetingPacks,
        name: 'Meeting Packs'
      },
      {
        path: '/Meetings',
        component: Meetings,
        name: 'Meetings'
      },
      {
        path: '/Resources',
        component: Resources,
        name: 'Resources'
      },
      {
        path: '/Approvals',
        component: Approvals,
        name: 'Approvals'
      },
      {
        path: '/Compliance',
        component: Compliance,
        name: 'Compliance'
      },
      {
        path: '/Evaluation',
        component: Evaluation,
        name: 'Evaluation'
      },
      {
        path: '/Discussion',
        component: Discussion,
        name: 'Discussion'
      },
      {
        path: '/Plans',
        component: Plans,
        name: 'Plans'
      },
      {
        path: '/Plan',
        component: Plan,
        name: 'Plan'
      },
      {
        path: '/Conference',
        component: Conference,
        name: 'Conference'
      },
      {
        path: '/Elections',
        component: Elections,
        name: 'Elections'
      },
      {
        path: '/election',
        component: SingleElection,
        name: 'SingleElections'
      },
      {
        path: '/Risk%20Management',
        component: RiskManagement,
        name: 'Risk Management'
      },
      {
        path: '/Procurement',
        component: Procurement,
        name: 'Procurement'
      },
      {
        path: '/line_chart',
        component: LineChart,
        name: 'LineChart'
      },
      {
        path: '/Archives',
        component: Archives,
        name: 'Archives'
      },
      {
        path: '/board-directory',
        component: BoardDirectory,
        name: 'BoardDirectory'
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  base: '/'
});

// control auth in all routes
router.beforeEach((to, from, next) => {
  const publicPages = ['/', '/open-meeting'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('token');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next({ path: '/' });
  } else {
    next();
  }
});

export default router;