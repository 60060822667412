<template>
  <div>
    <!-- NAV-MENU -->
    <div
      class="nav-menu"
      style="height: 45px; width: 100%; padding: 0px; padding-top: 0.3%; text-align: center;"
    >
      <div class="left-buttons">
        <router-link to="/dashboard" style="text-decoration: none">
          <v-icon color="#fff" size="35" style="margin-left: 10px"
            >mdi-home</v-icon
          >
        </router-link>
      </div>
      <div class="filler"></div>
      <p style="font-weight: bold; font-size: 19px;">
        APPROVALS
      </p>
      <div class="filler"></div>
      <div class="right-buttons">
        <v-menu 
          location="bottom" 
          :close-on-content-click="false"
          transition="slide-x-transition"
        >
          <template v-slot:activator="{ props }">
            <v-icon 
              class="mr-4"
              size="x-large"
              color="#ffffff"
              v-bind="props"
            >
              mdi-magnify
            </v-icon>
          </template>

          <div class="mt-3 mr-n16">
            <ApprovalSearch @search_approvals="searchApprovals" />
          </div>
        </v-menu>
        <v-icon 
          color="#ffffff" 
          class="mr-5"
          @click="reloadPage"
          icon="mdi-cached"
          size="35"
          style="cursor: pointer;"
        />
      </div>
    </div>

    <!-- COMPONENT -->
    <div class="component">
      <div class="left" style="color: #000000">
        <div class="left-bottom">
          <v-list 
            density="compact"
            style="background-color: #f8f9f9;"
          >
            <v-list-item-title style="color: rgb(71, 183, 83); padding: 15px;">
              <v-icon style="margin-right: 10px" color="rgb(71, 183, 83)"
                >mdi-server</v-icon
              >
              Category
            </v-list-item-title>
            <v-list-item-content>
              <v-card
                class="mx-auto"
              >
                <v-list density="compact">
                  <v-list-item
                    v-for="(item, index) in approvalTypes"
                    :key="index"
                    :value="item"
                    active-color="rgb(71, 183, 83)"
                    @click="getCategoryId(item)"
                  >
                    <v-list-item-title class="text-wrap mt-3 mb-n5">
                      <a href="#" @click="getCategoryId(item)">
                        {{ item.categoryName }}
                      </a>
                      <v-divider thickness="2px"></v-divider>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-list-item-content>
          </v-list>
        </div>
      </div>

      <div class="right" v-if="!closePDF && !closeDocument">
        <!-- Header 1 -->
        <div
          class="parent"
          style="height: 45px; width: 100%; background-color: #fff"
        >
          <div class="left-buttons">
            <v-icon
              style="float: left; margin-top: 5px; margin-left: 10px; background-color: rgb(71, 183, 83); color: #fff;"
              size="25"
              >mdi-calendar-check-outline</v-icon
            >
            <p
              style="float: left; font-weight: bold; font-size: 19px; text-align: left; margin-left: 10px; margin-top: 3px; color: rgb(71, 183, 83);"
            >
              {{ approvalName }}
            </p>
          </div>
          <div class="filler">
            <div class="dropdown-table float-right" style="display: inline; margin-top: 5px;">
              <v-menu>
                <template v-slot:activator="{ props }">
                  <v-btn 
                    variant="text" 
                    v-bind="props"
                    class="btn"
                    :style="getFilterStyle(9)"
                  >
                    {{ sort.desc ? 'Descending' : 'Ascending' }}
                    <v-icon>mdi-swap-vertical-bold</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item style="display: block">
                    <v-list-item-title
                      style="padding: 12px; font-size: 16px; cursor: pointer"
                      @click="sort.desc = true"
                      >Descending
                    </v-list-item-title>

                    <v-list-item-title
                      style="padding: 12px; font-size: 16px; cursor: pointer"
                      @click="sort.desc = false"
                      >Ascending
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
          <div class="dropdown-table float-right" style="display: inline; margin-top: 5px;">
            <v-menu>
              <template v-slot:activator="{ props }">
                <v-btn 
                  variant="text" 
                  v-bind="props"
                  class="btn"
                  :style="getFilterStyle(9)"
                >
                  {{ filterName }}
                  <span v-if="sort.status == 9"
                    >({{ sort.desc ? "desc" : "asc" }})</span
                  >
                </v-btn>
              </template>
              <v-list>
                <v-list-item style="display: block">
                  <v-list-item-title
                    style="padding: 12px; font-size: 16px; cursor: pointer"
                    @click="doSort('approvalName', 9, 'Approval Name')"
                    >Approval Name
                    <span v-if="sort.field == 'approvalName'"
                      >({{ sort.desc ? "desc" : "asc" }})</span
                    >
                  </v-list-item-title>

                  <v-list-item-title
                    style="padding: 12px; font-size: 16px; cursor: pointer"
                    @click="doSort('status', 9, 'Status')"
                    >Status
                    <span v-if="sort.field == 'status'"
                      >({{ sort.desc ? "desc" : "asc" }})</span
                    >
                  </v-list-item-title>

                  <v-list-item-title
                    style="padding: 12px; font-size: 16px; cursor: pointer"
                    @click="doSort('applicantName', 9, 'Applicant Name')"
                    >Applicant Name
                    <span v-if="sort.field == 'applicantName'"
                      >({{ sort.desc ? "desc" : "asc" }})</span
                    >
                  </v-list-item-title>

                  <v-list-item-title
                    style="padding: 12px; font-size: 16px; cursor: pointer"
                    @click="doSort('amount', 9, 'Amount')"
                    >Amount
                    <span v-if="sort.field == 'amount'"
                      >({{ sort.desc ? "desc" : "asc" }})</span
                    >
                  </v-list-item-title>

                  <v-list-item-title
                    style="padding: 12px; font-size: 16px; cursor: pointer"
                    @click="doSort('dateSubmitted', 8, 'Date Submitted')"
                    >Date Submitted
                    <span v-if="sort.field == 'dateSubmitted'"
                      >({{ sort.desc ? "desc" : "asc" }})</span
                    >
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <div class="right-buttons" style="display: inline; margin-top: 5px;">
            <div style="display: inline; margin-right: 10px;">
              <v-btn
                style="padding: 0px;"
                class="btn"
                variant="text"
                v-on:click="doSort('approvalId', 7)"
                :style="getFilterStyle(7)"
              >
                My Pending
                <span v-if="sort.field == 'approvalId' && sort.status == 7"
                  >({{ sort.desc ? "desc" : "asc" }})</span
                >
              </v-btn>
            </div>
            <div style="display: inline; margin-right: 10px;">
              <ApprovalStatus 
                :approval-status="approvalStatus" 
                :status-name="getStatusName" 
                :filter-style="getFilterStyle(sort.status)" 
                :approval-sort="sort"
                @filter_list="filterApprovals"
              />
            </div>
          </div>
        </div>

        <div v-if="store.getLoading">
          <v-progress-linear
            color="rgb(71, 183, 83)"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </div>

        <!-- Table -->
        <v-table 
          v-if="sortedData2.length > 0" 
          fixed-header
          fixed-footer
          height="100vh"
        >
          <thead>
            <tr>
              <th style="background-color: rgb(71, 183, 83); font-weight: bolder;" class="text-left text-white">Proposal </th>
              <th style="background-color: rgb(71, 183, 83); font-weight: bolder;" class="text-left text-white">Approval Name</th>
              <th style="background-color: rgb(71, 183, 83); font-weight: bolder;" class="text-left text-white">Applicant Name</th>
              <th style="background-color: rgb(71, 183, 83); font-weight: bolder;" class="text-left text-white">Amount</th>
              <th style="background-color: rgb(71, 183, 83); font-weight: bolder;" class="text-left text-white">Date Submitted</th>
              <th style="background-color: rgb(71, 183, 83); font-weight: bolder;" class="text-left text-white">Votes</th>
              <th style="background-color: rgb(71, 183, 83); font-weight: bolder;" class="text-center text-white">Status</th>
            </tr>
          </thead>
          <tbody
            v-for="(item, index) in sortedData2" 
            :key="item.approvalId"
            class="table-striped"
          >
            <tr
              @click="
                approvalDescription(item);
                getApprovalDocuments();
                toggle(item.approvalId);
              "
              style="height: 100%; cursor: pointer;"
            >
              <td
                style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; padding-top: 20px; padding-bottom: 20px;"
              >
                {{ index + 1 }}
              </td>
              <td
                style="overflow: hidden; text-overflow: ellipsis;"
              >
                {{ item.approvalName }}
              </td>
              <td
                style="overflow: hidden; text-overflow: ellipsis;"
              >
                {{ item.applicantName }}
              </td>
              <td
                style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
              >
                {{ toMoneyFormat(item.amount, item.currency) }}
              </td>
              <td
                style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
              >
                {{ formatDate(item.dateSubmitted) }}
              </td>
              <td
                style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
              >
                {{ item.votes }}
              </td>
              <td
                class="text-center"
                style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
              >
                <v-btn 
                  class="text-white" 
                  :color="getItemDetails(item.status).color"
                  block
                >
                  {{ getItemDetails(item.status).name }}
                </v-btn>
              </td>
            </tr>
            <tr v-if="opened.includes(item.approvalId)">
              <td colspan="7">
                <v-card class="elevation-0">
                  <v-card-text>
                    <div
                      style="display: block; margin: 2%; border: 1px solid grey; overflow: hidden;"
                    >
                      <v-row class="pa-4">
                        <v-col cols="6" md="6" sm="6">
                          <div
                            class="top-left"
                          >
                            <p style="font-weight: bold; font-size: 19px; margin: 0px;">
                              Full Proposal Description:
                            </p>
                            <br>
                            <p style="margin-top: 0px; height: auto; text-align: justify;">
                              {{ approvalDesc || 'No Description Found' }}
                            </p>
                          </div>
                        </v-col>

                        <v-col cols="6" md="6" sm="6">
                          <v-row>
                            <v-col cols="12" md="12" sm="12">
                              <p style=" font-weight: bold; font-size: 19px; margin: 0px;">
                                Source Documents:
                              </p>
                              <div v-if="approvalDocs && approvalDocs.length > 0">
                                <v-list density="compact" class="ml-n4">
                                  <v-list-item
                                    v-for="(item, index) in approvalDocs"
                                    :key="index"
                                    :value="item.itemName"
                                  >
                                    <template v-slot:prepend>
                                      <v-progress-circular
                                        v-if="loading[item.itemId]"
                                        indeterminate
                                        color="rgb(71, 183, 83)"
                                        class="mr-2"
                                      ></v-progress-circular> 
                                      <v-icon
                                        v-else
                                        :color="fileIds.includes(item.itemId) ? 'rgb(227, 58, 58)' : 'rgb(71, 183, 83)'"
                                        class="mr-2"
                                        size="large"
                                        :icon="globalMethods.getDocumentIcon(item.itemExtension)"
                                      />
                                    </template>
                                    <v-list-item-title class="text-wrap">
                                      <file-options
                                        :file="item"
                                        :file_color="'rgb(71, 183, 83)'"
                                        @open_pdf="getDocument(item)"
                                        :file_class="item.crossout == 0 ? '' : ' text-decoration-line-through'"
                                        v-if="item.itemExtension == 'pdf'"
                                        :select_type="true"
                                        :pdf_type="2"
                                      ></file-options>
                                      <span 
                                        v-else
                                        :class="item.crossout == 0 ? ' name' : ' name text-decoration-line-through'"
                                        @click="getDocument(item)"
                                      >
                                        {{ item.itemName }}
                                      </span>
                                    </v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </div>
                              <div v-else>
                                <br>
                                <p style="margin-top: 0px; height: auto; text-align: justify;">
                                  No Documents Found
                                </p>
                              </div>
                            </v-col>

                            <v-col cols="12" md="12" sm="12">
                              <p style="font-weight: bold; font-size: 19px; margin: 0px; margin-top: 10px;">
                                Supplementary Documents:
                              </p>
                              <div v-if="approvalSuppDocs && approvalSuppDocs.length > 0">
                                <v-list density="compact" class="ml-n4">
                                  <v-list-item
                                    v-for="(suppItem, index) in approvalSuppDocs"
                                    :key="index"
                                    :value="suppItem.itemName"
                                  >
                                    <template v-slot:prepend>
                                      <v-progress-circular
                                        v-if="loading[suppItem.itemId]"
                                        indeterminate
                                        color="rgb(71, 183, 83)"
                                        class="mr-2"
                                      ></v-progress-circular> 
                                      <v-icon
                                        v-else
                                        :color="fileIds.includes(suppItem.itemId) ? 'rgb(227, 58, 58)' : 'rgb(71, 183, 83)'"
                                        class="mr-2"
                                        size="large"
                                        :icon="globalMethods.getDocumentIcon(suppItem.itemExtension)"
                                      />
                                    </template>
                                    <v-list-item-title class="text-wrap">
                                      <file-options
                                        :file="suppItem"
                                        :file_color="'rgb(71, 183, 83)'"
                                        @open_pdf="getDocument(suppItem)"
                                        :file_class="suppItem.crossout == 0 ? '' : ' text-decoration-line-through'"
                                        v-if="suppItem.itemExtension == 'pdf'"
                                        :select_type="true"
                                        :pdf_type="2"
                                      ></file-options>
                                      <span 
                                        v-else
                                        :class="suppItem.crossout == 0 ? ' name' : ' name text-decoration-line-through'"
                                        @click="getDocument(suppItem)"
                                      >
                                        {{ suppItem.itemName }}
                                      </span>
                                    </v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </div>
                              <div v-else>
                                <br>
                                <p style="margin-top: 0px; height: auto; text-align: justify;">
                                  No Documents Found
                                </p>
                              </div>
                            </v-col>
                          </v-row>
                        </v-col>

                        <v-col cols="12" md="12" sm="12">
                          <p style="margin: 0px; padding-left: 10px; font-weight: bold; font-size: 19px;">
                            Actions
                          </p>
                          <br>
                          <div class="modals">
                            <!-- APPROVE MODAL -->
                            <div class="approve" style="float: left;">
                              <v-btn
                                @click="store.setDialogApprove(true)"
                                style="margin-right: 10px"
                                :disabled="item.RCIRequired ? (item.RCIRegistered ? (checkApprove ? true : false) : true) : (checkApprove ? true : false)"
                                :color="item.RCIRequired ? (item.RCIRegistered ? (checkApprove ? 'transparent' : 'success') : 'transparent') : (checkApprove ? 'transparent' : 'success')"
                                class="mb-3"
                              >
                                Approve
                              </v-btn>
                            </div>

                            <!-- REJECT MODAL -->
                            <div class="reject" style="float: left;">
                              <v-btn
                                @click="store.setDialogReject(true)"
                                style="margin-right: 10px; color: #FFFFFF;"
                                :disabled="item.RCIRequired ? (item.RCIRegistered ? (checkApprove ? true : false) : true) : (checkApprove ? true : false)"
                                :color="item.RCIRequired ? (item.RCIRegistered ? (checkApprove ? 'transparent' : 'error') : 'transparent') : (checkApprove ? 'transparent' : 'error')"
                                class="mb-3"
                              >
                                Reject
                              </v-btn>
                            </div>

                            <!-- COMMENT MODAL -->
                            <div class="comment" style="float: left;">
                              <v-btn
                                @click="store.setDialogComment(true)"
                                style="margin-right: 10px"
                                color="warning"
                                class="mb-3"
                              >
                                Comment
                              </v-btn>
                            </div>

                            <!-- RCI MODAL -->
                            <div class="rci" style="float: left">
                              <v-btn
                                @click="
                                  store.setDialogRCI(true);
                                  store.setRCIType(2);
                                "
                                style="margin-right: 10px"
                                :disabled="item.RCIRequired ? (item.RCIRegistered ? true : false) : true"
                                :color="item.RCIRequired ? (item.RCIRegistered ? 'transparent' : 'info') : 'transparent'"
                                class="mb-3"
                              >
                                RCI
                              </v-btn>
                            </div>
                          </div>
                        </v-col>

                        <v-col cols="12" sm="12" md="12">
                          <!-- APPROVER TABLE -->
                          <div>
                            <v-table>
                              <thead>
                                <tr style="background-color: rgb(71, 183, 83); color: #fff; font-weight: bolder;" class="approver-table">
                                  <td>Approver</td>
                                  <td>Action</td>
                                  <td>Comment</td>
                                  <td>Condition</td>
                                  <td>Date</td>
                                </tr>
                              </thead>
                              <tbody v-if="approvalComments.length > 0">
                                <tr
                                  v-for="(item, index) in approvalComments"
                                  v-bind:key="index"
                                  @click="replyComment(item)"
                                  style="cursor: pointer;"
                                >
                                  <td>
                                    {{ item.approver }}
                                  </td>
                                  <td>
                                    {{ item.approvalActionName }}
                                  </td>
                                  <td>
                                    <div v-if="item.totalCommentCount > 0">
                                      {{ truncateText(item.approverComment, 50) }} 
                                      <span style="cursor: pointer;" class="text-red float-right font-weight-bold">(i)</span>
                                    </div>
                                    <div v-else>
                                      {{ truncateText(item.approverComment, 50) }}
                                    </div>
                                  </td>
                                  <td>
                                    {{ truncateText(item.approvalCondition, 50) }}
                                  </td>
                                  <td>
                                    {{ formatDate(item.approvalCommentDate) }}
                                  </td>
                                </tr>
                              </tbody>
                              <tbody v-else>
                                <tr>
                                  <td 
                                    colspan="5"
                                    class="text-center"
                                  >
                                    No Comments Found for this Approval
                                  </td>
                                </tr>
                              </tbody>
                            </v-table>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card-text>
                </v-card>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="7" class="bg-white">
                <v-btn 
                  block
                  color="rgb(71, 183, 83)"
                  class="text-capitalize text-white font-weight-bold"
                  @click="getCategoryId({}, true)"
                  :loading="store.getLoading"
                >
                  Load More Records
                </v-btn>
              </td>
            </tr>
          </tfoot>
        </v-table>

        <v-table v-else>
          <thead>
            <tr style="background-color: rgb(71, 183, 83); color: #fff; font-weight: bolder;">
              <td class="text-left">Proposal</td>
              <td class="text-left">Approval Name</td>
              <td class="text-left">Applicant Name</td>
              <td class="text-left">Amount</td>
              <td class="text-left">Date Submitted</td>
              <td class="text-left">Votes</td>
              <td class="text-center">Status</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td 
                colspan="7"
                class="text-center"
              >
                <span>
                  No Approvals Found
                </span>
              </td>
            </tr>
          </tbody>
        </v-table>
      </div>

      <!-- REPLY COMMENTS MODAL -->
      <div class="text-center">
        <ReplyComment v-if="dialog_reply" :comment="comment" :replies="selected_replies" @add_comment="replyComment(comment)" />
      </div>

      <!-- APPROVE MODAL -->
      <div class="text-center">
        <ApproveApproval @approval_updated="approvalUpdated" />
      </div>

      <!-- REJECT MODAL -->
      <div class="text-center">
        <RejectApproval @approval_updated="approvalUpdated" />
      </div>

      <!-- COMMENTS MODAL -->
      <div class="text-center">
        <CommentApproval @approval_updated="approvalUpdated" />
      </div>

      <!-- RCI MODAL -->
      <div class="text-center">
        <RCI :type="2" @approval_updated="approvalUpdated" />
      </div>

      <!-- PSPDFKIT / OPEN DOCUMENT MODAL -->
      <div
        v-if="closePDF || closeDocument"
        style="position: absolute; background-color: #f5f5f5; width: 100%; height: 100%; padding: 1%; overflow: hidden; margin-right: 0;"
      >
        <v-card>
          <v-card-text>
            <v-main>
              <v-container fluid>
                <component
                  :pdf-url="current_pdf.itemUrl"
                  :license-key="LICENSE_KEY"
                  :base-url="baseUrl"
                  @close_pdf="closePdf"
                  :is="document_component"
                  :document-url="current_document.itemUrl"
                  :document-type="current_document.itemExtension"
                  :document-title="current_document.itemName"
                  :viewer-color="'rgb(71, 183, 83)'"
                  @close_document="documentClosed"
                  :document-list="documentList"
                  :list-color="'rgb(71, 183, 83)'"
                  @open_pdf="openDocument"
                  @open_document="openDocument"
                  @switch_pdf="switchDocument"
                  @switch_document="switchDocument"
                  :parent_select_type="true"
                  :parent_pdf_type="2"
                  :list-width="'25%'"
                  :briefcase-files="fileIds"
                />
              </v-container>
            </v-main>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <!-- END OF COMPONENT -->
  </div>
</template>

<script>
var moment = require("moment");
import axios from "axios";
import UserData from "../components/repository/UserData";
import PSPDFKit from "../components/PSPDFKit.vue";
import ReplyComment from "../components/Forms/ReplyComment.vue";
import { useAuthStore } from "../stores/auth";
import { useIndexStore } from "../stores/index";
import { useAlertStore } from "../stores/alert";
import ApproveApproval from "./Forms/ApproveApproval.vue";
import RejectApproval from "./Forms/RejectApproval.vue";
import CommentApproval from './Forms/CommentApproval.vue';
import RCI from './Forms/RCI.vue';
import ApprovalStatus from './ApprovalStatus.vue';
import ApprovalSearch from "./Cards/ApprovalSearch.vue";
import FileOptions from './Cards/FileOptions.vue';
import GlobalMethods from './repository/GlobalMethods';
import DocumentViewer from "./Cards/DocumentViewer.vue";

const LICENSE_KEY =
  "QvMr6j5706r3kYlOiVvJgEwu4fZnTL1fYz4t6paoBV5PekugaffzJnLtX5TczMS65OKAI1iIaSi06_l76UgF3kyKauJ9vaMZFuU3_oDplMFty6AhBhWPh80M7qoSa8xjtux3NqNrfqybICplLfHezwjhABIkPy6XxBMQ_uPU87_C5pMBXlc8A2PI_ShMjfhst6O_C0y9jyeMJ2DYudM3liFVsp90A0dOY51LM2Q3xV3jfcJ7K39FKpnKU_atpn1Ig8HXcmWdGQMjCXyquwVu9i2J850rGqBgFKJJFGixxnU3sZ__tA7Mb3aX8T2nXPlEnamtAhNf-DD1sEtqXHp5q5xxsFFuJDVh82tXKfd4oX8AkzWLd_-UVg_h_6ATML30e8fiQXSVcN0mmlZH5XYUv0jB5cHEyuIaGZac7gCNRn32pchJNpIwEdgHzWDRBQxxCR7F8b6z3Xs3yXJ6y9EhhrnKmjNYkMCdFJ0qNhI8zF8RhAbLA-uKtrzMy7hXLrlpuIHdGaCXMm7gA9iBpKiqwHIrX83rCFoVYUGM4BqNkim3HN8YJzZRDDI-ZHzdy7CcanSuvbe3NSxW5daeHdGVvg==";

export default {
  data: () => ({
    moment: moment,
    test: true,
    itemSubArrayStaticData: [],
    itemSubArrayDynamicData: [],
    resourceDirectors: [],
    itemName: "",
    approvalTypes: [],
    approvals: [],
    approvalName: "",
    approvalDesc: "",
    approvalDocs: [],
    approvalSuppDocs: [],
    approvalComments: [],
    checkApprove: true,
    interestDisclosed: "",
    interestConflict: "",
    isApproval: true,
    hasConflict: false,
    actionType: "",
    comments: "",
    conditions: "",
    password: "",
    pass_error: "",
    snackbar: false,
    timeout: 200000000,
    top: "top",
    radio: "",
    confirm: false,
    dialog: false,
    dialog2: false,
    dialog3: false,
    dialog4: false,
    opened: [],
    show: false,
    valid: false,
    from: 0,
    count: 20,
    rules: {
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
      selectRules: [(v) => !!v || "Select is required"],
      textRules: [
        v => !!v || 'Field is required',
        v => (v && v.length >= 3) || 'Min 3 characters',
      ],
      characterRules: [
        v => (v && v.length >= 3) || 'Min 3 characters',
      ],
    },

    items: [
      { title: "Name" },
      { title: "Size" },
      { title: "Modified On" },
      { title: "Submitted By" },
    ],

    sort: {
      field: "",
      desc: true,
      status: null,
      search: "",
    },

    navigationPath: [
      {
        itemName: "...",
        localUrl: "0",
      },
    ],

    currentParent: "",
    parentItemId: "",

    files: [],
    fileIds: [],
    globalMethods: GlobalMethods,
    loading: {},

    closeDocument: false,
    closePDF: false,
    pdf: "",
    type: "",
    title: "",
    LICENSE_KEY: LICENSE_KEY,
    baseUrl: "",
    errorMsg: "",
    token: localStorage.getItem("token") ? JSON.stringify(localStorage.getItem("token")).slice(1, -1) : "",
    store: useIndexStore(),
    auth: useAuthStore(),
    alert: useAlertStore(),
    selected_replies: [],
    filterName: "Date Submitted",
    documentList: [],
    allApprovalsForCategory: [],
    approvalStatus: [
      {
        approvalStatusColor: "#000000",
        approvalStatusId: "0",
        approvalStatusName: "All"
      },
      {
        approvalStatusColor: "#5BC0DE",
        approvalStatusId: "1",
        approvalStatusName: "Pending"
      },
      {
        approvalStatusColor: "#4BB543",
        approvalStatusId: "2",
        approvalStatusName: "Approved"
      },
      {
        approvalStatusColor: "#FF9494",
        approvalStatusId: "3",
        approvalStatusName: "Rejected"
      },
    ],
    comment: {},
  }),

  methods: {
    formatDate(date) {
      return moment.unix(date).format("DD-MMM-YYYY");
    },

    truncateText(str, n) {
      return str.length > n ? str.substr(0, n - 1) + "..." : str;
    },

    toMoneyFormat(number, currency) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: currency,
      }).format(number);
    },

    validate() {
      return this.$refs.form[0].validate();
    },

    resetForm() {
      return this.$refs.form[0].reset();
    },

    resetValidation() {
      return this.$refs.form[0].resetValidation();
    },
    
    closePdf() {
      this.closePDF = !this.closePDF;
      this.shareDocument = false;
      this.store.setCurrentPdf({});
      this.store.setLoading(false);
    },

    documentClosed() {
      this.closeDocument = !this.closeDocument;
      this.store.setDialogDocumentViewer(false);
      this.store.setCurrentDocument({});
    },

    toggle(id) {
      const index = this.opened.indexOf(id);
      if (index > -1) {
        this.opened.splice(index, 1);
      } else {
        this.opened = [];
        this.opened.push(id);
      }
    },

    removeScrollBlock() {
      document.querySelector('html').classList.remove('v-overlay-scroll-blocked');
    },

    reloadPage() {
      window.location.reload();
    },

    approvalUpdated() {
      this.getApprovalTypes();
      this.getApprovalComments();
    },

    getItemDetails(status) {
      let details = {};
      switch (status) {
        case "1":
          details.name = 'Pending';
          details.color = 'info';
          break;
        case "2":
          details.name = 'Approved';
          details.color = 'success';
          break;
        case "3":
          details.name = 'Rejected';
          details.color = 'error';
          break;
      }

      return details;
    },

    getApprovalTypes() {
      const formData = new FormData();
      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "getApprovalCategories");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);

      this.store.setLoading(true);
      axios.post(UserData.getBaseUrl(), formData).then((response) => {
        this.getApprovals = response.data;
        this.approvalTypes = this.getApprovals.approvalTypes;
        this.sortedData2 = this.store.getCategoryId ? this.getCategoryId(this.store.getCategoryId) : (this.approvalTypes.length > 0 ? this.getCategoryId(this.approvalTypes[0]) : []);
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    getCategoryId(item, more = false) {
      if (more) {
        this.from += 21;
      } else {
        this.from = 0;
      }

      const formData = new FormData();
      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "getApprovalsForCategory");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      formData.append("categoryId", item.categoryId && !more ? item.categoryId : this.store.getCategoryId);
      formData.append("from", this.from);
      formData.append("count", this.count);

      this.store.setLoading(true);
      axios.post(UserData.getBaseUrl(), formData).then((response) => {
        if (more) {
          this.allApprovalsForCategory.push(...response.data.approvals);
        } else {
          this.approvals = response.data.approvals;
          this.allApprovalsForCategory = this.approvals;
        }

        this.approvalName = item.categoryName ? item.categoryName : this.store.getCategoryName;
        this.doSort('approvalId', more ? this.sort.status : 7);

        if (!more) {
          this.store.setCategoryId(item.categoryId);
          this.store.setCategoryName(item.categoryName ? item.categoryName : this.store.getCategoryName);
        }

        this.store.setLoading(false);
      })
      .catch((e) => {
        console.log("Error", e);
        this.store.setLoading(false);
      });
    },
    
    approvalDescription(item) {
      this.approvalDesc = item.description;
      UserData.setApprovalId(item.approvalId);
      this.store.setApprovalId(item.approvalId);
      this.store.setCurrentApproval(item);
    },
    
    getApprovalDocuments() {
      const formData = new FormData();
      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "getApprovalDocuments");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      formData.append("approvalId", UserData.getApprovalId() ? UserData.getApprovalId() : this.store.getApprovalId);

      axios.post(UserData.getBaseUrl(), formData).then((response) => {
        this.getApprovalComments();
        this.approvalDocs = response.data.documents;
        this.approvalSuppDocs = response.data.suppDocuments;
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    getApprovalComments() {
      const formData = new FormData();
      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "getApprovalComments");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      formData.append("approvalId", UserData.getApprovalId() ? UserData.getApprovalId() : this.store.getApprovalId);

      axios.post(UserData.getBaseUrl(), formData).then((response) => {
        this.getApprovalComment = response.data;
        this.approvalComments = this.getApprovalComment.comments;
        this.checkApprove = this.approvalComments.some(item => (this.user.userInfo.userFullName === item.approver && item.approvalActionType === "2" 
        || this.user.userInfo.userFullName === item.approver && item.approvalActionType === "3"));
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    getDocument(item) {
      if (item.itemExtension == "pdf") {
        this.briefcaseDocument(item);
      } else {
        if (this.globalMethods.getDocumentExtension(item.itemExtension)) {
          this.openDocument(item);
        }
      }
    },

    openDocument(item, url = null) {
      let count = this.documentList.length;
      this.documentList.splice(0, count);
      this.documentList.push(...this.approvalDocs);
      this.documentList.push(...this.approvalSuppDocs);

      if (item.itemExtension == "pdf") {
        this.store.setBriefcaseUrl(url);
        this.store.setDialogDocumentViewer(false);
        this.closeDocument = false;
        this.closePDF = true;
        this.store.setDocumentComponent('pspdfkit');
        this.store.setCurrentPdf(item);
        this.store.setCurrentDocument(item);

        UserData.setDocumentId(item.itemId);
        this.store.setDocumentId(item.itemId);
      } else {
        if (item.itemExtension != 'No File Extension') {
          this.store.setDialogDocumentViewer(true);
          this.closePDF = false;
          this.closeDocument = true;
          this.store.setDocumentComponent('document-viewer');
          this.store.setCurrentDocument(item);

          UserData.setDocumentId(item.itemId);
          this.store.setDocumentId(item.itemId);
        } else {
          if (item.itemSize > 0) {
            this.alert.showError(item.itemExtension);
          } else {
            this.alert.showError('Error Opening File');
          }
        }
      }
    },

    switchDocument(item) {
      this.store.setBriefcaseUrl(null);
      this.openDocument(item);
    },

    briefcaseDocument(item) {
      if (item.itemExtension) {
        this.loading[item.itemId] = true;
        this.globalMethods.downloadEbrDocument(item.itemUrl).then(async (response) => {
          if (response.status == 200) {
            let fileType = response.headers['content-type'];
            let fileData = new Blob([response.data], { type: fileType });
            let blob = window.URL.createObjectURL(fileData);
            this.openDocument(item, blob);
            this.loading[item.itemId] = false;
          } else {
            this.loading[item.itemId] = false;
            console.log("Response: ", response);
          }
        }).catch((e) => {
          this.loading[item.itemId] = false;
          console.log("Error: ", e);
        });
      } else {
        this.alert.showInfo("Briefcasing Folders is Under Development");
      }
    },

    registerOfInterestApproval() {
      this.validate().then(() => {
        if (this.valid && this.confirm) {
          const formData = new FormData();
          formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
          formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
          formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
          formData.append("model", "registerOfInterestApproval");
          formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
          formData.append("approvalId", UserData.getApprovalId() ? UserData.getApprovalId() : this.store.getApprovalId);
          formData.append("interestDisclosed", this.interestDisclosed);
          formData.append("isApproval", this.isApproval);
          formData.append("hasConflict", this.hasConflict);
          formData.append("interestConflict", this.interestConflict);

          this.store.setLoading(true);
          axios.post(UserData.getBaseUrl(), formData).then((response) => {
            this.getApprovalTypes();
            this.getApprovalComments();
            this.pass_error = "";
            this.pass_error = response.data.message;
            this.alert.showSuccess(this.pass_error);
            this.store.setLoading(false);
            document.querySelector('html').classList.remove('v-overlay-scroll-blocked');
            this.dialog4 = false;
            this.reset();
          })
          .catch((e) => {
            console.log("Error", e);
            this.store.setLoading(false);
          });
        }
      });
    },

    replyComment(item) {
      const formData = new FormData();
      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "getApprovalsReplies");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      formData.append("approvalId", UserData.getApprovalId() ? UserData.getApprovalId() : this.store.getApprovalId);
      formData.append("actionId", item.actionId);
      formData.append("from", 0);
      formData.append("count", 20);
      formData.append("isRefresh", false);

      axios.post(UserData.getBaseUrl(), formData).then((response) => {
        this.selected_comment = item;
        this.comment = item;
        this.selected_replies = response.data.commentList;
        this.store.setDialogReply(true);
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    groupBy(arr, property) {
      return arr.reduce(function (memo, x) {
          if (!memo[x[property]]) { memo[x[property]] = []; }
          memo[x[property]].push(x);
          return memo;
      }, {});
    },

    reset() {
      this.comments = "";
      this.conditions = "";
      this.password = "";
      this.row = null;
      this.interestDisclosed = "";
      this.interestConflict = "";
      this.confirm = false;
      this.hasConflict = false;
      document.querySelector('html').classList.remove('v-overlay-scroll-blocked');
    },

    doSort(field, status = null, name = null) {
      if (status != null) {
        this.sort.status = status;
      }

      if (name != null) {
        this.filterName = name;
      }
      
      if (status == 7) {
        let pending = this.allApprovalsForCategory.filter(item => item.userVoted == 0 && item.status == 1);
        this.approvals = pending;
      }

      if (field == this.sort.field) {
        this.sort.desc = !this.sort.desc;
      } else {
        this.sort.field = field;
        this.sort.desc = true;
      }
    },

    swapComponent: function (component) {
      this.currentComponent = component;
    },

    filterApprovals(status) {
      if (status == 0) {
        this.filterApprovalList('approvalId', 0);
      } else {
        this.filterApprovalList('approvalId', status);
      }
    },

    filterApprovalList(field, status = null) {
      if (status != 0) {
        this.sort.status = status;
      } else {
        this.sort.status = 0;
        this.approvals = this.allApprovalsForCategory;
      }

      if (field == this.sort.field) {
        this.sort.desc = !this.sort.desc;
      } else {
        this.sort.field = field;
        this.sort.desc = true;
      }
    },

    getFilterStyle(status) {
      if (this.sort.status === status) {
        return 'color: rgb(71, 183, 83); font-weight: bold; border: none;';
      } 
    },

    filteredApprovals(approvals) {
      this.approvals = approvals;
    },

    checkSortField(a, b) {
      switch (this.sort.field) {
        case 'amount':
          if (this.sort.desc) {
            return parseFloat(a[this.sort.field]) > parseFloat(b[this.sort.field]) ? -1 : 1;
          } else {
            return parseFloat(a[this.sort.field]) > parseFloat(b[this.sort.field]) ? 1 : -1;
          }
        case 'status':
          if (this.sort.desc) {
            if (parseInt(a[this.sort.field]) == parseInt(b[this.sort.field])) {
              return b['approvalId'] - a['approvalId'];
            }

            return parseInt(a[this.sort.field]) > parseInt(b[this.sort.field]) ? -1 : 1;
          } else {
            if (parseInt(a[this.sort.field]) == parseInt(b[this.sort.field])) {
              return a['approvalId'] - b['approvalId'];
            }

            return parseInt(a[this.sort.field]) > parseInt(b[this.sort.field]) ? 1 : -1;
          }
        default: 
          if (this.sort.desc) {
            return a[this.sort.field] > b[this.sort.field] ? -1 : 1;
          } else {
            return a[this.sort.field] > b[this.sort.field] ? 1 : -1;
          }
      }
    },

    searchApprovals(search) {
      this.sort.search = search;
    },
  },

  computed: {
    sortedData2() {
      if (this.sort.search) {
        return this.approvals.filter(item => item.approvalName.toLowerCase().includes(this.sort.search.toLowerCase()));
      }

      if (!this.sort.field) {
        return this.approvals;
      }

      if (this.sort.status == 1 || this.sort.status == 2 || this.sort.status == 3) {
        let approvals = this.allApprovalsForCategory.filter(item => item.status == this.sort.status);
        this.filteredApprovals(approvals);
        return approvals.concat().sort((a, b) => {
          return this.checkSortField(a, b);
        });
      } else {
        return this.approvals.concat().sort((a, b) => {
          return this.checkSortField(a, b);
        });
      }
    },

    getStatusName() {
      var name = '';
      var status = this.sort.status;

      if (status != null && status != 7 && status != 8 && status != 9) {
        let status = {};
        status = this.approvalStatus.filter((status) => status.approvalStatusId == parseInt(this.sort.status))[0];
        name = status.approvalStatusName;
      } else {
        name = 'All';
      }

      return name;
    },

    passCheck() {
      return !this.password;
    },

    passErrorsCheck() {
      return !this.pass_error;
    },

    user() {
      return this.auth.getLoggedInUser;
    },

    dialog_reply() {
      return this.store.getDialogReply;
    },

    rci_type() {
      return this.store.getRCIType;
    },

    current_pdf() {
      return this.store.getCurrentPdf;
    },

    current_document() {
      return this.store.getCurrentDocument;
    },

    document_component() {
      return this.store.getDocumentComponent;
    },

    selected_comment: {
      get() {
        return this.store.getSelectedComment;
      },
      set(value) {
        this.store.setSelectedComment(value);
      },
    },

    overlay_type: {
      get() {
        return this.store.getOverlayType;
      },
      set(value) {
        this.store.setOverlayType(value);
      },
    },

    session_overlay: {
      get() {
        return this.store.getSessionOverlay;
      },
      set(value) {
        this.store.setSessionOverlay(value);
      },
    },
  },

  beforeMount() {
    this.store.setCategoryId(null);
    this.store.setCategoryName("");
    this.store.setDialogReply(false);
    this.store.setDialogReplyComment(false);
    this.store.setDialogApprove(false);
    this.store.setDialogReject(false);
    this.store.setDialogComment(false);
    this.store.setDialogRCI(false);
    this.getApprovalTypes();
  },

  components: {
    pspdfkit: PSPDFKit,
    ReplyComment,
    ApproveApproval,
    RejectApproval,
    CommentApproval,
    RCI,
    ApprovalStatus,
    ApprovalSearch,
    FileOptions,
    DocumentViewer,
},
};
</script>

<style scoped>
.nav-menu {
  margin-top: 0px;
  background-color: rgb(71, 183, 83);
  color: #fff;
  display: flex;
  margin-bottom: 1%;
}

.component {
  width: 99%;
  margin-left: 0.5%;
  margin-right: 0.5%;
  height: 100vh;
}

.left {
  width: 20%;
  margin-right: 1%;
  float: left;
  height: 100%;
}

.left-top {
  width: 100%;
  /* height: 49%; */
  margin-bottom: 4%;
  background-color: #f8f9f9;
  border-top: 5px solid rgb(71, 183, 83);
}

.left-bottom {
  width: 100%;
  height: 100%;
  background-color: #f8f9f9;
  border-top: 5px solid rgb(71, 183, 83);
}

.left a {
  color: #000;
  text-decoration: none;
}

.left a:hover {
  color: rgb(71, 183, 83);
}

.right {
  width: 79%;
  float: left;
  background-color: #f8f9f9;
  border-top: 5px solid rgb(71, 183, 83);
}

.parent {
  display: flex;
  width: 100%;
}

.filler {
  flex-grow: 1;
}

.left-buttons {
  display: inline;
}

.right-buttons {
  display: inline;
}

button:focus {
  outline: 0 !important;
  border: 0 !important;
  box-shadow: none !important;
}

.table-striped a {
  text-decoration: none;
  color: #000;
}

.table-striped a:hover {
  color: rgb(71, 183, 83);
}

.table-striped a:active {
  color: rgb(71, 183, 83);
}

.table-striped:nth-child(even) {
  background-color: rgba(0, 0, 0, .05);
}

.table-striped:hover {
  background-color: rgba(255, 255, 255, 255) !important;
}

.approver-table:hover { 
  background-color: rgb(71, 183, 83) !important; 
}

.name {
  cursor: pointer !important;
}

.name:hover {
  color: #27ae60 !important;
}
</style>
