<template>
  <div>
    <!-- NAV-MENU -->
    <div
      class="nav-menu"
      style="
        height: 45px;
        width: 100%;
        padding: 0px;
        padding-top: 0.3%;
        text-align: center;
      "
    >
      <router-link to="/dashboard" style="text-decoration: none">
        <v-icon color="#fff" size="35" style="margin-left: 10px"
          >mdi-home</v-icon
        >
      </router-link>
      <p style="font-weight: bold; font-size: 24px; margin-left: 43%">
        Archives
      </p>
    </div>

    <!-- COMPONENT -->
    <div class="component">
      <div class="container">
        <v-card 
          height="100vh" 
          class="mx-auto pa-4 d-flex align-center justify-center"
          elevation="0"
        >
          <v-row no-gutters>
            <v-col cols="12" md="12" sm="12" align="center">
              <v-card-text>
                <span class="text-center">
                  No Resources Found
                </span>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </div>

      <div
        v-if="closePDF"
        style="
          position: absolute;
          background-color: #f5f5f5;
          width: 100%;
          height: 100%;
          padding: 1%;
          overflow: hidden;
          margin-right: 0;
        "
      >
        <v-card>
          <v-card-text>
            <v-main>
              <v-container fluid>
                <pspdfkit
                  :pdf-url="pdf"
                  :license-key="LICENSE_KEY"
                  :base-url="baseUrl"
                  @close_pdf="closePdf"
                />
              </v-container>
            </v-main>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <!-- END OF COMPONENT -->
  </div>
</template>

<script>
var moment = require("moment");
import axios from "axios";
import UserData from "../../components/repository/UserData";
import PSPDFKit from "../../components/PSPDFKit.vue";
import { useAuthStore } from "../../stores/auth";
import { useIndexStore } from "../../stores/index";
import prettyBytes from 'pretty-bytes';

const LICENSE_KEY =
  "QvMr6j5706r3kYlOiVvJgEwu4fZnTL1fYz4t6paoBV5PekugaffzJnLtX5TczMS65OKAI1iIaSi06_l76UgF3kyKauJ9vaMZFuU3_oDplMFty6AhBhWPh80M7qoSa8xjtux3NqNrfqybICplLfHezwjhABIkPy6XxBMQ_uPU87_C5pMBXlc8A2PI_ShMjfhst6O_C0y9jyeMJ2DYudM3liFVsp90A0dOY51LM2Q3xV3jfcJ7K39FKpnKU_atpn1Ig8HXcmWdGQMjCXyquwVu9i2J850rGqBgFKJJFGixxnU3sZ__tA7Mb3aX8T2nXPlEnamtAhNf-DD1sEtqXHp5q5xxsFFuJDVh82tXKfd4oX8AkzWLd_-UVg_h_6ATML30e8fiQXSVcN0mmlZH5XYUv0jB5cHEyuIaGZac7gCNRn32pchJNpIwEdgHzWDRBQxxCR7F8b6z3Xs3yXJ6y9EhhrnKmjNYkMCdFJ0qNhI8zF8RhAbLA-uKtrzMy7hXLrlpuIHdGaCXMm7gA9iBpKiqwHIrX83rCFoVYUGM4BqNkim3HN8YJzZRDDI-ZHzdy7CcanSuvbe3NSxW5daeHdGVvg==";

export default {
  data: () => ({
    moment: moment,
    test: true,
    itemSubArrayStaticData: [],
    itemSubArrayDynamicData: [],
    resourceDirectors: [],
    itemName: "",

    items: [
      { title: "Name" },
      { title: "Size" },
      { title: "Modified On" },
      { title: "Submitted By" },
    ],

    sort: {
      field: "",
      desc: true,
      code: null,
    },

    list: {
      view: 1,
    },

    navigationPath: [
      {
        itemName: "...",
        localUrl: "0",
      },
    ],

    currentParent: "",
    parentItemId: "",

    userdata: {
      rootUrl:
        "https://v3.egovernance.io/api_v13/frontend/web/index.php/user/create",
    },

    closePDF: false,
    pdf: "",
    LICENSE_KEY: LICENSE_KEY,
    token: localStorage.getItem("token") ? JSON.stringify(localStorage.getItem("token")).slice(1, -1) : "",
    auth: useAuthStore(),
    store: useIndexStore(),
    baseUrl: "",
    errorMsg: "",
  }),

  methods: {
    formatDate(date) {
      return moment.unix(date).format("DD-MMM-YYYY");
    },

    reloadPage() {
      window.location.reload();
    },

    getPrettyBytes(value) {
      return prettyBytes(parseInt(value));
    },

    closePdf() {
      this.closePDF = !this.closePDF;
      this.shareDocument = false;
    },

    getResourcePackFolder() {
      this.store.setLoading(true);
      axios.post(UserData.getBaseUrl(), this.getUserData()).then((response) => {
        this.getResourcePackFolder = response.data;
        localStorage.setItem("getResourcePackFolder", JSON.stringify(this.getResourcePackFolder));
        this.itemSubArrayStaticData = this.getResourcePackFolder.staticData;
        this.itemSubArrayDynamicData = this.getResourcePackFolder.dynamicData;
        this.loadDirectoriesAndFiles(this.itemSubArrayDynamicData.itemSubArray[0]);
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    loadDirectoriesAndFiles(item) {
      axios.post(UserData.getBaseUrl(), this.getUserData(item.itemId, item.itemParentId)).then((response) => {
        this.getResourcePackFolder = response.data;
        this.resourceDirectors = this.getResourcePackFolder.data.itemSubArray;
        this.navigationPath = [];
        this.navigationPath.push({
          itemName: this.getResourcePackFolder.data.itemName,
          itemId: this.itemParentId,
        });

        this.store.setLoading(false);
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    getUserData(itemId = 0, parentItemId = 0) {
      this.parentItemId = parentItemId;
      const formData = new FormData();

      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "getResourcePackFolder");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      formData.append("itemId", itemId);
      formData.append("isArchive", 0);

      return formData;
    },

    getResources(item) {
      if (item.itemExtension == "pdf") {
        this.closePDF = !this.closePDF;
        this.pdf = item.itemUrl;
        UserData.setDocumentId(item.itemId);
        this.store.setDocumentId(item.itemId);
        return;
      }
      if (item.itemExtension == "docx") {
        window.open(item.itemUrl);
      }
      if (item.itemExtension == "pptx") {
        window.open(item.itemUrl);
      }
      if (item.itemExtension == "xlsx") {
        window.open(item.itemUrl);
      }

      axios.post(UserData.getBaseUrl(), this.getUserData(item.itemId, item.itemParentId)).then((response) => {
        this.getResourcePackFolder = response.data;
        this.resourceDirectors = this.getResourcePackFolder.data.itemSubArray;
        this.navigationPath.push({
          itemName: this.getResourcePackFolder.data.itemName,
          itemId: this.parentItemId,
        });
      });
    },

    loadItemFromHistory() {
      let currentTop = this.navigationPath.pop();
      axios.post(UserData.getBaseUrl(), this.getUserData(currentTop.itemId)).then((response) => {
        this.getSubMeetingPackFolder = response.data;
        this.resourceDirectors = this.getSubMeetingPackFolder.data.itemSubArray;
      });
    },

    joinNames() {
      return [
        ...this.navigationPath.map((value) => {
          return value.itemName;
        }),
      ].join("/");
    },

    doSort(field, status = null) {
      if (status != null) {
        this.sort.code = status;
      }

      if (field == this.sort.field) {
        this.sort.desc = !this.sort.desc;
      } else {
        this.sort.field = field;
        this.sort.desc = true;
      }
    },

    swapComponent: function (component) {
      this.currentComponent = component;
    },

    getFilterStyle(status) {
      if (this.sort.code === status) {
        return 'color: rgb(227, 58, 58); font-weight: bold;';
      } 
    },

    changeListView(status) {
      this.list.view = status;
      this.sort.code = status;
    },
  },

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },
    
    sortedData2() {
      if (!this.sort.field) {
        return this.resourceDirectors;
      }
      return this.resourceDirectors.concat().sort((a, b) => {
        if (this.sort.desc) {
          return a[this.sort.field] > b[this.sort.field] ? -1 : 1;
        } else {
          return a[this.sort.field] > b[this.sort.field] ? 1 : -1;
        }
      });
    },
  },

  beforeMount() {
    this.getResourcePackFolder();
  },

  mounted() {
    const getResourcePackFolder = JSON.parse(localStorage.getItem("getResourcePackFolder"));
    if (getResourcePackFolder) {
      this.getResourcePackFolder = getResourcePackFolder;
    }
  },

  components: {
    pspdfkit: PSPDFKit,
  },
};
</script>

<style scoped>
.nav-menu {
  margin-top: 0px;
  background-color: rgb(227, 58, 58);
  color: #fff;
  display: flex;
  margin-bottom: 1%;
}

.component {
  width: 99%;
  margin-left: 0.5%;
  margin-right: 0.5%;
  height: 900px;
}

.left {
  width: 20%;
  margin-right: 1%;
  float: left;
  height: 100%;
}

.left-top {
  width: 100%;
  height: 49%;
  margin-bottom: 4%;
  background-color: #f8f9f9;
  border-top: 5px solid rgb(227, 58, 58);
  overflow: auto;
}

.left-bottom {
  width: 100%;
  height: 49%;
  background-color: #f8f9f9;
  border-top: 5px solid rgb(227, 58, 58);
  overflow: auto;
}

.left a {
  color: #000;
  text-decoration: none;
}

.left a:hover {
  color: rgb(227, 58, 58);
}

.right {
  width: 79%;
  float: left;
  background-color: #f8f9f9;
  height: 100%;
  border-top: 5px solid rgb(227, 58, 58);
  overflow: auto;
}

.parent {
  display: flex;
  width: 100%;
}

.filler {
  flex-grow: 1;
}

.left-buttons {
  display: inline;
}

.right-buttons {
  display: inline;
}

button:focus {
  outline: 0 !important;
  border: 0 !important;
  box-shadow: none !important;
}
.table-striped a {
  text-decoration: none;
  color: #000;
}

.table-striped a:hover {
  color: rgb(227, 58, 58);
}

.table-striped a:active {
  color: rgb(227, 58, 58);
}

.table-striped:nth-child(even) {
  background-color: rgba(0, 0, 0, .05);
}

.table-striped:hover {
  background-color: rgba(255, 255, 255, 255) !important;
}

.resources-table:hover { 
  background-color: rgb(227, 58, 58) !important; 
}
</style>
