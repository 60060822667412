<template>
  <Bar
    :chart-options="options"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>
  
<script>
import { Bar } from "vue-chartjs";
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from "chart.js";
ChartJS.register( Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale );

export default {
  name: "ColumnChart",
  components: { Bar },

  props: {
    chartId: {
      type: String,
      default: "column-chart",
    },

    datasetIdKey: {
      type: String,
      default: "label",
    },

    chartData: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Object,
      default: () => {},
    },

    width: {
      type: Number,
      default: 400,
    },

    height: {
      type: Number,
      default: 400,
    },

    cssClasses: {
      default: "",
      type: String,
    },

    styles: {
      type: Object,
      default: () => {},
    },

    plugins: {
      type: Object,
      default: () => {},
    },
  },

  data: (vm) => ({
    chartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      onClick(event, clickedElements) {
        if (clickedElements.length > 0) {
          vm.$emit("bar_click", clickedElements[0].index);
        }
      },
      onHover: (event, chartElement) => {
        event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
      },
    },
  }),

};
</script>