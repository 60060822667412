<template>
  <div>
    <v-row justify="center" no-gutters>
      <v-col :cols="cols[0]" v-if="listType">
        <v-sheet>
          <StaticList
            :document-list="documentList" 
            :list-menu="menu" 
            :list-color="listColor" 
            @open_document="switchDocument" 
            @close_list="menu = false" 
            :list-width="listWidth"
            :parent_select_type="parent_select_type"
            :parent_pdf_type="parent_pdf_type"
            v-if="documentList.length > 0"
            :file-ids="briefcaseFiles"
            :briefcase-color="briefcaseColor"
          />
        </v-sheet>
      </v-col>

      <v-divider vertical class="mx-4" v-if="listType"></v-divider>

      <v-col :cols="listType ? cols[1] : 12">
        <v-sheet>
          <v-card width="100%">
            <v-card-title>
              <v-toolbar
                dark
                :color="viewerColor"
                class="text-white mx-2"
              >
                <v-toolbar-items>
                  <v-btn
                    icon
                    dark
                    @click="menu = true"
                    v-if="documentList.length > 0 && !listType"
                  >
                    <v-icon>mdi-menu</v-icon>
                  </v-btn>
                </v-toolbar-items>
                <v-toolbar-title>{{ documentTitle }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn
                    icon
                    dark
                    @click="closeDialog"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
            </v-card-title>
            <v-card-text style="height: 90vh; overflow-y: scroll;">
              <div class="text-center mx-2">
                <embed :src="'https://view.officeapps.live.com/op/embed.aspx?src=' + encodeURIComponent(documentUrl)" width="100%" style="height: 100vh;" allowfullscreen webkitallowfullscreen />
              </div>
            </v-card-text>
          </v-card>
        </v-sheet>
      </v-col>
    </v-row>

    <div class="text-center">
      <DocumentList 
        :document-list="documentList" 
        :list-menu="menu" 
        :list-color="listColor" 
        @open_document="switchDocument" 
        @close_list="menu = false"
        :list-width="listWidth" 
        :parent_select_type="parent_select_type"
        :parent_pdf_type="parent_pdf_type"
        v-if="documentList.length > 0"
        :file-ids="briefcaseFiles"
        :briefcase-color="briefcaseColor"
      />
    </div>
  </div>
</template>

<script>
import { useIndexStore } from '../../stores';
import DocumentList from './DocumentList.vue';
import StaticList from './StaticList.vue';

export default {
  components: { DocumentList, StaticList },
  name: "DocumentViewer",

  props: {
    documentUrl: {
      type: String,
      default: "",
    },

    documentType: {
      type: String,
      default: "",
    },

    documentTitle: {
      type: String,
      default: "",
    },

    listType: {
      type: Boolean,
      default: false,
    },

    viewerColor: {
      type: String,
      default: "rgb(227, 58, 58)"
    },

    documentList: {
      type: Array,
      default: () => [],
    },

    listColor: {
      type: String,
      default: "#e33333",
    },

    briefcaseColor: {
      type: String,
      default: "#e33333",
    },

    listWidth: {
      type: String,
      default: "50%",
    },

    parent_select_type: {
      type: Boolean,
      default: false,
    },

    parent_pdf_type: {
      type: Number,
      default: null,
    },

    briefcaseOnLoad: {
      type: Boolean,
      default: false,
    },

    briefcaseFiles: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    dialog_document_viewer: {
      get() {
        return this.store.getDialogDocumentViewer;
      },
      set(value) {
        this.store.setDialogDocumentViewer(value);
      },
    },

    briefcase_url() {
      return this.store.getBriefcaseUrl;
    },

    cols () {
      const { lg, sm } = this.$vuetify.display;
      return lg ? [2, 9] : sm ? [2, 9] : [5, 6];
    },
  },

  data: () => ({
    menu: false,
    store: useIndexStore(),
  }),

  methods: {
    switchDocument(document) {
      this.store.setSignatureDocument(false);
      if (document.itemExtension == 'pdf') {
        this.$emit("switch_pdf", document);
      } else {
        this.$emit("switch_document", document);
      }
    },

    closeDialog() {
      this.dialog_document_viewer = false;
      this.store.setSignatureDocument(false);
      this.$emit('close_document');
    },
  },
}
</script>