<template>
  <div>
    <!-- REPLY COMMENTS MODAL -->
    <div class="text-center">
      <v-dialog 
        v-model="dialog_reply"
        max-width="950px"
      >
        <v-row justify="center">
          <v-card style="border-top: 5px solid rgb(71, 183, 83);" min-width="500px">
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-table
                fixed-header
                fixed-footer
                height="80vh"
              >
                <thead>
                  <tr>
                    <th>
                      <v-card-title style="width: 100%;">
                        <v-toolbar class="bg-white">
                          <v-icon
                            style="float: left; background-color: rgb(71, 183, 83); color: #fff;"
                            class="float-left mr-2"
                            @click="dialog_reply = false"
                            >mdi-chevron-left</v-icon
                          >
                          <v-toolbar-title 
                            class="ml-n1"
                            color="rgb(71, 183, 83)"
                          >
                            Approval Comments
                          </v-toolbar-title>
                          <v-icon
                            color="rgb(71, 183, 83)"
                            class="float-right"
                            @click="$emit('add_comment', selected_comment)"
                            >mdi-cached</v-icon
                          >
                        </v-toolbar>
                      </v-card-title>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <v-card-text style="width: 100%;">
                        <v-row>
                          <v-col cols="12" md="12" sm="12">
                            <v-toolbar color="rgb(255, 255, 255)">
                              Comment:&nbsp;
                              <span class="text-ellipsis font-weight-bolder" style="color: rgb(71, 183, 83);">
                                <tooltip
                                  :data="comment['approverComment']"
                                  :location="'bottom'"
                                  :styling="'bg-success font-weight-bold'"
                                  :truncate="80"
                                />
                              </span>
                              <v-spacer></v-spacer>
                              <v-toolbar-items>
                                <v-btn
                                  class="btn"
                                  color="rgb(255, 255, 255)"
                                  @click="openReplyCommentDialog(comment)"
                                >
                                  <v-icon
                                    style="float: left; background-color: rgb(255, 255, 255); color: rgb(71, 183, 83);"
                                    size="x-large"
                                    >mdi-comment-multiple</v-icon
                                  >
                                </v-btn>
                              </v-toolbar-items>
                            </v-toolbar>
                          </v-col>

                          <v-col cols="12" md="12" sm="12">
                            <span class="text-ellipsis">
                              {{ comment['approver'] }} - {{ formatDate(comment['approvalCommentDate']) }}
                            </span>
                          </v-col>
                          
                          <v-col cols="12" md="12" sm="12">
                            <v-card
                              class="mx-auto elevation-0"
                              v-if="replies.length > 0"
                            >
                              <!-- Agenda Table -->
                              <v-table height="100vh">
                                <tbody
                                  v-for="(item, index) in replies" 
                                  :key="index"
                                  class="table-striped"
                                >
                                  <tr
                                    @click="toggle(index)"
                                    style="height: 100%; cursor: pointer;"
                                  >
                                    <td
                                      style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                                    >
                                      <v-list class="bg-transparent">
                                        <v-list-item
                                          active-color="rgb(71, 183, 83)"
                                        >
                                          <template v-slot:append v-if="item.userId == user.userInfo.userId">
                                            <v-icon
                                              style="float: right; background-color: rgba(0, 0, 0, .05); color: rgb(71, 183, 83);"
                                              size="x-large"
                                              @click="openReplyCommentDialog(item)"
                                              >mdi-comment-multiple-outline</v-icon
                                            >
                                          </template>
        
                                          <template v-slot:prepend v-if="item.userId != user.userInfo.userId">
                                            <v-icon
                                              style="float: left; background-color: rgba(0, 0, 0, .05); color: rgb(71, 183, 83);"
                                              size="x-large"
                                              @click="openReplyCommentDialog(item)"
                                              >mdi-comment-multiple</v-icon
                                            >
                                          </template>
        
                                          <v-list-item-title>
                                            <span 
                                              v-if="item.userId == user.userInfo.userId && item.commentListCount > 0" 
                                              style="cursor: pointer;" 
                                              class="text-red float-right font-weight-bold ml-1"
                                            >
                                              (i)
                                            </span>
                                            <span :class="item.userId == user.userInfo.userId ? 'text-ellipsis font-weight-bold float-right' : 'text-ellipsis font-weight-bold float-left'">
                                              {{ item.comment }}
                                            </span>
                                            <span 
                                              v-if="item.userId != user.userInfo.userId && item.commentListCount > 0" 
                                              style="cursor: pointer;"
                                              class="text-red float-left font-weight-bold ml-1"
                                            >
                                              (i)
                                            </span>
                                          </v-list-item-title>
        
                                          <v-list-item-subtitle>
                                            <span :class="item.userId == user.userInfo.userId ? 'text-ellipsis float-right' : 'text-ellipsis float-left'">
                                              {{ item.userName }} - {{ formatDateAndTime(item.commentDate) }}
                                            </span>
                                          </v-list-item-subtitle>
                                        </v-list-item>
                                      </v-list>
                                    </td>
                                  </tr>
                                  <tr v-if="opened.includes(index)">
                                    <td
                                      v-if="item.commentList && item.commentList.length > 0"
                                      style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; border: 1px solid grey;"
                                    >
                                      <v-list>
                                        <v-list-item
                                          v-for="(reply, index) in item.commentList"
                                          :key="index"
                                          :value="reply"
                                          active-color="rgb(71, 183, 83)"
                                        >
                                          <v-list-item-title>
                                            <span :class="reply.userId == user.userInfo.userId ? 'text-ellipsis font-weight-bold float-left' : 'text-ellipsis font-weight-bold float-right'">
                                              {{ reply['comment'] }}
                                            </span>
                                          </v-list-item-title>

                                          <v-list-item-subtitle>
                                            <span :class="reply.userId == user.userInfo.userId ? 'text-ellipsis float-left' : 'text-ellipsis float-right'">
                                              {{ reply['userName'] }} - {{ formatDateAndTime(reply['commentDate']) }}
                                            </span>
                                          </v-list-item-subtitle>
                                        </v-list-item>
                                      </v-list> 
                                    </td>

                                    <td 
                                      class="text-center"
                                      style="border: 1px solid #000000;"
                                      v-else
                                    >
                                      No replies found for this comment
                                    </td>
                                  </tr>
                                </tbody>
                              </v-table>
                            </v-card>
        
                            <v-card
                              class="mx-auto elevation-0"
                              v-else
                            >
                              <v-table class="bg-grey-lighten-5" height="100vh">
                                <tbody>
                                  <tr>
                                    <td class="text-center">
                                      <span>
                                        No comments found for this approval
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </v-table>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td>
                      <v-btn 
                        block
                        variant="text"
                        color="rgb(71, 183, 83)"
                        @click="store.setDialogReply(false)"
                      >
                        Close
                      </v-btn>
                    </td>
                  </tr>
                </tfoot>
              </v-table>
            </v-form>
          </v-card>
        </v-row>
      </v-dialog>
    </div>

    <!-- REPLY TO COMMENTS MODAL -->
    <ReplyToComment :selected="reply_comment" :comment="comment" @add_comment="replyComment" />
  </div>
</template>

<script>
var moment = require("moment");
import { useAuthStore } from "../../stores/auth";
import { useIndexStore } from "../../stores/index";
import { useAlertStore } from "../../stores/alert";
import Tooltip from '../Cards/Tooltip.vue';
import ReplyToComment from './ReplyToComment.vue';

export default {
  name: "ReplyComment",
  props: {
    comment: {
      type: Object,
      default: () => {},
    },

    replies: {
      type: Array,
      default: () => [],
    },
  },

  components: {
    tooltip: Tooltip,
    ReplyToComment,
  },

  data: () => ({
    valid: false,
    reply: "",
    show: false,
    dialog_comment: false,
    current_comment: "",
    reply_comment: {},
    opened: [],
    rules: {
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
      selectRules: [(v) => !!v || "Select is required"],
      textRules: [
        v => !!v || 'Field is required',
        v => (v && v.length >= 3) || 'Min 3 characters',
      ],
      characterRules: [
        v => (v && v.length >= 3) || 'Min 3 characters',
      ],
    },
    token: localStorage.getItem("token") ? JSON.stringify(localStorage.getItem("token")).slice(1, -1) : "",
    auth: useAuthStore(),
    store: useIndexStore(),
    alert: useAlertStore(),
  }),

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },

    dialog_reply: {
      get: function () {
        return this.store.getDialogReply;
      },
      set: function (value) {
        this.store.setDialogReply(value);
      }
    },

    selected_comment: {
      get() {
        return this.store.getSelectedComment;
      },
      set(value) {
        this.store.setSelectedComment(value);
      },
    },
  },
  
  methods: {
    formatDate(date) {
      return moment.unix(date).format("DD MMM YYYY");
    },

    formatDateAndTime(date) {
      return moment.unix(date).format("DD MMM YYYY, hh:mm A");
    },

    validate() {
      return this.$refs.form.validate();
    },

    resetForm() {
      return this.$refs.form.reset();
    },

    resetValidation() {
      return this.$refs.form.resetValidation();
    },

    reset() {
      this.reply = "";
      document.querySelector('html').classList.remove('v-overlay-scroll-blocked');
    },

    toggle(i) {
      const index = this.opened.indexOf(i);
      if (index > -1) {
        this.opened.splice(index, 1);
      } else {
        this.opened = [];
        this.opened.push(i);
      }
    },

    openReplyCommentDialog(comment) {
      this.store.setDialogReplyComment(true);
      this.current_comment = comment['approverComment'];
      this.reply_comment = comment;
      this.selected_comment = comment;
    },

    noRepliesFound() {
      this.alert.showInfo('No replies found');
    },

    replyComment() {
      this.$emit('add_comment', this.selected);
    },
  },
};
</script>