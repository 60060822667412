<template>
  <div>
    <!-- USERS MODAL -->
    <div class="text-center">
      <v-dialog v-model="dialog_users" max-width="650px">
        <v-card style="background-color: #f5f5f5; width: 100%;">
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <div
              style="border-top: 5px solid rgb(0, 0, 0);"
            >
              <v-table
                fixed-header
                fixed-footer
                height="80vh"
              >
                <tbody>
                  <tr>
                    <td>
                      <v-card-text>
                        <div style="height: 92%; overflow: auto;">
                          <v-card>
                            <v-tabs
                              v-model="tab"
                              fixed-tabs
                            >
                              <v-tab value="share">Share Now</v-tab>
                              <v-tab value="to">To</v-tab>
                              <v-tab value="from">From</v-tab>
                              <v-tab 
                                value="my-annotations"
                                v-if="annotations && annotations.ufa_id"
                                @click.native.prevent.stop.capture="loadMyAnnotations"
                              >
                                <v-btn
                                  variant="outlined"
                                  color="info"
                                  :loading="store.getLoading"
                                >
                                  My Annotations
                                </v-btn>
                              </v-tab>
                            </v-tabs>

                            <v-card-text>
                              <v-row>
                                <v-col cols="12" md="12" sm="12">
                                  <v-responsive
                                    class="mx-auto"
                                    v-if="tab == 'share'"
                                  >
                                    <v-text-field
                                      :rules="[rules.required]"
                                      clearable
                                      placeholder="Type Annotation Topic"
                                      variant="outlined"
                                      v-model="topic"
                                    ></v-text-field>
                                  </v-responsive>

                                  <v-window v-model="tab">
                                    <v-window-item value="share">
                                      <UserList 
                                        :users="users" 
                                        :annotations="annotations" 
                                        :load="change"
                                        :shared_annotations="shared_annotations"
                                        @load_annotations="loadUserAnnotations"
                                        @update_selected="updateSelected"
                                      />
                                    </v-window-item>
    
                                    <v-window-item value="to">
                                      <SharedAnnotations 
                                        :users="users" 
                                        :annotations="annotations" 
                                        :load="change"
                                        :shared_annotations="shared_annotations"
                                        :annotations_list="to_annotations"
                                        @load_annotations="loadUserAnnotations"
                                        @update_list="importSharedAnnotations"
                                        :tab="tab"
                                      />
                                    </v-window-item>
    
                                    <v-window-item value="from">
                                      <SharedAnnotations 
                                        :users="users" 
                                        :annotations="annotations" 
                                        :load="change"
                                        :shared_annotations="shared_annotations"
                                        :annotations_list="from_annotations"
                                        @load_annotations="loadUserAnnotations"
                                        @update_list="importSharedAnnotations"
                                        :tab="tab"
                                      />
                                    </v-window-item>
                                  </v-window>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </div>
                      </v-card-text>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td class="bg-white">
                      <v-card-actions class="grey">
                        <v-btn 
                          @click="closeDialogUsers"
                          class="mx-2"
                          color="rgb(0, 0, 0)"
                        >
                          Close
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn 
                          @click="getTabAction(tab)"
                          class="mx-2"
                          color="success"
                          :loading="store.getLoading"
                          :disabled="checkTabStatus(tab)"
                        >
                          {{  getTabText(tab) }}
                        </v-btn>
                      </v-card-actions>
                    </td>
                  </tr>
                </tfoot>
              </v-table>
            </div>
          </v-form>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
var moment = require("moment");
import axios from 'axios';
import { useAuthStore } from "../../stores/auth";
import { useIndexStore } from "../../stores/index";
import { useAlertStore } from "../../stores/alert";
import UserData from "../repository/UserData";
import authHeader from '../repository/auth-header';
import UserList from './UserList.vue';
import DocumentUsersList from './DocumentUsersList.vue';
import SharedAnnotations from './SharedAnnotations.vue';

export default {
  name: "ShareAnnotations",
  props: {
    users: {
      type: Array,
      default: () => [],
    },

    shared_annotations: {
      type: Boolean,
      default: false,
    },

    annotations: {
      type: Object,
      default: () => {},
    },

    load: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    UserList,
    DocumentUsersList,
    SharedAnnotations,
  },

  data: () => ({
    valid: false,
    selected: [],
    selected_topics: [],
    from_annotations: [],
    from_annotation_id: null,
    to_annotations: [],
    to_annotation_id: null,
    tab: null,
    loading: false,
    token: localStorage.getItem("token") ? JSON.stringify(localStorage.getItem("token")).slice(1, -1) : "",
    auth: useAuthStore(),
    store: useIndexStore(),
    alert: useAlertStore(),
    change: false,
    topic: "",
    rules: {
      required: value => !!value || 'Field is required',
    },
  }),

  watch: { 
    load() {
      this.change = !this.change;
      this.trigger = !this.trigger;
      this.importSharedAnnotations();
    },
  },

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },
    
    dialog_users() {
      return this.store.getDialogUsers;
    },

    trigger: {
      get() {
        return this.store.getTrigger;
      },
      set(status) {
        this.store.setTrigger(status);
      },
    },

    to_annotation_ids: {
      get() {
        return this.store.getToAnnotationIds;
      },
      set(ids) {
        this.store.setToAnnotationIds(ids);
      },
    },

    selected_annotation: {
      get() {
        return this.store.getSelectedAnnotation;
      },
      set(value) {
        this.store.setSelectedAnnotation(value);
      },
    },
  },
  
  methods: {
    formatDate(date) {
      return moment.unix(date).format("DD MMM YYYY");
    },

    async validate() {
      return this.$refs.form.validate();
    },

    resetForm() {
      return this.$refs.form.reset();
    },

    resetValidation() {
      return this.$refs.form.resetValidation();
    },

    closeDialogUsers() {
      this.store.setDialogUsers(false);
      this.store.setSelectedAnnotation({});
      document.querySelector('html').classList.remove('v-overlay-scroll-blocked');
    },

    updateSelected(selected) {
      this.selected = selected;
    },

    checkTabStatus(tab) {
      switch(tab) {
        case 'share':
          if (this.annotations.ufa_id && !this.shared_annotations) 
            return false;
          else
            return true;
        default:
          if (this.selected_annotation && this.selected_annotation.topicId)
            return false;
          else
            return true;
      }
    },

    loadUserAnnotations() {
      this.validate().then((validator_response) => {
        this.valid = validator_response.valid;
        if (this.valid) {
          this.$emit('load_annotations', this.selected_annotation.topicId);
        }
      });
    },

    loadMyAnnotations() {
      if (this.annotations && this.annotations.ufa_id) {
        this.store.setLoading(true);
        this.$emit('load_annotations', this.annotations.ufa_id);
      }
    },

    getTabText(tab) {
      let text = '';

      switch (tab) {
        case 'share':
          text = 'Share My Annotations';
          break;
        case 'to':
          text = 'Load Shared Annotations';
          break;
        case 'from':
          text = 'Load Shared Annotations';
          break;
      }

      return text;
    },

    getTabAction(tab) {
      switch(tab) {
        case 'share':
          return this.shareUserAnnotations();
        case 'to':
          return this.loadUserAnnotations();
        case 'from':
          return this.loadUserAnnotations();
      }
    },

    getAnnotationUsers() {
      if (this.tab == 'to' && this.to_annotations.length > 0) {
        let topic = this.to_annotations.find((item) => item.topicId == this.to_annotation_id);
        this.to_annotation_ids = topic.userList;
      }
    },

    async importSharedAnnotations() {
      const form = new FormData();
      form.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      form.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      form.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      form.append("model", "importSharedAnnotations");
      form.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      form.append("annotationId", this.annotations.ufa_id);
      form.append("documentId", UserData.getDocumentId() ? UserData.getDocumentId() : this.index.getDocumentId);

      return await new Promise((resolve, reject) => {
        axios.post((process.env.NODE_ENV == 'production' ? UserData.getProductionAdminBaseUrl() : UserData.getLocalAdminBaseUrl()) + '/import-shared-annotations', form, { headers: authHeader() }).then((response) => {
          if (response.status == 200) {
            this.to_annotations = response.data != null ? (response.data.to != null ? response.data.to : []) : [];
            if (this.to_annotations.length > 0) {
              this.to_annotation_ids = this.to_annotations[0].userList;
              this.to_annotation_id = this.to_annotations[0].topicId;
            }

            this.from_annotations = response.data != null ? (response.data.from != null ? response.data.from : []) : [];
            if (this.from_annotations.length > 0) {
              let from_annotation_ids = this.from_annotations[0].users;
            }
          } else {
            if (response.response.status == 403 && !this.session_overlay) {
              this.store.setPSPDFKitOverlay(true);
              this.session = true;
            }
          }
        })
        .catch((e) => {
          console.log("Error", e);
        });
      });
    },

    shareUserAnnotations() {
      this.validate().then((validator_response) => {
        this.valid = validator_response.valid;
        if (this.valid) {
          if (this.selected.length > 0) {
            const form = new FormData();
            form.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
            form.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
            form.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
            form.append("model", "shareAnnotation");
            form.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
            form.append("topic", this.topic);
            form.append("users", JSON.stringify(this.selected));
            form.append("annotationId", this.annotations.ufa_id);
            form.append("documentId", UserData.getDocumentId() ? UserData.getDocumentId() : this.store.getDocumentId);
  
            this.store.setLoading(true);
            axios.post((process.env.NODE_ENV == 'production' ? UserData.getProductionAdminBaseUrl() : UserData.getLocalAdminBaseUrl()) + '/share-annotation', form, { headers: authHeader() }).then((response) => {
              if (response.status == 200) {
                this.topic = "";
                this.alert.showSuccess("Annotations Shared Successfully");
                this.store.setLoading(false);
                this.store.setDialogUsers(false);
              } else {
                if (response.response.status == 403) {
                  this.store.setLoading(false);
                  this.$emit("session_timeout", true);
                }
              }
            })
            .catch((e) => {
              console.log("Error", e);
            });
          } else {
            this.alert.showInfo("Please Select at Least one User");
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.list-striped a {
  text-decoration: none;
  color: #000;
}

.list-striped a:hover {
  color: rgb(72, 61, 139);
}

.list-striped a:active {
  color: rgb(72, 61, 139);
}
</style>