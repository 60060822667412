const DB_NAME = 'egovernance';
const KEYGEN_STORE = 'KeyGen';
const APP_DATA_STORE = 'AppData';

// For use with stores that will only have one object in them.
const ONLY_ID = 1; 

const SCHEMA = {
  __version: 1,
  [KEYGEN_STORE]: {
    __options: { keyPath: 'id' }, 
    id: ONLY_ID, deriveKeySalt: null
  },
  [APP_DATA_STORE]: {
    __options: { keyPath: 'id' },
    id: ONLY_ID, sensitiveData: null, sensitiveType: null
  }
};

var OBJECT_STORE = null;

class database {
  static getSchema = () => {
    return SCHEMA;
  }

  static getObjectStore = () => {
    return OBJECT_STORE;
  }

  static setObjectStore = (objectStore) => {
    OBJECT_STORE = objectStore;
  }

  static _getStoreNamesFromSchema = (schema) => {
    return Object.keys(schema).filter(key => key !== '__version');
  }
  
  static _getStoreObjectValuesFromSchema = (schema, storeName) => {
    const storeSchema = schema[storeName];
    const values = {...storeSchema};
    delete values.__options;
    return values;
  }

  static _populateStores = async (db, schema) => {
    const storeNames = this._getStoreNamesFromSchema(schema);
    const transaction = db.transaction(storeNames, 'readwrite');
    let remainingCount = storeNames.length;
    return new Promise((resolve, reject) => {
      storeNames.forEach(storeName => {
        const objectToAdd = this._getStoreObjectValuesFromSchema(schema, storeName);
        const objectStore = transaction.objectStore(storeName);
        const request = objectStore.add(objectToAdd);

        request.onerror = (event) => {
          reject(`Failed to add object to "${storeName}" with error code ${event.target.errorCode}.`);
        };

        request.onsuccess = () => {
          if (--remainingCount === 0) resolve();
        }
      });
    });
  }
  
  static _createStores = (db, schema) => {
    const storeNames = this._getStoreNamesFromSchema(schema);
    const existingNames = db.objectStoreNames;
    storeNames.forEach(storeName => {
      if (!existingNames.contains(storeName)) {
        const storeSchema = schema[storeName];
        db.createObjectStore(storeName, storeSchema.__options);
      }
    });
  }

  static _open = async (name, schema) => {
    const version = localStorage.getItem('DB_VERSION');
    let wereStoresCreated = false;
    return new Promise((resolve, reject) => {
      const request = window.indexedDB.open(name, version);
      request.onerror = (event) => reject(`Failed to open "${name}" database with error code ${event.target.errorCode}.`);

      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        this._createStores(db, schema);
        wereStoresCreated = true;
      }

      request.onsuccess = (event) => {
        const db = event.target.result;
        // Not using reject() since error could come later after this promise completes.
        db.onerror = (event) => { throw Error("Database error: " + event.target.errorCode); } 
        db.onversionchange = (event) => { console.log("DB Version Changed: ", event); }
        if (wereStoresCreated) this._populateStores(db, schema);
        resolve(db);
      }
    });
  }

  static _get = async (db, storeName, key) => {
    try {
      const transaction = db.transaction(storeName);
      const objectStore = transaction.objectStore(storeName);
      const request = objectStore.get(key);
      return new Promise((resolve, reject) => {
        request.onerror = (event) => reject(`Failed to get from "${storeName} with error code ${event.target.errorCode}.`);
        request.onsuccess = () => typeof request.result !== 'undefined' ? resolve(request.result) : resolve({ id: null, sensitiveData: null });
      });
    } catch(e) {
      if (e instanceof DOMException) {
        if (e.message == "Failed to execute 'transaction' on 'IDBDatabase': One of the specified object stores was not found.") {
          console.log(e.message);
        }
      } else {
        console.log(e);
      }
    }
  }

  static _put = async (db, storeName, objectToStore) => {
    const transaction = db.transaction(storeName, 'readwrite');
    const objectStore = transaction.objectStore(storeName);
    const request = objectStore.put(objectToStore);
    return new Promise((resolve, reject) => {
      request.onerror = (event) => reject(`Failed to put to "${storeName} with error code ${event.target.errorCode}.`);
      request.onsuccess = () => resolve();
    });
  }
  
  static deleteDatabase = async () => {
    const request = indexedDB.deleteDatabase(DB_NAME);
    return new Promise((resolve, reject) => {
      request.onerror = (event) => reject(`Failed to delete "${DB_NAME}}" database with error code ${event.target.errorCode}.`);
      request.onsuccess = () => resolve();
    });
  }

  static getKeyGenData = async () => {
    const db = await this._open(DB_NAME, SCHEMA);
    return (await this._get(db, KEYGEN_STORE, ONLY_ID));
  }

  static putKeyGenData = async (keyGenData) => {
    const db = await this._open(DB_NAME, SCHEMA);
    return this._put(db, KEYGEN_STORE, keyGenData);
  }

  static getAppData = async () => {
    const db = await this._open(DB_NAME, SCHEMA);
    return (await this._get(db, APP_DATA_STORE, ONLY_ID));
  }

  static putAppData = async (appData) => {
    const db = await this._open(DB_NAME, SCHEMA);
    return this._put(db, APP_DATA_STORE, appData);
  }
}

export default database;