<template>
  <v-menu>
    <template v-slot:activator="{ props }">
      <v-btn 
        color="rgb(86, 182, 229)" 
        variant="outlined" 
        v-bind="props"
        class="btn text-uppercase"
        :style="filterStyle + (currentRouteName === 'dashboard' ? ' padding: 12px;' : '')"
      >
        {{ statusName }}
      </v-btn>
    </template>
    <v-list class="mt-2">
      <v-list-item style="display: block">
        <v-list-item-title
          v-for="status in meetingStatus"
          :key="status.meetingStatusId"
          :style="'padding: 12px; font-size: 16px; cursor: pointer; color: ' + status.meetingStatusColor + ';'"
          @click="filterMeetingList(status.meetingStatusId)"
          >{{ status.meetingStatusName }}
          <span v-if="meetingSort.field == 'meetingStatusId' && meetingSort.status == status.meetingStatusId"
            >({{ meetingSort.desc ? "desc" : "asc" }})</span
          >
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { useAuthStore } from '../stores/auth';

export default {
  props: {
    meetingStatus: {
      type: Array,
      default: () => [],
    },
    meetingSort: {
      type: Object,
      default: () => {},
    },
    statusName: {
      type: String,
      default: "All",
    },
    filterStyle: {
      type: String,
      default: "All",
    },
  },

  computed: {
    dashboardComponent() {
      return this.store.getDashboardComponent;
    },

    currentRouteName() {
      return this.$route.name;
    },
  },

  data: () => ({
    store: useAuthStore(),
  }),

  methods: {
    filterMeetingList(statusId) {
      this.$emit('filter_list', statusId);
    },
  },
}
</script>