<template>
  <!-- PROFILE -->
  <v-card style="color: #e33333;" min-width="250px">
    <v-card-text>
      <v-text-field
        label="Search"
        append-inner-icon="mdi-magnify"
        variant="outlined"
      ></v-text-field>
    </v-card-text>
  </v-card>
</template>

<script>
import { useAuthStore } from "../../stores/auth";
import { useIndexStore } from "../../stores/index";
import { useAlertStore } from "../../stores/alert";

export default {
  name: "DashboardSearch",

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },
  },

  data: () => ({
    auth: useAuthStore(),
    store: useIndexStore(),
    alert: useAlertStore(),
  }),
}
</script>