<template>
  <div>
    <v-snackbar
      location="bottom right"
      :timeout="-1"
      v-model="updateExists" 
      color="success"
    >
      An Update is Available
      <v-btn 
        @click="refreshApp" 
        color="error"
        class="text-capitalize"
      >
        Click to Refresh
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import update from '../../mixins/update';

export default {
  name: "RefreshSnackBar",
  mixins: [update],
};
</script>
