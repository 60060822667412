<template>
  <div>
    <!-- UPDATE TASK MODAL -->
    <div class="text-center">
      <v-dialog v-model="dialog_task" max-width="450px" scrollable>
        <v-row justify="center">
          <v-card style="background-color: #f5f5f5; width: 100%;">
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-table
                fixed-header
                fixed-footer
                height="80vh"
              >
                <thead>
                  <tr>
                    <th>
                      <v-card-title>
                        {{ formTitle }}
                      </v-card-title>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12" md="12" sm="12">
                              <v-textarea
                                type="text"
                                v-model="description"
                                variant="outlined"
                                :rules="rules.textRules"
                                clearable
                                rows="3"
                              />
                            </v-col>
          
                            <v-col cols="12" md="12" sm="12">
                              <v-radio-group 
                                v-model="confirm"
                                :rules="rules.selectRules"
                                inline
                              >
                                <v-radio value="2">
                                  <template v-slot:label>
                                    <span class="pt-2 text-wrap">Partial</span>
                                  </template>
                                </v-radio>
                                <v-radio value="3">
                                  <template v-slot:label>
                                    <span class="pt-2 text-wrap">Completed</span>
                                  </template>
                                </v-radio>
                              </v-radio-group>
                            </v-col>
          
                            <v-col cols="12" md="12" sm="12">
                              <v-text-field
                                :rules="rules.passwordRules"
                                v-model="password"
                                :type="show ? 'text' : 'password'"
                                label="Password (Required)"
                                variant="outlined"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td class="bg-white mt-5">
                      <v-card-actions>
                        <v-btn
                          color="error"
                          @click="
                            store.setDialogTask(false);
                            reset();
                          "
                          variant="flat"
                          elevation="1"
                        >
                          Cancel
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="success"
                          style="float: right"
                          variant="flat"
                          elevation="1"
                          :loading="store.loading"
                          @click="updateTaskWithDescription"
                        >
                          Submit
                        </v-btn>
                      </v-card-actions>
                    </td>
                  </tr>
                </tfoot>
              </v-table>
            </v-form>
          </v-card>
        </v-row>
      </v-dialog>
    </div>
  </div>
</template>

<script>
var moment = require("moment");
import axios from "axios";
import UserData from "../repository/UserData";
import { useAuthStore } from "../../stores/auth";
import { useIndexStore } from "../../stores/index";
import { useAlertStore } from "../../stores/alert";

export default {
  name: "UpdateTask",
  props: {
    task: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    valid: false,
    dialog: false,
    description: "",
    hasConflict: false,
    confirm: false,
    comments: "",
    conditions: "",
    password: "",
    isApproval: false,
    rules: {
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
      selectRules: [(v) => !!v || "Select is required"],
      textRules: [
        v => !!v || 'Field is required',
        v => (v && v.length >= 3) || 'Min 3 characters',
      ],
      characterRules: [
        v => (v && v.length >= 3) || 'Min 3 characters',
      ],
    },
    token: localStorage.getItem("token") ? JSON.stringify(localStorage.getItem("token")).slice(1, -1) : "",
    auth: useAuthStore(),
    store: useIndexStore(),
    alert: useAlertStore(),
  }),

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },

    formTitle() {
      return Object.keys(this.current_task).length > 0 ? 'Update Task with Description' : 'No Task Found';
    },

    dialog_task() {
      return this.store.getDialogTask;
    },

    current_task() {
      return this.store.getCurrentTask;
    },
  },
  
  methods: {
    formatDate(date) {
      return moment.unix(date).format("DD MMM YYYY");
    },

    validate() {
      return this.$refs.form.validate();
    },

    resetForm() {
      return this.$refs.form.reset();
    },

    resetValidation() {
      return this.$refs.form.resetValidation();
    },

    reset() {
      this.password = "";
      this.description = "";
      this.confirm = false;
      document.querySelector('html').classList.remove('v-overlay-scroll-blocked');
    },

    updateTaskWithDescription() {
      this.validate().then((validator_response) => {
        this.valid = validator_response.valid;
        if (this.valid) {
          const formData = new FormData();
          formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
          formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
          formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
          formData.append("model", "updateTask");
          formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
          formData.append("taskId", this.current_task.taskId);
          formData.append("description", this.description);
          formData.append("taskStatus", this.confirm);
          formData.append("userPassword", this.password);
          formData.append("isTaskChecked", 1);
          formData.append("touchAuthProvided", 0);
          
          this.store.setLoading(true);
          axios.post(UserData.getBaseUrl(), formData).then((response) => {
            this.store.setLoading(false);
            if (response.data.success == 1) {
              this.reset();
              this.store.setDialogTask(false);
              this.alert.showSuccess(response.data.message);
              this.$emit('task_updated');
            } else {
              this.alert.showInfo (response.data.message);
            }
          })
          .catch((e) => {
            console.log("Error", e);
            this.store.setLoading(false);
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.list-striped a {
  text-decoration: none;
  color: #000;
}

.list-striped a:hover {
  color: rgb(72, 61, 139);
}

.list-striped a:active {
  color: rgb(72, 61, 139);
}
</style>