import { createApp, h } from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import vuetify from './plugins/vuetify';
import router from './router';
import axios from 'axios';
import App from "./App.vue";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './registerServiceWorker';
import VDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

const moment = require('moment-timezone');
moment.tz.setDefault("Etc/UTC");

const app = createApp({
  render: () => h(App)
});

if(process.env.NODE_ENV != 'development'){
  // Enable this on production
  window.addEventListener("contextmenu", (event) => {
    event.preventDefault();
  });
}

app.config.productionTip = false;
window.axios = axios;

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
app.use(pinia);
app.use(vuetify);
app.use(router);
app.component('VDatePicker', VDatePicker);
app.mount("#app");
