<template>
  <v-app>
    <v-app-content>
      <router-view></router-view>
      <Alert />
      <RefreshSnackBar />
    </v-app-content>
  </v-app>
</template>

<script>
import Alert from './components/Alert.vue';
import RefreshSnackBar from './components/Cards/RefreshSnackBar.vue';
import GlobalMethods from './components/repository/GlobalMethods';
import Countdown from './components/repository/countdown';
import { useAlertStore } from './stores/alert';
import { useAuthStore } from './stores/auth';
import { useIndexStore } from './stores/index';

let userActivityTimeout = null;

export default {
  name: 'App',

  data: () => ({
    auth: useAuthStore(),
    alert: useAlertStore(),
    store: useIndexStore(), 
    db: null,
    globalMethods: GlobalMethods,
    currentRouteName: null,
    resetCall: false,
  }),

  components: {
    Alert,
    RefreshSnackBar,
  },

  computed: {
    session_overlay: {
      get() {
        return this.store.getSessionOverlay;
      },
      set(value) {
        this.store.setSessionOverlay(value);
      },
    },

    session_warning_overlay: {
      get() {
        return this.store.getSessionWarningOverlay;
      },
      set(value) {
        this.store.setSessionWarningOverlay(value);
      },
    },

    overlay_type: {
      get() {
        return this.store.getOverlayType;
      },
      set(value) {
        this.store.setOverlayType(value);
      },
    },

    session: {
      get() {
        return this.alert.getSession;
      },
      set(value) {
        this.alert.setSession(value);
      },
    },

    session_expired: {
      get() {
        return this.auth.getSessionExpired;
      },
      set(value) {
        this.auth.setSessionExpired(value);
      },
    },

    reset_session: {
      get() {
        return this.auth.getResetSession;
      },
      set(value) {
        this.auth.setResetSession(value);
      },
    },

    pspdfkit_overlay: {
      get() {
        return this.store.getPSPDFKitOverlay;
      },
      set(value) {
        this.store.setPSPDFKitOverlay(value);
      },
    },

    user_activity_throttler_ids: {
      get() {
        return this.auth.getUserActivityThrottlerIds;
      },
      set(value) {
        this.auth.setUserActivityThrottlerIds(value);
      },
    },

    session_countdown_timeout_ids: {
      get() {
        return this.auth.getSessionCountdownTimeoutIds;
      },
      set(value) {
        this.auth.setSessionCountdownTimeoutIds(value);
      },
    },

    reset_session_timeout_ids: {
      get() {
        return this.auth.getResetSessionTimeoutIds;
      },
      set(value) {
        this.auth.setResetSessionTimeoutIds(value);
      },
    },

    user_activity_timeout_ids: {
      get() {
        return this.auth.getUserActivityTimeoutIds;
      },
      set(value) {
        this.auth.setUserActivityTimeoutIds(value);
      },
    },
  },

  watch: {
    session() {
      this.checkSession();
    },

    $route(newRouteValue) {
      this.currentRouteName = newRouteValue.name;
      // this.globalMethods.getCompanySessionTimeout(this.currentRouteName);
    },
  },

  beforeMount() {
    this.activateActivityTracker();
  },

  beforeUnmount() {
    this.clearIdleTimer();
  },

  methods: {
    checkSession() {
      if (typeof localStorage.getItem('admin_session_timeout') != 'undefined') {
        this.resetUserActivityTimeout();
      }
    },

    clearIdleTimer() {
      window.removeEventListener("mousemove", () => this.userActivityThrottler());
      window.removeEventListener("scroll", () => this.userActivityThrottler());
      window.removeEventListener("keydown", () => this.userActivityThrottler());
      window.removeEventListener("resize", () => this.userActivityThrottler());
      window.removeEventListener("click", () => this.resetSession());

      this.reset_session = false;
      this.resetUserActivityTimeout();
    },

    userActivityThrottler() {
      const USER_ACTIVITY_THROTTLER_TIME = 1 * 60 * 1000;

      if (this.user_activity_throttler_ids.length == 0) {
        const userActivityThrottlerTimeout = setTimeout(() => {
          this.resetUserActivityTimeout();
          clearTimeout(userActivityThrottlerTimeout);
        }, USER_ACTIVITY_THROTTLER_TIME);
  
        this.resetSession();
        this.user_activity_throttler_ids.push(userActivityThrottlerTimeout);
      }
    },

    clearTimeoutIds(ids) {
      ids.forEach((id) => {
        const index = ids.indexOf(id);
        if (index > -1) {
          clearTimeout(id);
          ids.splice(index, 1);
        }
      });
    },

    activateActivityTracker() {
      window.addEventListener("mousemove", () => this.userActivityThrottler());
      window.addEventListener("scroll", () => this.userActivityThrottler());
      window.addEventListener("keydown", () => this.userActivityThrottler());
      window.addEventListener("resize", () => this.userActivityThrottler());
      window.addEventListener("click", () => this.resetSession());
    },
    
    resetSession() {
      const RESET_SESSION_THRESHOLD = 1 * 60 * 1000;

      const resetSessionTimeout = setTimeout(() => {
        if (!this.reset_session) {
          // this.globalMethods.getCompanySessionTimeout(this.currentRouteName);
          this.resetUserActivityTimeout();
          clearTimeout(resetSessionTimeout);
        }
      }, RESET_SESSION_THRESHOLD);

      this.reset_session_timeout_ids.push(resetSessionTimeout);
    },

    resetSessionTimeoutCountdown() {
      Countdown.setExpiredDate(this.globalMethods.getAdminSessionTimeout(), true);
    },

    async resetUserActivityTimeout(login = false) {
      await this.clearTimeoutIds(this.user_activity_throttler_ids);
      await this.clearTimeoutIds(this.session_countdown_timeout_ids);
      await this.clearTimeoutIds(this.reset_session_timeout_ids);

      if ((!login || this.currentRouteName != '')) {
        const SESSION_COUNTDOWN_THRESHOLD = 1 * 60 * 1000;
        
        const sessionCountdownTimeout = setTimeout(() => {
          // reset session timeout countdown
          this.resetSessionTimeoutCountdown();
          clearTimeout(sessionCountdownTimeout);
        }, SESSION_COUNTDOWN_THRESHOLD); 

        this.session_countdown_timeout_ids.push(sessionCountdownTimeout);
      } 

      const INACTIVE_USER_TIME_THRESHOLD = parseInt(localStorage.getItem('admin_session_timeout')) - 1;

      if (!userActivityTimeout || this.user_activity_timeout_ids.length == 0) {
        clearTimeout(userActivityTimeout);
        userActivityTimeout = setTimeout(() => {
          this.inactiveUserAction();
        }, INACTIVE_USER_TIME_THRESHOLD * 60 * 1000);

        this.user_activity_timeout_ids.push(userActivityTimeout);
      } else {
        await this.clearTimeoutIds(this.user_activity_timeout_ids);
        clearTimeout(userActivityTimeout);
        userActivityTimeout = null;
      }
    },

    inactiveUserAction() {
      // session timeout logic
      if (!this.session_warning_overlay && !this.pspdfkit_overlay && !this.session_overlay) {
        this.session_warning_overlay = true;
      }
    },

    closeWarning(countdown) {
      if (this.session_warning_overlay) {
        this.session_warning_overlay = false;
        this.overlay_type = 1;
        
        if (this.session_expired && (countdown == 0)) {
          this.session_overlay = true;
          this.deleteBriefcaseItems();
        }
        
        this.auth.setSessionExpired(false);
        this.store.setSessionWarningOverlay(false);
        this.session_expired = false;
      }
    },

    deleteBriefcaseItems() {
      this.store.setLoading(true);
      this.globalMethods.deleteStore('AppData', this.db).then(() => {
        this.store.setLoading(false);
        this.session = !this.session;
        this.reloadPage();
      })
      .catch((e) => {
        console.log("Error: ", e);
        this.store.setLoading(false);
      });
    },

    reloadPage() {
      window.location.reload();
    },

    renewTokenLease(userPassword) {
      this.globalMethods.renewTokenLease(userPassword).then(async (response) => {
        if (response.code == 200) {
          this.admin = response.data;
          
          localStorage.setItem('admin_session_expiry', this.admin.token_expiry);
          localStorage.setItem('admin_session_timeout', this.admin.token_expiry_Tminus);

          if (!localStorage.getItem('DB_VERSION'))
            localStorage.setItem('DB_VERSION', 1);
          
          this.session = !this.session;
          this.resetUserActivityTimeout(true);
          
          try {
            this.overlay_type = false;
            this.store.setSessionOverlay(false);
            this.store.setSessionWarningOverlay(false);
            this.auth.setSessionExpired(false);
            this.store.setPSPDFKitOverlay(false);
            
            this.alert.showSuccess(response.message);
            this.store.setSessionLoading(false);
          } catch(e) { 
            // Sometimes a derived key from a wrong password will cause Web Crypto to throw.
            console.log(e);
          }
        } else {
          this.alert.showError(response.response.data.message);
          this.store.setSessionLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        this.store.setSessionLoading(false);
      });
    },
  },
}
</script>

<style>
.swal2-container {
  z-index: 10000;
}
</style>