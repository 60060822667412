<template>
  <div>
    <!-- CONTACT SUPPORT MODAL -->
    <div class="text-center">
      <v-dialog v-model="dialog_support" max-width="750px" scrollable>
        <v-row justify="center">
          <v-card
            style="padding: 3%; background-color: #f5f5f5; width: 100%; height: 100vh; overflow-y: scroll;"
          >
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-card-title class="text-center">
                {{ formTitle }}
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="12" sm="12">
                      <v-combobox
                        v-model="to"
                        chips
                        closable-chips
                        variant="outlined"
                        :items="emails"
                        item-title="email"
                        item-value="id"
                        :rules="rules.selectRules"
                        multiple
                        @keydown.enter="addEmail"
                      >
                        <template v-slot:prepend>
                          <span class="mr-10">To:</span>
                        </template>
                      </v-combobox>
                    </v-col>

                    <v-col cols="12" md="12" sm="12">
                      <v-combobox
                        v-model="cc"
                        chips
                        closable-chips
                        variant="outlined"
                        :items="emails"
                        item-title="email"
                        item-value="id"
                        multiple
                        @keydown.enter="addEmail"
                      >
                        <template v-slot:prepend>
                          <span class="mr-2">Cc/Bcc:</span>
                        </template>
                      </v-combobox>
                    </v-col>

                    <v-col cols="12" md="12" sm="12">
                      <v-text-field
                        :rules="rules.textRules"
                        v-model="subject"
                        variant="outlined"
                      >
                        <template v-slot:prepend>
                          <span class="mr-2">Subject:</span> 
                        </template>
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" md="12" sm="12">
                      <v-textarea
                        :rules="rules.textRules"
                        v-model="body"
                        variant="outlined"
                        clearable
                      >
                        <template v-slot:prepend>
                          <span>Message:</span>
                        </template>
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="error"
                  @click="
                    store.setDialogSupport(false);
                    clearForm();
                  "
                  variant="flat"
                  elevation="1"
                >
                  Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="info"
                  @click="
                    changePassword();
                  "
                  style="float: right"
                  variant="flat"
                  elevation="1"
                  :loading="store.loading"
                >
                  Send
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-row>
      </v-dialog>
    </div>
  </div>
</template>

<script>
var moment = require("moment");
import { useAuthStore } from "../../stores/auth";
import { useIndexStore } from "../../stores/index";
import { useAlertStore } from "../../stores/alert";

export default {
  name: "ContactSupport",
  props: {
    type: {
      type: Number,
      default: 0,
    },
    meeting: {
      type: Object,
      default: () => {},
    },
    load: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    valid: false,
    dialog: false,
    searchInput: "",
    to: [ 'notifications@theblueion.com' ],
    emails: [ 'notifications@theblueion.com' ],
    cc: [],
    subject: "",
    body: "Sent from eGovernance Web",
    rules: {
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
      selectRules: [(v) => !!v || "Select is required"],
      textRules: [
        v => !!v || 'Field is required',
        v => (v && v.length >= 3) || 'Min 3 characters',
      ],
      characterRules: [
        v => (v && v.length >= 3) || 'Min 3 characters',
      ],
    },
    token: localStorage.getItem("token") ? JSON.stringify(localStorage.getItem("token")).slice(1, -1) : "",
    auth: useAuthStore(),
    store: useIndexStore(),
    alert: useAlertStore(),
  }),

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },

    formTitle() {
      return this.menu_option == 1 ? 'Contact for Support' : 'Report an Issue';
    },

    dialog_support() {
      return this.store.getDialogSupport;
    },

    menu_option() {
      return this.store.getMenuOption;
    },
  },
  
  watch: { 
    load() {
      this.getSubject();
    }
  },

  methods: {
    formatDate(date) {
      return moment.unix(date).format("DD MMM YYYY");
    },

    validate() {
      return this.$refs.form.validate();
    },

    resetForm() {
      return this.$refs.form.reset();
    },

    resetValidation() {
      return this.$refs.form.resetValidation();
    },

    reset() {
      this.subject = "";
      this.body = "";
      this.store.setDialogSupport(false);
      document.querySelector('html').classList.remove('v-overlay-scroll-blocked');
    },

    clearForm() {
      this.subject = "";
      this.body = "";
    },

    getSubject() {
      this.subject = this.menu_option == 1 ? 'Contact for Support' : 'Report an Issue';
    },

    changePassword() {
      this.validate().then((validator_response) => {
        this.valid = validator_response.valid;
        if (this.valid) {
          let toEmails = this.to.toString();
          let ccEmails = this.cc.toString();
          window.open("mailto:" + toEmails + '?cc=' + ccEmails + '&subject=' + this.subject + '&body=' + this.body);
          this.reset();
        }
      });
    },
  },
};
</script>