<template>
  <div>
    <!-- COMMENTS MODAL -->
    <div class="text-center">
      <v-dialog v-model="dialog_evaluation_comments" max-width="500px" scrollable>
        <v-row justify="center">
          <v-card
            style="padding: 3%;"
          >
            <v-form
              ref="comments_form"
              v-model="valid"
              lazy-validation
            >
              <v-card-title>
                <v-toolbar class="bg-white">
                  <v-toolbar-title>
                    {{ comments_type.title }}
                  </v-toolbar-title>
                  <v-toolbar-items>
                    <v-btn
                      class="btn ml-4"
                      color="error"
                      @click="
                        dialog_evaluation_comments = !dialog_evaluation_comments;
                        alert.setChecked(null);
                        reset();
                      "
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="12" sm="12">
                    <v-textarea
                      type="text"
                      v-model="comments"
                      label="Comments"
                      variant="outlined"
                      :rules="rules.textRules"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="primary"
                  @click="
                    dialog_evaluation_comments = false;
                    alert.setChecked(null);
                    reset();
                  "
                  variant="flat"
                  elevation="1"
                >
                  Close
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="success"
                  @click="
                    commentEvaluation();
                  "
                  style="float: right;"
                  variant="flat"
                  elevation="1"
                  :loading="store.loading"
                >
                  Submit
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-row>
      </v-dialog>
    </div>
  </div>
</template>

<script>
var moment = require("moment");
import axios from "axios";
import UserData from "../repository/UserData";
import { useAuthStore } from "../../stores/auth";
import { useIndexStore } from "../../stores/index";
import { useAlertStore } from "../../stores/alert";

export default {
  name: "EvaluationCommentsDialog",
  props: {
    evaluation: {
      type: Object,
      default: () => {},
    },
    chairman: {
      type: String,
      default: "0",
    },
    category: {
      type: Object,
      default: () => {},
    },
    load: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    valid: false,
    valid_comments: false,
    dialog: false,
    comments: "",
    items: [
      {
        id: 1, 
        title: 'Comment on Evaluation' 
      },
      {
        id: 2, 
        title: 'Comment on Current Category' 
      },
    ],
    chairman_items: [
      {
        id: 1, 
        title: 'Comment on Evaluation',
      },
      {
        id: 2, 
        title: 'Comment on Current Category',
      },
      {
        id: 3, 
        title: 'Comment as Chairman',
      },
    ],
    rules: {
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
      selectRules: [(v) => !!v || "Select is required"],
      textRules: [
        v => !!v || 'Field is required',
        v => (v && v.length >= 3) || 'Min 3 characters',
      ],
      characterRules: [
        v => (v && v.length >= 3) || 'Min 3 characters',
      ],
    },
    token: localStorage.getItem("token") ? JSON.stringify(localStorage.getItem("token")).slice(1, -1) : "",
    auth: useAuthStore(),
    store: useIndexStore(),
    alert: useAlertStore(),
    selected: [],
  }),

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },

    dialog_evaluation_comments: {
      get() {
        return this.store.getDialogEvaluationComments;
      },
      set(value) {
        this.store.setDialogEvaluationComments(value);
      },
    },

    comments_type() {
      return this.store.getCommentsType;
    },
  },
  
  methods: {
    formatDate(date) {
      return moment.unix(date).format("DD MMM YYYY");
    },

    formatDateAndTime(date) {
      return moment.unix(date).format("DD MMM YYYY, hh:mm A");
    },

    validate() {
      return this.$refs.form.validate();
    },

    validateComments() {
      return this.$refs.comments_form.validate();
    },

    resetForm() {
      return this.$refs.form.reset();
    },

    resetValidation() {
      return this.$refs.form.resetValidation();
    },

    reset() {
      this.comments = "";
      this.store.setDialogEvaluationComments(false);
      this.$emit('comments_opened', false);
      document.querySelector('html').classList.remove('v-overlay-scroll-blocked');
    },

    commentEvaluation() {
      this.validateComments().then((validator_response) => {
        this.valid_comments = validator_response.valid;
        if (this.valid_comments) {
          const formData = new FormData();
          formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
          formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
          formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
          formData.append("model", "commentEvaluation");
          formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
          formData.append("evaluationId", UserData.getEvaluationId() ? UserData.getEvaluationId() : this.store.getEvaluationId);
          formData.append("evaluateeId", UserData.getEvaluateeId() ? UserData.getEvaluateeId() : this.store.getEvaluateeId);
          formData.append("comment", this.comments);
          formData.append("isChairman", this.chairman);
          formData.append("categoryId", this.comments_type.id == 1 ? 0 : this.category.categoryId); 
          
          this.store.setLoading(true);
          axios.post(UserData.getBaseUrl(), formData).then((response) => {
            this.alert.showSuccess(response.data.message);
            this.reset();
            this.dialog = false;
            this.store.setLoading(false);
            this.store.setDialogEvaluationComments(false);
          })
          .catch((e) => {
            console.log("Error", e);
            this.store.setLoading(false);
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.list-striped a {
  text-decoration: none;
  color: #000;
}

.list-striped a:hover {
  color: rgb(72, 61, 139);
}

.list-striped a:active {
  color: rgb(72, 61, 139);
}
</style>