<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog_news"
      max-width="450px"
    >
      <v-card>
        <v-card-title class="text-center">{{ title }}</v-card-title>
        <v-card-text>{{ details }}</v-card-text>
        <v-card-actions>
          <v-btn color="rgb(227, 58, 58)" block @click="dialog_news = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { useIndexStore } from '../../stores';

export default {
  name: "NewsDetails",

  props: {
    title: {
      type: String,
      default: "",
    },

    details: {
      type: String,
      default: "",
    },
  },

  computed: {
    dialog_news: {
      get() {
        return this.store.getDialogNews;
      },
      set(value) {
        this.store.setDialogNews(value);
      },
    },
  },

  data: () => ({
    store: useIndexStore(),
  }),

  methods: {
    truncateText(str, n) {
      return str.length > n ? str.substr(0, n - 1) + "..." : str;
    },
  },
}
</script>

<style scoped>
span {
  text-decoration: none;
  color: #000000;
}

span:hover {
  color: rgb(227, 58, 58);
}
</style>