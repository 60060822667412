<template>
  <div>
    <!-- DASHBOARD MENUS MODAL -->
    <div class="text-center">
      <v-dialog v-model="dialog_menus" max-width="500px" scrollable>
        <v-row justify="center">
          <v-card style="background-color: #f5f5f5; width: 100%;">
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-table
                fixed-header
                fixed-footer
                height="80vh"
              >
                <thead>
                  <tr>
                    <th>
                      <v-card-actions>
                        <v-btn
                          color="error"
                          variant="text"
                          class="text-capitalize"
                          :disabled="edit ? false : true"
                          style="font-size: 1.25rem;"
                          @click="resetMenus"
                        >
                          Reset
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-card-title>
                          <span>Dashboard Menus</span>
                        </v-card-title>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="info"
                          variant="text"
                          class="text-capitalize"
                          style="font-size: 1.25rem;"
                          @click="edit = !edit"
                        >
                          {{ edit ? 'Cancel' : 'Edit' }}
                        </v-btn>
                      </v-card-actions>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>         
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12" md="12" sm="12">
                              <v-list>
                                <v-list-item-group
                                  v-model="selected"
                                  active-class="pink--text"
                                  multiple
                                >
                                  <draggable 
                                    :disabled="!edit" 
                                    v-model="editDashboardMenuList" 
                                    item-key="menuTitle" 
                                    @change="checkMove"
                                  >
                                    <template #item="{ element, index }">
                                      <v-list-item>
                                        <v-list-item-title :style="'color: rgb(' + element.menuColor + '); font-size: 1.25rem;'">
                                          <span class="mr-2">
                                            <img
                                              v-if="element.menuImageUrl !== ''"
                                              v-bind:src="element.menuImageUrl"
                                              v-bind:alt="element.menuTitle"
                                              v-bind:style="{ width: '60px', height: '50px' }"
                                            />
                                            <v-icon 
                                              v-else
                                              icon="mdi-image-broken-variant"
                                              size="x-large"
                                              v-bind:style="{ width: '60px', height: '50px' }"
                                            />
                                          </span>
                                        
                                          <span v-if="!edit">
                                            {{ element.menuTitle }}
                                          </span>

                                          <span v-else contenteditable @blur="updateContent($event, element)">
                                            {{ element.menuTitle }}
                                          </span>

                                          <v-icon
                                            class="float-right align-center mt-2"
                                            style="cursor: pointer;"
                                            v-if="edit"
                                          >
                                            mdi-reorder-horizontal
                                          </v-icon>

                                          <v-checkbox
                                            class="float-right align-center mt-n1 mr-2"
                                            v-if="edit"
                                            v-model="element.userEnable"
                                            false-value="false"
                                            true-value="1"
                                            hide-details
                                          ></v-checkbox>
                                        </v-list-item-title>
                                        <v-divider
                                          v-if="index < editDashboardMenuList.length - 1"
                                          :key="index"
                                        ></v-divider>
                                      </v-list-item>
                                    </template>
                                  </draggable>
                                </v-list-item-group>
                              </v-list>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td class="bg-white">
                      <v-card-actions>
                        <v-btn 
                          block
                          :color="edit ? 'success' : 'error'"
                          class="text-capitalize"
                          style="font-size: 1.25rem;"
                          @click="checkCardActions"
                          :loading="edit ? store.getLoading : false"
                        >
                          {{  edit ? 'Save' : 'Close' }}
                        </v-btn>
                      </v-card-actions>
                    </td>
                  </tr>
                </tfoot>
              </v-table>
            </v-form>
          </v-card>
        </v-row>
      </v-dialog>
    </div>
  </div>
</template>

<script>
var moment = require("moment");
import axios from "axios";
import UserData from "../repository/UserData";
import { useAuthStore } from "../../stores/auth";
import { useIndexStore } from "../../stores/index";
import { useAlertStore } from "../../stores/alert";
import draggable from 'vuedraggable';

export default {
  name: "DashboardMenus",
  props: {
    menus: {
      type: Array,
      default: () => [],
    },
  },

  components: {
    draggable
  },

  data: () => ({
    valid: false,
    selected: [],
    edit: false,
    rules: {
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
      selectRules: [(v) => !!v || "Select is required"],
      textRules: [
        v => !!v || 'Field is required',
        v => (v && v.length >= 3) || 'Min 3 characters',
      ],
      characterRules: [
        v => (v && v.length >= 3) || 'Min 3 characters',
      ],
    },
    token: localStorage.getItem("token") ? JSON.stringify(localStorage.getItem("token")).slice(1, -1) : "",
    auth: useAuthStore(),
    store: useIndexStore(),
    alert: useAlertStore(),
    editDashboardMenuList: [],
    initialDashboardMenuList: [],
  }),

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },

    dialog_menus() {
      return this.store.getDialogMenus;
    },
  },

  created() {
    const initialList = this.even(this.menus);
    this.initialDashboardMenuList = initialList;
    this.editDashboardMenuList = this.even(this.menus);
  },
  
  methods: {
    formatDate(date) {
      return moment.unix(date).format("DD MMM YYYY");
    },

    validate() {
      return this.$refs.form.validate();
    },

    resetForm() {
      return this.$refs.form.reset();
    },

    resetValidation() {
      return this.$refs.form.resetValidation();
    },

    closeDialogMenus() {
      this.reset();
      this.store.setDialogMenus(false);
    },

    even(dashboardMenuList) {
      return dashboardMenuList.slice().sort(function (a, b) {
        return a.menuId - b.menuId;
      });
    },

    resetMenus() {
      this.editDashboardMenuList = this.initialDashboardMenuList;
    },

    checkMove(evt) {
      var oldIndex = evt.moved.oldIndex;
      var newIndex = evt.moved.newIndex;
      var menu = evt.moved.element;

      var newMenu = this.editDashboardMenuList.find(item => item.menuId == menu.menuId);
      if (!newMenu) {
        return false;
      } else {
        var newMenuIndex = this.editDashboardMenuList.indexOf(newMenu);
        newMenu.userSortOrder = newIndex;
        this.editDashboardMenuList.splice(newMenuIndex, 1, newMenu);
      }

      var oldMenu = this.editDashboardMenuList[oldIndex];
      if (!oldMenu) {
        return false;
      } else {
        var oldMenuIndex = this.editDashboardMenuList.indexOf(oldMenu);
        oldMenu.userSortOrder = oldIndex;
        this.editDashboardMenuList.splice(oldMenuIndex, 1, oldMenu);
      }
    },

    checkCardActions() {
      if (this.edit) {
        this.updateUserMenus();
      } else {
        this.closeDialogMenus();
      }
    },

    updateContent(e, menu) {
      const inputText = e.target.innerText;
      let updateMenu = this.editDashboardMenuList.find(item => item.menuId == menu.menuId);

      if (!updateMenu) {
        return false;
      } else {
        var index = this.editDashboardMenuList.indexOf(updateMenu); 
        updateMenu.userTitle = inputText;
        this.editDashboardMenuList.splice(index, 1, updateMenu);
        return true;
      }
    },

    reset() {
      document.querySelector('html').classList.remove('v-overlay-scroll-blocked');
    },

    updateUserMenus() {
      this.validate().then((validator_response) => {
        this.valid = validator_response.valid;
        if (this.valid) {
          const formData = new FormData();
          formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
          formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
          formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
          formData.append("model", "updateDashboardMenu");
          formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
          formData.append("dashboardMenus", JSON.stringify(this.editDashboardMenuList));

          this.store.setLoading(true);
          axios.post(UserData.getBaseUrl(), formData).then((response) => {
            if (response.data.success == 1) {
              this.auth.setDashboardMenuList(JSON.parse(JSON.stringify(response.data.updatedMenuList)));
              this.store.setLoading(false);
              this.closeDialogMenus();
              this.alert.showSuccess(response.data.message);
            }
          })
          .catch((e) => {
            console.log("Error", e);
            this.store.setLoading(false);
          });
        }
      });
    },
  },
};
</script>