<template>
  <div>
    <!-- NAV-MENU -->
    <div
      class="nav-menu"
      style="height: 45px; width: 100%; padding: 0px; padding-top: 0.3%; text-align: center;"
    >
      <div class="left-buttons">
        <router-link to="/dashboard" style="text-decoration: none">
          <v-icon color="#fff" size="35" style="margin-left: 10px"
            >mdi-home</v-icon
          >
        </router-link>
      </div>
      <div class="filler"></div>
      <p style="font-weight: bold; font-size: 19px;">
        COMPLIANCE
      </p>
      <div class="filler"></div>
      <div class="right-buttons">
        <v-icon 
          color="#ffffff" 
          class="mr-5"
          @click="reloadPage"
          icon="mdi-cached"
          size="35"
          style="cursor: pointer;"
        />
      </div>
    </div>

    <!-- COMPONENT -->
    <div class="component">
      <div class="compliance-one" v-if="complianceOne">
        <!-- Header 1 -->
        <div
          class="parent"
          style="height: 45px; width: 99%; background-color: #fff; border-top: 5px solid rgb(162, 29, 33); margin-bottom: 7px;"
        >
          <div class="left-buttons">
            <v-icon
              style="float: left; margin-top: 5px; margin-left: 10px; background-color: rgb(162, 29, 33); color: #fff;"
              size="25"
              >mdi-bell-outline</v-icon
            >
            <p
              style="float: left; font-weight: bold; font-size: 19px; text-align: left; margin-left: 10px; width: 250px;"
            >
              {{ this.bodyCode }}
            </p>
          </div>
          <div class="filler"></div>
          <div class="right-buttons" style="display: inline">
            <div class="text-center">
              <v-dialog v-model="dialog" max-width="950px">
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    @click="getComplianceReportData1"
                    style="float: left; margin-top: 5px; margin-left: 10px; background-color: rgb(162, 29, 33); color: #fff;"
                    size="25"
                    >mdi-file-document</v-icon
                  >
                </template>
                <v-card class="pa-2">
                  <div
                    class="header"
                    style="display: flex; padding-left: 10px"
                  >
                    <v-icon size="25" color="rgb(162,29,33)"
                      >mdi-file-document</v-icon
                    >
                    <p
                      style="color: rgb(162, 29, 33); margin-left: 10px; font-weight: 19px; font-weight: bold;"
                    >
                      Compliance Report
                    </p>
                    <v-spacer></v-spacer>
                    <v-icon
                      @click="dialog = false"
                      color="rgb(162, 29, 33)"
                      style="margin-right: 10px"
                      >mdi-close</v-icon
                    >
                  </div>

                  <div
                    class="graph"
                    style="background-color: rgb(235, 235, 235); margin-left: 10px; margin-right: 10px; margin-bottom: 15px;"
                  >
                    <bar-chart 
                      :width="800"
                      :height="400"
                      :chartData="chartData"
                      :styles="chartStyles"
                    />
                  </div>

                  <div
                    class="graph-title"
                    style="height: 400px; width: 950px; margin-left: 10px; margin-right: 10px;"
                  >
                    <table>
                      <tr>
                        <td>
                          <table style="width: 950px;">
                            <tr
                              style="background-color: rgb(162, 29, 33); color: #fff;"
                            >
                              <th style="padding: 7px; width: 20%">Authority</th>
                              <th style="width: 20%">Compliance</th>
                              <th style="width: 20%">Compliance %</th>
                              <th style="width: 20%">Non Compliance</th>
                              <th style="width: 20%">Non Compliance %</th>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <table
                            class="table-striped"
                            style="width: 950px; overflow: auto"
                          >
                            <tbody>
                              <tr
                                v-for="(item, index) in complianceReportData"
                                v-bind:key="index"
                              >
                                <td style="padding: 7px; width: 20%">
                                  {{ item.bodyName }}
                                </td>
                                <td style="width: 20%">
                                  {{ item.compliance }}
                                </td>
                                <td style="width: 20%">
                                  {{ Math.round((item.compliance / (parseInt(item.compliance) + parseInt(item.nonCompliance))) * 100) }}
                                  {{ "%" }}
                                </td>
                                <td style="width: 20%">
                                  {{ item.nonCompliance }}
                                </td>
                                <td style="width: 20%">
                                  {{ Math.round((item.nonCompliance / (parseInt(item.compliance) + parseInt(item.nonCompliance))) * 100) }}
                                  {{ "%" }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </div>
                </v-card>
              </v-dialog>
            </div>
          </div>
        </div>

        <div class="left" style="color: #000000">
          <div class="left-top">
            <v-list 
              density="compact"
              style="background-color: #f8f9f9"
            >
              <v-list-item-content>
                <v-card
                  class="mx-auto"
                >
                  <v-list density="compact">
                    <v-list-item
                      v-for="(item, index) in complianceList"
                      :key="index"
                      :value="item"
                      active-color="rgb(162, 29, 33)"
                      @click="getComplianceArray(item)"
                    >
                      <v-list-item-title class="text-wrap mt-3 mb-n5">
                        <a href="#" @click="getComplianceArray(item)">
                          {{ item.bodyCode }}
                        </a>
                        <v-divider thickness="2px"></v-divider>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-list-item-content>
            </v-list>
          </div>
        </div>

        <div class="right">
          <div v-if="store.getLoading">
            <v-progress-linear
              color="rgb(162, 29, 33)"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </div>
          
          <!-- Table -->
          <div style="width: 100%;">
            <v-table 
              v-if="sortedData2.length > 0"
              height="100vh"
              fixed-header
            >
              <thead>
                <tr>
                  <th class="text-left text-white" style="background-color: rgb(162, 29, 33); font-weight: bolder;">Sr. No.</th>
                  <th class="text-left text-white" style="background-color: rgb(162, 29, 33); font-weight: bolder;">Code</th>
                  <th class="text-left text-white" style="background-color: rgb(162, 29, 33); font-weight: bolder;">Description</th>
                  <th class="text-left text-white" style="background-color: rgb(162, 29, 33); font-weight: bolder;">Frequency</th>
                </tr>
              </thead>
              <tbody
                v-for="item in sortedData2" 
                :key="item.itemId"
                class="table-striped"
              >
                <tr 
                  @click="toggle(item.cdsId)"
                  style="cursor: pointer;"
                >
                  <td
                    style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; padding-top: 20px; padding-bottom: 20px;"
                  >
                    {{ item.serialNumber }}
                  </td>
                  <td
                    style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                  >
                    {{ item.complianceCode }}
                  </td>
                  <td
                    style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                  >
                    {{ item.codeDescription }}
                  </td>
                  <td
                    style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                  >
                    <v-btn 
                      block
                      class="text-white"
                      color="rgb(162, 29, 33)"
                      @click="loadCompliance(item)"
                    >
                      {{ item.frequencyName }}
                    </v-btn>
                  </td>
                </tr>
                <tr v-if="opened.includes(item.cdsId)">
                  <td colspan="7">
                    <v-card class="elevation-0">
                      <v-card-text>
                        <div
                          style="display: block; margin: 2%; border: 1px solid grey; overflow: hidden;"
                        >
                          <v-row class="pa-4">
                            <v-col cols="12" md="12" sm="12">
                              <div
                                class="top-left"
                              >
                                <p
                                  style="font-weight: bold; font-size: 19px; margin: 0px;"
                                >
                                  Description:
                                </p>
                                <br>
                                <p
                                  style="margin-top: 0px; height: auto; text-align: justify;"
                                >
                                  {{ item.detailedDescription || 'No Description Found' }}
                                </p>
                              </div>
                            </v-col>
                          </v-row>
                        </div>
                      </v-card-text>
                    </v-card>
                  </td>
                </tr>
              </tbody>
            </v-table>

            <v-table v-else>
              <thead>
                <tr>
                  <td class="text-left text-white" style="background-color: rgb(162, 29, 33); font-weight: bolder;">Sr. No.</td>
                  <td class="text-left text-white" style="background-color: rgb(162, 29, 33); font-weight: bolder;">Code</td>
                  <td class="text-left text-white" style="background-color: rgb(162, 29, 33); font-weight: bolder;">Description</td>
                  <td class="text-left text-white" style="background-color: rgb(162, 29, 33); font-weight: bolder;">Frequency</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td 
                    colspan="7"
                    class="text-center"
                  >
                    <span>
                      No Compliance Found
                    </span>
                  </td>
                </tr>
              </tbody>
            </v-table>
          </div>
        </div>
      </div>

      <div class="compliance-two" v-if="complianceTwo">
        <!-- Header 1 -->
        <div
          class="parent"
          style="height: 45px; width: 99%; background-color: #fff; border-top: 5px solid rgb(162, 29, 33); margin-bottom: 7px;"
        >
          <div class="left-buttons">
            <v-icon
              @click="
                complianceTwo = !complianceTwo;
                complianceOne = true;
              "
              style="float: left; margin-top: 5px; margin-left: 10px; background-color: rgb(162, 29, 33); color: #fff;"
              size="30"
              >mdi-chevron-left</v-icon
            >
            <p
              style="float: left; font-weight: bold; font-size: 24px; text-align: left; margin-left: 10px; width: 250px;"
            >
              {{ this.bodyCode }}
            </p>
          </div>
          <div class="filler"></div>
          <div class="right-buttons" style="display: inline">
            <div class="text-center">
              <v-dialog v-model="dialog" max-width="950px">
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    @click="getComplianceReportData1"
                    style="float: left; margin-top: 5px; margin-left: 10px; background-color: rgb(162, 29, 33); color: #fff;"
                    size="30"
                  >
                    mdi-file-document
                  </v-icon>
                </template>
                <v-card class="pa-2">
                  <div
                    class="header"
                    style="display: flex; padding-left: 10px"
                  >
                    <v-icon size="30" color="rgb(162,29,33)"
                      >mdi-file-document</v-icon
                    >
                    <p
                      style="color: rgb(162, 29, 33); margin-left: 10px; font-weight: 24px; font-weight: bold;"
                    >
                      Compliance Report
                    </p>
                    <v-spacer></v-spacer>
                    <v-icon
                      @click="dialog = false"
                      color="rgb(162, 29, 33)"
                      style="margin-right: 10px"
                      >mdi-close</v-icon
                    >
                  </div>

                  <div
                    class="graph"
                    style="background-color: rgb(235, 235, 235); margin-left: 10px; margin-right: 10px; margin-bottom: 15px;"
                  >
                    <bar-chart 
                      :width="800"
                      :height="400"
                      :chartData="chartData"
                      :styles="chartStyles"
                    />
                  </div>

                  <div
                    class="graph-title"
                    style="height: 400px; width: 950px; margin-left: 10px; margin-right: 10px;"
                  >
                    <table>
                      <tr>
                        <td>
                          <table style="width: 950px">
                            <tr
                              style="background-color: rgb(162, 29, 33); color: #fff;"
                            >
                              <th style="padding: 7px; width: 20%">Authority</th>
                              <th style="width: 20%">Compliance</th>
                              <th style="width: 20%">Compliance %</th>
                              <th style="width: 20%">Non Compliance</th>
                              <th style="width: 20%">Non Compliance %</th>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <table
                            class="table-striped"
                            style="width: 950px; overflow: auto;"
                          >
                            <tbody>
                              <tr
                                v-for="(item, index) in complianceReportData"
                                v-bind:key="index"
                              >
                                <td style="padding: 7px; width: 20%">
                                  {{ item.bodyName }}
                                </td>
                                <td style="width: 20%">
                                  {{ item.compliance }}
                                </td>
                                <td style="width: 20%">
                                  {{ Math.round((item.compliance / (parseInt(item.compliance) + parseInt(item.nonCompliance))) * 100) }}
                                  {{ " %" }}
                                </td>
                                <td style="width: 20%">
                                  {{ item.nonCompliance }}
                                </td>
                                <td style="width: 20%">
                                  {{ Math.round((item.nonCompliance / (parseInt(item.compliance) + parseInt(item.nonCompliance))) * 100) }}
                                  {{ " %" }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </div>
                </v-card>
              </v-dialog>
            </div>
          </div>
        </div>

        <div class="mixedish">
          <h5 style="color: rgb(162, 29, 33)">{{ complianceDetails }}</h5>
          
          <div v-if="store.getLoading">
            <v-progress-linear
              color="rgb(162, 29, 33)"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </div>

          <div style="width: 100%;">  
            <!-- Table -->
            <v-table 
              v-if="sortedData3.length > 0"
              fixed-header
              height="100vh"
            >
              <thead>
                <tr>
                  <th class="text-left text-white" style="background-color: rgb(162, 29, 33); font-weight: bolder;">Due Date</th>
                  <th class="text-left text-white" style="background-color: rgb(162, 29, 33); font-weight: bolder;">Complied On</th>
                  <th class="text-left text-white" style="background-color: rgb(162, 29, 33); font-weight: bolder;">Status</th>
                </tr>
              </thead>
              <tbody
                v-for="(item, index) in sortedData3" 
                :key="index"
                class="table-striped"
              >
                <tr 
                  @click="toggle(item.complianceId, item.documents)"
                  style="cursor: pointer;"
                >
                  <td
                    style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; padding-top: 20px; padding-bottom: 20px;"
                  >
                    {{ formatDate(item.dueDate) }}
                  </td>
                  <td
                    style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                  >
                    {{ formatDate(item.dateComplied) }}
                  </td>
                  <td
                    style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                  >
                    <v-btn 
                      block
                      class="text-white"
                      :color="item.complianceStatus == 'complied' ? 'success' : 'info'"
                    >
                      {{ item.complianceStatus == 'complied' ? 'Complied' : 'Not Complied' }}
                    </v-btn>
                  </td>
                </tr>
                <tr v-if="opened.includes(item.complianceId)">
                  <td colspan="7">
                    <v-card class="elevation-0">
                      <v-card-text>
                        <div
                          style="display: block; margin: 2%; border: 1px solid grey; overflow: hidden;"
                        >
                          <v-row class="pa-4">
                            <v-col cols="12" md="12" sm="12">
                              <p
                                style="font-weight: bold; font-size: 19px; margin: 0px;"
                              >
                                Documents:
                              </p>
                              <div v-if="item.documents && item.documents.length > 0">
                                <v-list density="compact" class="ml-n4">
                                  <v-list-item
                                    v-for="(subItem, index) in item.documents"
                                    :key="index"
                                    :value="subItem.itemName"
                                  >
                                    <template v-slot:prepend>
                                      <v-progress-circular
                                        v-if="loading[subItem.itemId]"
                                        indeterminate
                                        color="rgb(162, 29, 33)"
                                        class="mr-2"
                                      ></v-progress-circular> 
                                      <v-icon
                                        v-else
                                        :color="fileIds.includes(subItem.itemId) ? 'rgba(204, 142, 41, 255)' : 'rgb(162, 29, 33)'"
                                        class="mr-2"
                                        size="large"
                                        :icon="globalMethods.getDocumentIcon(subItem.itemExtension)"
                                      />
                                    </template>
                                    <v-list-item-title class="text-wrap">
                                      <file-options
                                        :file="subItem"
                                        :file_color="'rgb(162, 29, 33)'"
                                        @open_pdf="getDocument(subItem)"
                                        v-if="subItem.itemExtension == 'pdf'"
                                        :select_type="true"
                                        :pdf_type="1"
                                      ></file-options>
                                      <span 
                                        v-else
                                        @click="getDocument(subItem)"
                                      >
                                        {{ subItem.itemName }}
                                      </span>
                                    </v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </div>
                              <div v-else>
                                <br>
                                <p style="margin-top: 0px; height: auto; text-align: justify;">
                                  No Documents Found
                                </p>
                              </div>
                            </v-col>
                          </v-row>
                        </div>
                      </v-card-text>
                    </v-card>
                  </td>
                </tr>
              </tbody>
            </v-table>

            <v-table v-else>
              <thead>
                <tr>
                  <th class="text-left text-white" style="background-color: rgb(162, 29, 33); font-weight: bolder;">Due Date</th>
                  <th class="text-left text-white" style="background-color: rgb(162, 29, 33); font-weight: bolder;">Complied On</th>
                  <th class="text-left text-white" style="background-color: rgb(162, 29, 33); font-weight: bolder;">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td 
                    colspan="7"
                    class="text-center"
                  >
                    <span>
                      No Compliance Details Found
                    </span>
                  </td>
                </tr>
              </tbody>
            </v-table>
          </div>
        </div>
      </div>
      
      <!-- PSPDFKIT / OPEN DOCUMENT MODAL -->
      <div
        v-if="closePDF || closeDocument"
        style="position: absolute; background-color: #f5f5f5; width: 100%; height: 100%; padding: 1%; overflow: hidden; margin-right: 0;"
      >
        <v-card>
          <v-card-text>
            <v-main>
              <v-container fluid>
                <component
                  :pdf-url="current_pdf.itemUrl"
                  :license-key="LICENSE_KEY"
                  :base-url="baseUrl"
                  @close_pdf="closePdf"
                  :is="document_component"
                  :document-url="current_document.itemUrl"
                  :document-type="current_document.itemExtension"
                  :document-title="current_document.itemName"
                  :viewer-color="'rgb(162, 29, 33)'"
                  @close_document="documentClosed"
                  :document-list="documentList"
                  :list-color="'rgb(162, 29, 33)'"
                  :briefcase-color="'rgba(204, 142, 41, 255)'"
                  @open_pdf="openDocument"
                  @open_document="openDocument"
                  @switch_pdf="switchDocument"
                  @switch_document="switchDocument"
                  :parent_select_type="true"
                  :parent_pdf_type="1"
                  :listWidth="'25%'"
                  :briefcase-files="fileIds"
                  :briefcase-on-load="true"
                  @briefcase_document="briefcaseDocument"
                />
              </v-container>
            </v-main>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <!-- END OF COMPONENT -->
  </div>
</template>

<script>
var moment = require("moment");
import axios from "axios";
import UserData from "../components/repository/UserData";
import PSPDFKit from "../components/PSPDFKit.vue";
import { useAuthStore } from "../stores/auth";
import { useIndexStore } from "../stores/index";
import { useAlertStore } from "../stores/alert";
import BarChart from './BarChart.vue';
import FileOptions from "./Cards/FileOptions.vue";
import GlobalMethods from "./repository/GlobalMethods";
import encAtRest from "./repository/encAtRest";

const LICENSE_KEY =
  "QvMr6j5706r3kYlOiVvJgEwu4fZnTL1fYz4t6paoBV5PekugaffzJnLtX5TczMS65OKAI1iIaSi06_l76UgF3kyKauJ9vaMZFuU3_oDplMFty6AhBhWPh80M7qoSa8xjtux3NqNrfqybICplLfHezwjhABIkPy6XxBMQ_uPU87_C5pMBXlc8A2PI_ShMjfhst6O_C0y9jyeMJ2DYudM3liFVsp90A0dOY51LM2Q3xV3jfcJ7K39FKpnKU_atpn1Ig8HXcmWdGQMjCXyquwVu9i2J850rGqBgFKJJFGixxnU3sZ__tA7Mb3aX8T2nXPlEnamtAhNf-DD1sEtqXHp5q5xxsFFuJDVh82tXKfd4oX8AkzWLd_-UVg_h_6ATML30e8fiQXSVcN0mmlZH5XYUv0jB5cHEyuIaGZac7gCNRn32pchJNpIwEdgHzWDRBQxxCR7F8b6z3Xs3yXJ6y9EhhrnKmjNYkMCdFJ0qNhI8zF8RhAbLA-uKtrzMy7hXLrlpuIHdGaCXMm7gA9iBpKiqwHIrX83rCFoVYUGM4BqNkim3HN8YJzZRDDI-ZHzdy7CcanSuvbe3NSxW5daeHdGVvg==";

export default {
  data: () => ({
    moment: moment,
    opened: [],
    complianceList: [],
    complianceArray: [],
    complianceDetailsList: [],
    documents: [],
    complianceReportData: [],
    bodyName: [],
    compliancePercentage: [],
    nonCompliancePercentage: [],
    compliance: [],
    nonCompliance: [],
    bodyCode: "",
    pdf: "",
    complianceOne: true,
    complianceTwo: false,
    closePDF: false,
    closeDocument: false,
    dialog: false,
    complianceDetails: "",

    files: [],
    fileIds: [],
    db: null,
    globalMethods: GlobalMethods,
    encAtRest: encAtRest,
    loading: {},

    chartData: {
      labels: ["January", "February", "March"],
      datasets: [{ data: [40, 20, 12] }],
    },

    chartStyles: [],

    sort: {
      field: "",
      desc: true,
    },

    LICENSE_KEY: LICENSE_KEY,
    baseUrl: "",
    errorMsg: "",

    token: localStorage.getItem("token") ? JSON.stringify(localStorage.getItem("token")).slice(1, -1) : "",
    store: useIndexStore(),
    auth: useAuthStore(),
    alert: useAlertStore(),
    documentList: [],
  }),

  methods: {
    formatDate(date) {
      return moment.unix(date).format("DD-MMM-YYYY");
    },

    reloadPage() {
      window.location.reload();
    },

    toggle(id, documents = []) {
      const index = this.opened.indexOf(id);
      if (index > -1) {
        this.opened.splice(index, 1);
      } else {
        this.opened = [];
        this.opened.push(id);
      }

      this.documentList = documents;
    },

    closePdf() {
      this.getBriefcaseFiles();
      this.closePDF = !this.closePDF;
      this.complianceTwo = true;
      this.store.setCurrentPdf({});
    },

    documentClosed() {
      this.getBriefcaseFiles();
      this.closeDocument = !this.closeDocument;
      this.complianceTwo = true;
      this.store.setDialogDocumentViewer(false);
      this.store.setCurrentDocument({});
    },
    
    getComplianceList() {
      const formData = new FormData();
      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "getComplianceList");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);

      this.store.setLoading(true);
      axios.post(UserData.getBaseUrl(), formData).then((response) => {
        if (response.data.success == 1) {
          this.getComplianceList = response.data;
          this.complianceList = this.getComplianceList.complianceList;
          this.getComplianceArray(this.complianceList[0]);
        }

        this.store.setLoading(false);
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    getComplianceArray(item) {
      this.complianceArray = item.complianceRules;
      this.bodyCode = item.bodyCode;
      let bodyId = item.bodyId;
      UserData.setBodyId(bodyId);
      this.store.setBodyId(bodyId);
    },

    sendCdsId(item) {
      let cdsId = item.cdsId;
      UserData.setCdsId(cdsId);
      this.store.setCdsId(cdsId);
      let cdsBsId = item.cdsBsid;
      UserData.setCdsBsId(cdsBsId);
      this.store.setCdsBsId(cdsBsId);
    },

    loadCompliance(item) {
      this.complianceOne = !this.complianceOne;
      this.complianceTwo = !this.complianceTwo;
      this.sendCdsId(item);
      this.complianceDetails = item.codeDescription;
      this.getComplianceDetails();
      this.toggle(item.cdsId);
    },

    getComplianceDetails() {
      let formData = new FormData();
      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "getComplianceDetails");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      formData.append("cdsId", UserData.getCdsId() ? UserData.getCdsId() : this.store.getCdsId);
      formData.append("cdsBsid", UserData.getCdsBsId() ? UserData.getCdsBsId() : this.store.getCdsBsId);
      formData.append("bodyId", UserData.getBodyId() ? UserData.getBodyId() : this.store.getBodyId);

      this.store.setLoading(true);
      axios.post(UserData.getBaseUrl(), formData).then((response) => {
        this.complianceDetailsList = response.data.complianceList;
        this.store.setLoading(false);
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    getComplianceReportData1() {
      const formData = new FormData();
      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "getComplianceReportData");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);

      axios.post(UserData.getBaseUrl(), formData).then((response) => {
        this.getComplianceReportData = response.data;
        this.complianceReportData = this.getComplianceReportData.ComplianceReportData;

        this.bodyName = this.complianceReportData.map(
          (item) => item.bodyName
        );

        this.chartData.labels = [];
        this.bodyName.forEach((label) => {
          this.chartData.labels.push(label);
        });

        this.chartData.datasets = [];
        this.chartData.datasets.push({
          label: 'Compliance %',
          data: this.complianceReportData.map((item) => Math.round(parseInt(item.compliance) / (parseInt(item.nonCompliance) + parseInt(item.compliance)) * 100)),
          borderColor: '#EC9800',
          borderWidth: 1,
          backgroundColor: '#EC9800',
          fill: false,
        });

        this.chartData.datasets.push({
          label: 'Non Compliance %',
          data: this.complianceReportData.map((item) => Math.round(parseInt(item.nonCompliance) / (parseInt(item.nonCompliance) + parseInt(item.compliance)) * 100)),
          borderColor: '#9EDE00',
          borderWidth: 1,
          backgroundColor: '#9EDE00',
          fill: false,
        });

        this.dialog = true;
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    getDocument(item) {
      if (this.fileIds.includes(item.itemId)) {
        this.getBriefcaseFile(item);
      } else {
        this.briefcaseDocument(item);
      }
    },

    openPdf(item) {
      this.store.setDialogDocumentViewer(false);
      this.closeDocument = false;
      this.closePDF = true;
      this.store.setDocumentComponent('pspdfkit');
      this.store.setCurrentPdf(item);
      this.store.setCurrentDocument(item);

      UserData.setDocumentId(item.itemId);
      this.store.setDocumentId(item.itemId);
    },

    openDocument(item) {
      this.complianceOne = false;
      this.complianceTwo = false;

      if (item.itemExtension == "pdf") {
        this.openPdf(item);
      } else {
        if (item.itemExtension != 'No File Extension') {
          this.store.setDialogDocumentViewer(true);
          this.closePDF = false;
          this.closeDocument = true;
          this.store.setDocumentComponent('document-viewer');
          this.store.setCurrentDocument(item);

          UserData.setDocumentId(item.itemId);
          this.store.setDocumentId(item.itemId);
        } else {
          if (item.itemSize > 0) {
            this.alert.showError(item.itemExtension);
          } else {
            this.alert.showError('Error Opening File');
          }
        }
      }
    },

    switchDocument(item) {
      if (this.fileIds.includes(item.itemId)) {
        this.getBriefcaseFile(item);
      } else {
        this.store.setBriefcaseUrl(null);
        this.openDocument(item);
      }
    },

    briefcaseDocument(item) {
      if (item.itemExtension) {
        this.loading[item.itemId] = true;
        this.globalMethods.downloadEbrDocument(item.itemUrl).then(async (response) => {
          if (response.status == 200) {
            let fileType = response.headers['content-type'];
            let fileData = new Blob([response.data], { type: fileType });
  
            this.globalMethods.addFile(item.itemId, fileData, fileType).then(() => {
              setTimeout(() => {
                this.loading[item.itemId] = false;
                this.getBriefcaseFile(item);
                this.getBriefcaseFiles();
              }, 1000);
            })
            .catch((e) => {
              if (e.code == 500) {
                this.overlay_type = 1;
                this.session_overlay = true;
              }
  
              this.openDocument(item);
              console.log("Error: ", e);
              this.loading[item.itemId] = false;
            });
          } else {
            console.log("Response: ", response);
          }
        });
      } else {
        this.alert.showInfo("Briefcasing Folders is Under Development");
      }
    },

    doSort(field) {
      if (field == this.sort.field) {
        this.sort.desc = !this.sort.desc;
      } else {
        this.sort.field = field;
        this.sort.desc = true;
      }
    },

    swapComponent: function (component) {
      this.currentComponent = component;
    },
  },

  computed: {
    sortedData2() {
      if (!this.sort.field) {
        return this.complianceArray;
      }
      return this.complianceArray.concat().sort((a, b) => {
        if (this.sort.desc) {
          return a[this.sort.field] > b[this.sort.field] ? -1 : 1;
        } else {
          return a[this.sort.field] > b[this.sort.field] ? 1 : -1;
        }
      });
    },

    sortedData3() {
      if (!this.sort.field) {
        return this.complianceDetailsList;
      }
      return this.complianceDetailsList.concat().sort((a, b) => {
        if (this.sort.desc) {
          return a[this.sort.field] > b[this.sort.field] ? -1 : 1;
        } else {
          return a[this.sort.field] > b[this.sort.field] ? 1 : -1;
        }
      });
    },

    user() {
      return this.auth.getLoggedInUser;
    },

    current_pdf() {
      return this.store.getCurrentPdf;
    },

    current_document() {
      return this.store.getCurrentDocument;
    },

    document_component() {
      return this.store.getDocumentComponent;
    },

    overlay_type: {
      get() {
        return this.store.getOverlayType;
      },
      set(value) {
        this.store.setOverlayType(value);
      },
    },

    session_overlay: {
      get() {
        return this.store.getSessionOverlay;
      },
      set(value) {
        this.store.setSessionOverlay(value);
      },
    },
  },

  beforeMount() {
    this.getComplianceList();
  },
  
  components: {
    pspdfkit: PSPDFKit,
    'bar-chart': BarChart,
    FileOptions,
  },
};
</script>

<style scoped>
.nav-menu {
  margin-top: 0px;
  background-color: rgb(162, 29, 33);
  color: #fff;
  display: flex;
  margin-bottom: 1%;
}

.component {
  width: 99%;
  margin-left: 0.5%;
  margin-right: 0.5%;
  height: 100vh;
}

.compliance-one {
  position: absolute;
  width: 99%;
  margin-left: 0.5%;
  margin-right: 0.5%;
  height: 800px;
  background-color: #fff;
  z-index: 1;
}

.compliance-two {
  position: absolute;
  width: 99%;
  margin-left: 0.5%;
  margin-right: 0.5%;
  height: 100vh;
  background-color: #fff;
  z-index: 2;
}

.pdfClass {
  position: absolute;
  background-color: #f5f5f5;
  width: 98%;
  height: 880px;
  margin-left: 0.5%;
  margin-right: 1.5%;
  overflow: hidden;
  margin-right: 0;
  z-index: 3;
}

.left {
  width: 10%;
  margin-right: 1%;
  float: left;
  height: 100%;
}

.left-top {
  width: 100%;
  height: 49%;
  margin-bottom: 4%;
  background-color: #f8f9f9;
  border-top: 5px solid rgb(162, 29, 33);
}

.left-bottom {
  width: 100%;
  height: 100%;
  background-color: #f8f9f9;
}

.left a {
  color: #000;
  text-decoration: none;
}

.left a:hover {
  color: rgb(162, 29, 33);
}

.right {
  width: 88%;
  float: left;
  background-color: #f8f9f9;
  border-top: 5px solid rgb(162, 29, 33);
}

.parent {
  display: flex;
  width: 100%;
}

.filler {
  flex-grow: 1;
}

.left-buttons {
  display: inline;
}

.right-buttons {
  display: inline;
}

.mixedish {
  width: 99%;
  height: 100%;
}

button:focus {
  outline: 0 !important;
  border: 0 !important;
  box-shadow: none !important;
}

.table-striped a {
  text-decoration: none;
  color: #000;
}

.table-striped a:hover {
  color: rgb(162, 29, 33);
}

.table-striped a:active {
  color: rgb(162, 29, 33);
}

.table-striped:nth-child(even) {
  background-color: rgba(0, 0, 0, .05);
}

.table-striped:hover {
  background-color: rgba(255, 255, 255, 255) !important;
}
</style>
