<template>
  <div></div>
</template>

<script>
import Swal from 'sweetalert2';
import { useAlertStore } from "../stores/alert";

export default {
  name: "Alert",
  data: () => ({
    alert: useAlertStore(),
  }),
  methods: {
    hideAlert() {
      this.alert.clearAlert();
    },
    showAlert() {
      Swal.fire({
        icon: this.color,
        title: this.message,
        timer: 1500
      }).then(
        this.hideAlert()
      );
    },
    hideConfirm() {
      this.alert.clearConfirm();
    },
    showConfirm() {
      Swal.fire({
        title: this.title,
        text: this.message,
        icon: this.color,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.button,
        cancelButtonText: this.cancel,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.alert.setChecked(true);
        } else {
          this.alert.setChecked(false);
        }

        this.hideConfirm();
      });
    },
  },
  watch: {
    show() {
      if(this.show == true) {
        this.showAlert();
      }
    },
    confirm() {
      if (this.confirm == true) {
        this.showConfirm();
      }
    },
    message() {
      return this.alert.getMessage;
    },
    color() {
      return this.alert.getColor;
    },
  },
  computed: {
    show: {
      get() {
        return this.alert.getShow;
      },
      set() {
        setTimeout(() => {
          this.alert.clearAlert();
        }, 3000);
      },
    },
    confirm: {
      get() {
        return this.alert.getConfirm;
      },
      set(value) {
        setTimeout(() => {
          this.alert.setConfirm(value);
        }, 3000);
      },
    },
    checked: {
      get() {
        return this.alert.getChecked;
      },
      set(value) {
        setTimeout(() => {
          this.alert.setChecked(value);
        }, 3000);
      },
    },
    message() {
      return this.alert.getMessage;
    },
    title() {
      return this.alert.getTitle;
    },
    button() {
      return this.alert.getButton;
    },
    cancel() {
      return this.alert.getCancel;
    },
    color() {
      return this.alert.getColor;
    },
  },
};
</script>
