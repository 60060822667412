<template>
  <div>
    <v-row justify="center" v-if="!pdf_type" no-gutters>
      <v-col :cols="cols[0]" v-if="listType">
        <v-sheet>
          <StaticList 
            :document-list="documentList" 
            :list-menu="menu" 
            :list-color="listColor" 
            @open_document="switchPdf" 
            @close_list="menu = false" 
            :list-width="listWidth"
            :parent_select_type="parent_select_type"
            :parent_pdf_type="parent_pdf_type"
            v-if="documentList.length > 0"
            :file-ids="briefcaseFiles"
            :briefcase-color="briefcaseColor"
          />
        </v-sheet>
      </v-col>

      <v-divider vertical class="mx-4" v-if="listType"></v-divider>

      <v-col :cols="listType ? cols[1] : 12">
        <v-sheet>
          <v-card width="100%">
            <v-card-title>
              <v-toolbar
                dark
                :color="listColor"
                class="text-white mx-2"
              >
                <v-toolbar-items>
                  <v-btn
                    icon
                    dark
                    @click="menu = true"
                    v-if="documentList.length > 0 && !listType"
                  >
                    <v-icon>mdi-menu</v-icon>
                  </v-btn>
                </v-toolbar-items>
                <v-toolbar-title>{{ documentTitle }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn
                    icon
                    dark
                    @click="showAnnotated"
                    v-if="!signature_document"
                  >
                    <v-icon>mdi-format-annotation-plus</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    dark
                    @click="closePdf"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
            </v-card-title>
            <v-card-text style="height: 90vh; overflow-y: scroll;">
              <div class="container"></div>
            </v-card-text>
          </v-card>
        </v-sheet>
      </v-col>
    </v-row>
    
    <div v-else>
      <v-row
        no-gutters
      >
        <v-col :cols="cols[0]" v-if="listType">
          <v-sheet>
            <StaticList 
              :document-list="documentList" 
              :list-menu="menu" 
              :list-color="listColor" 
              @open_document="switchPdf" 
              @close_list="menu = false" 
              :list-width="listWidth"
              :parent_select_type="parent_select_type"
              :parent_pdf_type="parent_pdf_type"
              v-if="documentList.length > 0"
              :file-ids="briefcaseFiles"
            />
          </v-sheet>
        </v-col>

        <v-divider vertical class="mx-4" v-if="listType"></v-divider>

        <v-col :cols="listType ? cols[1] : 12">
          <v-sheet>
            <div class="container"></div>
          </v-sheet>
        </v-col>
      </v-row>
    </div>

    <v-overlay
      :model-value="overlay"
      class="align-center justify-center"
      contained
    >
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <div class="text-center">
      <DocumentList 
        :document-list="documentList" 
        :list-menu="menu" 
        :list-color="listColor" 
        @open_document="switchPdf" 
        @close_list="menu = false" 
        :list-width="listWidth"
        :parent_select_type="parent_select_type"
        :parent_pdf_type="parent_pdf_type"
        v-if="documentList.length > 0"
        :file-ids="briefcaseFiles"
        :briefcase-color="briefcaseColor"
      />
    </div>

    <div class="text-center">
      <ShareAnnotations 
        :users="users" 
        :annotations="imported_annotations" 
        :load="trigger"
        :shared_annotations="shared"
        @load_annotations="getUserAnnotation"
      />
    </div>

    <div class="text-center">
      <PublicNotes 
        :notes="public_notes" 
        @add_note="getPublicNotesForDocument(1)" 
      />
    </div>
  </div>
</template>

<script>
import PSPDFKit from "pspdfkit";
import UserData from "./repository/UserData";
import axios from "axios";
import authHeader from './repository/auth-header';
import { useAuthStore } from "../stores/auth";
import { useIndexStore } from "../stores/index";
import { useAlertStore } from "../stores/alert";
import ShareAnnotations from './Cards/ShareAnnotations.vue';
import PublicNotes from './Forms/PublicNotes.vue';
import DocumentList from './Cards/DocumentList.vue';
import GlobalMethods from "./repository/GlobalMethods";
import SlackNotificationChannel from './repository/SlackNotificationChannel';
import encAtRest from "./repository/encAtRest";
import database from "./repository/database";
import StaticList from './Cards/StaticList.vue';
import forge from 'node-forge';
// import { attachListeners, positionSignatureElement } from '../digital-signatures/index';
import { attachListeners } from '../digital-signatures/index';

export default {
  name: "PSPDFKit",

  props: {
    pdfUrl: {
      type: String,
      default: "",
    },

    licenseKey: {
      type: String,
      default: "",
    },

    baseUrl: {
      type: String,
      default: "",
    },

    documentList: {
      type: Array,
      default: () => [],
    },

    documentTitle: {
      type: String,
      default: "",
    },

    listType: {
      type: Boolean,
      default: false,
    },

    listColor: {
      type: String,
      default: "#e33333",
    },

    briefcaseColor: {
      type: String,
      default: "#e33333",
    },

    listWidth: {
      type: String,
      default: "50%",
    },

    parent_select_type: {
      type: Boolean,
      default: false,
    },

    parent_pdf_type: {
      type: Number,
      default: null,
    },

    briefcaseOnLoad: {
      type: Boolean,
      default: false,
    },

    briefcaseFiles: {
      type: Array,
      default: () => [],
    },

    documentLoader: {
      type: Object,
      default: () => {},
    },
  },

  _instance: null,

  components: {
    ShareAnnotations,
    PublicNotes,
    DocumentList,
    StaticList,
},

  data() {
    return {
      selected: [],
      selected_ids: [],
      shared: false,
      menu: false,
      loading: false,
      admin: {},
      imported_annotations: {},
      shared_annotations: {},
      token: localStorage.getItem("token") ? JSON.stringify(localStorage.getItem("token")).slice(1, -1) : "",
      store: useIndexStore(),
      auth: useAuthStore(),
      alert: useAlertStore(),
      users: [],
      trigger: false,
      public_notes: [],
      public_notes_count: 0,
      document: "",
      files: [],
      db: [],
      globalMethods: GlobalMethods,
      encAtRest: encAtRest,
      database: database,
      overlay: false,
      session: false,
      status: true,
      initialUsersList: [],
    }
  },

  created() {
    this.store.setPSPDFKitOverlay(false);
    this.store.setDialogUsers(false);
    this.store.setDialogNotes(false);
    this.store.setLoading(false);
  },

  async mounted() {
    this.startLoading();
  },
  
  methods: {
    closePdf() {
      this.store.setSignatureDocument(false);
      this.$emit('close_pdf');
    },

    startLoading() {
      // this.loadSharedAnnotations();

      switch (this.pdf_type)
      {
        case true: 
          if (this.currentRouteName == 'Meetings' && !this.approval_document) {
            this.getPublicNotesForDocument();
          } else {
            this.getAnnotations();
          }

          break;
        case false:
          if (this.currentRouteName == 'Meetings' && !this.approval_document) {
            this.getPublicNotesForDocument();
          } else {
            this.load(null);
          }

          break;
      }
    },

    switchPdf(document) {
      this.store.setSignatureDocument(false);
      if (document.itemExtension == 'pdf') {
        this.$emit("switch_pdf", document);
      } else {
        this.$emit("switch_document", document);
      }
    },
    
    async load(annotations) {
      const that = this;
      
      // PSPDFKit exposes the default list of items as PSPDFKit.defaultToolbarItems
      // This is an Array that can be filtered or customized as you please.
      const toolbarItems = PSPDFKit.defaultToolbarItems;

      if (this.currentRouteName == 'Meetings' && !this.approval_document)
      {
        // comments svg
        let comments = '';
        
        var notes = this.public_notes_count;
        if (notes > 0) {
          comments = '<svg style="width:24px;height:24px;color:#ffc107;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><defs><style type="text/css"> .blink { animation: blinker 1.5s linear infinite; color: red; font-family: sans-serif; } @keyframes blinker {50% {opacity: 0;}}</style></defs><path d="M9,22A1,1 0 0,1 8,21V18H4A2,2 0 0,1 2,16V4C2,2.89 2.9,2 4,2H20A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H13.9L10.2,21.71C10,21.9 9.75,22 9.5,22V22H9Z" /><g class="blink"><text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" style="color:red;font-size:12px;">' + notes + '</text></g></svg>';
        } else {
          comments = '<svg style="width:24px;height:24px;color:#ffc107;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M9,22A1,1 0 0,1 8,21V18H4A2,2 0 0,1 2,16V4C2,2.89 2.9,2 4,2H20A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H13.9L10.2,21.71C10,21.9 9.75,22 9.5,22V22H9Z" /></svg>';
        }
  
        // A custom item. Inside the onPress callback we can call into PSPDFKit APIs.
        toolbarItems.push({
          type: "custom",
          id: "comments-button",
          title: "Public Notes",
          icon: comments,
          className: "comments",
          onPress: function () {
            that.store.setLoading(false);
            that.store.setDialogNotes(true);
          },
        });
      }
  
      // share svg
      let share = '<svg style="width:24px;height:24px;color:#007bff;" viewBox="0 0 24 24"><path fill="currentColor" d="M18,16.08C17.24,16.08 16.56,16.38 16.04,16.85L8.91,12.7C8.96,12.47 9,12.24 9,12C9,11.76 8.96,11.53 8.91,11.3L15.96,7.19C16.5,7.69 17.21,8 18,8A3,3 0 0,0 21,5A3,3 0 0,0 18,2A3,3 0 0,0 15,5C15,5.24 15.04,5.47 15.09,5.7L8.04,9.81C7.5,9.31 6.79,9 6,9A3,3 0 0,0 3,12A3,3 0 0,0 6,15C6.79,15 7.5,14.69 8.04,14.19L15.16,18.34C15.11,18.55 15.08,18.77 15.08,19C15.08,20.61 16.39,21.91 18,21.91C19.61,21.91 20.92,20.61 20.92,19A2.92,2.92 0 0,0 18,16.08Z" /></svg>';

      // A custom item. Inside the onPress callback we can call into PSPDFKit APIs.
      toolbarItems.push({
        type: "custom",
        id: "share-button",
        title: "Share Annotations",
        icon: share,
        className: "share",
        onPress: function () {
          if (Object.keys(that.imported_annotations).length != 0 || that.selected_ids.length != 0) {
            that.store.setLoading(false);
            that.shared = false;
            that.users = that.initialUsersList;
            that.trigger = !that.trigger;
            that.store.setSelectedAnnotation({});
            that.store.setDialogUsers(true);
          } else {
            that.alert.showInfo('No Imported Annotations Found');
          }
        },
      });

      if (!this.pdf_type) {
        if (!this.signature_document) {
          // annotated svg
          let annotated = '<svg style="width:24px;height:24px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>View Annotations</title><path d="M8.5,7H10.5L16,21H13.6L12.5,18H6.3L5.2,21H3L8.5,7M7.1,16H11.9L9.5,9.7L7.1,16M22,5V7H19V10H17V7H14V5H17V2H19V5H22Z" /></svg>';
          
          // A custom item. Inside the onPress callback we can call into PSPDFKit APIs.
          toolbarItems.push({
            type: "custom",
            id: "view-annotations",
            title: "View Annotations",
            icon: annotated,
            className: "menu",
            onPress: function () {
              that.pdf_type = true;
            },
          });
        }
      } else {
        // original svg
        let original = '<svg style="width:24px;height:24px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>View Original</title><path d="M10.5,7H8.5L3,21H5.2L6.3,18H12.5L13.6,21H16L10.5,7M7.1,16L9.5,9.7L11.9,16H7.1M22,7H14V5H22V7Z" /></svg>';
        
        // A custom item. Inside the onPress callback we can call into PSPDFKit APIs.
        toolbarItems.push({
          type: "custom",
          id: "view-original",
          title: "View Original",
          icon: original,
          className: "menu",
          onPress: function () {
            that.pdf_type = false;
            that.unload();
            that.getAnnotations();
          },
        });
      }

      // close svg
      let close = '<svg style="width:24px;height:24px;color:#dc3545;" viewBox="0 0 24 24"><path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" /></svg>';

      if (this.documentList.length > 0 && !this.listType) 
      {
        // menu svg
        let menu = '<svg style="width:24px;height:24px;color:#22bb33;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>View Documents</title><path d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z" /></svg>';
  
        // A custom item. Inside the onPress callback we can call into PSPDFKit APIs.
        toolbarItems.unshift({
          type: "custom",
          id: "view-documents",
          title: "View Documents",
          icon: menu,
          className: "menu",
          onPress: function () {
            that.menu = true;
          },
        });
      }

      this.overlay = true;

      PSPDFKit.load({
        disableWebAssemblyStreaming: true,
        document: that.briefcase_url != null ? that.briefcase_url : that.pdfUrl,
        renderPageCallback: that.pdf_type ? that.addWatermark : () => {},
        licenseKey: process.env.NODE_ENV == 'production' ? that.licenseKey : '',
        container: ".container",
        baseUrl: process.env.NODE_ENV == 'production' ? location.origin + '/' : that.baseUrl,
        instantJSON: that.pdf_type ? annotations : null,
        enableHistory: true,
        toolbarItems: toolbarItems.reduce((acc, item) => {
          // Position the undo and redo buttons after the last annotation button, polyline.
          if (item.type === 'polyline') {
            return acc.concat([item, { type: 'undo' }, { type: 'redo' }]);
          }
          return acc.concat([item]);
        }, []),
        // styleSheets: ['/digital-signatures/styles.css'],
        initialViewState: new PSPDFKit.ViewState({ readOnly: false }),
      })
      .then(async function (instance) {
        const buttons = {
          // finishButton: {
          //   type: "custom",
          //   title: "Finish Signing",
          //   className: "finish-signing",
          //   name: "sign",
          //   async onPress() {
          //     // When "Finish Signing" is pressed, after the user
          //     // has added an ink signature, we proceed to apply
          //     // a digital signature to the document. From this
          //     // point on the integrity of the file is guaranteed.
          //     try {
          //       await instance.signDocument(null, that.generatePKCS7);
          //       console.log("New signature added to the document!");
          //     } catch (error) {
          //       that.alert.showError("The document could not be signed: " + error);
          //     }
          //   },
          // },
          // resetButton: {
          //   type: "custom",
          //   title: "Reset",
          //   name: "reset",
          //   async onPress() {
          //     location.href = "../digital-signatures/";
          //   },
          // },
          closeButton: {
            type: "custom",
            id: "close-button",
            title: "Close",
            icon: close,
            className: "close",
            onPress: function () {
              that.store.setSignatureDocument(false);
              that.$emit('close_pdf');
            },
          },
        };

        // if (that.digital_signatures && that.signature_document) {
        //   instance.getSignaturesInfo().then(signaturesInfo => {
        //     if (signaturesInfo.signatures) {
        //       const invalidSignatures = signaturesInfo.signatures.filter(signature => signature.signatureValidationStatus !== PSPDFKit.SignatureValidationStatus.valid);
        //     }
        //   });
        // }

        // instance.setViewState(viewState => (
        //   viewState.set("showSignatureValidationStatus", PSPDFKit.ShowSignatureValidationStatusMode.IF_SIGNED)
        // ));
        
        const items = instance.toolbarItems;
        // `fixedToolbarItems` is an array with other items that are not changing.
        const fixedToolbarItems = items.filter((item) => {
          if (item.type === 'export-pdf' || item.type === 'print' || item.type === 'document-editor' || item.type == 'signature') {
            return false;
          } 

          return true;
        });

        attachListeners(instance, buttons, fixedToolbarItems);

        if (!that.pdf_type) {
          instance.setViewState(viewState => viewState.set("showToolbar", !viewState.showToolbar));
        }

        if ((that.selected_annotation && that.selected_annotation.topicId) || that.checkViewState) {
          instance.setViewState(viewState => viewState.set("readOnly", true));
        }

        that.overlay = false;
        that.store.setLoading(false);

        // Revoke briefcase URL to prevent memory leaks
        window.URL.revokeObjectURL(that.briefcase_url);
        that.store.setBriefcaseUrl(null);

        let document_id = UserData.getDocumentId() ? UserData.getDocumentId() : that.store.getDocumentId;

        // if (that.digital_signatures && that.signature_document && that.signature_text) {
        //   positionSignatureElement(instance, that.signature_text);
        // }
        
        that.$parent.errorMsg = "";
        if ((!that.digital_signatures || !that.signature_document) || (!that.selected_annotation && !that.selected_annotation.topicId)) {
          instance.addEventListener("annotations.didSave", async () => {
            instance.exportInstantJSON().then(function (instantJSON) {
              const form = new FormData();
              form.append("userId", UserData.getUserId() ? UserData.getUserId() : that.user.userInfo.userId);
              form.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : that.user.companyDetail.companyCode);
              form.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : that.token);
              form.append("model", "saveAnnotation");
              form.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : that.user.companyDetail.companyId);
              form.append("payload", JSON.stringify(instantJSON));
              form.append("documentId", document_id);

              axios.post(process.env.NODE_ENV == 'production' ? UserData.getProductionSaveAnnotationUrl() : UserData.getLocalSaveAnnotationUrl(), form, { headers: authHeader() }).then((response) => {
                if (response.status == 200) {
                  that.imported_annotations = response.data.data;
                } else {
                  if (response.response.status == 403 && !that.session_overlay) {
                    this.store.setPSPDFKitOverlay(true);
                    that.session = true;
                  }
                }
              }).catch((err) => {
                SlackNotificationChannel.errorMessage(err.message, 'load(583) in PSPDFKit.vue', Object.fromEntries(form));
              });
            });
          });
        }
      })
      .catch(function (err) {
        console.group("Error Message")
        console.log(err)
        PSPDFKit.unload(".container");
        that.$parent.errorMsg = err.message;
        that.alert.showError("Problem loading document");
        SlackNotificationChannel.errorMessage(err.message, 'load(605) in PSPDFKit.vue', {});
        window.URL.revokeObjectURL(that.briefcase_url);
        that.store.setBriefcaseUrl(null);
        that.load(null);
      });
    },

    unload() {
      PSPDFKit.unload(this._instance || ".container");
      this._instance = null;
    },

    async trustedCAsCallback() {
      let res;
      let cert;

      try {
        res = await fetch('/digital-signatures/ca.pem');
        // Use `res.text()` for a PEM-encoded certificate.
        cert = await res.text();
      } catch (e) {
        throw `Error ${e}`;
      }

      return [cert];
    },

    addWatermark(ctx, pageIndex, pageSize) {
      ctx.font = "14px Arial";
      ctx.fillStyle = "rgba(76, 130, 212, .1)";

      var watermark = "";
      
      var i = 0;
      for(i = 0; i < 10; i++) {
        watermark += watermark.concat(" ", this.eboardUpdates.watermarkMessage);
      }

      for(i = 10; i < pageSize.height; i += 15) {
        ctx.fillText(watermark, -20, i);
      }
    },

    showAnnotated() {
      this.pdf_type = true;
      this.startLoading();
    },

    checkPayloadJSON(payload) {
      try {
        JSON.parse(payload);
      } catch (e) {
        return false;
      }

      return true;
    },

    getAnnotations() {
      // this.getImportedData((annotations) => {
      //   try {
      //     this.load(annotations);
      //   } catch (error) {
          this.load(null);
      //   }
      // });
    },

    getImportedData(annotationsCallBack) {
      let documentId = UserData.getDocumentId() ? UserData.getDocumentId() : this.index.getDocumentId;

      const form = new FormData();
      form.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      form.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      form.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      form.append("model", "importAnnotation");
      form.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      form.append("documentId", documentId);

      axios.post(process.env.NODE_ENV == 'production' ? UserData.getProductionAdminImportAnnotationUrl() : UserData.getLocalAdminImportAnnotationUrl(), form, { headers: authHeader() }).then((response) => {
        if (response.status == 200) {
          var data = response.data && response.data.instant_json ? response.data.instant_json : null;
          this.imported_annotations = response.data;
          this.getUsersForDocument(documentId);

          if (annotationsCallBack instanceof Function) {
            annotationsCallBack(data);
          }
        } else {
          if (response.response.status == 403 && !this.session_overlay) {
            this.store.setPSPDFKitOverlay(true);
            this.session = true;
          } else {
            this.load(null);
          }
        }
      });
    },

    getDigitalSignature(signatureType) {
      const form = new FormData();
      form.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      form.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      form.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      form.append("model", "getDigitalSignature");
      form.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);

      return new Promise((resolve, reject) => {
        axios.post((process.env.NODE_ENV == 'production' ? UserData.getProductionAdminBaseUrl() : UserData.getLocalAdminBaseUrl()) + '/digital-signature/' + signatureType, form, { headers: authHeader(), responseType: 'blob' }).then((response) => {
          if (response.status == 200) {
            resolve(response.data);
          } else {
            if (response.response.status == 403 && !this.session_overlay) {
              this.store.setPSPDFKitOverlay(true);
              this.session = true;
              reject();
            }
          }
        });
      });
    },

    generatePKCS7({ fileContents }) {
      const certificatePromise = this.getDigitalSignature(1).then((response) => response.text());
      const privateKeyPromise = this.getDigitalSignature(2).then((response) => response.text());

      return new Promise((resolve, reject) => {
        Promise.all([certificatePromise, privateKeyPromise])
          .then(([certificatePem, privateKeyPem]) => {
            const certificate = forge.pki.certificateFromPem(certificatePem);
            const privateKey = forge.pki.privateKeyFromPem(privateKeyPem);

            const p7 = forge.pkcs7.createSignedData();
            p7.content = new forge.util.ByteBuffer(fileContents);
            p7.addCertificate(certificate);
            p7.addSigner({
              key: privateKey,
              certificate: certificate,
              digestAlgorithm: forge.pki.oids.sha256,
              authenticatedAttributes: [
                {
                  type: forge.pki.oids.contentType,
                  value: forge.pki.oids.data
                },
                {
                  type: forge.pki.oids.messageDigest
                },
                {
                  type: forge.pki.oids.signingTime,
                  value: new Date()
                }
              ]
            });

            p7.sign({ detached: true });
            const result = this.stringToArrayBuffer(forge.asn1.toDer(p7.toAsn1()).getBytes());
            resolve(result);
          })
          .catch(reject);
      });
    },

    // https://developers.google.com/web/updates/2012/06/How-to-convert-ArrayBuffer-to-and-from-String
    stringToArrayBuffer(binaryString) {
      const buffer = new ArrayBuffer(binaryString.length);
      let bufferView = new Uint8Array(buffer);
      for (let i = 0, len = binaryString.length; i < len; i++) {
        bufferView[i] = binaryString.charCodeAt(i);
      }

      return buffer;
    },

    sesssionTimeout(status) {
      this.status = false;
      this.store.setPSPDFKitOverlay(status);
      this.session = status;
    },

    closeSession() {
      this.store.setSignatureDocument(false);
      this.$emit('close_pdf');
    },

    renewTokenLease(userPassword) {
      this.globalMethods.renewTokenLease(userPassword).then(async (response) => {
        if (response.code == 200) {
          localStorage.setItem('admin_session_expiry', response.data.token_expiry);
          localStorage.setItem('admin_session_timeout', response.data.token_expiry_Tminus);

          if (!localStorage.getItem('DB_VERSION'))
            localStorage.setItem('DB_VERSION', 1);
          
          this.session = !this.session;
          this.resetUserActivityTimeout(true);
          
          try {
            const credentialKey = await this.encAtRest.generateCredentialKey(userPassword);
            this.encAtRest.setCredentialKey(credentialKey);

            this.overlay_type = false;
            this.store.setSessionOverlay(false);
            this.store.setSessionWarningOverlay(false);
            this.auth.setSessionExpired(false);
            this.store.setPSPDFKitOverlay(false);
            
            this.alert.showSuccess(response.message);
            this.store.setSessionLoading(false);
          } catch(e) { 
            // Sometimes a derived key from a wrong password will cause Web Crypto to throw.
            console.log(e);
          }
        } else {
          this.alert.showError(response.response.data.message);
          this.store.setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        this.store.setLoading(false);
      });
    },

    getUsersForDocument(documentId) {
      const formData = new FormData();
      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "getUsersForDocument");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      formData.append("itemId", documentId);

      this.store.setLoading(true);
      axios.post(UserData.getBaseUrl(), formData).then((response) => {
        this.users = response.data.usersList;
        this.initialUsersList = response.data.usersList;
        this.trigger = !this.trigger;
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    getPublicNotesForDocument(isRefresh = 0) {
      const formData = new FormData();
      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "getPublicNotesForDocument");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      formData.append("itemId", this.current_pdf.itemId);
      formData.append("from", 0);
      formData.append("count", 10);
      formData.append("isRefresh", isRefresh);
      formData.append("itemParentId", this.current_pdf.itemParentId);

      if (!isRefresh) {
        this.store.setLoading(true);
      }

      this.public_notes = [];
      this.public_notes_count = 0;

      axios.post(UserData.getBaseUrl(), formData).then((response) => {
        if (response.data.success == 1) {
          this.public_notes = response.data.noteList;
          this.public_notes_count = response.data.totalNoteCount;
        }

        this.store.setLoading(false);
        if (!isRefresh)
        {
          this.unload();
          this.getAnnotations();
        }
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    getUserAnnotation(annotationId) {
      this.checkSharedAnnotations(annotationId);
    },

    checkSharedAnnotations(annotationId) {
      const form = new FormData();
      let document_id = UserData.getDocumentId() ? UserData.getDocumentId() : this.index.getDocumentId;

      form.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      form.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      form.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      form.append("model", "checkSharedAnnotations");
      form.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      form.append("documentId", document_id);
      form.append("annotationId", annotationId);

      this.store.setLoading(true);
      axios.post((process.env.NODE_ENV == 'production' ? UserData.getProductionAdminBaseUrl() : UserData.getLocalAdminBaseUrl()) + '/import-shared-annotation', form, { headers: authHeader() }).then((response) => {
        if (response.status == 200) {
          this.shared_annotations = response.data;

          this.alert.showSuccess("Annotations Imported Successfully");
          this.store.setLoading(false);
          this.store.setDialogUsers(false);

          if (this.shared_annotations && this.shared_annotations.ufa_id) {
            var data = this.shared_annotations && this.shared_annotations.instant_json ? this.shared_annotations.instant_json : null;

            try {
              this.unload();
              this.load(JSON.parse(JSON.stringify(data)));
              this.getUsersForDocument(document_id);
            } catch (error) {
              this.unload();
              this.load(null);
            }
          }
        } else {
          if (response.response.status == 403 && !this.session_overlay) {
            this.store.setPSPDFKitOverlay(true);
            this.session = true;
          } else {
            this.load(null);
          }
        }
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    async loadSharedAnnotations() {
      const form = new FormData();
      form.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      form.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      form.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      form.append("model", "loadSharedAnnotations");
      form.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      form.append("documentId", UserData.getDocumentId() ? UserData.getDocumentId() : this.index.getDocumentId);

      return await new Promise((resolve, reject) => {
        axios.post((process.env.NODE_ENV == 'production' ? UserData.getProductionAdminBaseUrl() : UserData.getLocalAdminBaseUrl()) + '/load-shared-annotations', form, { headers: authHeader() }).then((response) => {
          if (response.status == 200) {
            this.selected = response.data != null ? response.data : [];
            this.selected = this.selected.filter(item => item.id_user == this.user.userInfo.userId);
            this.selected_ids = this.selected.map(item => item.from_id_user);
          } else {
            if (response.response.status == 403 && !this.session_overlay) {
              this.store.setPSPDFKitOverlay(true);
              this.session = true;
            }
          }
        })
        .catch((e) => {
          console.log("Error", e);
        });
      });
    },
  },

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },

    eboardUpdates() {
      return this.auth.getEboardUpdates;
    },

    currentRouteName() {
      return this.$route.name;
    },

    current_pdf() {
      return this.store.getCurrentPdf;
    },

    open_pdf() {
      return this.store.getOpenPdf;
    },

    signature_document: {
      get() {
        return this.store.getSignatureDocument;
      },
      set(val) {
        this.store.setSignatureDocument(val);
      },
    },

    signature_text: {
      get() {
        return this.store.getSignatureText;
      },
      set(val) {
        this.store.setSignatureText(val);
      },
    },

    selected_annotation: {
      get() {
        return this.store.getSelectedAnnotation;
      },
      set(value) {
        this.store.setSelectedAnnotation(value);
      },
    },

    checkViewState() {
      let status = false;

      switch (this.currentRouteName) {
        case 'Approvals':
          status = true;
        case 'Compliance':
          status = true;
        case 'Plan':
          status = true;
      }

      return status;
    },

    pdf_type: {
      get() {
        return this.store.getPdfType;
      },
      set(value) {
        this.store.setPdfType(value);
      },
    },

    dialog_approval() {
      return this.store.getDialogApproval;
    },

    approval_document() {
      return this.store.getApprovalDocument;
    },

    document_id() {
      return this.store.getDocumentId;
    },

    briefcase_url() {
      return this.store.getBriefcaseUrl;
    },

    session_timeout() {
      return localStorage.getItem('admin_session_timeout');
    },

    session_overlay: {
      get() {
        return this.store.getSessionOverlay;
      },
      set(value) {
        this.store.setSessionOverlay(value);
      },
    },

    pspdfkit_overlay: {
      get() {
        return this.store.getPSPDFKitOverlay;
      },
      set(value) {
        this.store.setPSPDFKitOverlay(value);
      },
    },

    digital_signatures: {
      get() {
        return this.auth.getDigitalSignatures;
      },
      set(value) {
        this.auth.setDigitalSignatures(value);
      },
    },

    cols () {
      const { lg, sm } = this.$vuetify.display;
      return lg ? [2, 9] : sm ? [2, 9] : [5, 6];
    },
  },

  watch: {
    pdfUrl() {
      this.unload();
      this.startLoading();
    },
  },

  beforeUnmount() {
    this.unload();
  },
};
</script>

<style scoped>
.container {
  height: 100vh;
}
</style>
