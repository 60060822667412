<template>
  <v-menu>
    <template v-slot:activator="{ props }">
      <v-btn
        variant="outlined" 
        v-bind="props"
        class="btn"
        :style="filterStyle"
      >
        {{ statusName }}
        <span v-if="approvalSort.field == 'approvalId' && (approvalSort.status == 0 || approvalSort.status == 1 || approvalSort.status == 2 || approvalSort.status == 3)">({{ approvalSort.desc ? "desc" : "asc" }})</span>
      </v-btn>
    </template>
    <v-list class="mt-2">
      <v-list-item style="display: block;">
        <v-list-item-title
          v-for="status in approvalStatus"
          :key="status.approvalStatusId"
          :style="'padding: 12px; font-size: 16px; cursor: pointer; color: ' + status.approvalStatusColor + ';'"
          v-on:click="filterApprovalList(status.approvalStatusId)"
          >{{ status.approvalStatusName }}
          <span v-if="approvalSort.field == 'approvalId' && approvalSort.status == status.approvalStatusId"
            >({{ approvalSort.desc ? "desc" : "asc" }})</span
          >
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { useAuthStore } from '../stores/auth';

export default {
  name: 'ApprovalStatus',

  props: {
    approvalStatus: {
      type: Array,
      default: () => [],
    },
    approvalSort: {
      type: Object,
      default: () => {},
    },
    statusName: {
      type: String,
      default: "All",
    },
    filterStyle: {
      type: String,
      default: "All",
    },
  },

  data: () => ({
    store: useAuthStore(),
  }),

  methods: {
    filterApprovalList(statusId) {
      this.$emit('filter_list', statusId);
    },
  },
}
</script>

<style scoped>
.action-list:nth-child(odd) {
  background-color: rgba(71, 183, 83, .2);
}
.action-list:hover {
  background-color: rgba(255, 255, 255, 255) !important;
}
</style>