<template>
  <div>
    <!-- PARTICIPANTS MODAL -->
    <div class="text-center">
      <v-dialog v-model="dialog_results" max-width="750px">
        <v-card>
          <div
            style="border-top: 5px solid rgb(86, 182, 229);"
          >
            <v-card-title>
              <v-toolbar color="transparent" flat class="pt-2">
                <v-col cols="12" sm="6" class="mt-5">
                  <v-toolbar-title
                    class="text-uppercase font-weight-bold text--darken-1 justify-center"
                    style="color: rgb(86, 182, 229);"
                  >
                    <v-icon class="mr-2 mt-n1"
                      >mdi-file-chart-outline</v-icon
                    >Vote Results
                  </v-toolbar-title>
                  <p class="ml-9 mt-n2">{{ description }}</p>
                  <v-divider class="mx-4" inset vertical></v-divider>
                </v-col>
              </v-toolbar>
            </v-card-title>
            <v-card-text>
              <div style="height: 92%; overflow: auto">
                <v-card class="grey lighten-2" style="padding-bottom: 20px" v-if="results.length > 0">
                  <div
                    style="overflow: auto"  
                  >
                    <v-table
                      fixed-header
                      class="table-striped"
                    >
                      <thead>
                        <tr>
                          <th>
                            Directors/Votes
                          </th>
                          <th
                            v-for="option in options"
                            :key="option.optionId"
                            class="text-center"
                          >
                            {{ option.optoinName }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, index) in results" 
                          :key="index"
                        >
                          <td
                            style="
                              overflow: hidden;
                              text-overflow: ellipsis;
                            "
                          >
                            {{ item.name }}
                          </td>
                          <td
                            v-for="option in options"
                            :key="option.optionId"
                            class="text-center"
                            style="
                              overflow: hidden;
                              text-overflow: ellipsis;
                            "
                          >
                            <v-icon
                              v-if="option.optionId == item.optionId"
                              icon="mdi-check-bold"
                            />
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td
                            style="
                              overflow: hidden;
                              text-overflow: ellipsis;
                            "
                          >
                            Total
                          </td>
                          <td
                            v-for="option in options"
                            :key="option.optionId"
                            class="text-center"
                            style="
                              overflow: hidden;
                              text-overflow: ellipsis;
                            "
                          >
                            {{ optionsCount(option.optionId) }}
                          </td>
                        </tr>
                      </tfoot>
                    </v-table>
                  </div>
                </v-card>
                <v-card class="grey lighten-2" style="padding-bottom: 20px" v-else>
                  <v-card-text
                    class="ml-1"
                    style="
                      color: rgb(72, 61, 139);
                      font-size: 19px;
                      font-weight: bold;
                    "
                  >
                    No Agenda Results Found
                  </v-card-text>
                </v-card>
              </div>
            </v-card-text>
            <v-card-actions class="grey" @click="closeDialogResults">
              <v-btn 
                block
                color="rgb(86, 182, 229)"
              >
                Close
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
var moment = require("moment");
import { useAuthStore } from "../../stores/auth";
import { useIndexStore } from "../../stores/index";
import { useAlertStore } from "../../stores/alert";

export default {
  name: "VoteResults",
  props: {
    description: {
      type: String,
      default: "",
    },
    results: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    valid: false,
    dialog: false,
    interestDisclosed: "",
    interestConflict: "",
    hasConflict: false,
    confirm: false,
    comments: "",
    conditions: "",
    password: "",
    rules: {
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
      selectRules: [(v) => !!v || "Select is required"],
      textRules: [
        v => !!v || 'Field is required',
        v => (v && v.length >= 3) || 'Min 3 characters',
      ],
      characterRules: [
        v => (v && v.length >= 3) || 'Min 3 characters',
      ],
    },
    token: localStorage.getItem("token") ? JSON.stringify(localStorage.getItem("token")).slice(1, -1) : "",
    auth: useAuthStore(),
    store: useIndexStore(),
    alert: useAlertStore(),
  }),

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },

    dialog_results: {
      get: function () {
        return this.store.getDialogResults;
      },
      set: function (value) {
        this.store.setDialogResults(value);
      }
    },
  },
  
  methods: {
    formatDate(date) {
      return moment.unix(date).format("DD MMM YYYY");
    },

    validate() {
      return this.$refs.form.validate();
    },

    resetForm() {
      return this.$refs.form.reset();
    },

    resetValidation() {
      return this.$refs.form.resetValidation();
    },

    closeDialogResults() {
      this.store.setDialogResults(false);
      document.querySelector('html').classList.remove('v-overlay-scroll-blocked');
    },

    getAttendanceStatus(status) {
      var index = 0;
      switch(status) {
        case "1":
          index = 1;
          break;
        case "2":
          index = 2;
          break;
        case "3":
          index = 3;
          break;
      }

      return index;
    },
    
    optionsCount(optionId) {
      var option = this.results.filter((option) => option.optionId == optionId);
      return option.length;
    },
  },
};
</script>

<style scoped>
.table-striped th, td {
  border: 1px solid black;
  padding: 5px !important;
}
.table-striped a:active {
  color: rgb(86, 182, 229);
}

.table-striped tbody:nth-child(odd) {
  background-color: rgba(0, 0, 0, .05);
}

.table-striped tbody:hover {
  background-color: rgba(255, 255, 255, 255) !important;
}
</style>