<template>
  <div style="width: auto; height: auto; cursor: default;">
    <div v-if="!closePDF && !closeDocument">
      <!-- TOP -->
      <div
        :style="
          'width: 98%; margin: 1%; border: 1px solid #f2f2f2; height: auto; display: flex; padding: 1%; padding-bottom: 0px;' 
          + getStatusBorder(meeting.meetingStatusId)
        "
      >
        <span
          style="display: inline; cursor: pointer;"
          @click="switchToView01"
        >
          <p style="font-size: 19px;">{{ meeting.itemName }}</p>
          <p style="color: grey;">{{ meeting.itemVenueTitle }}</p>
          <p style="color: grey;">
            <span 
              style="font-weight: bold;"
              :class="getStatusClass(meeting.meetingStatusId)"
            >
              {{ meeting.meetingStatusName }}
            </span> - {{ formatDate(meeting.itemVenueDate) }}
          </p>
        </span>
        <span style="flex-grow: 1"></span>
        <span style="display: inline">
          <table style="margin-top: 30px; font-size: 17px;" v-if="!open_pdf">
            <tr>
              <td style="padding-right: 20px;" v-if="meeting.conferenceList && meeting.conferenceList.length > 0">
                <v-btn
                  class="btn mt-n3"
                  variant="text"
                  @click="startVirtualMeeting(meeting.conferenceList)"
                >
                  <v-icon size="35" style="color: rgb(23, 162, 184);"
                    >mdi-calendar-start</v-icon
                  >

                  <span v-if="meeting.conferenceList[0].isHost == 1">Start</span>
                  <span v-else>Join</span>
                </v-btn>
              </td>
              <td 
                style="padding-right: 20px; cursor: pointer" 
                @click="getParticipants(meeting)" 
              >
                <v-icon size="35" style="color: rgb(86, 182, 229);"
                  >mdi-account-group</v-icon
                >
                Participants
              </td>
              <td style="padding-right: 20px">
                <v-menu location="bottom">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      class="btn mt-n3"
                      v-bind="props"
                      variant="text"
                      :loading="store.getLoading"
                    >
                      <v-icon size="35" style="color: rgb(86, 182, 229);"
                        >mdi-email</v-icon
                      >
                      {{ meeting.itemUserAction ? getRSVP(meeting.itemUserAction, meeting.itemAction) : 'RSVP' }}
                    </v-btn>
                  </template>

                  <div class="mt-2">
                    <RSVP 
                      :actions="meeting.itemAction" 
                      :meeting-id="meeting.itemId" 
                      :user-action="meeting.itemUserAction"
                      :event-id="meeting.eventId"
                    />
                  </div>
                </v-menu>
              </td>
              <td style="padding-right: 20px;">
                <v-btn
                  class="btn mt-n3"
                  variant="text"
                  @click="openRCIDialog"
                >
                  <v-icon size="35" style="color: rgb(86, 182, 229);"
                    >mdi-message-text</v-icon
                  >
                  RCI
                </v-btn>
              </td>
            </tr>
          </table>
        </span>
      </div>

      <div class="text-center">
        <MeetingParticipants :participants="meetingParticipants" :options="attendanceOptions" />
      </div>

      <div class="text-center">
        <ApprovalDetails :participants="meetingParticipants" :options="attendanceOptions" />
      </div>
      <!-- END OF TOP -->

      <!-- LOWER -->
      <div
        v-if="meetingAgendas.length > 0"
        style="width: 98%; margin: 1%; background-color: #fff; border: 2px solid rgb(86, 182, 229); font-size: 16px; height: auto; border-top: 0px;"
      >
        <div style="background-color: rgb(220, 220, 220); padding: 7px;">
          Agenda
        </div>
        <div
          v-for="item in meetingAgendas"
          :key="item.itemId"
        >
          <div style="display: flex; background-color: rgb(247, 247, 247); padding: 7px; padding-left: 16px;">  
            <span style="display: inline; cursor: pointer;">
              <a @click="openAgendaComments(item)">
                {{ item.itemName }}
              </a>
            </span>
            <span style="flex-grow: 1"></span>
            <span style="display: inline;" v-if="!open_pdf && item.agendaTypeId == 3 && meetingAgendaVoteActions && meetingAgendaVoteActions.length > 0">
              <p 
                style="margin: 0px;" 
                class="text-right" 
              >
                <v-tooltip
                  location="top"
                  v-for="action in meetingAgendaVoteActions"
                  :key="action.itemId"
                >
                  <template v-slot:activator="{ props }">
                    <v-icon 
                      :color="getActionColor(action.itemId)"
                      v-bind="props"
                      :style="action.itemId == item.userVoteActionId ? 'cursor: pointer; border: 1px solid ' + getActionColor(action.itemId) : 'cursor: pointer;'"
                      class="mr-3"
                      @click="agendaVoteAction(item, action)"
                    >
                      {{ getActionIcon(action.itemId) }}
                    </v-icon>
                    <span class="mr-3">
                      {{ item.votesCount.find(item => item.voteActionId == action.itemId).totalVotesCount }} 
                    </span> 
                  </template>
                  <span>{{ action.itemName }}</span>
                </v-tooltip>
              </p>
            </span>
            <span style="display: inline;" v-if="!open_pdf && item.agendaTypeId == 3 && meetingAgendaVoteActions && meetingAgendaVoteActions.length > 0">
              <p 
                style="margin: 0px;" 
                class="text-right mr-3" 
                @click="agendaVoteResults(item)"
              >
                <v-tooltip
                  location="top"
                >
                  <template v-slot:activator="{ props }">
                    <v-icon 
                      color="rgb(86, 182, 229)"
                      v-bind="props"
                      style="cursor: pointer;"
                    >
                      mdi-chart-box-outline
                    </v-icon>
                    <span style="color: rgb(86, 182, 229); cursor: pointer;">
                      Results
                    </span>
                  </template>
                  <span>Agenda Vote Results</span>
                </v-tooltip>
              </p>
            </span>
            <span style="display: inline;" v-if="!open_pdf && item.agendaTypeId == 5">
              <p 
                style="margin: 0px;" 
                class="text-right mr-3" 
                @click="agendaOpenApproval(item)"
              >
                <v-tooltip
                  location="top"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                      color="rgb(86, 182, 229)"
                      v-bind="props"
                      variant="outlined"
                      :loading="loading[item.itemId]"
                    >
                      Approval
                    </v-btn>
                  </template>
                  <span>Open Approval</span>
                </v-tooltip>
              </p>
            </span>
            <span style="display: inline;">
              <p style="margin: 0px;" class="text-right">{{ formatTime(item.itemStartTime) }}</p>
              <p style="margin: 0px;" class="text-right">{{ item.itemPresenter }}</p>
            </span>
          </div>

          <div
            v-if="item.itemArray && item.itemArray.length > 0"
            style="cursor: pointer; margin-bottom: 6px; padding: 7px; padding-left: 26px; width: 100%;"
          >
            <div>
              <v-table>
                <tbody
                  v-for="(item, index) in item.itemArray" 
                  :key="index"
                >
                  <tr>
                    <td
                      style="overflow: hidden; text-overflow: ellipsis;"
                    >
                      <span class="input-group-addon">
                        <v-progress-circular
                          v-if="loading[item.itemId]"
                          indeterminate
                          color="rgb(86, 182, 229)"
                          class="mr-2"
                        ></v-progress-circular> 
                        <v-icon
                          v-else
                          :color="fileIds.includes(item.itemId) ? 'rgb(227, 58, 58)' : 'rgb(86, 182, 229)'"
                          class="mr-2"
                          size="large"
                          :icon="globalMethods.getDocumentIcon(item.itemExtension)"
                        />
                      </span>

                      <file-options
                        :file="item"
                        :file_color="'rgb(86, 182, 229)'"
                        @open_pdf="open_pdf ? switchDocument(item) : setDocument(item)"
                        v-if="item.itemExtension == 'pdf'"
                        :select_type="true"
                        :pdf_type="2"
                      ></file-options>

                      <span 
                        v-else
                        class="name"
                        @click="open_pdf ? switchDocument(item) : setDocument(item)"
                      >
                        {{ item.itemName }}
                      </span>

                      <span 
                        v-if="!open_pdf && (is_admin == 1) && item.itemExtension == 'pdf'"
                        class="float-right"
                      >
                        <v-tooltip
                          location="top"
                        >
                          <template v-slot:activator="{ props }">
                            <v-btn
                              color="rgb(86, 182, 229)"
                              v-bind="props"
                              variant="outlined"
                              size="small"
                              :loading="loading[item.itemId]"
                              @click="generateSignature(item)"
                            >
                              Add Signature
                            </v-btn>
                          </template>
                          <span>Add Signature File</span>
                        </v-tooltip>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </div>
          </div>
          <div
            v-else
            class="text-center"
            style="margin-bottom: 6px; padding: 7px; padding-left: 26px;"
          >
            No Agenda Documents Found
          </div>
        </div>
      </div>
      <div
        v-else
        style="width: 98%; margin: 1%; background-color: #fff; border: 2px solid rgb(86, 182, 229); font-size: 16px; height: auto; border-top: 0px;"
      >
        <div style="background-color: rgb(220, 220, 220); padding: 7px;">
          Agenda
        </div>
        <div
          class="text-center"
          style="margin-bottom: 6px; padding: 7px; padding-left: 26px;"
        >
          No Agenda Found For This Meeting
        </div>
      </div>

      <!-- VOTE RESULTS MODAL -->
      <div class="text-center">
        <VoteResults :description="agendaVoteDescription" :options="agendaVoteOptionList" :results="agendaVoteResultData" />
      </div>

      <!-- APPROVAL DETAILS MODAL -->
      <div class="text-center">
        <ApprovalDetails 
          :meeting="meeting" 
          :load="trigger" 
          @approval_updated="agendaOpenApproval" 
          @approval_document="setDocument" 
          :briefcase-files="fileIds"
          :approval-loading="loading"
        />
      </div>

      <!-- AGENDA COMMENTS MODAL -->
      <div class="text-center">
        <AgendaComments v-if="dialog_agenda_comments" :agenda="selected_agenda" :comments="agenda_comments" @add_comment="getAgendaComments" />
      </div>
    </div>
    <!-- END OF LOWER -->

    <div
      v-if="closePDF || closeDocument"
      style="position: absolute; background-color: #f5f5f5; width: 100%; height: 100%; padding: 1%; overflow: hidden; margin-top: 1%; margin-right: 1%; z-index: 2;"
    >
      <v-card>
        <v-card-text>
          <v-main>
            <v-container fluid>
              <component
                :pdf-url="current_pdf.itemUrl"
                :license-key="LICENSE_KEY"
                :base-url="baseUrl"
                :is="document_component"
                :document-url="current_document.itemUrl"
                :document-type="current_document.itemExtension"
                :document-title="current_document.itemName"
                :viewer-color="'rgb(86, 182, 229)'"
                @signature_file="openSignatureFile"
                @close_pdf="closePdf"
                @close_document="documentClosed"
                @switch_pdf="switchDocument"
                @switch_document="switchDocument"
                :parent_select_type="true"
                :parent_pdf_type="2"
                :list-width="'100%'"
                :briefcase-files="fileIds"
                @briefcase_document="briefcaseDocument"
              />
            </v-container>
          </v-main>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
var moment = require("moment");
import axios from "axios";
import UserData from "../repository/UserData";
import { useAuthStore } from "../../stores/auth";
import { useIndexStore } from "../../stores/index";
import { useAlertStore } from "../../stores/alert";
import RSVP from '../Forms/RSVP.vue';
import MeetingParticipants from '../Forms/MeetingParticipants.vue';
import VoteResults from '../Forms/VoteResults.vue';
import PSPDFKit from '../PSPDFKit.vue';
import ApprovalDetails from '../Forms/ApprovalDetails.vue';
import AgendaComments from '../Forms/AgendaComments.vue';
import FileOptions from "../Cards/FileOptions.vue";
import DocumentViewer from "../Cards/DocumentViewer.vue";
import GlobalMethods from "../repository/GlobalMethods";
import View03 from "./View03.vue";

const LICENSE_KEY =
  "QvMr6j5706r3kYlOiVvJgEwu4fZnTL1fYz4t6paoBV5PekugaffzJnLtX5TczMS65OKAI1iIaSi06_l76UgF3kyKauJ9vaMZFuU3_oDplMFty6AhBhWPh80M7qoSa8xjtux3NqNrfqybICplLfHezwjhABIkPy6XxBMQ_uPU87_C5pMBXlc8A2PI_ShMjfhst6O_C0y9jyeMJ2DYudM3liFVsp90A0dOY51LM2Q3xV3jfcJ7K39FKpnKU_atpn1Ig8HXcmWdGQMjCXyquwVu9i2J850rGqBgFKJJFGixxnU3sZ__tA7Mb3aX8T2nXPlEnamtAhNf-DD1sEtqXHp5q5xxsFFuJDVh82tXKfd4oX8AkzWLd_-UVg_h_6ATML30e8fiQXSVcN0mmlZH5XYUv0jB5cHEyuIaGZac7gCNRn32pchJNpIwEdgHzWDRBQxxCR7F8b6z3Xs3yXJ6y9EhhrnKmjNYkMCdFJ0qNhI8zF8RhAbLA-uKtrzMy7hXLrlpuIHdGaCXMm7gA9iBpKiqwHIrX83rCFoVYUGM4BqNkim3HN8YJzZRDDI-ZHzdy7CcanSuvbe3NSxW5daeHdGVvg==";

export default {
  name: "View02",
  components: {
    RSVP,
    MeetingParticipants,
    VoteResults,
    pspdfkit: PSPDFKit,
    ApprovalDetails,
    AgendaComments,
    FileOptions,
    "document-viewer": DocumentViewer,
    "view-03": View03,
},

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },

    meeting() {
      return this.store.getCurrentMeeting;
    },

    dialog_agenda_comments() {
      return this.store.getDialogAgendaComments;
    },

    current_agenda() {
      return this.store.getCurrentAgenda;
    },

    dialog_approval() {
      return this.store.getDialogApproval;
    },

    close_pdf() {
      return this.store.getClosePdf;
    },

    open_pdf() {
      return this.store.getOpenPdf;
    },

    current_pdf() {
      return this.store.getCurrentPdf;
    },

    current_document() {
      return this.store.getCurrentDocument;
    },

    document_component() {
      return this.store.getDocumentComponent;
    },

    is_admin() {
      return localStorage.getItem('is_admin');
    },

    overlay_type: {
      get() {
        return this.store.getOverlayType;
      },
      set(value) {
        this.store.setOverlayType(value);
      },
    },

    session_overlay: {
      get() {
        return this.store.getSessionOverlay;
      },
      set(value) {
        this.store.setSessionOverlay(value);
      },
    },
  },

  data: () => ({
    trigger: false,
    closePDF: false,
    closeDocument: false,
    title: "",
    type: "",
    pdf: "",
    LICENSE_KEY: LICENSE_KEY,
    baseUrl: "",
    meetingAgendas: [],
    meetingAgendaVoteActions: [],
    meetingAgendaVoteTypes: [],
    meetingParticipants: [],
    attendanceOptions: [],
    agendaVoteDescription: "",
    agendaVoteOptionList: [],
    agendaVoteResultData: [],
    auth: useAuthStore(),
    store: useIndexStore(),
    alert: useAlertStore(),
    token: localStorage.getItem("token") ? JSON.stringify(localStorage.getItem("token")).slice(1, -1) : "",
    selected_agenda: {},
    agenda_comments: [],
    files: [],
    fileIds: [],
    globalMethods: GlobalMethods,
    loading: {},
  }), 

  created() {
    this.store.setDialogResults(false);
    this.store.setDialogParticipants(false);
    this.store.setDialogApproval(false);
    this.store.setDialogAgendaComments(false);
    this.getMeetingAgendas(this.meeting);
  },

  methods: {
    formatDate(date) {
      return moment(date).format("DD MMM YYYY, h:mm a");
    },

    formatTime(date) {
      return moment(date).format("hh:mm A");
    },

    getRSVP(itemUserAction, itemAction) {
      let action = 'No Action';
      action = itemAction.find(item => item.actionId == itemUserAction);
      return action.actionName;
    },

    openRCIDialog() {
      this.store.setRCIType(1);
      this.store.setDialogRCI(true);
    },

    switchToView01() {
      this.store.setLoading(false);
      this.store.setCurrentComponent('view-01');
      this.store.setOpenPdf(false);
    },

    closePdf() {
      if (this.close_pdf) {
        this.agendaOpenApproval(this.current_agenda);
      }
      
      this.closeDocument = false;
      this.closePDF = false;
      this.shareDocument = false;
      this.store.setLoading(false);
      this.store.setOpenPdf(false);
      this.store.setApprovalDocument(false);
      this.store.setCurrentPdf({});
    },

    documentClosed() {
      if (this.close_pdf) {
        this.agendaOpenApproval(this.current_agenda);
      }

      this.closeDocument = false;
      this.closePDF = false;
      this.store.setLoading(false);
      this.store.setDialogDocumentViewer(false);
      this.store.setOpenPdf(false);
      this.store.setApprovalDocument(false);
      this.store.setCurrentDocument({});
    },

    openAgendaComments(item) {
      this.selected_agenda = item;
      this.getAgendaComments();
      this.store.setDialogAgendaComments(true);
    },

    getAgendaComments() {
      const formData = new FormData();
      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "getCommentsForAgenda");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      formData.append("agendaId", this.selected_agenda.itemId);
      formData.append("meetingId", this.meeting.itemId);
      formData.append("from", 0);
      formData.append("count", 20);
      formData.append("isRefresh", false);

      axios.post(UserData.getBaseUrl(), formData).then((response) => {
        this.agenda_comments = response.data.commentList;
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    generateSignature(item) {
      if (!this.open_pdf) {
        this.closePDF = !this.closePDF;
      }

      this.closeDocument = false;
      this.store.setDialogDocumentViewer(false);
      this.store.setDocumentComponent('view-03');
      this.store.setCurrentPdf(item);
      this.store.setCurrentDocument(item);
      UserData.setDocumentId(item.itemId);
      this.store.setDocumentId(item.itemId);
      this.store.setOpenPdf(true);
    },

    setDocument(item) {
      if (item.itemExtension == "pdf") {
        this.briefcaseDocument(item);
      } else {
        this.openDocument(item);
      }
    },

    openPdf(item, url = null, signature = false) {
      if (!this.open_pdf) {
        this.closePDF = !this.closePDF;
      }

      if (!signature) {
        this.store.setBriefcaseUrl(url);
      }
      
      this.closeDocument = false;
      this.store.setDialogDocumentViewer(false);
      this.store.setDocumentComponent('pspdfkit');
      this.store.setCurrentPdf(item);
      this.store.setCurrentDocument(item);
      UserData.setDocumentId(item.itemId);
      this.store.setDocumentId(item.itemId);
      this.store.setOpenPdf(true);
    },

    openDocument(item) {
      if (item.itemExtension != 'No File Extension') {
        if (!this.open_pdf) {
          this.closeDocument = !this.closeDocument;
          this.store.setDialogDocumentViewer(true);
        }
        
        this.closePDF = false;
        this.store.setDocumentComponent('document-viewer');
        this.store.setCurrentDocument(item);
        UserData.setDocumentId(item.itemId);
        this.store.setDocumentId(item.itemId);
        this.store.setOpenPdf(true);
      } else {
        if (item.itemSize > 0) {
          this.alert.showError(item.itemExtension);
        } else {
          this.alert.showError('Error Opening File');
        }
      }
    },

    openSignatureFile(item) {
      if (item.itemExtension == "pdf") {
        this.store.setSignatureDocument(true);
        this.store.setPdfType(false);
        this.openPdf(item, null, true);
      }
    },

    switchDocument(item) {
      if (item.itemExtension == "pdf") {
        this.store.setBriefcaseUrl(null);
        this.openPdf(item);
      } else {
        this.openDocument(item);
      }
    },

    briefcaseDocument(item) {
      if (item.itemExtension) {
        this.loading[item.itemId] = true;
        this.globalMethods.downloadEbrDocument(item.itemUrl).then(async (response) => {
          if (response.status == 200) {
            let fileType = response.headers['content-type'];
            let fileData = new Blob([response.data], { type: fileType });
            let blob = window.URL.createObjectURL(fileData);
            this.openPdf(item, blob);
            this.loading[item.itemId] = false;
          } else {
            this.loading[item.itemId] = false;
            console.log("Response: ", response);
          }
        }).catch((e) => {
          this.loading[item.itemId] = false;
          console.log("Error: ", e);
        });
      } else {
        this.alert.showInfo("Briefcasing Folders is Under Development");
      }
    },

    startVirtualMeeting(conferenceList) {
      let conference = conferenceList[0];
      if (conference.password !== "" && conference.conferenceId !== "") {
        this.store.setCurrentConference(conference);
        this.$router.push('/initiate-meeting/' + conference.conferenceId);
      }
    },

    getActionIcon(id) {
      var icon = '';
      switch(id) {
        case "1":
          icon = 'mdi-check';
          break;
        case "2":
          icon = 'mdi-close-thick';
          break;
        case "3":
          icon = 'mdi-cancel';
          break;
      }

      return icon;
    },

    getActionColor(id) {
      var color = '';
      switch(id) {
        case "1":
          color = '#009944';
          break;
        case "2":
          color = '#CF000F';
          break;
        case "3":
          color = '#63C0DF';
          break;
      }

      return color;
    },

    getStatusClass(id) {
      var status = '';
      switch(id) {
        case "1":
          status = 'text-success';
          break;
        case "2":
          status = 'text-info';
          break;
        case "3":
          status = 'text-warning';
          break;
        case "5":
          status = 'text-teal';
          break;
        case "6":
          status = 'text-purple';
          break;
        case "7":
          status = 'text-info';
          break;
        default:
          status = 'text-black';
          break;
      }

      return status;
    },

    getStatusBorder(id) {
      var status = 'border-left: 5px solid ';
      switch(id) {
        case "1":
          status += 'rgb(40, 167, 69); background-color: rgb(40, 167, 69, .1);';
          break;
        case "2":
          status += 'rgb(23, 162, 184); background-color: rgb(23, 162, 184, .1);';
          break;
        case "3":
          status += 'rgb(255, 193, 7); background-color: rgb(255, 193, 7, .1);';
          break;
        case "5":
          status += 'rgb(0, 150, 136); background-color: rgb(0, 150, 136, .1);';
          break;
        case "6":
          status += 'rgb(156, 39, 176); background-color: rgb(156, 39, 176, .1);';
          break;
        case "7":
          status += 'rgb(23, 162, 184); background-color: rgb(23, 162, 184, .1);';
          break;
        default:
          status += 'rgb(0, 0, 0); background-color: rgb(0, 0, 0, .1);';
          break;
      }

      return status;
    },

    getMeetingAgendas(item) {
      axios.post(UserData.getBaseUrl(), this.getAgendasForMeeting(item.itemId)).then((response) => {
        if (response.data.data && response.data.data.agendaList) {
          this.meetingAgendas = response.data.data.agendaList;
          this.meetingAgendaVoteActions = response.data.data.agendaVoteActions;
          this.meetingAgendaVoteTypes = response.data.data.agendaVoteTypes;
        }
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    getAgendasForMeeting(meetingId = 0) {
      const formData = new FormData();

      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "getAgendasForMeeting");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      formData.append("meetingId", meetingId);

      return formData;
    },

    getParticipants(item) {
      axios.post(UserData.getBaseUrl(), this.getParticipantsForMeeting(item.itemId)).then((response) => {
        this.meetingParticipants = response.data.usersList;
        this.attendanceOptions = response.data.attendanceOptions;
        this.store.setDialogParticipants(true);
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    getParticipantsForMeeting(meetingId = 0) {
      const formData = new FormData();

      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "getParticipantListForMeeting");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      formData.append("itemId", meetingId);

      return formData;
    },

    containsKey(obj, key) {
      return Object.keys(obj).includes(key);
    },

    agendaOpenApproval(agenda) {  
      const formData = new FormData();
      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "getAgendaApprovals");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      formData.append("agendaId", agenda.itemId);

      this.loading[agenda.itemId] = true;
      axios.post(UserData.getBaseUrl(), formData).then((response) => {
        if (response.data.success == 1 && response.data.approvals.length > 0) {
          var approval = response.data.approvals[0];
          this.store.setCurrentAgenda(agenda);
          UserData.setApprovalId(approval.approvalId);
          this.store.setApprovalId(approval.approvalId);
          this.store.setCurrentApproval(approval);
          this.trigger = !this.trigger;
          this.loading[agenda.itemId] = false;
        } else {
          this.alert.showInfo(response.data.message);
        }
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    agendaVoteAction(agenda, action) {
      const formData = new FormData();
      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "updateVoteForAgenda");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      formData.append("agendaId", agenda.itemId);
      formData.append("agendaVoteTypeId", action.itemId);
      formData.append("agendaVoteActionId", action.itemId);

      axios.post(UserData.getBaseUrl(), formData).then((response) => {
        if (response.data.success == 1) {
          this.getMeetingAgendas(this.meeting);
          this.alert.showSuccess(response.data.message);
        } else {
          this.alert.showInfo(response.data.message);
        }
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    agendaVoteResults(item) {
      const formData = new FormData();
      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "getVoteResults");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      formData.append("agendaId", item.itemId);

      axios.post(UserData.getBaseUrl(), formData).then((response) => {
        if (response.data.success == 1) {
          this.agendaVoteDescription = response.data.description;
          this.agendaVoteOptionList = response.data.voteOptionList;
          this.agendaVoteResultData = response.data.voteResultData;
          this.store.setDialogResults(true);
        } else {
          this.alert.showInfo(response.data.message);
        }

        return response;
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000000;
}

a:hover {
  color: rgb(86, 182, 229) !important;
}

.name {
  cursor: pointer !important;
}

.name:hover {
  color: rgb(86, 182, 229) !important;
}
</style>