import axios from "axios";
import GlobalMethods from "./GlobalMethods";

const SLACK_WEB_HOOK_URL = process.env.NODE_ENV == 'production' ? 'https://hooks.slack.com/services/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' : '';

class SlackNotificationChannel {
  static successMessage = (message, method, data) => {
    const currentTimestamp = new Date().getTime();

    let payload = {
      attachments: [
        {
          color: "#5cb85c",
          title: "Success Message",
          text: message,
          ts: currentTimestamp,
          fields: [
            {
              "title": "Method",
              "value": method,
              "short": false
            },
            {
              "title": "User",
              "value": Object.keys(data).length > 0 ? data.userId ? (data.userName + ' - ' + data.userId) : data.userName  : 'User Not Found',
              "short": true
            },
            {
              "title": "Company",
              "value": Object.keys(data).length > 0 ? data.companyId ? (data.companyCode + ' - ' + data.companyId) : data.companyCode  : 'Company Not Found',
              "short": true
            }, 
            {
              "title": "Payload",
              "value": Object.keys(data).length > 0 ? JSON.stringify(data) : 'Payload Not Found',
              "short": false
            },
          ]
        }
      ],
    };
    
    axios.post(SLACK_WEB_HOOK_URL, JSON.stringify(payload)).then((response) => {
      console.log(response);
    }).catch((error) => {
      console.log(error);
    });
  }

  static errorMessage = (message, method, data) => {
    const currentTimestamp = new Date().getTime();

    var userDetails = null;
    var companyDetails = null;
    const loggedInUser = GlobalMethods.getUser();

    if (Object.keys(loggedInUser).length > 0) {
      userDetails = loggedInUser.userName + ' - ' + loggedInUser.userId;
      companyDetails = loggedInUser.companyCode + ' - ' + loggedInUser.companyId;
    } else {
      if (Object.keys(data).length > 0) {
        userDetails = Object.keys(data).length > 0 ? data.userId ? (data.userName + ' - ' + data.userId) : data.userName  : 'User Not Found';
        companyDetails = Object.keys(data).length > 0 ? data.companyId ? (data.companyCode + ' - ' + data.companyId) : data.companyCode  : 'Company Not Found';
      }
    }

    let payload = {
      attachments: [
        {
          color: "#d9534f",
          title: "Error Message",
          text: message,
          ts: currentTimestamp,
          fields: [
            {
              "title": "Method",
              "value": method,
              "short": false
            },
            {
              "title": "User",
              "value": userDetails,
              "short": true
            },
            {
              "title": "Company",
              "value": companyDetails,
              "short": true
            }, 
            {
              "title": "Payload",
              "value": Object.keys(data).length > 0 ? JSON.stringify(data) : 'Payload Not Found',
              "short": false
            },
          ]
        }
      ],
    };
    
    axios.post(SLACK_WEB_HOOK_URL, JSON.stringify(payload)).then((response) => {
      console.log(response);
    }).catch((error) => {
      console.log(error);
    });
  }

  static infoMessage = (message, method, data) => {
    const currentTimestamp = new Date().getTime();

    let payload = {
      attachments: [
        {
          color: "#54b4d3",
          title: "Info Message",
          text: message,
          ts: currentTimestamp,
          fields: [
            {
              "title": "Method",
              "value": method,
              "short": false
            },
            {
              "title": "User",
              "value": Object.keys(data).length > 0 ? data.userId ? (data.userName + ' - ' + data.userId) : data.userName  : 'User Not Found',
              "short": true
            },
            {
              "title": "Company",
              "value": Object.keys(data).length > 0 ? data.companyId ? (data.companyCode + ' - ' + data.companyId) : data.companyCode  : 'Company Not Found',
              "short": true
            }, 
            {
              "title": "Payload",
              "value": Object.keys(data).length > 0 ? JSON.stringify(data) : 'Payload Not Found',
              "short": false
            },
          ]
        }
      ],
    };
    
    axios.post(SLACK_WEB_HOOK_URL, JSON.stringify(payload)).then((response) => {
      console.log(response);
    }).catch((error) => {
      console.log(error);
    });
  }
}

export default SlackNotificationChannel;