<template>
  <div style="height: 100vh;">
    <!-- DASHBOARD -->
    <div class="nav-menu parent" style=" height: 45px; width: 98%; padding: 0px; padding-top: 0.3%; margin-left: 1%; margin-right: 1%;">
      <div class="left-buttons" style="margin-left: 10px">
        <v-menu location="bottom" transition="slide-x-transition">
          <template v-slot:activator="{ props }">
            <v-btn class="btn" variant="text" v-bind="props">
              {{ selectedCompanyName }}
              <span class="input-group-addon"><i class="fa fa-chevron-down"></i></span>
            </v-btn>
          </template>

          <div class="mt-4">
            <SelectCompany :companies="companyList" @company_selected="changeCompanyDetails" />
          </div>
        </v-menu>
      </div>
      <div class="filler"></div>
      <img 
        src="../img/egovernance-white.png" 
        style="height: 75%; width: auto; padding-top: 5px;"
        @click="$router.push('/dashboard')" 
      />
      <div class="filler"></div>
      <div class="right-buttons">
        <v-menu location="bottom" transition="slide-x-transition">
          <template v-slot:activator="{ props }">
            <v-btn class="btn font-weight-bolder" variant="text" v-bind="props">
              {{ userLanguage.language }}
            </v-btn>
          </template>

          <div class="mt-4 mr-n10">
            <UserLanguage :languages="allLanguages" @language_selected="changeCompanyLanguage" />
          </div>
        </v-menu>
        <v-icon class="mr-4" size="x-large" color="#ffffff" @click="reloadPage">
          mdi-cached
        </v-icon>
        <v-menu location="bottom" :close-on-content-click="false" transition="slide-x-transition">
          <template v-slot:activator="{ props }">
            <v-icon class="mr-4" size="x-large" color="#ffffff" v-bind="props">
              mdi-magnify
            </v-icon>
          </template>

          <div class="mt-2">
            <DashboardSearch />
          </div>
        </v-menu>
        <v-menu location="bottom" :close-on-content-click="false" transition="slide-x-transition">
          <template v-slot:activator="{ props }">
            <v-avatar rounded="0" size="35" class="mr-4">
              <v-img
                :src="user.userInfo.imageUrl !== '' ? user.userInfo.imageUrl : (gender == 'null' ? '/img/neutral-icon.jpeg' : (gender == 'M' ? '/img/male-icon.png' : '/img/female-icon.png'))"
                style="height: 85px; width: 85px; background-color: #fff; border: 1px solid #27ae60; cursor: pointer;"
                v-bind="props" 
              />
            </v-avatar>
          </template>

          <div class="mt-2 mr-n16">
            <UserProfile :dashboard="true" />
          </div>
        </v-menu>
        <v-menu location="bottom" transition="slide-x-transition">
          <template v-slot:activator="{ props }">
            <v-icon class="mr-4" size="x-large" color="#ffffff" v-bind="props">
              mdi-server
            </v-icon>
          </template>

          <div class="mt-4 mr-n1">
            <OptionsMenu @contact_support="callTrigger" />
          </div>
        </v-menu>
      </div>
    </div>

    <div>
      <!-- ALERT -->
      <v-alert style="margin-left: 1%; margin-right: 1%" v-model="alert" dismissible color="cyan" border="left"
        elevation="2" colored-border>
        <v-btn x-small color="success" @click="reloadPage">
          <span class="text-white">Click Here to Restart</span>
        </v-btn>
      </v-alert>
    </div>

    <div style="display: flex;" class="row">
      <div class="col-md-8">
        <div class="col-left" style="overflow: auto; left: 1%; right: 1%; top: 1%; float: left; position: relative;">
          <div class="col-left-top" style="overflow-y: auto; overflow-x: hidden; left: 1%; right: 1%; top: 1%; float: left; position: relative; width: 98%;">
            <div v-if="dashboard_loading">
              <v-progress-linear color="info" indeterminate rounded height="6"></v-progress-linear>
            </div>

            <div v-if="dashboardMenuList && dashboardMenuList.length > 0">
              <v-row no-gutters>
                <v-col v-for="item in even(dashboardMenuList)" :key="item.menuId" cols="3" md="3" sm="3" align="center"
                  style="cursor: pointer;" class="ma-2">
                  <v-hover>
                    <template v-slot:default="{ isHovering, props }">
                      <router-link 
                        v-if="item.menuTitle !== 'Conference'" 
                        :to="{ name: item.menuTitle }"
                        style="text-decoration: none"
                      >
                        <v-card 
                          class="mx-auto pa-0" 
                          v-bind="props"
                          :color="isHovering ? 'rgb(' + item.menuColor + ')' : undefined" 
                          @click="resetComponents"
                        >
                          <v-card-title class="text-center">
                            <div class="left-menu" :style="'border-top: 5px solid rgb(' + item.menuColor + '); padding: 3.3%; cursor: pointer;'">
                              <div class="pa-1">
                                <span class="text-center text-white">
                                  <v-avatar 
                                    :color="'rgb(' + item.menuColor + ')'" 
                                    size="x-large"
                                    style="color: #ffffff;"
                                  >
                                    {{ item.menuCount }}
                                  </v-avatar>
                                </span>
                              </div>
                            </div>
                          </v-card-title>

                          <v-card-text
                            :style="!isHovering ? { color: 'rgb(' + item.menuColor + ')' } : { color: '#ffffff' }">
                            <span class="text-center">
                              {{ item.menuTitle }}
                            </span>
                          </v-card-text>
                        </v-card>
                      </router-link>
                      <v-card 
                        v-else 
                        class="mx-auto pa-0" 
                        v-bind="props"
                        :color="isHovering ? 'rgb(' + item.menuColor + ')' : undefined" 
                        @click="showConferenceModal"
                      >
                        <v-card-title class="text-center">
                          <div class="left-menu"
                            :style="'border-top: 5px solid rgb(' + item.menuColor + '); padding: 3.3%; cursor: pointer;'">
                            <div class="pa-1">
                              <span class="text-center">
                                <img 
                                  v-bind:src="item.menuImageUrl" 
                                  v-bind:alt="item.menuTitle"
                                  v-bind:style="{ width: '60px', height: '50px' }" 
                                />
                              </span>
                              <v-spacer></v-spacer>
                            </div>
                          </div>
                        </v-card-title>

                        <v-card-text>
                          <div>
                            <span class="text-center">
                              {{ item.menuTitle }}
                            </span>
                          </div>
                        </v-card-text>
                      </v-card>
                    </template>
                  </v-hover>
                </v-col>
              </v-row>
            </div>
            <div v-else>
              <v-row no-gutters>
                <v-col cols="12" md="12" sm="12" align="center">
                  <v-card class="mx-auto pa-4">
                    <v-card-text>
                      <span class="text-center">
                        No Dashboard Menus Found
                      </span>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </div>
          <div class="col-left-bottom" style="overflow-y: auto; overflow-x: hidden; left: 1%; right: 1%; top: 1%; float: left; position: relative; width: 98%;">
            <v-slide-group fixed-tabs v-if="dashboardComponent === 'MyInbox'">
              <v-slide-group-item>
                <v-btn 
                  :style="'margin-left: 20px; margin-right: 20px; font-weight: bold; cursor: pointer; ' + (dashboardComponent === 'MyInbox' ? 'background-color: #27ae60;' : 'background-color: #9e9e9e;')"
                  @click="swapComponent('MyInbox')" class="ma-2 pa-2 v-tab text-white" rounded role="tab" block
                >
                  Notifications
                </v-btn>
              </v-slide-group-item>
              <v-slide-group-item>
                <v-btn :style="'margin-left: 20px; margin-right: 20px; font-weight: bold; cursor: pointer; '
                  + (dashboardComponent === 'Tasks' ? 'background-color: #27ae60;' : 'background-color: #9e9e9e;')"
                  @click="swapComponent('Tasks')" class="ma-2 pa-2 v-tab text-white" rounded role="tab" block>
                  Tasks
                </v-btn>
              </v-slide-group-item>
            </v-slide-group>

            <!-- Components Switch -->
            <component 
              :is="dashboardComponent" 
              :recent-documents="getRecentDocumentsArr" 
              @open_document="openDocument"
              @open_pdf="openDocument" 
              :load="briefcase" 
              :task-list="getLatestNotificationsArr.taskList"
              :signature-list="signatureList" 
              @open_task="selectTask"
            ></component>
            <!-- End of components switch -->
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="col-right" style="overflow-y: auto; overflow-x: hidden; right: 1%; top: 1%; float: left; position: relative; width: 100%;">
          <div class="col-right-top" style="overflow-y: auto; overflow-x: hidden; left: 1%; right: 1%; top: 1%; float: left; position: relative; width: 98%;">
            <v-row no-gutters>
              <v-col cols="6" md="6" sm="6" align="center" class="pa-2">
                <v-hover>
                  <template v-slot:default="{ isHovering, props }">
                    <v-card :class="isHovering ? 'text-white' : 'text-info'" v-bind="props"
                      :color="isHovering ? 'info' : undefined" style="cursor: pointer;" @click="openEventsCalendar">
                      <v-card-text>
                        <v-row align="center" no-gutters>
                          <v-col cols="12" md="12" sm="12">
                            <span class="text-center text-h6 font-weight-light">
                              {{ moment().format('MMM') }}
                            </span>
                          </v-col>
                          <v-col cols="12" md="12" sm="12">
                            <span class="text-center text-h6 font-weight-bold">
                              {{ moment().format('DD') }}
                            </span>
                          </v-col>
                          <v-col cols="12" md="12" sm="12">
                            <span class="text-center text-h6 font-weight-light">
                              {{ moment().format('dddd') }}
                            </span>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </template>
                </v-hover>
              </v-col>
              <v-col cols="6" md="6" sm="6" align="center" class="pa-2">
                <v-hover>
                  <template v-slot:default="{ isHovering, props }">
                    <router-link 
                      to="/board-directory" 
                      style="text-decoration: none;"
                      :style="isHovering ? { color: '#ffffff' } : { color: '#e33333' }"
                    >
                      <v-card 
                        :class="isHovering ? 'text-white' : 'text-danger'" v-bind="props"
                        :color="isHovering ? '#e33333' : undefined" 
                        style="cursor: pointer;"
                      >
                        <v-card-title>
                          <div class="pa-1">
                            <span class="text-center">
                              <v-icon 
                                icon="mdi-card-account-mail-outline" 
                                size="x-large"
                                :style="{ width: '60px', height: '60px' }" 
                              />
                            </span>
                          </div>
                        </v-card-title>
                        <v-card-text>
                          <div class="pa-1">
                            <span class="text-center">
                              Board Directory
                            </span>
                          </div>
                        </v-card-text>
                      </v-card>
                    </router-link>
                  </template>
                </v-hover>
              </v-col>
            </v-row>
          </div>
          <div class="col-right-bottom"
            style="overflow-y: auto; overflow-x: hidden; left: 1%; right: 1%; top: 1%; float: left; position: relative; width: 98%;">
            <Meetings />
          </div>
        </div>
      </div>

      <div class="text-center">
        <Conference />
      </div>

      <div class="text-center">
        <UpdateTask @task_updated="getLatestNotifications" />
      </div>

      <!-- CHANGE PASSWORD MODAL -->
      <div v-if="dialog_password">
        <ChangePassword @password_changed="getRequestUserLogin" />
      </div>

      <!-- CONTACT SUPPORT MODAL -->
      <div class="text-center">
        <ContactSupport :load="trigger" />
      </div>

      <!-- DASHBOARD MENUS MODAL -->
      <div class="text-center">
        <DashboardMenus :menus="dashboardMenuList" />
      </div>

      <!-- EVENTS CALENDAR MODAL -->
      <div class="text-center">
        <EventsCalendar 
          :calendar-options="calendarOptions" 
          :all-meetings="allMeetings"
          @get_calendar_events="getCalendarEvents" 
        />
      </div>

      <div 
        v-if="closePDF"
        style="position: absolute; background-color: #f5f5f5; width: 80%; height: 900px; padding: 1%; overflow: hidden; margin-top: 1%; margin-right: 1%; margin-left: 22%; z-index: 2;"
      >
        <v-card>
          <v-card-text>
            <v-main>
              <v-container fluid>
                <pspdfkit 
                  :pdf-url="pdf" 
                  :license-key="LICENSE_KEY" 
                  :document-type="type" 
                  :document-title="title"
                  :base-url="baseUrl" 
                  @open_pdf="openDocument" 
                  @open_document="openDocument" 
                  @close_pdf="closePdf"
                  :document-list="getRecentDocumentsArr.recentDocumentsList" 
                  :list-color="'rgb(71, 183, 83)'"
                  :document-loader="loading" 
                  :briefcase-color="'#e33333'" 
                  :briefcase-files="fileIds" 
                  :list-width="'25%'"
                  @switch_pdf="switchDocument" 
                  @switch_document="switchDocument" 
                  :parent_select_type="true"
                  :parent_pdf_type="pdf_type ? 2 : 1" 
                />
              </v-container>
            </v-main>
          </v-card-text>
        </v-card>
      </div>

      <div 
        v-if="closeDocument"
        style="position: absolute; background-color: #f5f5f5; width: 77%; height: 900px; padding: 1%; overflow: hidden; margin-top: 1%; margin-right: 1%; margin-left: 22%; z-index: 2;"
      >
        <v-card>
          <v-card-text>
            <v-main>
              <v-container fluid>
                <DocumentViewer 
                  :document-url="pdf" 
                  :document-type="type" 
                  :document-title="title"
                  :viewer-color="'#e33333'" 
                  @open_pdf="openDocument" 
                  @open_document="openDocument"
                  @close_document="documentClosed" 
                  :document-list="getRecentDocumentsArr.recentDocumentsList"
                  :list-color="'rgb(71, 183, 83)'" 
                  :briefcase-color="'#e33333'" 
                  :briefcase-files="fileIds"
                  :list-width="'25%'" 
                  @switch_pdf="switchDocument" 
                  @switch_document="switchDocument"
                  :parent_select_type="true" 
                  :parent_pdf_type="pdf_type ? 2 : 1" 
                />
              </v-container>
            </v-main>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
var moment = require("moment");
import axios from "axios";
import PSPDFKit from "../../components/PSPDFKit.vue";
import { useAuthStore } from "../../stores/auth";
import { useIndexStore } from "../../stores/index";
import { useAlertStore } from "../../stores/alert";
import DashboardSearch from '../Cards/DashboardSearch.vue';
import SelectCompany from "../Cards/SelectCompany.vue";
import UserLanguage from "../Cards/UserLanguage.vue";
import UserProfile from '../Cards/UserProfile.vue';
import ChangePassword from '../Forms/ChangePassword.vue';
import Conference from '../Forms/Conference.vue';
import OptionsMenu from "../Forms/OptionsMenu.vue";
import UserData from "../repository/UserData";
import MyInbox from '../Swap/MyInbox.vue';
import Meetings from '../Meetings.vue';
import UpdateTask from "../Forms/UpdateTask.vue";
import ContactSupport from '../Forms/ContactSupport.vue';
import DashboardMenus from '../Forms/DashboardMenus.vue';
import DocumentViewer from "../Cards/DocumentViewer.vue";
import GlobalMethods from "../repository/GlobalMethods";
import SlackNotificationChannel from "../repository/SlackNotificationChannel";
import Tasks from "../Swap/Tasks.vue";
import Signatures from "../Swap/Signatures.vue";
import authHeader from "../repository/auth-header";
import EventsCalendar from '../Forms/EventsCalendar.vue';

const LICENSE_KEY =
  "QvMr6j5706r3kYlOiVvJgEwu4fZnTL1fYz4t6paoBV5PekugaffzJnLtX5TczMS65OKAI1iIaSi06_l76UgF3kyKauJ9vaMZFuU3_oDplMFty6AhBhWPh80M7qoSa8xjtux3NqNrfqybICplLfHezwjhABIkPy6XxBMQ_uPU87_C5pMBXlc8A2PI_ShMjfhst6O_C0y9jyeMJ2DYudM3liFVsp90A0dOY51LM2Q3xV3jfcJ7K39FKpnKU_atpn1Ig8HXcmWdGQMjCXyquwVu9i2J850rGqBgFKJJFGixxnU3sZ__tA7Mb3aX8T2nXPlEnamtAhNf-DD1sEtqXHp5q5xxsFFuJDVh82tXKfd4oX8AkzWLd_-UVg_h_6ATML30e8fiQXSVcN0mmlZH5XYUv0jB5cHEyuIaGZac7gCNRn32pchJNpIwEdgHzWDRBQxxCR7F8b6z3Xs3yXJ6y9EhhrnKmjNYkMCdFJ0qNhI8zF8RhAbLA-uKtrzMy7hXLrlpuIHdGaCXMm7gA9iBpKiqwHIrX83rCFoVYUGM4BqNkim3HN8YJzZRDDI-ZHzdy7CcanSuvbe3NSxW5daeHdGVvg==";

export default {
  data: () => ({
    opened: [],
    submitRSVP: false,
    aspectRatio: 16 / 9,
    calendarOptions: {
      plugins: [],
      initialView: 'dayGridMonth',
      events: [],
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'multiMonthYear,dayGridMonth,timeGridDay',
      },
      editable: true,
      selectable: true,
      selectMirror: true,
      dayMaxEvents: true,
      weekends: true,
      buttonText: {
        today: 'Today',
        month: 'Month',
        year: 'Year',
        day: 'Day',
        list: 'List'
      },
    },
    currentEvents: [],
    tasks: [],
    notifications: [],

    seen: false,
    seen1: false,
    seen2: false,
    seen3: true,
    seen4: false,
    seen5: false,
    seen6: false,
    dashboard_loading: false,
    test: true,
    closePDF: false,
    closePDF2: false,
    closeDocument: false,
    shareDocument: false,
    showMessages: false,
    dialog: false,
    changePassDiv: false,
    eboardUpdates: [],
    requestUserLogin: [],
    userInfo: [],
    eventArray: [],
    companyDetail: [],
    userLanguage: [],
    allLanguages: [],
    companyList: [],
    getLatestNotificationsArr: [],
    getRecentDocumentsArr: [],
    getCalendarEventsArr: [],
    checkNames: [],
    companyListId: "",
    selectedCompanyName: "",
    moment: moment,
    date: 1570064727,
    type: "",
    title: "",
    pdf: "example.pdf",
    LICENSE_KEY: LICENSE_KEY,
    baseUrl: "",
    errorMsg: "",
    expiredMessage: "",
    expiredSuccess: "",
    oldPass: "",
    newPass: "",
    confirmPass: "",
    selectedLanguage: "",
    alert: !true,
    userPassword: localStorage.getItem("userPassword") ? JSON.stringify(localStorage.getItem("userPassword")).slice(1, -1) : "",
    token: localStorage.getItem("token") ? JSON.stringify(localStorage.getItem("token")).slice(1, -1) : "",
    store: useIndexStore(),
    auth: useAuthStore(),
    alert_store: useAlertStore(),
    allMeetings: [],
    trigger: false,
    briefcase: false,
    admin: {},
    globalMethods: GlobalMethods,
    files: [],
    fileIds: [],
    loading: {},
    signatureList: [],
  }),

  filters: {
    moment: function (date) {
      return moment(date).format("YYYY-MM-DD");
    },
  },

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },

    dashboardComponent() {
      return this.store.getDashboardComponent;
    },

    switchComponent() {
      return this.store.getSwitchComponent;
    },

    dialog_password() {
      return this.store.getDialogPassword;
    },

    dashboardMenuList() {
      return this.auth.getDashboardMenuList;
    },

    current_task() {
      return this.store.getCurrentTask;
    },

    gender() {
      return localStorage.getItem('admin_gender');
    },

    pdf_type() {
      return this.store.getPdfType;
    },

    checkEnvironment() {
      return process.env.NODE_ENV == 'production' ? true : false;
    },

    digital_signatures: {
      get() {
        return this.auth.getDigitalSignatures;
      },
      set(value) {
        this.auth.setDigitalSignatures(value);
      },
    },

    session_overlay: {
      get() {
        return this.store.getSessionOverlay;
      },
      set(value) {
        this.store.setSessionOverlay(value);
      },
    },

    reset_session: {
      get() {
        return this.auth.getResetSession;
      },
      set(value) {
        this.auth.setResetSession(value);
      },
    },
  },

  methods: {
    showConferenceModal() {
      this.store.setDialogConference(true);
    },

    selectTask(task) {
      this.store.setCurrentTask(task);
      this.openTaskDialog();
    },

    getComponentText(component) {
      let text = null;
      switch (component) {
        case 'Tasks':
          text = 'Signatures';
          break;
        case 'Signatures':
          text = 'Tasks';
          break;
      }

      return text;
    },

    callTrigger() {
      this.trigger = !this.trigger;
    },

    closePdf() {
      this.briefcase = !this.briefcase;
      this.closePDF = !this.closePDF;
      this.shareDocument = false;
      this.store.setLoading(false);
    },

    documentClosed() {
      this.closeDocument = !this.closeDocument;
      this.store.setDialogDocumentViewer(false);
    },

    openDocument(item, url = null) {
      if (item.itemExtension != 'No File Extension') {
        this.pdf = item.itemUrl;
        this.type = item.itemExtension;
        this.title = item.itemName;
        UserData.setDocumentId(item.itemId);
        this.store.setDocumentId(item.itemId);
        this.store.setCurrentDocument(item);
        this.store.setBriefcaseUrl(url);

        if (item.itemExtension == "pdf") {
          this.closeDocument = false;
          this.closePDF = true;
          this.store.setCurrentPdf(item);
        } else {
          this.closePDF = false;
          this.closeDocument = true;
          this.store.setDialogDocumentViewer(true);
        }
      } else {
        if (item.itemSize > 0) {
          this.alert_store.showError(item.itemExtension);
        } else {
          this.alert_store.showError('Error Opening File');
        }
      }
    },

    switchDocument(item) {
      this.store.setBriefcaseUrl(null);
      this.openDocument(item);
    },

    briefcaseDocument(item) {
      this.globalMethods.downloadEbrDocument(item.itemUrl).then(async (response) => {
        if (response.status == 200) {
          let fileType = response.headers['content-type'];
          let fileData = new Blob([response.data], { type: fileType });
          let blob = window.URL.createObjectURL(fileData);
          this.openDocument(item, blob);
        } else {
          console.log("Response: ", response);
        }
      }).catch((e) => {
        SlackNotificationChannel.errorMessage(e.message, 'briefcaseDocument(888) in Dashboard.vue', item);
      });
    },

    openPdfOriginal(item) {
      this.closePDF = !this.closePDF;
      this.pdf = item.itemUrl;
    },

    reloadPage() {
      window.location.reload();
    },

    convertDate(date, format) {
      return moment(date).utc().format(format);
    },

    getStartEndDate(date, format) {
      var converted = {};
      // get start and end date for a specific calendar date
      converted['start'] = moment(date).startOf('day').format(format);
      converted['end'] = moment(date).endOf('day').format(format);
      return converted;
    },

    getUserDetails() {
      this.allLanguages = [];
      this.allLanguages = [...this.user.allLanguages];
      this.allLanguages.unshift({
        languageId: "0",
        language: "Select Language",
      });

      this.userInfo = JSON.parse(JSON.stringify(this.user.userInfo));
      this.companyDetail = JSON.parse(JSON.stringify(this.user.companyDetail));
      this.userLanguage = JSON.parse(JSON.stringify(this.user.userLanguage));
      this.auth.setUserLanguage(this.userLanguage);
      this.auth.setDashboardMenuList(JSON.parse(JSON.stringify(this.user.dashboardMenuList)));
    },

    getSignaturesForUser() {
      const form = new FormData();
      form.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      form.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      form.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      form.append("model", "getSignaturesForUser");
      form.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);

      axios.post((process.env.NODE_ENV == 'production' ? UserData.getProductionAdminBaseUrl() : UserData.getLocalAdminBaseUrl()) + '/company-signature-files', form, { headers: authHeader() }).then((response) => {
        if (response.status == 200) {
          this.signatureList = response.data.signatureList;
        } else {
          if (response.response.status == 403 && !this.session_overlay) {
            console.log(response);
          }
        }
      });
    },

    checkDigitalSignatures() {
      const form = new FormData();
      form.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      form.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      form.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      form.append("model", "checkDigitalSignatures");
      form.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);

      axios.post((process.env.NODE_ENV == 'production' ? UserData.getProductionAdminBaseUrl() : UserData.getLocalAdminBaseUrl()) + '/check-signatures', form, { headers: authHeader() }).then((response) => {
        if (response.status == 200) {
          const user_digital_signatures = response.data.data;
          if (user_digital_signatures.length > 0 && !this.checkEnvironment) {
            this.digital_signatures = true;
          } else {
            this.digital_signatures = false;
          }
        } else {
          if (response.response.status == 403 && !this.session_overlay) {
            console.log(response);
          }
        }
      });
    },

    getRequestUserLogin() {
      let password = UserData.getUserName() ? UserData.getUserName() : this.user.userInfo.userName;

      const formData = new FormData();
      formData.append("userName", password);
      formData.append("userPassword", password);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("model", "requestUserLogin");
      formData.append("mobileVersion", "11.4.1");
      formData.append("deviceName", "ipad air 2");
      formData.append("eboardVersion", "2.5.7");
      formData.append("wifiName", Math.random().toString(36).slice(2));
      formData.append("userLocation", Math.random().toString(36).slice(2));
      formData.append("deviceToken", Math.random().toString(36).slice(2));
      this.dashboard_loading = true;

      axios.post(UserData.getBaseUrl(), formData).then((response) => {
        this.requestUserLogin = response.data;
        this.dashboard_loading = false;

        this.allLanguages = [];
        this.allLanguages = [...this.requestUserLogin.allLanguages];
        this.allLanguages.unshift({
          languageId: "0",
          language: "Select Language",
        });

        this.userInfo = JSON.parse(JSON.stringify(this.requestUserLogin.userInfo));
        this.companyDetail = JSON.parse(JSON.stringify(this.requestUserLogin.companyDetail));
        this.userLanguage = JSON.parse(JSON.stringify(this.requestUserLogin.userLanguage));
        this.auth.setUserLanguage(this.userLanguage);
        this.auth.setDashboardMenuList(JSON.parse(JSON.stringify(this.requestUserLogin.dashboardMenuList)));
        this.loginAsAdmin(password);
      })
        .catch((error) => {
          console.log(error);
        });
    },

    getEboardUpdates() {
      const formData = new FormData();
      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("model", "getEboardUpdates");
      formData.append("mobileVersion", "11.4.1");
      formData.append("deviceName", "ipad air 2");
      formData.append("eboardVersion", "5.3.0");
      formData.append("wifiName", Math.random().toString(36).slice(2));
      formData.append("deviceToken", Math.random().toString(36).slice(2));
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);

      this.dashboard_loading = true;
      axios.post(UserData.getBaseUrl(), formData).then((response) => {
        this.eboardUpdates = response.data;
        this.auth.setEboardUpdates(this.eboardUpdates);
        this.dashboard_loading = false;
      })
        .catch((error) => {
          console.log(error);
        });
    },

    getCompanyName() {
      this.selectedCompanyName = UserData.getCompanyName() ? UserData.getCompanyName() : this.user.companyDetail.companyName;
    },

    getCompanyList() {
      const formData = new FormData();
      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "getCompanyList");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);

      axios.post(UserData.getBaseUrl(), formData).then((response) => {
        this.getCompanyListArr = response.data;
        this.companyList = this.getCompanyListArr.companyList;

        this.companyList.unshift({
          companyId: "0",
          companyName: "Company List",
        });
      })
        .catch((e) => {
          console.log("Error", e);
        });
    },

    getLatestNotifications() {
      const formData = new FormData();
      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "getLatestNotifications");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);

      axios.post(UserData.getBaseUrl(), formData).then((response) => {
        this.getLatestNotificationsArr = response.data;
        localStorage.setItem("getLatestNotifications", JSON.stringify(this.getLatestNotificationsArr));
      })
        .catch((e) => {
          console.log("Error", e);
        });
    },

    getRecentDocuments() {
      const formData = new FormData();
      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "getRecentDocuments");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);

      this.store.setLoading(true);
      axios.post(UserData.getBaseUrl(), formData).then((response) => {
        this.getRecentDocumentsArr = response.data;
        localStorage.setItem("getRecentDocuments", JSON.stringify(this.getRecentDocumentsArr));
        this.store.setLoading(false);
      })
        .catch((e) => {
          console.log("Error", e);
        });
    },

    getCalendarEvents() {
      const formData = new FormData();
      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "getCalendarEvents");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);

      axios.post(UserData.getBaseUrl(), formData).then((response) => {
        this.getCalendarEventsArr = response.data;
        this.eventArray = this.getCalendarEventsArr.eventList.length > 0 ? this.getCalendarEventsArr.eventList : [];
        localStorage.setItem("getCalendarEvents", JSON.stringify(this.getCalendarEventsArr));
        this.getAllMeetings();
        this.calendarOptions.events = this.eventArray.map((element) => {
          return {
            id: element.eventId,
            title: element.eventTitle,
            start: element.eventType.id == 4 ? this.convertDate(element.eventBeginDate, 'YYYY-MM-DDTHH:mm:ss') : this.getStartEndDate(element.eventBeginDate, 'YYYY-MM-DDTHH:mm:ss')['start'],
            end: element.eventType.id == 4 ? this.convertDate(element.eventEndDate, 'YYYY-MM-DDTHH:mm:ss') : this.getStartEndDate(element.eventBeginDate, 'YYYY-MM-DDTHH:mm:ss')['end'],
            color: element.eventType.color_code || '#56B6E5',
            textColor: "#FFFFFF",
            display: 'block',
            companyId: element.companyId,
            companyName: element.companyName,
            meetingId: element.meetingId,
            eventId: element.eventId,
            module: element.module,
            eventType: element.eventType,
            eventAction: element.eventAction,
            location: element.location,
            eventColor: element.eventColor || '#56B6E5',
          };
        });
      })
        .catch((e) => {
          console.log("Error", e);
        });
    },

    changeCompanyDetails(item) {
      this.seen1 = false;
      this.auth.setDashboardMenuList(item.dashboardMenuList);
      // Set Repository Company Details
      UserData.setCompanyId(item.companyId);
      UserData.setCompanyName(item.companyName);
      // Set Store Company Details
      this.auth.setCompanyId(item.companyId);
      this.auth.setCompanyName(item.companyName);
      localStorage.setItem('admin_company_id', item.companyId);
      this.closePDF = false;
      this.closeDocument = false;
      this.getUserDetails();
      this.getCompanyList();
      this.getSignaturesForUser();
      this.checkDigitalSignatures();
      this.getLatestNotifications();
      this.getCalendarEvents();
      this.getRecentDocuments();
      this.selectedCompanyName = UserData.getCompanyName() ? UserData.getCompanyName() : this.user.companyDetail.companyName;
    },

    changeCompanyLanguage(item) {
      const formData = new FormData();
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "changUserLanguage");
      formData.append("lang_id", item.languageId);

      this.store.setLoading(true);
      axios.post(UserData.getBaseUrl(), formData).then((response) => {
        this.selectedLanguage = response.data.userLanguage.language;
        this.auth.setUserLanguage(this.selectedLanguage);
        this.getRequestUserLogin();
        this.store.setLoading(false);
      })
        .catch((error) => {
          console.log(error);
        });
    },

    langAlert(alert) {
      this.alert = alert;
    },

    getLink() {
      let routeData = this.$router.resolve({
        name: this.getRecentDocumentsArr.recentDocumentsList.itemUrl,
        query: { data: this.getRecentDocumentsArr.recentDocumentsList.itemUrl },
      });
      window.open(routeData.href, "_blank");
    },

    even(dashboardMenuList) {
      return dashboardMenuList.slice().sort(function (a, b) {
        return a.menuId - b.menuId;
      });
    },

    sendSharePeople() {
      if (this.checkNames == 0) {
        console.log("Select persons to share to!");
      } else {
        const formData = new FormData();
        formData.append("document_id", UserData.getDocumentId());
        formData.append("company_code", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
        formData.append("id_users", this.checkNames);

        axios.post("https://eserver1.stl-horizon.com/pspdfkit/shareAnnotation.php", formData).then((response) => {
          this.sendSharePeopleArray = response.data;
        })
          .catch((e) => {
            console.log("Error", e);
          });

        this.checkNames.length = 0;
        this.shareDocument = false;
      }
    },

    openShareButton() {
      const formData = new FormData();
      formData.append("company_code", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("document_id", UserData.getDocumentId());

      axios.post("https://eserver1.stl-horizon.com/pspdfkit/shareAnnotation.php", formData).then((response) => {
        this.sharePeople = response.data;
        this.shareDocument = !this.shareDocument;
      })
        .catch((e) => {
          console.log("Error", e);
        });
    },

    getAllMeetings(meetingCategoryId = -999) {
      const formData = new FormData();

      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "getMeetingForCategory");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      formData.append("meetingCategoryId", meetingCategoryId);

      axios.post(UserData.getBaseUrl(), formData).then((response) => {
        if (response.data.data && response.data.data.meetingList && response.data.data.meetingList.length > 0) {
          this.allMeetings = response.data.data.meetingList;
        }
      })
        .catch((e) => {
          console.log("Error", e);
        });
    },

    swapComponent(component) {
      this.store.setDashboardComponent(component);
    },

    swapSwitchComponent(component) {
      this.store.setSwitchComponent(component);
    },

    openEventsCalendar() {
      this.store.setDialogEventsCalendar(true);
    },

    openDashboard() {
      this.store.setDialogTask(false);
      this.store.setDialogConference(false);
      this.store.setDialogPassword(false);
      this.store.setDialogEventsCalendar(false);
      this.store.setDashboardComponent('MyInbox');
      this.store.setSwitchComponent('Tasks');
      this.store.setBriefcaseUrl(null);
      this.store.setSignatureDocument(false);
      this.auth.setResetSession(false);
      this.alert_store.setChecked(null);
    },

    openTaskDialog() {
      this.store.setDialogTask(true);
    },

    getUserInitials(user) {
      const name = user.split(' ');
      return `${name[0].charAt(0)}${name[1] ? name[1].charAt(0) : ''}`;
    },

    resetComponents() {
      this.store.setDashboardComponent('MyInbox');
      this.store.setSwitchComponent('Tasks');
      this.store.setCurrentComponent('view-01');
      this.store.setDashboardMeeting(false);
    },
  },

  beforeMount() {
    this.openDashboard();

    if (this.$router.options.history.state.back == '/') {
      this.getEboardUpdates();
    }

    this.getUserDetails();
    this.getSignaturesForUser();
    this.checkDigitalSignatures();
    this.getLatestNotifications();
    this.getRecentDocuments();
    this.getCalendarEvents();
    this.getCompanyList();
    this.getCompanyName();
  },

  components: {
    EventsCalendar,
    pspdfkit: PSPDFKit,
    Conference,
    OptionsMenu,
    ChangePassword,
    UserProfile,
    DashboardSearch,
    UserLanguage,
    SelectCompany,
    MyInbox,
    Meetings,
    UpdateTask,
    ContactSupport,
    DashboardMenus,
    DocumentViewer,
    Tasks,
    Signatures,
  },
};
</script>

<style scoped>
.v-col-md-3 {
  max-width: 22%;
}

body {
  padding: 0;
  margin: 0;
}

.nav-menu {
  margin-top: 0px;
  background-color: #e33333;
}

.nav-menu .btn {
  background-color: #e33333;
  border: none;
  padding: 0px;
  margin-right: 15px;
  color: white;
  outline: none;
}

.col-left .left-menu {
  background-color: #f5f5f5;
  align-content: center;
  margin-bottom: 10px;
  font-size: 20px;
}

.col-left-top {
  height: 75vh;
  background-color: #f5f5f5;
  margin-bottom: 15px;
  padding: 10px;
}

.col-left-bottom {
  height: 25vh;
  background-color: #f5f5f5;
  margin-bottom: 15px;
  padding: 10px;
}

.col-right-top {
  height: 25vh;
  background-color: #f5f5f5;
  margin-bottom: 15px;
  padding: 10px;
}

.col-right-bottom {
  height: 75vh;
  background-color: #f5f5f5;
  margin-bottom: 15px;
  padding: 10px;
}

.col-right-bottom-left {
  background-color: #f5f5f5;
}

.col-right-bottom-right {
  width: 59%;
  height: 430px;
  background-color: #f5f5f5;
  float: left;
  padding: 1%;
}

.col-right-top .btn {
  padding: 3px;
  width: 100px;
}

.menu-row-icon-six {
  margin: 0px;
  padding: 0px;
  color: #e33333;
}

button:focus {
  outline: 0 !important;
  border: 0 !important;
  box-shadow: none !important;
}

.parent {
  display: flex;
  width: 100%;
  height: auto;
  padding: 2%;
}

.filler {
  flex-grow: 1;
}

p:hover {
  color: #27ae60 !important;
}
</style>