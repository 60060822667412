<template>
  <div>
    
      <!-- NAV-MENU -->
      <div
      class="nav-menu"
      style="height: 45px; width: 100%; padding: 0px; padding-top: 0.3%; text-align: center;"
    >
      <div class="left-buttons">
        <router-link to="/dashboard" style="text-decoration: none">
          <v-icon color="#fff" size="35" style="margin-left: 10px"
            >mdi-home</v-icon
          >
        </router-link>
      </div>
      <div class="filler"></div>
      <p style="font-weight: bold; font-size: 19px;">
        PLANS
      </p>
      <div class="filler"></div>
      <div class="right-buttons">
        <v-icon 
          color="#ffffff" 
          class="mr-5"
          @click="reloadPage"
          icon="mdi-cached"
          size="35"
          style="cursor: pointer;"
        />
      </div>
    </div>  

    <!-- COMPONENT -->
    <div class="component">
      <div class="graph bg-white mx-4 my-4">
        <bar-chart
          :width="800"
          :height="250"
          :chartData="chartData"
          :options="options"
        />
      </div>

      <div>
        <v-card>
          <div class="d-flex flex-column">
            <v-tabs
              class="tabs mb-4"
              v-model="tab"
              direction="horizontal"
              color="#26acc2"
              outlined
              style="border: 2px solid rgb(38, 172, 194);"
            >
              <v-tab :class="{ 'selectedItem' : tab == 'option-1' }" value="option-1" styles="margin-right: 5px;" @click="filterMeetings(null)">
                All Plans
              </v-tab>
              <v-divider
                class="vider"
                vertical
                inset
                color="rgb(38, 172, 194)"
                thickness="4px"
                style="size: 2px"
                dark
              ></v-divider>
              <v-tab :class="{ 'selectedItem' : tab == 'option-2' }" value="option-2" @click="filterMeetings('internal')"> 
                Internal Plans 
              </v-tab>
              <v-divider
                class="vider"
                vertical
                inset
                color="rgb(38, 172, 194)"
                thickness="4px"
                style="size: 2px"
                dark
              ></v-divider>
              <v-tab :class="{ 'selectedItem' : tab == 'option-3' }" value="option-3" @click="filterMeetings('external')"> 
                External Plans 
              </v-tab>
            </v-tabs>
            <v-window v-model="tab">
              <v-window-item value="option-1">
                <v-layout column style="height: 90vh;">       
                  <v-flex md12 style="overflow: unset"> 
                    <div v-if="store.getLoading">
                      <v-progress-linear
                        color="rgb(38, 172, 194)"
                        indeterminate
                        rounded
                        height="6"
                      ></v-progress-linear>
                    </div>
                    
                    <v-table fixed-header height="70vh" style="width:99vw;" >
                      <thead>
                        <tr
                          style="background-color: rgb(38, 172, 194); color: #fff; font-weight: bolder;"
                        >
                          <th
                            style="background-color: rgb(38, 172, 194); color: #fff; font-weight: bolder;"
                            class="text-left"
                          >
                            Code
                          </th>
                          <th
                            style="background-color: rgb(38, 172, 194); color: #fff; font-weight: bolder;"
                            class="text-left"
                          >
                            Plan Name
                          </th>
                          <th
                            style="background-color: rgb(38, 172, 194); color: #fff; font-weight: bolder;"
                            class="text-left"
                          >
                            Completion %
                          </th>
                          <th
                            style="background-color: rgb(38, 172, 194); color: #fff; align-content: flex-end; font-weight: bolder;"
                            class="text-left"
                          ></th>
                        </tr>
                      </thead>
                      <tbody
                        v-for="item in planList"
                        :key="item.planCode"
                        class="table-striped"
                      >
                        <tr 
                          style="height: 100%; cursor: pointer !important;"
                          @click="getPlanMilestoneList(item)"
                        >
                          <td
                            style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; padding-top: 20px; padding-bottom: 20px;"
                          >
                            {{ item.planCode }}
                          </td>
                          <td
                            style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                            class="name"
                          >
                            {{ item.planName }}
                          </td>
                          <td
                            style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                          >
                            {{ item.percentage }}%
                          </td>
                          <td
                            style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;align-self: flex-end;"
                          >
                            <v-icon
                              v-if="item.planDocuments.length > 0"
                              icon="mdi-chevron-right"
                              size="xx-large"
                              color="grey"
                              @click="getPlanMilestoneList(item)"
                            ></v-icon>
                            <v-icon
                              v-else
                              icon="mdi-chevron-right"
                              size="xx-large"
                              color="grey"
                              @click="getPlanMilestoneAlert()"
                            ></v-icon>
                          </td>
                        </tr>
                      </tbody>
                    </v-table>
                  </v-flex>
                </v-layout>
              </v-window-item>
              <v-window-item value="option-2">
                <v-layout column style="height: 90vh;">       
                  <v-flex md12 style="overflow: unset"> 
                    <v-table fixed-header height="60vh" style="width:99vw;">
                      <thead>
                        <tr
                          style="background-color: rgb(38, 172, 194); color: #fff; font-weight: bolder;"
                        >
                          <th
                            style="background-color: rgb(38, 172, 194); color: #fff; font-weight: bolder;"
                            class="text-left"
                          >
                            Code
                          </th>
                          <th
                            style="background-color: rgb(38, 172, 194); color: #fff; font-weight: bolder;"
                            class="text-left"
                          >
                            Plan Name
                          </th>
                          <th
                            style="background-color: rgb(38, 172, 194); color: #fff; font-weight: bolder;"
                            class="text-left"
                          >
                            Completion %
                          </th>
                          <th
                            style="background-color: rgb(38, 172, 194); color: #fff; align-content: flex-end; font-weight: bolder;"
                            class="text-left"
                          ></th>
                        </tr>
                      </thead>
                      <tbody
                        v-for="item in planList"
                        :key="item.planCode"
                        class="table-striped"
                      >
                        <tr style="height: 100%; cursor: pointer !important;"
                          @click="getPlanMilestoneList(item)">
                          <td
                            style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; padding-top: 20px; padding-bottom: 20px;"
                          >
                            {{ item.planCode }}
                          </td>
                          <td
                            style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                          >
                            {{ item.planName }}
                          </td>
                          <td
                            style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                          >
                            {{ item.percentage }}%
                          </td>
                          <td
                            style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; align-self: flex-end;"
                          >
                          <v-icon
                            icon="mdi-chevron-right"
                            size="xx-large"
                            color="grey"
                            @click="getPlanMilestoneList(item)"
                          ></v-icon>
                        </td>
                      </tr>
                    </tbody>
                  </v-table>
                </v-flex>
                </v-layout>
              </v-window-item>
              <v-window-item value="option-3">
                <v-layout column style="height: 90vh;">       
                  <v-flex md12 style="overflow: unset"> 
                    <v-table fixed-header height="60vh" style="width:99vw;">
                      <thead>
                        <tr
                          style="background-color: rgb(38, 172, 194); color: #fff; font-weight: bolder;"
                        >
                          <th
                            style="background-color: rgb(38, 172, 194); color: #fff; font-weight: bolder;"
                            class="text-left"
                          >
                            Code
                          </th>
                          <th
                            style="background-color: rgb(38, 172, 194); color: #fff; font-weight: bolder;"
                            class="text-left"
                          >
                            Plan Name
                          </th>
                          <th
                            style="background-color: rgb(38, 172, 194); color: #fff; font-weight: bolder;"
                            class="text-left"
                          >
                            Completion %
                          </th>
                          <th
                            style="background-color: rgb(38, 172, 194); color: #fff; align-content: flex-end; font-weight: bolder;"
                            class="text-left"
                          ></th>
                        </tr>
                      </thead>
                      <tbody
                        v-for="item in planList"
                        :key="item.planCode"
                        class="table-striped"
                      >
                        <tr style="height: 100%; cursor: pointer !important;"
                          @click="getPlanMilestoneList(item)">
                          <td
                            style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; padding-top: 20px; padding-bottom: 20px;"
                          >
                            {{ item.planCode }}
                          </td>
                          <td
                            style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                          >
                            {{ item.planName }}
                          </td>
                          <td
                            style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                          >
                            {{ item.percentage }}%
                          </td>
                          <td
                            style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; align-self: flex-end;"
                          >
                          <v-icon
                            v-if="item.planDocuments.length > 0"
                              icon="mdi-chevron-right"
                              size="xx-large"
                              color="grey"
                              @click="getPlanMilestoneList(item)"
                            ></v-icon>
                            <v-icon
                            v-else
                              icon="mdi-chevron-right"
                              size="xx-large"
                              color="grey"
                              @click="getPlanMilestoneAlert()"
                            ></v-icon>
                          </td>
                        </tr>
                      </tbody>
                    </v-table>
                  </v-flex>
                </v-layout>
              </v-window-item>
            </v-window>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import BarChart from "../components/BarChart.vue";
import UserData from "../components/repository/UserData";
import { useIndexStore } from "../stores/index";
import { useAuthStore } from "../stores/auth";
import { useAlertStore } from "../stores/alert";
import GlobalMethods from '../components/repository/GlobalMethods' ;

export default {
  data() {
    return {
      sort: {
        field: "",
        desc: true,
        category: null
      },
      toggle_exclusive: undefined,
      tab: "option-1",
      bodyName: [],
      initialPlanList: [],
      planList:[],
      chartData: {},
      options: {},  
      chartOptions: {}, 
      globalMethods: GlobalMethods,
      token: localStorage.getItem("token") ? JSON.stringify(localStorage.getItem("token")).slice(1, -1) : "", 
      auth: useAuthStore(),
      store: useIndexStore(),
      alert: useAlertStore(),
    };
  },
  components: { 
    "bar-chart": BarChart,
  },
  methods: {
    filterMeetings(category) {
      this.filterMeetingList('planCategory', category);

      this.filterPlans();
    },

    reloadPage() {
      window.location.reload();
    },

    clickedBar(index) {
      let plan = this.initialPlanList[index];
      this.getPlanMilestoneList(plan);
    },

    filterMeetingList(field, category = null) {
      if (category != null) {
        this.sort.category = category;
        this.planList = this.initialPlanList.filter((plan) => plan.planCategory == category);
      } else {
        this.sort.category = null;
        this.planList = this.initialPlanList;
      }

      this.getChartData();

      if (field == this.sort.field) {
        this.sort.desc = !this.sort.desc;
      } else {
        this.sort.field = field;
        this.sort.desc = true;
      }
    },
    
    filterPlans() {
      this.planCode = this.planList.map(
        (item) => item.planCode
      );

      this.chartData.labels = [];
      this.planCode.forEach((label) => {
        this.chartData.labels.push(label);
      }); 

      this.chartData.datasets = [];
      this.chartData.datasets.push({
        responsive: true,
        label: 'Plans',
        data: this.planList.map((item) => item.percentage),
        borderColor: '#26acc2',
        borderWidth: 1,
        backgroundColor: '#26acc2',
        fill: false,
      });
      
      this.options = { 
        onClick : (e, clickedElements) => {
          let index = clickedElements[0].index;
          let plan = this.initialPlanList[index];
          this.getPlanMilestoneList(plan);
        },
        
        onHover: (event, chartElement) => {
          event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
        },
      };
    },

    getPlanList() {
      const formData = new FormData();
      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "getPlanList");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      formData.append("from", 0);
      formData.append("count", 1);

      this.store.setLoading(true);
      axios.post(UserData.getBaseUrl(), formData).then((response) => {
        this.getPlanList = response.data;
        this.planList = this.getPlanList.planList;
        this.initialPlanList = this.planList;
        this.getPlanListArray = this.planList[0];
        this.store.setLoading(false);

        this.planCode = this.planList.map(
          (item) => item.planCode
        );

        this.chartData.labels = [];
        this.planCode.forEach((label) => {
          this.chartData.labels.push(label);
        });

        this.chartData.datasets = [];
        this.chartData.datasets.push({
          responsive: true,
          label: 'Plans',
          data: this.planList.map((item) => item.percentage),
          borderColor: '#26acc2',
          borderWidth: 1,
          backgroundColor: '#26acc2',
          fill: false,
        });

        this.options = { 
          onClick : (e, clickedElements) => {
            let index = clickedElements[0].index;
            let plan = this.initialPlanList[index];
            this.getPlanMilestoneList(plan);
          },
          
          onHover: (event, chartElement) => {
            event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
          },
        };
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },
    
    getChartData() {
      this.planCode = this.planList.map(
        (item) => item.planCode
      );

      this.chartData.labels = [];
      this.planCode.forEach((label) => {
        this.chartData.labels.push(label);
      });

      this.chartData.datasets = [];
      this.chartData.datasets.push({
        label: 'Plans',
        data: this.planList.map((item) => item.percentage),
        borderColor: '#26acc2',
        borderWidth: 1,
        backgroundColor: '#26acc2',
        fill: false,
      });

      this.options = { 
        onClick : (e, clickedElements) => {
          let index = clickedElements[0].index;
          let plan = this.initialPlanList[index];
          this.getPlanMilestoneList(plan);
        },
        
        onHover: (event, chartElement) => {
          event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
        },
      };
    },

    getPlanMilestoneList(item) {
    this.globalMethods.getPlanMilestones(item.planId).then(
      (response) => {
        this.milestones = response.data;
        if (this.milestones.planMilestones.length > 0) {
            this.store.setCurrentPlan(item);
            this.$router.replace('/plan'); 
          } else {
            this.getPlanMilestoneAlert();
          }
        }
     )
     },

    getPlanMilestoneAlert() {
      this.alert.showInfo("No Milestones Found");
    },
  },

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },
  },
  
  beforeMount() {
    this.getPlanList();
  },
};
</script>

<style scoped>
.nav-menu {
  margin-top: 0px;
  background-color: rgb(38, 172, 194);
  color: #fff;
  display: flex;
  margin-bottom: 1%;
  border: none;
  padding: 0px;
  margin-right: 15px;
  outline: none;
  justify-content: space-between;
}

.component {
  width: 99%;
  margin-left: 0.5%;
  margin-right: 0.5%;
  height: 100%;
}

.tabs {
  align-self: center;
  border-radius: 4px;
  justify-content: space-between;
  color: rgb(38, 172, 194);
  margin-bottom: 5px;
}

.selectedItem {
  background: rgb(38, 172, 194);
  color: #fff !important; 
}

.right-buttons {
  display: inline;
  float: right;
}

.table-striped a {
  text-decoration: none;
  color: #000;
}

.table-striped a:hover {
  color: rgb(72, 61, 139);
}

.table-striped a:active {
  color: rgb(72, 61, 139);
}

.table-striped:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-striped:hover {
  background-color: rgba(255, 255, 255, 255) !important;
}

.vider {
  border-color: rgba(0, 0, 0, 0.12) !important;
}

.name {
  cursor: pointer;
}

.name:hover {
  color: rgb(38, 172, 194);
}
</style>
