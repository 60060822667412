<template>
  <div>
    <v-list
      subheader
      three-line
      class="bg-transparent"
      v-if="signatureList && signatureList.length > 0"
    >
      <v-list-item-group
        v-model="selected"
        active-class="pink--text"
        multiple
      >
        <div
          v-for="(signature, index) in signatureList"
          :key="index"
        >
          <v-list-item 
            :value="signature.signatureFileId" 
            @click="selectSignature(signature)"
          >
            <v-list-item-content>
              <v-row align="center" class="spacer">
                <v-col cols="12" md="12" sm="12">
                  <v-list-item-title class="text-h6 text-wrap">
                    {{ signature.signatureFileTitle }}
                  </v-list-item-title>

                  <v-list-item-subtitle class="text-wrap" v-if="signature.currentUserName != ''">
                    Pending From - {{ signature.currentUserName }}
                  </v-list-item-subtitle>

                  <v-list-item-subtitle class="text-wrap" v-if="signature.currentUserName == '' && signature.signatureSigned">
                    All Users Have Signed
                  </v-list-item-subtitle>

                  <v-list-item-subtitle class="text-wrap">
                    Due on {{ formatDate(signature.signatureFileDueDate) }}
                  </v-list-item-subtitle>

                  <v-list-item-subtitle class="text-wrap">
                    {{ signature.signatureFileInfo.companyName }}
                  </v-list-item-subtitle>
                </v-col>
              </v-row>
            </v-list-item-content>
            <v-list-item-actions>
              <v-btn 
                class="my-2 mx-1 float-left text-error"
                :loading="viewLoading[signature.eboardFileInfo.itemId]"
                @click="viewDocument(signature)"
              >
                View
              </v-btn>
              <v-btn 
                class="my-2 mx-1 float-right text-error"
                :loading="signatureLoading[signature.signatureFileInfo.itemId]"
                @click="signDocument(signature)"
                :disabled="digital_signatures ? ((user.userId == signature.currentUserId && !signature.currentUserSigned) ? false : true) : true"
              >
                Sign
              </v-btn>
            </v-list-item-actions>
          </v-list-item>
          <v-divider
            v-if="index < signatureList.length - 1"
            :key="index"
          ></v-divider>
        </div>
      </v-list-item-group>
    </v-list>

    <div style="width: 100%;" v-else>
      <div
        class="w-100 p-4 d-flex align-items-center justify-content-center"
        style="height: 64vh;"
      >
        <v-table 
          color="transparent"
          style="width: 1000px; background-color: transparent;"
        >
          <tbody>
            <tr>
              <td 
                colspan="5"
                class="text-center"
              >
                <span>
                  No Signatures Found
                </span>
              </td>
            </tr>
          </tbody>
        </v-table>
      </div>
    </div>
  </div>
</template>

<script>
import { useIndexStore } from "../../stores";
import { useAuthStore } from "../../stores/auth";
import GlobalMethods from "../repository/GlobalMethods";

var moment = require("moment");

export default {
  name: 'Signatures',

  props: {
    signatureList: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },

    signature_document: {
      get() {
        return this.store.getSignatureDocument;
      },
      set(val) {
        this.store.setSignatureDocument(val);
      },
    },

    digital_signatures: {
      get() {
        return this.auth.getDigitalSignatures;
      },
      set(value) {
        this.auth.setDigitalSignatures(value);
      },
    },
  },

  data: () => ({
    tasks: [],
    selected: [],
    moment: moment,
    viewLoading: {},
    signatureLoading: {},
    globalMethods: GlobalMethods,
    store: useIndexStore(),
    auth: useAuthStore(),
  }),

  methods: {
    formatDate(date) {
      return moment.unix(date).format("DD MMM YYYY, h:mm A");
    },

    selectSignature(signature) {
      this.$emit('open_signatue', signature);
    },

    viewDocument(document) {
      this.viewLoading[document.eboardFileInfo.itemId] = true;
      this.store.setSignatureDocument(false);
      this.getDocument(document.eboardFileInfo);
    },

    signDocument(document) {
      this.signatureLoading[document.signatureFileInfo.itemId] = true;
      this.store.setSignatureDocument(true);
      this.store.setSignatureText(document.currentUserName);
      this.getDocument(document.signatureFileInfo);
    },

    getDocument(item) {
      this.store.setPdfType(false);
      if (item.itemExtension == 'pdf') {
        this.briefcaseDocument(item);
      } else {
        this.openDocument(item);
      }
    },

    openPdf(item, url = null) {
      this.store.setBriefcaseUrl(url);
      this.$emit('open_pdf', item);
    },

    openDocument(item, url = null) {
      this.store.setBriefcaseUrl(url);
      this.$emit('open_document', item);
    },

    briefcaseDocument(item) {
      this.globalMethods.downloadEbrDocument(item.itemUrl).then(async (response) => {
        if (response.status == 200) {
          let fileType = response.headers['content-type'];
          let fileData = new Blob([response.data], { type: fileType });
          let blob = window.URL.createObjectURL(fileData);
          this.openPdf(item, blob);
          this.viewLoading[item.itemId] = false;
          this.signatureLoading[item.itemId] = false;
        } else {
          this.viewLoading[item.itemId] = false;
          this.signatureLoading[item.itemId] = false;
          console.log("Response: ", response);
        }
      }).catch((e) => {
        this.viewLoading[item.itemId] = false;
        this.signatureLoading[item.itemId] = false;
        console.log("Error: ", e);
      });
    },
  },
}
</script>