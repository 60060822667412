import { defineStore } from 'pinia';

export const useIndexStore = defineStore('index', {
  persist: true,
  state: () => {
    return {
      loading: false,
      session_loading: false,
      authenticated: false,
      document_id: null,
      briefcase_url: null,
      approval_id: null,
      category_id: null,
      category_name: "",
      body_id: null,
      cds_id: null,
      cds_bs_id: null,
      evaluation_id: null,
      evaluatee_id: null,
      current_component: "View01",
      current_meeting_component: "Meetings",
      dashboard_component: "MyInbox",
      risk_management_component: "MyInbox",
      switch_component: "Tasks",
      document_component: "pspdfkit",
      dialog_password: false,
      recover_password: false,
      verify_user: false,
      dialog_notes: false,
      dialog_filter: false,
      dialog_support: false,
      dialog_menus: false,
      dialog_events_calendar: false,
      dialog_reply: false,
      dialog_rsvp: false,
      dialog_rci: false,
      rci_type: 0,
      dialog_task: false,
      dialog_participants: false,
      dialog_users: false,
      dialog_conference: false,
      dialog_results: false,
      evaluated_status: false,
      current_meeting: {},
      current_agenda: {},
      current_matter: {},
      current_conference: {},
      current_approval: {},
      current_plan: {},
      dialog_approval: false,
      dialog_approve: false,
      dialog_reject: false,
      dialog_comment: false,
      dialog_reply_comment: false,
      dialog_agenda_comments: false,
      dialog_matters_comments: false,
      dialog_evaluation_comments: false,
      dialog_evaluation_question_comments: false,
      comments_type: {},
      close_pdf: false,
      open_pdf: false,
      pdf_type: false,
      current_pdf: {},
      current_document: {},
      current_task: {},
      dashboard_meeting: false,
      menu_option: null,
      approval_document: false,
      dialog_news: false,
      dialog_news_image: false,
      dialog_document_viewer: false,
      selected_comment: {},
      session_overlay: false,
      session_warning_overlay:false,
      overlay_type: false,
      pspdfkit_overlay: false,
      trigger: false,
      to_annotation_ids: [],
      signature_document: false,
      signature_text: "",
      selected_annotation: {},
    }
  },
  getters: {
    //risk
    getRiskManagementComponent() {
      return this.risk_management_component
    },
    getLoading() {
      return this.loading
    },
    getSessionLoading() {
      return this.session_loading
    },
    getAuthentication() {
      return this.authenticated
    },
    getDocumentId() {
      return this.document_id
    },
    getBriefcaseUrl() {
      return this.briefcase_url
    },
    getApprovalId() {
      return this.approval_id
    },
    getCategoryId() {
      return this.category_id
    },
    getCategoryName() {
      return this.category_name
    },
    getBodyId() {
      return this.body_id
    },
    getCdsId() {
      return this.cds_id
    },
    getCdsBsId() {
      return this.cds_bs_id
    },
    getEvaluationId() {
      return this.evaluation_id
    },
    getEvaluateeId() {
      return this.evaluatee_id
    },
    getCurrentComponent() {
      return this.current_component
    },
    getCurrentMeetingComponent() {
      return this.current_meeting_component
    },
    getDashboardComponent() {
      return this.dashboard_component
    },
    getSwitchComponent() {
      return this.switch_component
    },
    getDocumentComponent() {
      return this.document_component
    },
    getDialogPassword() {
      return this.dialog_password
    },
    getRecoverPassword() {
      return this.recover_password
    },
    getVerifyUser() {
      return this.verify_user
    },
    getDialogNotes() {
      return this.dialog_notes
    },
    getDialogFilter() {
      return this.dialog_filter
    },
    getDialogSupport() {
      return this.dialog_support
    },
    getDialogMenus() {
      return this.dialog_menus
    },
    getDialogEventsCalendar() {
      return this.dialog_events_calendar
    },
    getDialogReply() {
      return this.dialog_reply
    },
    getDialogRSVP() {
      return this.dialog_rsvp
    },
    getDialogAgendaComments() {
      return this.dialog_agenda_comments
    },
    getDialogMattersComments() {
      return this.dialog_matters_comments
    },
    getDialogEvaluationComments() {
      return this.dialog_evaluation_comments
    },
    getDialogEvaluationQuestionComments() {
      return this.dialog_evaluation_question_comments
    },
    getCommentsType() {
      return this.comments_type
    },
    getDialogRCI() {
      return this.dialog_rci
    },
    getRCIType() {
      return this.rci_type
    },
    getDialogTask() {
      return this.dialog_task
    },
    getDialogParticipants() {
      return this.dialog_participants
    },
    getDialogUsers() {
      return this.dialog_users
    },
    getDialogConference() {
      return this.dialog_conference
    },
    getDialogResults() {
      return this.dialog_results
    },
    getCurrentMeeting() {
      return this.current_meeting
    },
    getCurrentAgenda() {
      return this.current_agenda
    },
    getCurrentMatter() {
      return this.current_matter
    },
    getCurrentConference() {
      return this.current_conference
    },
    getEvaluatedStatus() {
      return this.evaluated_status
    },
    getCurrentApproval() {
      return this.current_approval
    },
    getCurrentPlan() {
      return this.current_plan
    },
    getDialogApproval() {
      return this.dialog_approval
    },
    getDialogApprove() {
      return this.dialog_approve
    },
    getDialogReject() {
      return this.dialog_reject
    },
    getDialogComment() {
      return this.dialog_comment
    },
    getDialogReplyComment() {
      return this.dialog_reply_comment
    },
    getClosePdf() {
      return this.close_pdf
    },
    getOpenPdf() {
      return this.open_pdf
    },
    getPdfType() {
      return this.pdf_type
    },
    getCurrentPdf() {
      return this.current_pdf
    },
    getCurrentDocument() {
      return this.current_document
    },
    getCurrentTask() {
      return this.current_task
    },
    getDashboardMeeting() {
      return this.dashboard_meeting
    },
    getMenuOption() {
      return this.menu_option
    },
    getApprovalDocument() {
      return this.approval_document
    },
    getDialogNews() {
      return this.dialog_news
    },
    getDialogNewsImage() {
      return this.dialog_news_image
    },
    getDialogDocumentViewer() {
      return this.dialog_document_viewer
    },
    getSelectedComment() {
      return this.selected_comment
    },
    getSessionOverlay() {
      return this.session_overlay
    },
    getSessionWarningOverlay() {
      return this.session_warning_overlay
    },
    getOverlayType() {
      return this.overlay_type
    },
    getPSPDFKitOverlay() {
      return this.pspdfkit_overlay
    },
    getTrigger() {
      return this.trigger
    },
    getToAnnotationIds() {
      return this.to_annotation_ids
    },
    getSignatureDocument() {
      return this.signature_document
    },
    getSignatureText() {
      return this.signature_text
    },
    getSelectedAnnotation() {
      return this.selected_annotation
    },
  },
  actions: {
    setLoading(val) {
      this.loading = val
    },
    setSessionLoading(val) {
      this.session_loading = val
    },
    setAuthentication(status) {
      this.authenticated = status
    },
    setDocumentId(id) {
      this.document_id = id
    },
    setBriefcaseUrl(url) {
      this.briefcase_url = url
    },
    setApprovalId(id) {
      this.approval_id = id
    },
    setCategoryId(id) {
      this.category_id = id
    },
    setCategoryName(name) {
      this.category_name = name
    },
    setBodyId(id) {
      this.body_id = id
    },
    setCdsId(id) {
      this.cds_id = id
    },
    setCdsBsId(id) {
      this.cds_bs_id = id
    },
    setEvaluationId(id) {
      this.evaluation_id = id
    },
    setEvaluateeId(id) {
      this.evaluatee_id = id
    },
    setCurrentComponent(component) {
      this.current_component = component
    },
    setCurrentMeetingComponent(component) {
      this.current_meeting_component = component
    },
    setDashboardComponent(component) {
      this.dashboard_component = component
    },
    setSwitchComponent(component) {
      this.switch_component = component
    },
    setDocumentComponent(component) {
      this.document_component = component
    },
    setDialogPassword(value) {
      this.dialog_password = value
    },
    setRecoverPassword(value) {
      this.recover_password = value
    },
    setVerifyUser(value) {
      this.verify_user = value
    },
    setDialogNotes(value) {
      this.dialog_notes = value
    },
    setDialogFilter(value) {
      this.dialog_filter = value
    },
    setDialogSupport(value) {
      this.dialog_support = value
    },
    setDialogMenus(value) {
      this.dialog_menus = value
    },
    setDialogEventsCalendar(value) {
      this.dialog_events_calendar = value
    },
    setDialogReply(value) {
      this.dialog_reply = value
    },
    setDialogRSVP(value) {
      this.dialog_rsvp = value
    },
    setDialogRCI(value) {
      this.dialog_rci = value
    },
    setRCIType(value) {
      this.rci_type = value
    },
    setDialogTask(value) {
      this.dialog_task = value
    },
    setDialogParticipants(value) {
      this.dialog_participants = value
    },
    setDialogUsers(value) {
      this.dialog_users = value
    },
    setDialogConference(value) {
      this.dialog_conference = value
    },
    setDialogResults(value) {
      this.dialog_results = value
    },
    setCurrentMeeting(value) {
      this.current_meeting = value
    },
    setCurrentAgenda(agenda) {
      this.current_agenda = agenda
    },
    setCurrentMatter(matter) {
      this.current_matter = matter
    },
    setCurrentConference(value) {
      this.current_conference = value
    },
    setEvaluatedStatus(status) {
      this.evaluated_status = status
    },
    setCurrentApproval(value) {
      this.current_approval = value
    },
    setCurrentPlan(value) {
      this.current_plan = value
    },
    setDialogApproval(status) {
      this.dialog_approval = status
    },
    setDialogApprove(status) {
      this.dialog_approve = status
    },
    setDialogReject(status) {
      this.dialog_reject = status
    },
    setDialogComment(status) {
      this.dialog_comment = status
    },
    setDialogReplyComment(status) {
      this.dialog_reply_comment = status
    },
    setDialogAgendaComments(status) {
      this.dialog_agenda_comments = status
    },
    setDialogMattersComments(status) {
      this.dialog_matters_comments = status
    },
    setDialogEvaluationComments(status) {
      this.dialog_evaluation_comments = status
    },
    setDialogEvaluationQuestionComments(status) {
      this.dialog_evaluation_question_comments = status
    },
    setCommentsType(value) {
      this.comments_type = value
    },
    setClosePdf(status) {
      this.close_pdf = status
    },
    setOpenPdf(status) {
      this.open_pdf = status
    },
    setPdfType(status) {
      this.pdf_type = status
    },
    setCurrentPdf(pdf) {
      this.current_pdf = pdf
    },
    setCurrentDocument(document) {
      this.current_document = document
    },
    setCurrentTask(task) {
      this.current_task = task
    },
    setDashboardMeeting(status) {
      this.dashboard_meeting = status
    },
    setMenuOption(value) {
      this.menu_option = value
    },
    setApprovalDocument(status) {
      this.approval_document = status
    },
    setDialogNews(status) {
      this.dialog_news = status
    },
    setDialogNewsImage(status) {
      this.dialog_news_image = status
    },
    setDialogDocumentViewer(status) {
      this.dialog_document_viewer = status
    },
    setSelectedComment(comment) {
      this.selected_comment = comment
    },
    setSessionOverlay(status) {
      this.session_overlay = status
    },
    setSessionWarningOverlay(status) {
      this.session_warning_overlay = status
    },
    setOverlayType(type) {
      this.overlay_type = type
    },
    setPSPDFKitOverlay(status) {
      this.pspdfkit_overlay = status
    },
    setTrigger(status) {
      this.trigger = status
    },
    setToAnnotationIds(ids) {
      this.to_annotation_ids = ids;
    },
    setSignatureDocument(status) {
      this.signature_document = status
    },
    setSignatureText(value) {
      this.signature_text = value
    },
    setSelectedAnnotation(value) {
      this.selected_annotation = value
    },
  }
});