<template>
  <div>
    <!-- NAV-MENU -->
    <div
      class="nav-menu"
      style="height: 45px; width: 100%; padding: 0px; padding-top: 0.3%; text-align: center;"
    >
      <router-link to="/dashboard" style="text-decoration: none">
        <v-icon color="#fff" size="35" style="margin-left: 10px"
          >mdi-home</v-icon
        >
      </router-link>
      <p style="font-weight: bold; font-size: 24px; margin-left: 40%">
        Discussion
      </p>
    </div>

    <!-- COMPONENT -->
    <div
      style="margin-top: 0.5%; margin-bottom: 0.5%; background-color: #f8f9f9; border-top: 5px solid rgb(72, 61, 139); width: 98%; margin-left: 1%; margin-right: 1%;"
    >
      <div
        class="two"
        style="margin-top: 7px; position: absolute; background-color: #f5f5f5; width: 98%; height: 100vh; margin-right: 1%; z-index: 3;"
      >
        <iframe width="100%" height="100%" name="discussions" :src="discussion_url" sandbox="allow-scripts"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import UserData from "../../components/repository/UserData";
import { useAuthStore } from "../../stores/auth";
import { useIndexStore } from "../../stores/index";

export default {
  name: "Discussion",

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },
  },

  data() {
    return {
      discussion_url: null,
      token: localStorage.getItem("token") ? JSON.stringify(localStorage.getItem("token")).slice(1, -1) : "",
      userPassword: localStorage.getItem("userPassword") ? JSON.stringify(localStorage.getItem("userPassword")).slice(1, -1) : "",
      store: useIndexStore(),
      auth: useAuthStore(),
    }
  },

  mounted() {
    this.getDiscussionUrl();
  },

  methods: {
    getDiscussionUrl() {
      const form = new FormData();
      form.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      form.append("userName", UserData.getUserName() ? UserData.getUserName() : this.user.userInfo.userId);
      form.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      form.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      form.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      form.append("model", "openBrowser");
      form.append("urlType", 3);

      axios.post(UserData.getBaseUrl(), form).then((response) => {
        if (response.data.success == 1) {
          this.discussion_url = response.data.openUrl;
        }
      });
    },
  }
}
</script>

<style scoped>
body {
  margin: 0;
  padding: 0;
}

.nav-menu {
  margin-top: 0px;
  background-color: rgb(72, 61, 139);
  color: #fff;
  display: flex;
}

.nav-menu .btn {
  background-color: rgb(72, 61, 139);
  border: none;
  padding: 0px;
  margin-right: 15px;
  color: white;
  outline: none;
}
</style>