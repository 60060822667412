export default function authHeader() {
  let access_token = localStorage.getItem('admin_access_token');
  let refresh_token = localStorage.getItem('admin_refresh_token');
  let company_id = localStorage.getItem('admin_company_id');
  let company_code = localStorage.getItem('admin_company_code');

  if (access_token != 'undefined') {
    return {
      'Authorization': access_token,
      'Refresh-Token': refresh_token,
      'Company-Id': company_id,
      'Company-Code': company_code,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  } else {
    return {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  }
}