<template>
    <Doughnut
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
  </template>
    
  <script>
  import { Doughnut } from "vue-chartjs";
  import {
    Chart as ChartJS,
  CategoryScale,
  ArcElement,
  Title,
  Tooltip,
  Legend
  } from "chart.js";
  
  ChartJS.register(
  CategoryScale,
  ArcElement,
  Title,
  Tooltip,
  Legend
  );
  
  export default {
    name: "DoughnutChart",
    components: { 
      // eslint-disable-next-line
      Doughnut 
    },
    props: {
      chartId: {
        type: String,
        default: "DoughnutChart",
      },
      datasetIdKey: {
        type: String,
        default: "label",
      },
      chartData: {
        type: Object,
        default: () => {},
      },
      width: {
        type: Number,
        default: 400,
      },
      height: {
        type: Number,
        default: 400,
      },
      cssClasses: {
        default: "",
        type: String,
      },
      styles: {
        type: Object,
        default: () => {},
      },
      plugins: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false,
        },
      };
    },
  };
  </script>