<template>
  <div style="height: 100vh;">
    <div v-if="store.getLoading">
      <v-progress-linear
        color="rgb(86, 182, 229)"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
    </div>

    <v-table 
      v-if="categoryMeetings && categoryMeetings.length > 0"
      :height="currentRouteName !== 'dashboard' ? '' : ''"
    >
      <div
        v-for="(item, i) in categoryMeetings"
        :key="i"
        :style="
          'width: 98%; margin: 1%; border: 1px solid #f2f2f2; height: auto; display: flex; padding: 1%; padding-bottom: 0px;' 
          + getStatusBorder(item.meetingStatusId)
        "
      >
        <span
          style="display: inline; cursor: pointer;"
          @click="setCurrentMeeting(item)"
        >
          <p style="font-size: 19px">{{ item.itemName }}</p>
          <p style="color: grey;" v-if="currentRouteName !== 'dashboard'">{{ item.itemVenueTitle }}</p>
          <p style="color: grey;">
            <span 
              style="font-weight: bold;"
              :class="getStatusClass(item.meetingStatusId)"
            >
              {{ item.meetingStatusName }}
            </span> - {{ formatDate(item.itemVenueDate) }}
          </p>
        </span>
        <span style="flex-grow: 1"></span>
        <span style="display: inline">
          <table style="margin-top: 30px; font-size: 17px;">
            <tr>
              <td style="padding-right: 20px;" v-if="item.conferenceList.length > 0">
                <v-btn
                  class="btn mt-n3"
                  variant="text"
                  @click="startVirtualMeeting(item.conferenceList)"
                >
                  <v-icon size="35" style="color: rgb(23, 162, 184);"
                    >mdi-calendar-start</v-icon
                  >
                  
                  <span v-if="item.conferenceList[0].isHost == 1">Start</span>
                  <span v-else>Join</span>
                </v-btn>
              </td>
              <td 
                style="padding-right: 20px; cursor: pointer" 
                @click="getParticipants(item)" 
                v-if="currentRouteName !== 'dashboard'"
              >
                <v-icon size="35" style="color: rgb(86, 182, 229);"
                  >mdi-account-group</v-icon
                >
                Participants
              </td>
              <td 
                style="padding-right: 20px"
                v-if="currentRouteName !== 'dashboard'"
              >
                <v-menu location="bottom">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      class="btn mt-n3"
                      v-bind="props"
                      variant="text"
                    >
                      <v-icon size="35" style="color: rgb(86, 182, 229);"
                        >mdi-email</v-icon
                      >
                      {{ item.itemUserAction ? getRSVP(item.itemUserAction, item.itemAction) : 'RSVP' }}
                    </v-btn>
                  </template>

                  <div class="mt-2">
                    <RSVP 
                      :actions="item.itemAction" 
                      :meeting-id="item.itemId" 
                      :user-action="item.itemUserAction"
                      :event-id="item.eventId"
                    />
                  </div>
                </v-menu>
              </td>
              <td 
                style="padding-right: 20px"
                v-if="currentRouteName !== 'dashboard'"
              >
                <v-btn
                  class="btn mt-n3"
                  variant="text"
                  @click="openRCIDialog"
                >
                  <v-icon size="35" style="color: rgb(86, 182, 229);"
                    >mdi-message-text</v-icon
                  >
                  RCI
                </v-btn>
              </td>
            </tr>
          </table>
        </span>
      </div>

      <div class="text-center">
        <MeetingParticipants :participants="meetingParticipants" :options="attendanceOptions" />
      </div>
    </v-table>

    <v-table v-else>
      <div
        class="text-center"
        style="width: 98%; margin: 1%; border: 1px solid #f2f2f2; height: auto; padding: 1%; padding-bottom: 0px;"
      >
        <span v-if="currentRouteName !== 'dashboard'">
          No Meetings Found For This Category
        </span>
        <span v-else>
          Loading Meetings... Please Wait
        </span>
      </div>
    </v-table>
  </div>
</template>

<script>
var moment = require("moment");
import axios from "axios";
import UserData from "../repository/UserData";
import { useAuthStore } from "../../stores/auth";
import { useIndexStore } from "../../stores/index";
import RSVP from '../Forms/RSVP.vue';
import MeetingParticipants from '../Forms/MeetingParticipants.vue';

export default {
  name: "View01",

  props: {
    categoryMeetings: {
      type: Array,
      default: () => [],
    },
  },

  components: {
    RSVP,
    MeetingParticipants,
  },

  data: () => ({
    attendanceOptions: [],
    token: localStorage.getItem("token") ? JSON.stringify(localStorage.getItem("token")).slice(1, -1) : "",
    auth: useAuthStore(),
    store: useIndexStore(),
    meetingParticipants: [],
  }),

  beforeMount() {
    this.store.setDialogRCI(false);
  },

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },
    
    dialog_rci() {
      return this.store.getDialogRCI;
    },

    dialog_rsvp() {
      return this.store.getDialogRSVP;
    },

    dashboardComponent() {
      return this.store.getDashboardComponent;
    },

    currentRouteName() {
      return this.$route.name;
    },
  },
  
  methods: {
    formatDate(date) {
      return moment(date).format("DD MMM YYYY, h:mm a");
    },

    getRSVP(itemUserAction, itemAction) {
      let action = 'No Action';
      action = itemAction.find(item => item.actionId == itemUserAction);
      return action.actionName;
    },

    startVirtualMeeting(conferenceList) {
      let conference = conferenceList[0];
      if (conference.password !== "" && conference.conferenceId !== "") {
        this.store.setCurrentConference(conference);
        this.$router.push('/initiate-meeting/' + conference.conferenceId);
      }
    },

    setCurrentMeeting(meeting) {
      this.store.setCurrentMeeting(meeting);
      this.store.setCurrentComponent('view-02');

      if (this.currentRouteName === 'dashboard') {
        this.store.setDashboardComponent('MyInbox');
        this.store.setDashboardMeeting(true);
        this.$router.push('/Meetings');
      }
    },

    openRCIDialog() {
      this.store.setRCIType(1);
      this.store.setDialogRCI(true);
    },

    getStatusClass(id) {
      var status = '';
      switch(id) {
        case "1":
          status = 'text-success';
          break;
        case "2":
          status = 'text-info';
          break;
        case "3":
          status = 'text-warning';
          break;
        case "5":
          status = 'text-teal';
          break;
        case "6":
          status = 'text-purple';
          break;
        case "7":
          status = 'text-info';
          break;
        default:
          status = 'text-black';
          break;
      }

      return status;
    },

    getStatusBorder(id) {
      var status = 'border-left: 5px solid ';
      switch(id) {
        case "1":
          status += 'rgb(40, 167, 69); background-color: rgb(40, 167, 69, .1);';
          break;
        case "2":
          status += 'rgb(23, 162, 184); background-color: rgb(23, 162, 184, .1);';
          break;
        case "3":
          status += 'rgb(255, 193, 7); background-color: rgb(255, 193, 7, .1);';
          break;
        case "5":
          status += 'rgb(0, 150, 136); background-color: rgb(0, 150, 136, .1);';
          break;
        case "6":
          status += 'rgb(156, 39, 176); background-color: rgb(156, 39, 176, .1);';
          break;
        case "7":
          status += 'rgb(23, 162, 184); background-color: rgb(23, 162, 184, .1);';
          break;
        default:
          status += 'rgb(0, 0, 0); background-color: rgb(0, 0, 0, .1);';
          break;
      }

      return status;
    },

    getParticipants(item) {
      axios.post(UserData.getBaseUrl(), this.getParticipantsForMeeting(item.itemId)).then((response) => {
        this.meetingParticipants = response.data.usersList;
        this.attendanceOptions = response.data.attendanceOptions;
        this.store.setDialogParticipants(true);
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    getParticipantsForMeeting(meetingId = 0) {
      const formData = new FormData();

      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "getParticipantListForMeeting");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      formData.append("itemId", meetingId);

      return formData;
    },
  },
};
</script>

<style scoped>
p {
  text-decoration: none;
  color: #000000;
}

p:hover {
  color: rgb(86, 182, 229);
}
</style>