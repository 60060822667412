<template>
  <div>
    <!-- NAV-MENU -->
    <div
      class="nav-menu"
      style="height: 45px; width: 100%; padding: 0px; padding-top: 0.3%; text-align: center;"
    >
      <router-link to="/dashboard" style="text-decoration: none">
        <v-icon color="#fff" size="35" style="margin-left: 10px"
          >mdi-home</v-icon
        >
      </router-link>
      <p style="font-weight: bold; font-size: 24px; margin-left: 40%">
        Resources
      </p>
      <v-spacer></v-spacer>
      <v-btn
        class="text-white"
        variant="text"
        @click="openBoardDirectory"
      >
        <v-icon 
          size="35" 
          class="mr-1"
          icon="mdi-card-account-mail-outline"
        ></v-icon>
        Board Directory
      </v-btn>
    </div>

    <!-- COMPONENT -->
    <div class="component">
      <div class="left" style="color: #000000">
        <div class="left-top">
          <v-list 
            density="compact"
            style="background-color: #f8f9f9"
          >
            <v-list-item-title style="color: rgb(227, 58, 58); padding: 15px;">
              <v-icon style="margin-right: 10px" color="rgb(227, 58, 58)"
                >mdi-server</v-icon
              >
              Dynamic Resources
            </v-list-item-title>
            <v-list-item-content>
              <v-card
                class="mx-auto"
              >
                <v-list density="compact">
                  <v-list-item
                    v-for="(item, index) in itemSubArrayDynamicData.itemSubArray"
                    :key="index"
                    :value="item"
                    active-color="rgb(227, 58, 58)"
                    @click="loadDirectoriesAndFiles(item)"
                  >
                    <v-list-item-title class="text-wrap mt-3 mb-n5">
                      <a href="#" @click="loadDirectoriesAndFiles(item)">
                        {{ item.itemName }}
                      </a>
                      <v-divider thickness="2px"></v-divider>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-list-item-content>
          </v-list>
        </div>

        <div class="left-bottom">
          <v-list 
            density="compact"
            style="background-color: #f8f9f9"
          >
            <v-list-item-title style="color: rgb(227, 58, 58); padding: 15px;">
              <v-icon style="margin-right: 10px" color="rgb(227, 58, 58)"
                >mdi-server</v-icon
              >
              Static Resources
            </v-list-item-title>
            <v-list-item-content>
              <v-card
                class="mx-auto"
              >
                <v-list density="compact">
                  <v-list-item
                    v-for="(item, index) in itemSubArrayStaticData.itemSubArray"
                    :key="index"
                    :value="item"
                    active-color="rgb(227, 58, 58)"
                    @click="loadDirectoriesAndFiles(item)"
                  >
                    <v-list-item-title class="text-wrap mt-3 mb-n5">
                      <a href="#" @click="loadDirectoriesAndFiles(item)">
                        {{ item.itemName }}
                      </a>
                      <v-divider thickness="2px"></v-divider>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-list-item-content>
          </v-list>
        </div>

        <div class="pb-2">
          <v-btn 
            block
            color="rgb(227, 58, 58)"
            class="text-white"
            prepend-icon="mdi-briefcase-variant"
            @click="goToArchives"
          >
            Archives
          </v-btn>
        </div>
      </div>

      <div class="right">
        <!-- Header 1 -->
        <div
          class="parent"
          style="height: 45px; width: 100%; background-color: #fff;"
        >
          <div class="left-buttons">
            <v-icon
              @click="loadItemFromHistory()"
              style="float: left; margin-top: 5px; margin-left: 10px; background-color: rgb(227, 58, 58); color: #fff;"
              size="25"
              >mdi-chevron-left</v-icon
            >
            <p
              style="float: left; font-weight: bold; font-size: 24px; text-align: left; margin-left: 10px; color: rgb(227, 58, 58);"
            >
              Resources
            </p>
          </div>
          <div class="filler">
            <div class="dropdown-table float-right" style="display: inline; margin-top: 5px;">
              <v-menu>
                <template v-slot:activator="{ props }">
                  <v-btn 
                    variant="text" 
                    v-bind="props"
                    class="btn"
                    :style="getFilterStyle(9)"
                  >
                    Descending
                    <v-icon>mdi-swap-vertical-bold</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item style="display: block">
                    <v-list-item-title
                      style="padding: 12px; font-size: 16px; cursor: pointer;"
                      v-on:click="doSort('itemName', 9)"
                      >Name
                      <span v-if="sort.field == 'itemName'"
                        >({{ sort.desc ? "desc" : "asc" }})</span
                      >
                    </v-list-item-title>

                    <v-list-item-title
                      style="padding: 12px; font-size: 16px; cursor: pointer;"
                      v-on:click="doSort('itemSize', 9)"
                      >Size
                      <span v-if="sort.field == 'itemSize'"
                        >({{ sort.desc ? "desc" : "asc" }})</span
                      >
                    </v-list-item-title>

                    <v-list-item-title
                      style="padding: 12px; font-size: 16px; cursor: pointer;"
                      v-on:click="doSort('itemUploadedBy', 9)"
                      >Submitted By
                      <span v-if="sort.field == 'itemUploadedBy'"
                        >({{ sort.desc ? "desc" : "asc" }})</span
                      >
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
          <div class="right-buttons" style="display: inline; margin-top: 5px;">
            <div style="display: inline; margin-right: 10px">
              <v-btn
                style="padding: 0px"
                class="btn"
                variant="text"
                v-on:click="doSort('itemLastUpdatedOn', 8)"
                :style="getFilterStyle(8)"
              >
                Date Modified
                <span v-if="sort.field == 'itemLastUpdatedOn'"
                  >({{ sort.desc ? "desc" : "asc" }})</span
                >
              </v-btn>
            </div>
            <div style="display: inline; margin-right: 10px">
              <v-btn
                style="padding: 0px"
                class="btn"
                variant="text"
                @click="changeListView(1)"
                :style="getFilterStyle(1)"
              >
                List
              </v-btn>
            </div>
            <div style="display: inline; margin-right: 10px">
              <v-btn
                style="padding: 0px"
                class="btn"
                variant="text"
                @click="changeListView(2)"
                :style="getFilterStyle(2)"
              >
                Grid
              </v-btn>
            </div>
          </div>
        </div>

        <!-- Header 2 -->
        <div
          class="parent"
          style="height: 55px; line-height: 55px; width: 100%; background-color: #f1f1f1;"
        >
          <p
            style="font-weight: bold; font-size: 24px; margin-left: 10px; float: left; color: rgb(227, 58, 58);"
          >
            {{ joinNames() }}
          </p>
        </div>

        <!-- Table Loader -->
        <div v-if="store.getLoading">
          <v-progress-linear
            color="rgb(227, 58, 58)"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </div>

        <!-- List View -->
        <div v-if="list.view != 2">
          <v-table v-if="sortedData2.length > 0">
            <thead>
              <tr style="background-color: rgb(227, 58, 58); color: #fff; font-weight: bolder;">
                <td class="text-left">No.</td>
                <td class="text-left"></td>
                <td class="text-left">Name</td>
                <td class="text-left">Size</td>
                <td class="text-left">Modified On</td>
                <td class="text-left">Submitted By</td>
              </tr>
            </thead>
            <tbody
              v-for="(item, index) in sortedData2" 
              :key="item.itemId"
              class="table-striped"
            >
              <tr>
                <td
                  style="overflow: hidden; text-overflow: ellipsis; padding-top: 20px; padding-bottom: 20px;"
                >
                  {{ index + 1 + "." }}
                </td>
                <td
                  style="overflow: hidden; text-overflow: ellipsis;"
                >
                  <span class="input-group-addon">
                    <v-progress-circular
                      v-if="loading[item.itemId]"
                      indeterminate
                      color="rgb(227, 58, 58)"
                    ></v-progress-circular> 
                    <v-icon
                      v-else-if="item.itemExtension"
                      :color="fileIds.includes(item.itemId) ? 'rgba(204, 142, 41, 255)' : 'rgb(227, 58, 58)'"
                      class="mr-2"
                      size="large"
                      :icon="globalMethods.getDocumentIcon(item.itemExtension)"
                    />
                    <v-icon
                      v-else
                      color="rgb(227, 58, 58)"
                      style="margin-right: 5px"
                    >
                      mdi-folder-open</v-icon
                    >
                  </span>
                </td>
                <td
                  style="overflow: hidden; text-overflow: ellipsis;"
                >
                  <file-options
                    v-if="item.itemExtension == 'pdf'"
                    :file="item"
                    :file_color="'rgb(227, 58, 58)'"
                    @open_pdf="getResources(item)"
                    @briefcase_pdf="briefcaseDocument(item)"
                    :briefcase-files="fileIds"
                    :file_class="' text-wrap'"
                    :select_type="true"
                    :pdf_type="2"
                  ></file-options>
                  <a 
                    v-else
                    href="#"
                    @click="getResources(item)"
                  >
                    {{ item.itemName }}
                  </a>
                </td>
                <td
                  style="overflow: hidden; text-overflow: ellipsis;"
                >
                  {{ getPrettyBytes(item.itemSize) }}
                </td>
                <td
                  style="overflow: hidden; text-overflow: ellipsis;"
                >
                  {{ formatDate(item.itemLastUpdatedOn) }}
                </td>
                <td
                  style="overflow: hidden; text-overflow: ellipsis;"
                >
                  {{ item.itemUploadedBy }}
                </td>
              </tr>
            </tbody>
          </v-table>

          <v-table v-else>
            <thead>
              <tr style="background-color: rgb(227, 58, 58); color: #fff; font-weight: bolder;">
                <td class="text-left">No.</td>
                <td class="text-left"></td>
                <td class="text-left">Name</td>
                <td class="text-left">Size</td>
                <td class="text-left">Modified On</td>
                <td class="text-left">Submitted By</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td 
                  colspan="7"
                  class="text-center"
                >
                  <span>
                    No Resources Found
                  </span>
                </td>
              </tr>
            </tbody>
          </v-table>
        </div>
        <!-- Grid View -->
        <div v-else class="table-striped">
          <v-card class="mx-auto pa-4" v-if="sortedData2.length > 0">
            <v-row no-gutters>
              <v-col 
                cols="3" md="3" sm="3" align="center"
                v-for="item in sortedData2" 
                :key="item.itemId"  
                @click="getResources(item)"
                style="cursor: pointer;"
              >
                <v-card-title>
                  <v-progress-circular
                    v-if="loading[item.itemId]"
                    indeterminate
                    color="rgb(227, 58, 58)"
                  ></v-progress-circular> 
                  <v-icon
                    v-else-if="item.itemExtension"
                    :color="fileIds.includes(item.itemId) ? 'rgba(204, 142, 41, 255)' : 'rgb(227, 58, 58)'"
                    class="mr-2"
                    size="large"
                    :icon="globalMethods.getDocumentIcon(item.itemExtension)"
                  />
                  <v-icon
                    v-else
                    color="rgb(227, 58, 58)"
                    style="margin-right: 5px"
                  >
                    mdi-folder-open</v-icon
                  >
                </v-card-title>

                <v-card-text>
                  <file-options
                    v-if="item.itemExtension == 'pdf'"
                    :file="item"
                    :file_color="'rgb(227, 58, 58)'"
                    @open_pdf="getResources(item)"
                    @briefcase_pdf="briefcaseDocument(item)"
                    :briefcase-files="fileIds"
                    :file_class="' text-wrap'"
                    :select_type="true"
                    :pdf_type="2"
                  ></file-options>
                  <a 
                    v-else
                    href="#"
                    @click="getResources(item)"
                  >
                    {{ item.itemName }}
                  </a>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>

          <v-card class="mx-auto pa-4" v-else>
            <v-row no-gutters>
              <v-col cols="12" md="12" sm="12" align="center">
                <v-card-text>
                  <span class="text-center">
                    No Resources Found
                  </span>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </div>

      <!-- PSPDFKIT / OPEN DOCUMENT MODAL -->
      <div
        v-if="closePDF || closeDocument"
        style="position: absolute; background-color: #f5f5f5; width: 100%; height: 100%; padding: 1%; overflow: hidden; margin-right: 0;"
      >
        <v-card>
          <v-card-text>
            <v-main>
              <v-container fluid>
                <component
                  :pdf-url="current_pdf.itemUrl"
                  :license-key="LICENSE_KEY"
                  :base-url="baseUrl"
                  @close_pdf="closePdf"
                  :is="document_component"
                  :document-url="current_document.itemUrl"
                  :document-type="current_document.itemExtension"
                  :document-title="current_document.itemName"
                  :viewer-color="'rgb(227, 58, 58)'"
                  @close_document="documentClosed"
                  :document-list="sortedData2"
                  :list-color="'rgb(227, 58, 58)'"
                  :briefcase-color="'rgba(204, 142, 41, 255)'"
                  @open_pdf="getResources"
                  @open_document="getResources"
                  @switch_pdf="switchDocument"
                  @switch_document="switchDocument"
                  :parent_pdf_type="pdf_type ? 2 : 1"
                  :list-width="'25%'"
                  :briefcase-files="fileIds"
                  :briefcase-on-load="true"
                  @briefcase_document="briefcaseDocument"
                />
              </v-container>
            </v-main>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <!-- END OF COMPONENT -->
  </div>
</template>

<script>
var moment = require("moment");
import axios from "axios";
import prettyBytes from 'pretty-bytes';
import PSPDFKit from "../components/PSPDFKit.vue";
import UserData from "../components/repository/UserData";
import { useAuthStore } from "../stores/auth";
import { useIndexStore } from "../stores/index";
import FileOptions from "./Cards/FileOptions.vue";
import DocumentViewer from './Cards/DocumentViewer.vue';
import GlobalMethods from "./repository/GlobalMethods";
import { useAlertStore } from "../stores/alert";

const LICENSE_KEY =
  "QvMr6j5706r3kYlOiVvJgEwu4fZnTL1fYz4t6paoBV5PekugaffzJnLtX5TczMS65OKAI1iIaSi06_l76UgF3kyKauJ9vaMZFuU3_oDplMFty6AhBhWPh80M7qoSa8xjtux3NqNrfqybICplLfHezwjhABIkPy6XxBMQ_uPU87_C5pMBXlc8A2PI_ShMjfhst6O_C0y9jyeMJ2DYudM3liFVsp90A0dOY51LM2Q3xV3jfcJ7K39FKpnKU_atpn1Ig8HXcmWdGQMjCXyquwVu9i2J850rGqBgFKJJFGixxnU3sZ__tA7Mb3aX8T2nXPlEnamtAhNf-DD1sEtqXHp5q5xxsFFuJDVh82tXKfd4oX8AkzWLd_-UVg_h_6ATML30e8fiQXSVcN0mmlZH5XYUv0jB5cHEyuIaGZac7gCNRn32pchJNpIwEdgHzWDRBQxxCR7F8b6z3Xs3yXJ6y9EhhrnKmjNYkMCdFJ0qNhI8zF8RhAbLA-uKtrzMy7hXLrlpuIHdGaCXMm7gA9iBpKiqwHIrX83rCFoVYUGM4BqNkim3HN8YJzZRDDI-ZHzdy7CcanSuvbe3NSxW5daeHdGVvg==";

export default {
  data: () => ({
    moment: moment,
    test: true,
    itemSubArrayStaticData: [],
    itemSubArrayDynamicData: [],
    resourceDirectors: [],
    itemName: "",

    items: [
      { title: "Name" },
      { title: "Size" },
      { title: "Modified On" },
      { title: "Submitted By" },
    ],

    sort: {
      field: "",
      desc: true,
      code: null,
    },

    list: {
      view: 1,
    },

    navigationPath: [
      {
        itemName: "...",
        localUrl: "0",
      },
    ],

    currentParent: "",
    parentItemId: "",

    userdata: {
      rootUrl:
        "https://v3.egovernance.io/api_v13/frontend/web/index.php/user/create",
    },

    closePDF: false,
    closeDocument: false,

    files: [],
    fileIds: [],
    globalMethods: GlobalMethods,
    loading: {},

    pdf: "",
    LICENSE_KEY: LICENSE_KEY,
    token: localStorage.getItem("token") ? JSON.stringify(localStorage.getItem("token")).slice(1, -1) : "",
    auth: useAuthStore(),
    store: useIndexStore(),
    alert: useAlertStore(),
    baseUrl: "",
    errorMsg: "",
  }),

  methods: {
    formatDate(date) {
      return moment.unix(date).format("DD-MMM-YYYY");
    },

    reloadPage() {
      window.location.reload();
    },

    getPrettyBytes(value) {
      return prettyBytes(parseInt(value));
    },

    openBoardDirectory() {
      this.$router.push('/board-directory');
    },

    closePdf() {
      this.closePDF = !this.closePDF;
      this.shareDocument = false;
      this.store.setCurrentPdf({});
    },

    documentClosed() {
      this.closeDocument = !this.closeDocument;
      this.store.setDialogDocumentViewer(false);
      this.store.setCurrentDocument({});
    },

    getResourcePackFolder() {
      this.store.setLoading(true);
      axios.post(UserData.getBaseUrl(), this.getUserData()).then((response) => {
        this.getResourcePackFolder = response.data;
        localStorage.setItem("getResourcePackFolder", JSON.stringify(this.getResourcePackFolder));
        this.itemSubArrayStaticData = this.getResourcePackFolder.staticData;
        this.itemSubArrayDynamicData = this.getResourcePackFolder.dynamicData;
        if (this.itemSubArrayDynamicData.itemSubArray[0] && Object.keys(this.itemSubArrayDynamicData.itemSubArray[0]).length > 0) {
          this.loadDirectoriesAndFiles(this.itemSubArrayDynamicData.itemSubArray[0]);
        } else {
          this.store.setLoading(false);
        }
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    loadDirectoriesAndFiles(item) {
      axios.post(UserData.getBaseUrl(), this.getUserData(item.itemId, item.itemParentId)).then((response) => {
        this.getResourcePackFolder = response.data;
        this.resourceDirectors = this.getResourcePackFolder.data.itemSubArray;
        this.navigationPath = [];
        this.navigationPath.push({
          itemName: this.getResourcePackFolder.data.itemName,
          itemId: this.itemParentId,
        });

        this.store.setLoading(false);
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    getUserData(itemId = 0, parentItemId = 0) {
      this.parentItemId = parentItemId;
      const formData = new FormData();

      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "getResourcePackFolder");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      formData.append("itemId", itemId);
      formData.append("isArchive", 0);

      return formData;
    },

    getResources(item) {
      if (item.itemExtension == "pdf") {
        this.briefcaseDocument(item);
      } else {
        if (this.globalMethods.getDocumentExtension(item.itemExtension)) {
          this.openDocument(item);
        } else {
          this.loading[item.itemId] = true;
          axios.post(UserData.getBaseUrl(), this.getUserData(item.itemId, item.itemParentId)).then((response) => {
            this.getResourcePackFolder = response.data;
            this.loading[item.itemId] = false;
            this.resourceDirectors = this.getResourcePackFolder.data.itemSubArray;
            this.navigationPath.push({
              itemName: this.getResourcePackFolder.data.itemName,
              itemId: this.parentItemId,
            });
          });
        }
      }
    },

    closeViewer(item) {
      if (item.itemExtension == "pdf") {
        this.closeDocument = false;
        this.closePDF = true;
      } else {
        this.closePDF = false;
        this.closeDocument = true;
      }
    },

    openPdf(item, url = null) {
      this.closeViewer(item);
      this.store.setBriefcaseUrl(url);
      this.store.setDialogDocumentViewer(false);
      this.store.setCurrentPdf(item);
      this.store.setCurrentDocument(item);
      this.store.setDocumentComponent('pspdfkit');
      UserData.setDocumentId(item.itemId);
      this.store.setDocumentId(item.itemId);
    },

    openDocument(item) {
      if (item.itemExtension != 'No File Extension') {
        this.closeViewer(item);
        this.store.setDialogDocumentViewer(true);
        this.store.setDocumentComponent('document-viewer');
        this.store.setCurrentDocument(item);
        UserData.setDocumentId(item.itemId);
        this.store.setDocumentId(item.itemId);
      } else {
        if (item.itemSize > 0) {
          this.alert.showError(item.itemExtension);
        } else {
          this.alert.showError('Error Opening File');
        }
      }
    },

    switchDocument(item) {
      this.store.setBriefcaseUrl(null);

      if (item.itemExtension == "pdf") {
        this.openPdf(item);
      } else {
        this.openDocument(item);
      }
    },

    briefcaseDocument(item) {
      if (item.itemExtension) {
        this.loading[item.itemId] = true;
        this.globalMethods.downloadEbrDocument(item.itemUrl).then(async (response) => {
          if (response.status == 200) {
            let fileType = response.headers['content-type'];
            let fileData = new Blob([response.data], { type: fileType });
            let blob = window.URL.createObjectURL(fileData);
            this.openPdf(item, blob);
            this.loading[item.itemId] = false;
          } else {
            this.loading[item.itemId] = false;
            console.log("Response: ", response);
          }
        }).catch((e) => {
          this.loading[item.itemId] = false;
          console.log("Error: ", e);
        });
      } else {
        this.alert.showInfo("Briefcasing Folders is Under Development");
      }
    },

    loadItemFromHistory() {
      let currentTop = this.navigationPath.pop();
      axios.post(UserData.getBaseUrl(), this.getUserData(currentTop.itemId)).then((response) => {
        this.getSubMeetingPackFolder = response.data;
        this.resourceDirectors = this.getSubMeetingPackFolder.data.itemSubArray;
      });
    },

    joinNames() {
      return [
        ...this.navigationPath.map((value) => {
          return value.itemName;
        }),
      ].join("/");
    },

    doSort(field, status = null) {
      if (status != null) {
        this.sort.code = status;
      }

      if (field == this.sort.field) {
        this.sort.desc = !this.sort.desc;
      } else {
        this.sort.field = field;
        this.sort.desc = true;
      }
    },

    swapComponent: function (component) {
      this.currentComponent = component;
    },

    getFilterStyle(status) {
      if (this.sort.code === status) {
        return 'color: rgb(227, 58, 58); font-weight: bold;';
      } 
    },

    changeListView(status) {
      this.list.view = status;
      this.sort.code = status;
    },

    goToArchives() {
      this.$router.push('/archives');
    },
  },

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },
    
    sortedData2() {
      if (!this.sort.field) {
        return this.resourceDirectors;
      }
      return this.resourceDirectors.concat().sort((a, b) => {
        if (this.sort.desc) {
          return a[this.sort.field] > b[this.sort.field] ? -1 : 1;
        } else {
          return a[this.sort.field] > b[this.sort.field] ? 1 : -1;
        }
      });
    },

    current_pdf() {
      return this.store.getCurrentPdf;
    },

    current_document() {
      return this.store.getCurrentDocument;
    },

    document_component() {
      return this.store.getDocumentComponent;
    },

    overlay_type: {
      get() {
        return this.store.getOverlayType;
      },
      set(value) {
        this.store.setOverlayType(value);
      },
    },

    session_overlay: {
      get() {
        return this.store.getSessionOverlay;
      },
      set(value) {
        this.store.setSessionOverlay(value);
      },
    },
  },

  beforeMount() {
    this.getResourcePackFolder();
  },

  mounted() {
    const getResourcePackFolder = JSON.parse(localStorage.getItem("getResourcePackFolder"));
    if (getResourcePackFolder) {
      this.getResourcePackFolder = getResourcePackFolder;
    }
  },
  
  components: {
    pspdfkit: PSPDFKit,
    FileOptions,
    DocumentViewer,
  },
};
</script>

<style scoped>
.nav-menu {
  margin-top: 0px;
  background-color: rgb(227, 58, 58);
  color: #fff;
  display: flex;
  margin-bottom: 1%;
}

.component {
  width: 99%;
  margin-left: 0.5%;
  margin-right: 0.5%;
  height: 900px;
}

.left {
  width: 20%;
  margin-right: 1%;
  float: left;
  height: 100%;
}

.left-top {
  width: 100%;
  height: 49%;
  margin-bottom: 4%;
  background-color: #f8f9f9;
  border-top: 5px solid rgb(227, 58, 58);
  overflow: auto;
}

.left-bottom {
  width: 100%;
  height: 49%;
  background-color: #f8f9f9;
  border-top: 5px solid rgb(227, 58, 58);
  overflow: auto;
}

.left a {
  color: #000;
  text-decoration: none;
}

.left a:hover {
  color: rgb(227, 58, 58);
}

.right {
  width: 79%;
  float: left;
  background-color: #f8f9f9;
  height: 100%;
  border-top: 5px solid rgb(227, 58, 58);
  overflow: auto;
}

.parent {
  display: flex;
  width: 100%;
}

.filler {
  flex-grow: 1;
}

.left-buttons {
  display: inline;
}

.right-buttons {
  display: inline;
}

button:focus {
  outline: 0 !important;
  border: 0 !important;
  box-shadow: none !important;
}
.table-striped a {
  text-decoration: none;
  color: #000;
}

.table-striped a:hover {
  color: rgb(227, 58, 58);
}

.table-striped a:active {
  color: rgb(227, 58, 58);
}

.table-striped:nth-child(even) {
  background-color: rgba(0, 0, 0, .05);
}

.table-striped:hover {
  background-color: rgba(255, 255, 255, 255) !important;
}

.resources-table:hover { 
  background-color: rgb(227, 58, 58) !important; 
}
</style>
