<template>
  <div>
    <!-- NAV-MENU -->
    <div
      class="nav-menu"
      style="height: 45px; width: 100%; padding: 0px; padding-top: 0.3%; text-align: center;"
    >
      <router-link to="/dashboard" style="text-decoration: none">
        <v-icon color="#fff" size="35" style="margin-left: 10px;"
          >mdi-home</v-icon
        >
      </router-link>
      <p style="font-weight: bold; font-size: 24px; margin-left: 40%;">
        Board Directory
      </p>
      <v-spacer></v-spacer>
      <v-menu location="bottom">
        <template v-slot:activator="{ props }">
          <v-btn
            class="btn text-white"
            variant="text"
            v-bind="props"
          >
            {{ selectedCompanyName }}
          </v-btn>
        </template>

        <div class="mt-4">
          <SelectCompany :companies="companyList" @company_selected="changeCompanyDetails" />
        </div>
      </v-menu>
      <v-btn
        class="mt-n3 ml-n5"
        size="x-large"
        variant="outlined"
        icon
        color="#ffffff"
        @click="reloadPage"
        style="margin-right: 50px; border: none;"
      >
        <v-icon>mdi-cached</v-icon>
      </v-btn>
    </div>

    <!-- COMPONENT -->
    <div class="component">
      <div>
        <v-card 
          class="mx-auto pa-4"
          elevation="0"
        >
          <v-row no-gutters>
            <v-col cols="4" md="4" sm="4">
              <v-card-text>
                <v-tabs
                  v-model="tab"
                  centered
                  fixed-tabs
                  color="rgb(227, 58, 58)"
                  hide-slider
                  class="left-tabs"
                >
                  <v-tab 
                    value="tab-1"
                    @click="switchToUsers"
                    :class="tab == 'tab-1' ? 'directory text-white' : ''"
                  >
                    Users
                  </v-tab>

                  <v-tab 
                    value="tab-2" 
                    @click="switchToGroups"
                    :class="tab == 'tab-2' ? 'directory text-white' : ''"
                  >
                    Groups
                  </v-tab>
                </v-tabs>

                <v-window v-model="tab">
                  <v-window-item
                    value="tab-1"
                  >
                    <v-card style="border: 2px solid rgb(227, 58, 58); padding: 0; height: 100vh; overflow-y: scroll;">
                      <v-card-text>
                        <div
                          style="overflow: auto"  
                        >
                          <v-list
                            subheader
                            two-line
                          >
                            <v-list-item-group
                              v-model="selected"
                              active-class="text-red"
                              multiple
                            >
                              <div
                                v-for="(item, index) in users"
                                :key="index"
                              >
                                <v-list-item 
                                  :value="item.boardMemberName"
                                  @click="setUserProfile(item)"
                                > 
                                  <template v-slot:prepend>
                                    <v-img 
                                      :src="item.memberImageURL ? item.memberImageURL : '/img/male-icon.png'" 
                                      width="100px"
                                      class="mr-3"
                                    ></v-img>
                                  </template>
                                  
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      {{ item.boardMemberName === user.userInfo.userFullName ? 'You' : item.boardMemberName }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                      <div v-html="item.boardMemberDesignation"></div>
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                      <div v-html="item.boardMemberPhone"></div>
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                      <div v-html="item.boardMemberEmail"></div>
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-divider
                                  v-if="index < users.length - 1"
                                  :key="index"
                                  color="black"
                                ></v-divider>
                              </div>
                            </v-list-item-group>
                          </v-list>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-window-item>
                  <v-window-item
                    value="tab-2"
                  >
                    <v-card style="border: 2px solid rgb(227, 58, 58); padding: 0; height: 100vh; overflow-y: scroll;">
                      <v-card-text>
                        <div
                          style="overflow: auto"  
                        >
                          <v-list
                            subheader
                            two-line
                          >
                            <v-list-item-group
                              v-model="selected"
                              active-class="pink--text"
                              multiple
                              v-if="groups.length > 0"
                            >
                              <div
                                v-for="(item, index) in groups"
                                :key="index"
                              >
                                <v-list-item 
                                  :value="item.boardMemberName"
                                  @click="setGroupProfile(item)"
                                > 
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <div v-html="item.boardMemberName"></div>
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-divider
                                  v-if="index < groups.length - 1"
                                  :key="index"
                                  color="black"
                                ></v-divider>
                              </div>
                            </v-list-item-group>
                            <v-list-item-group
                              v-else
                            >
                              <div>
                                <v-list-item>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <div class="text-center">
                                        No Groups Found
                                      </div>
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </div>
                            </v-list-item-group>
                          </v-list>
                        </div>
                      </v-card-text>
                    </v-card> 
                  </v-window-item>
                </v-window>
              </v-card-text>
            </v-col>
            <v-col cols="8" md="8" sm="8">
              <v-card-text>
                <v-tabs
                  v-model="tab1"
                  color="rgb(227, 58, 58)"
                  hide-slider
                  class="profile-tabs"
                >
                  <v-tab 
                    value="tab-1"
                    :class="tab1 == 'tab-1' ? 'directory text-white' : ''"
                  >
                    Profile
                  </v-tab>
                </v-tabs>

                <v-window v-model="tab1">
                  <v-window-item
                    v-for="i in 1"
                    :key="i"
                    :value="'tab-' + i"
                  >
                    <v-container style="border: 2px solid rgb(227, 58, 58); padding: 0; height: 100vh;">
                      <v-row style="height: 30vh; border-bottom: 2px solid rgb(227, 58, 58);">
                        <v-col cols="12" sm="12" md="12">
                          <v-card elevation="0">
                            <v-card-title style="color: rgb(227, 58, 58);">
                              <div class="float-left">
                                {{ selectedProfileTitle }}
                              </div>
                              <div class="float-right">
                                <v-icon 
                                  v-if="!show"
                                  icon="mdi-file-chart-outline"
                                  style="cursor: pointer;"
                                  class="mr-3"
                                  @click="getSkillMatrixReport"
                                ></v-icon>
                                <v-icon 
                                  icon="mdi-cached"
                                  style="cursor: pointer;"
                                  class="mr-3"
                                ></v-icon>
                              </div>
                            </v-card-title>
                            <v-card-text 
                              style="height: 20vh; overflow-y: scroll;" 
                              v-if="!show"
                            > 
                              <b>Designation - </b> <span v-html="selectedProfileDesignation"></span>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>

                      <v-row style="height: 70vh;">
                        <v-col cols="12" sm="12" md="12">
                          <v-card elevation="0">
                            <v-card-text>
                              <v-table
                                height="60vh"
                                fixed-header
                                v-if="!show"
                              >
                                <thead>
                                  <tr style="color: #fff;">
                                    <th style="font-weight: bolder;">
                                      <div class="float-left">
                                        <v-tabs
                                          v-model="tab2"
                                          centered
                                          color="rgb(227, 58, 58)"
                                          hide-slider
                                          class="profile-tabs"
                                          selected-class="profile"
                                        >
                                          <v-tab 
                                            value="tab-1"
                                            :class="tab2 == 'tab-1' ? 'directory text-white' : ''"
                                          >
                                            CV
                                          </v-tab>

                                          <v-tab 
                                            value="tab-2" 
                                            @click="getSkillMatrix"
                                            :class="tab2 == 'tab-2' ? 'directory text-white' : ''"
                                          >
                                            Skill Matrix
                                          </v-tab>
                                        </v-tabs>
                                      </div>

                                      <div class="float-right">
                                        <v-btn
                                          variant="text"
                                          color="rgb(227, 58, 58)"
                                          prepend-icon="mdi-email"
                                        >
                                          Message to {{ selectedProfileTitle }}
                                        </v-btn>
                                      </div>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td
                                      style="overflow: hidden; text-overflow: ellipsis; padding-top: 20px; padding-bottom: 20px;"
                                    >
                                      <v-window v-model="tab2">
                                        <v-window-item
                                          value="tab-1"
                                          style="border: 2px solid rgb(227, 58, 58); padding: 0;"
                                        >
                                          <v-card>
                                            <v-card-text>
                                              <div style="width: 100%;">
                                                <v-table 
                                                  v-if="profileItems && profileItems.length > 0"
                                                  height="60vh"
                                                  fixed-header
                                                >
                                                  <tbody
                                                    v-for="(item, index) in profileItems" 
                                                    :key="index"
                                                    class="table-striped"
                                                  >
                                                    <tr>
                                                      <td
                                                        style="overflow: hidden; text-overflow: ellipsis; padding-top: 20px; padding-bottom: 20px; color: rgb(227, 58, 58); background-color: rgba(0, 0, 0, .05);"
                                                      >
                                                        {{ item.cvCategoryTitle }}
                                                      </td>
                                                    </tr>
                                                    <tr v-if="item.cvItemList && item.cvItemList.length > 0">
                                                      <td
                                                        style="overflow: hidden; text-overflow: ellipsis; padding-top: 20px; padding-bottom: 20px; background-color: rgba(255, 255, 255, 255);"
                                                      >
                                                        {{ index + 1 + "." }}
                                                      </td>
                                                    </tr>
                                                    <tr v-else>
                                                      <td
                                                        class="text-center"
                                                        style="overflow: hidden; text-overflow: ellipsis; padding-top: 20px; padding-bottom: 20px;"
                                                      >
                                                        <span>
                                                          No Records Found
                                                        </span>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </v-table>
                                                <v-table v-else>
                                                  <tbody>
                                                    <tr>
                                                      <td 
                                                        class="text-center"
                                                      >
                                                        <span>
                                                          No Data Found
                                                        </span>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </v-table>
                                              </div>
                                            </v-card-text>
                                          </v-card>
                                        </v-window-item>

                                        <v-window-item
                                          value="tab-2"
                                          style="border: 2px solid rgb(227, 58, 58); padding: 0;"
                                        >
                                          <v-card>
                                            <v-card-text>
                                              <div style="width: 100%;">
                                                <v-table 
                                                  v-if="profileMatrix && profileMatrix.length > 0"
                                                  height="60vh"
                                                >
                                                  <tbody    >
                                                    <tr>
                                                      <td
                                                        style="overflow: hidden; text-overflow: ellipsis;"
                                                      >
                                                        <div
                                                          style="overflow: auto"  
                                                        >
                                                          <v-list
                                                            subheader
                                                            two-line
                                                          >
                                                            <v-list-item-group
                                                              v-model="selected"
                                                              active-class="pink--text"
                                                              multiple
                                                            >
                                                              <div
                                                                v-for="(item, index) in profileMatrix"
                                                                :key="index"
                                                              >
                                                                <v-list-item
                                                                  class="py-3 my-3"
                                                                  style="color: rgb(227, 58, 58); background-color: rgba(0, 0, 0, .05); font-weight: bolder; " 
                                                                >
                                                                  <v-list-item-content>
                                                                    <v-list-item-title>
                                                                      {{ item.skillMatrixCategoryTitle }}
                                                                    </v-list-item-title>
                                                                  </v-list-item-content>
                                                                </v-list-item>

                                                                <div
                                                                  style="overflow: auto"  
                                                                >
                                                                  <v-list
                                                                    subheader
                                                                    two-line
                                                                  >
                                                                    <v-list-item-group
                                                                      v-model="selected"
                                                                      active-class="pink--text"
                                                                      multiple
                                                                    >
                                                                      <div
                                                                        v-for="(matrix, i) in item.skillMatrixDataList"
                                                                        :key="matrix.skillMatrixItemId"
                                                                      >
                                                                        <v-list-item 
                                                                          :value="matrix.skillMatrixItemName"
                                                                          @click="updateUserSkill(matrix)"
                                                                        > 
                                                                          <v-list-item-content>
                                                                            <v-list-item-title>
                                                                              {{ matrix.skillMatrixItemName }}
                                                                            </v-list-item-title>
                                                                          </v-list-item-content>

                                                                          <template v-slot:append v-if="matrix.isSkillItemAvailable == 1">
                                                                            <v-btn
                                                                              variant="outlined"
                                                                              icon
                                                                              style="border: none;"
                                                                              color="rgb(227, 58, 58)"
                                                                            >
                                                                              <v-icon>mdi-check-outline</v-icon>
                                                                            </v-btn>
                                                                          </template>
                                                                        </v-list-item>

                                                                        <v-divider
                                                                          v-if="i < item.skillMatrixDataList.length - 1"
                                                                          :key="i"
                                                                        ></v-divider>
                                                                      </div>
                                                                    </v-list-item-group>
                                                                  </v-list>
                                                                </div>

                                                                <v-divider
                                                                  v-if="i < profileMatrix.length - 1"
                                                                  :key="i"
                                                                  color="black"
                                                                ></v-divider>
                                                              </div>
                                                            </v-list-item-group>
                                                          </v-list>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </v-table>
                                                <v-table v-else>
                                                  <tbody>
                                                    <tr>
                                                      <td 
                                                        class="text-center"
                                                      >
                                                        <span>
                                                          No Data Found
                                                        </span>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </v-table>
                                              </div>
                                            </v-card-text>
                                          </v-card>
                                        </v-window-item>
                                      </v-window>
                                    </td>
                                  </tr>
                                </tbody>
                              </v-table>
                              <v-table
                                height="60vh"
                                fixed-header
                                v-else
                              >
                                <div style="width: 100%;">
                                  <v-table 
                                    v-if="members && members.length > 0"
                                    height="60vh"
                                    fixed-header
                                  >
                                    <thead>
                                      <tr>
                                        <th 
                                          style="color: rgb(227, 58, 58); background-color: rgba(0, 0, 0, .05); font-weight: bolder;" 
                                          class="text-left"
                                        >
                                          <v-list style="background-color: #FF000000; color: rgb(227, 58, 58);">
                                            <v-list-item>
                                              <v-list-item-title>
                                                Members
                                              </v-list-item-title>
                                            </v-list-item>
                                          </v-list>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody    >
                                      <tr>
                                        <td
                                          style="overflow: hidden; text-overflow: ellipsis; padding-top: 20px; padding-bottom: 20px;"
                                        >
                                          <div
                                            style="overflow: auto"  
                                          >
                                            <v-list
                                              subheader
                                              two-line
                                            >
                                              <v-list-item-group
                                                v-model="selected"
                                                active-class="pink--text"
                                                multiple
                                              >
                                                <div
                                                  v-for="(item, index) in members"
                                                  :key="index"
                                                >
                                                  <v-list-item :value="item.boardMemberName"> 
                                                    <template v-slot:prepend>
                                                      <v-img 
                                                        :src="item.memberImageURL ? item.memberImageURL : '/img/male-icon.png'" 
                                                        width="40px"
                                                        class="mr-3"
                                                      ></v-img>
                                                    </template>
                                                    
                                                    <v-list-item-content>
                                                      <v-list-item-title>
                                                        {{ item.boardMemberName === user.userInfo.userFullName ? 'You' : item.boardMemberName }}
                                                      </v-list-item-title>
                                                    </v-list-item-content>
                                                  </v-list-item>
                                                  <v-divider
                                                    v-if="index < users.length - 1"
                                                    :key="index"
                                                    color="black"
                                                  ></v-divider>
                                                </div>
                                              </v-list-item-group>
                                            </v-list>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </v-table>
                                  <v-table v-else>
                                    <tbody>
                                      <tr>
                                        <td 
                                          class="text-center"
                                        >
                                          <span>
                                            No Data Found
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </v-table>
                                </div>
                              </v-table>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-window-item>
                </v-window>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </div>

      <div
        v-if="closePDF"
        style="position: absolute; background-color: #f5f5f5; width: 100%; height: 100%; padding: 1%; overflow: hidden; margin-right: 0;"
      >
        <v-card>
          <v-card-text>
            <v-main>
              <v-container fluid>
                <pspdfkit
                  :pdf-url="pdf"
                  :license-key="LICENSE_KEY"
                  :base-url="baseUrl"
                  @close_pdf="closePdf"
                />
              </v-container>
            </v-main>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <!-- END OF COMPONENT -->
  </div>
</template>

<script>
var moment = require("moment");
import axios from "axios";
import UserData from "../../components/repository/UserData";
import PSPDFKit from "../../components/PSPDFKit.vue";
import { useAuthStore } from "../../stores/auth";
import { useIndexStore } from "../../stores/index";
import { useAlertStore } from "../../stores/alert";
import prettyBytes from 'pretty-bytes';
import SelectCompany from "../../components/Cards/SelectCompany.vue";

const LICENSE_KEY =
  "QvMr6j5706r3kYlOiVvJgEwu4fZnTL1fYz4t6paoBV5PekugaffzJnLtX5TczMS65OKAI1iIaSi06_l76UgF3kyKauJ9vaMZFuU3_oDplMFty6AhBhWPh80M7qoSa8xjtux3NqNrfqybICplLfHezwjhABIkPy6XxBMQ_uPU87_C5pMBXlc8A2PI_ShMjfhst6O_C0y9jyeMJ2DYudM3liFVsp90A0dOY51LM2Q3xV3jfcJ7K39FKpnKU_atpn1Ig8HXcmWdGQMjCXyquwVu9i2J850rGqBgFKJJFGixxnU3sZ__tA7Mb3aX8T2nXPlEnamtAhNf-DD1sEtqXHp5q5xxsFFuJDVh82tXKfd4oX8AkzWLd_-UVg_h_6ATML30e8fiQXSVcN0mmlZH5XYUv0jB5cHEyuIaGZac7gCNRn32pchJNpIwEdgHzWDRBQxxCR7F8b6z3Xs3yXJ6y9EhhrnKmjNYkMCdFJ0qNhI8zF8RhAbLA-uKtrzMy7hXLrlpuIHdGaCXMm7gA9iBpKiqwHIrX83rCFoVYUGM4BqNkim3HN8YJzZRDDI-ZHzdy7CcanSuvbe3NSxW5daeHdGVvg==";

export default {
  data: () => ({
    moment: moment,
    companyList: [],
    selectedCompanyName: "",
    selectedProfileTitle: "",
    selectedProfileDesignation: "",
    selectedBoardMember: {},
    selectedBoardGroup: {},
    tab: null,
    tab1: null,
    tab2: null,
    selected: [],
    show: false,

    groups: [],
    users: [],
    members: [],
    profileItems: [],
    profileMatrix: [],

    closePDF: false,
    pdf: "",
    LICENSE_KEY: LICENSE_KEY,

    token: localStorage.getItem("token") ? JSON.stringify(localStorage.getItem("token")).slice(1, -1) : "",
    auth: useAuthStore(),
    store: useIndexStore(),
    alert: useAlertStore(),
    skillMatrixReport: [],
    skillMatrixReportUrl: "",
  }),

  methods: {
    formatDate(date) {
      return moment.unix(date).format("DD-MMM-YYYY");
    },

    reloadPage() {
      window.location.reload();
    },

    getPrettyBytes(value) {
      return prettyBytes(parseInt(value));
    },

    closePdf() {
      this.closePDF = !this.closePDF;
      this.shareDocument = false;
    },

    switchToGroups() {
      this.show = !this.show;
      this.getGroupProfile();
      this.getGroupMembers(this.groups[0].boardMemberId);
    },

    switchToUsers() {
      this.show = !this.show;
      this.getUserProfile();
    },

    getGroupMembers(boardMemberId) {
      const formData = new FormData();
      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "getGroupMembers");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      formData.append("boardMemberId", boardMemberId);
      formData.append("isGroup", 1);

      axios.post(UserData.getBaseUrl(), formData).then((response) => {
        this.members = response.data.boardMemberList;
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    getBoardMembers() {
      const formData = new FormData();
      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "getBoardMembers");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);

      axios.post(UserData.getBaseUrl(), formData).then((response) => {
        this.users = response.data.boardMemberList;
        this.groups = response.data.groupList;
        this.getUserProfile();
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    getDirectorProfile(boardMemberId) {
      const formData = new FormData();
      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "getProfile");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      formData.append("boardMemberId", boardMemberId);
      formData.append("isGroup", 0);

      axios.post(UserData.getBaseUrl(), formData).then((response) => {
        if (response.data.success == 1) {
          this.profileItems = response.data.cvList;
        }
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    getSkillMatrix() {
      const formData = new FormData();
      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "getUserSkillMatrix");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      formData.append("boardMemberId", this.selectedBoardMember.boardMemberId);

      axios.post(UserData.getBaseUrl(), formData).then((response) => {
        if (response.data.success == 1) {
          this.profileMatrix = response.data.skillMatrixList;
        }
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    updateUserSkill(item) {
      let skillMatrixList = {};
      skillMatrixList[item.skillMatrixItemId] = item.isSkillItemAvailable == 0 ? true : false;

      const formData = new FormData();
      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "updateUserSkillMatrix");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      formData.append("boardMemberId", this.selectedBoardMember.boardMemberId);
      formData.append("skillMatrixList", JSON.stringify(skillMatrixList));

      axios.post(UserData.getBaseUrl(), formData).then((response) => {
        if (response.data.success == 1) {
          this.alert.showSuccess(response.data.message);
          this.getSkillMatrix();
        }
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    getCompanyList() {
      const formData = new FormData();
      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ?  UserData.getAccessToken() : this.token);
      formData.append("model", "getCompanyList");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);

      axios.post(UserData.getBaseUrl(), formData).then((response) => {
        this.getCompanyListArr = response.data;
        this.companyList = this.getCompanyListArr.companyList;

        this.companyList.unshift({
          companyId: "0",
          companyName: "Company List",
        });
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    getCompanyName() {
      this.selectedCompanyName = UserData.getCompanyName() ? UserData.getCompanyName() : this.user.companyDetail.companyName;
    },
    
    changeCompanyDetails(item) {
      this.auth.setDashboardMenuList(item.dashboardMenuList);
      // Set Repository Company Details
      UserData.setCompanyId(item.companyId);
      UserData.setCompanyName(item.companyName);
      // Set Store Company Details
      this.auth.setCompanyId(item.companyId);
      this.auth.setCompanyName(item.companyName);
      this.getCompanyList();
      this.selectedCompanyName = UserData.getCompanyName() ? UserData.getCompanyName() : this.user.companyDetail.companyName;
    },

    getGroupProfile() {
      if (this.groups.length > 0) {
        this.selectedProfileTitle = this.groups[0].boardMemberName;
        this.selectedProfileDesignation = this.groups[0].boardMemberDesignation;
      } 
    },

    setGroupProfile(group) {
      this.selectedBoardGroup = group;
      this.selectedProfileTitle = group.boardMemberName;
      this.getGroupMembers(group.boardMemberId);
    },

    getUserProfile() {
      if (this.users.length > 0) {
        this.selectedBoardMember = this.users[0];
        this.selectedProfileTitle = this.users[0].boardMemberName;
        this.selectedProfileDesignation = this.users[0].boardMemberDesignation;
        this.getDirectorProfile(this.users[0].boardMemberId);
      } else {
        this.selectedProfileTitle = UserData.getUserName() ? UserData.getUserName() : this.user.userInfo.userFullName;
      }
    },

    setUserProfile(user) {
      this.selectedBoardMember = user;
      this.selectedProfileTitle = user.boardMemberName;
      this.selectedProfileDesignation = user.boardMemberDesignation;

      if (this.tab2 == 'tab-1') {
        this.getDirectorProfile(user.boardMemberId);
      } else {
        this.getSkillMatrix();
      }
    },

    getSkillMatrixReport() {
      const formData = new FormData();
      formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      formData.append("model", "getSkillMatrixReport");
      formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);

      this.store.setLoading(true);
      axios.post(UserData.getBaseUrl(), formData).then((response) => {
        if (response.data.success == 1) {
          this.skillMatrixReport = response.data;
          this.skillMatrixReportUrl = this.skillMatrixReport.reportUrl;
          window.open(this.skillMatrixReportUrl);
        }
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    doSort(field, status = null) {
      if (status != null) {
        this.sort.code = status;
      }

      if (field == this.sort.field) {
        this.sort.desc = !this.sort.desc;
      } else {
        this.sort.field = field;
        this.sort.desc = true;
      }
    },
  },

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },
    
    sortedData2() {
      if (!this.sort.field) {
        return this.resourceDirectors;
      }
      return this.resourceDirectors.concat().sort((a, b) => {
        if (this.sort.desc) {
          return a[this.sort.field] > b[this.sort.field] ? -1 : 1;
        } else {
          return a[this.sort.field] > b[this.sort.field] ? 1 : -1;
        }
      });
    },
  },

  beforeMount() {
    this.getBoardMembers();
    this.getCompanyList();
    this.getCompanyName();
  },

  mounted() {
    const getResourcePackFolder = JSON.parse(localStorage.getItem("getResourcePackFolder"));
    if (getResourcePackFolder) {
      this.getResourcePackFolder = getResourcePackFolder;
    }
  },

  components: {
    pspdfkit: PSPDFKit,
    SelectCompany,
  },
};
</script>

<style scoped>
.nav-menu {
  margin-top: 0px;
  background-color: rgb(227, 58, 58);
  color: #fff;
  display: flex;
  margin-bottom: 1%;
}

.component {
  width: 99%;
  margin-left: 0.5%;
  margin-right: 0.5%;
  height: 900px;
}

.left {
  width: 20%;
  margin-right: 1%;
  float: left;
  height: 100%;
}

.left-top {
  width: 100%;
  height: 49%;
  margin-bottom: 4%;
  background-color: #f8f9f9;
  border-top: 5px solid rgb(227, 58, 58);
  overflow: auto;
}

.left-bottom {
  width: 100%;
  height: 49%;
  background-color: #f8f9f9;
  border-top: 5px solid rgb(227, 58, 58);
  overflow: auto;
}

.left a {
  color: #000;
  text-decoration: none;
}

.left a:hover {
  color: rgb(227, 58, 58);
}

.right {
  width: 79%;
  float: left;
  background-color: #f8f9f9;
  height: 100%;
  border-top: 5px solid rgb(227, 58, 58);
  overflow: auto;
}

.parent {
  display: flex;
  width: 100%;
}

.filler {
  flex-grow: 1;
}

.left-buttons {
  display: inline;
}

.right-buttons {
  display: inline;
}

button:focus {
  outline: 0 !important;
  border: 0 !important;
  box-shadow: none !important;
}
.table-striped a {
  text-decoration: none;
  color: #000;
}

.table-striped a:hover {
  color: rgb(227, 58, 58);
}

.table-striped a:active {
  color: rgb(227, 58, 58);
}

.directory {
  background-color: rgb(227, 58, 58);
}

.left-tabs button {
  border: 2px solid rgb(227, 58, 58);
  color: rgb(227, 58, 58);
}

.profile-tabs button {
  border: 2px solid rgb(227, 58, 58);
  color: rgb(227, 58, 58);
}
</style>
