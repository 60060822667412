<template>
  <div>
    <!-- USERS MODAL -->
    <div class="text-center">
      <v-card style="background-color: #f5f5f5; width: 100%;">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <div>
            <v-table
              fixed-header
              fixed-footer
              height="80vh"
              class="text-left"
            >
              <tbody>
                <tr>
                  <td>
                    <v-card-text>
                      <div style="height: 92%; overflow: auto;">
                        <v-card class="grey lighten-2" style="padding-bottom: 20px;" v-if="users.length > 0">
                          <div
                            style="overflow: auto;"  
                          >
                            <v-list
                              subheader
                              two-line
                            >
                              <v-list-item-group
                                v-model="selected"
                                active-class="pink--text"
                                multiple
                              >
                                <div
                                  v-for="(item, index) in users"
                                  :key="index"
                                >
                                  <v-list-item 
                                    :value="item.userId" 
                                    :disabled="item.userName === user.userInfo.userFullName"
                                  > 
                                    <template v-slot:prepend="{ isActive }">
                                      <v-list-item-action start>
                                        <v-checkbox-btn
                                          :model-value="isActive"
                                          v-model="selected"
                                          :value="item.userId"
                                          :rules="rules"
                                          @click="updateSelected"
                                        ></v-checkbox-btn>
                                      </v-list-item-action>
                                    </template>
                                    
                                    <v-list-item-content>
                                      <v-row align="center" class="spacer">
                                        <v-col cols="12" md="12" sm="12">
                                          <v-badge
                                            :content="item.userName === user.userInfo.userFullName ? 'You' : item.userName"
                                            inline
                                            color="transparent"
                                          >
                                            <v-avatar 
                                              size="40"
                                              :content="item.userName === user.userInfo.userFullName ? 'You' : item.userName"
                                            >
                                              <v-img :src="item.userImageURL ? item.userImageURL : '/img/neutral-icon.jpeg'"></v-img>
                                            </v-avatar>
                                          </v-badge>
                                        </v-col>
                                      </v-row>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-divider
                                    v-if="index < users.length - 1"
                                    :key="index"
                                  ></v-divider>
                                </div>
                              </v-list-item-group>
                            </v-list>
                          </div>
                        </v-card>
                        <v-card class="grey lighten-2" style="padding-bottom: 20px" v-else>
                          <v-card-text
                            class="ml-1"
                            style="color: rgb(0, 0, 0); font-size: 19px; font-weight: bold;"
                          >
                            No users found
                          </v-card-text>
                        </v-card>
                      </div>
                    </v-card-text>
                  </td>
                </tr>
              </tbody>
            </v-table>
          </div>
        </v-form>
      </v-card>
    </div>
  </div>
</template>

<script>
var moment = require("moment");
import axios from 'axios';
import { useAuthStore } from "../../stores/auth";
import { useIndexStore } from "../../stores/index";
import { useAlertStore } from "../../stores/alert";
import UserData from "../repository/UserData";
import authHeader from '../repository/auth-header';

export default {
  name: "UserList",
  props: {
    users: {
      type: Array,
      default: () => [],
    },

    shared_annotations: {
      type: Boolean,
      default: false,
    },

    annotations: {
      type: Object,
      default: () => {},
    },

    check: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    valid: false,
    dialog: false,
    selected: [],
    selected_topics: [],
    interestDisclosed: "",
    interestConflict: "",
    hasConflict: false,
    confirm: false,
    loading: false,
    comments: "",
    conditions: "",
    password: "",
    token: localStorage.getItem("token") ? JSON.stringify(localStorage.getItem("token")).slice(1, -1) : "",
    auth: useAuthStore(),
    store: useIndexStore(),
    alert: useAlertStore(),
  }),

  watch: {
    trigger() {
      console.log('event triggered');
    },
  },

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },
    
    dialog_users() {
      return this.store.getDialogUsers;
    },

    rules() {
      return [
        this.selected.length > 0 || "At least one user should be selected"
      ];
    },

    trigger: {
      get() {
        return this.store.getTrigger;
      },
      set(status) {
        this.store.setTrigger(status);
      },
    },
  },
  
  methods: {
    formatDate(date) {
      return moment.unix(date).format("DD MMM YYYY");
    },

    async validate() {
      return this.$refs.form.validate();
    },

    resetForm() {
      return this.$refs.form.reset();
    },

    resetValidation() {
      return this.$refs.form.resetValidation();
    },

    closeDialogUsers() {
      this.store.setDialogUsers(false);
      document.querySelector('html').classList.remove('v-overlay-scroll-blocked');
    },

    loadUserAnnotations() {
      this.validate().then((validator_response) => {
        this.valid = validator_response.valid;
        if (this.valid && this.selected.length > 0) {
          this.$emit('load_annotations', this.selected);
        }
      });
    },

    loadSelectedUsers() {
      if (this.annotations.ufa_id && !this.shared_annotations) {
        // this.importSharedAnnotationUsers();
        // this.importSharedAnnotationDetails();
      } else {
        this.selected = [];
      }
    },

    updateSelected() {
      setTimeout(() => {
        this.$emit('update_selected', this.selected);
      }, 300);
    },

    shareUserAnnotations() {
      this.validate().then((validator_response) => {
        this.valid = validator_response.valid;
        if (this.valid && this.selected.length > 0) {
          const form = new FormData();
          form.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
          form.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
          form.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
          form.append("model", "shareAnnotation");
          form.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
          form.append("users", JSON.stringify(this.selected));
          form.append("annotationId", this.annotations.ufa_id);
          form.append("documentId", UserData.getDocumentId() ? UserData.getDocumentId() : this.store.getDocumentId);

          this.store.setLoading(true);
          axios.post((process.env.NODE_ENV == 'production' ? UserData.getProductionAdminBaseUrl() : UserData.getLocalAdminBaseUrl()) + '/share-annotation', form, { headers: authHeader() }).then((response) => {
            if (response.status == 200) {
              this.alert.showSuccess("Annotations Shared Successfully");
              this.store.setLoading(false);
              this.store.setDialogUsers(false);
            } else {
              if (response.response.status == 403) {
                this.store.setLoading(false);
                this.$emit("session_timeout", true);
              }
            }
          })
          .catch((e) => {
            console.log("Error", e);
          });
        }
      });
    },

    importSharedAnnotationUsers() {
      const form = new FormData();
      form.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      form.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      form.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      form.append("model", "importSharedAnnotationUsers");
      form.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      form.append("annotationId", this.annotations.ufa_id);
      form.append("documentId", UserData.getDocumentId() ? UserData.getDocumentId() : this.index.getDocumentId);

      axios.post((process.env.NODE_ENV == 'production' ? UserData.getProductionAdminBaseUrl() : UserData.getLocalAdminBaseUrl()) + '/import-annotation-users', form, { headers: authHeader() }).then((response) => {
        if (response.status == 200) {
          this.selected.push(...response.data);
          this.$emit('shared_users', this.selected);
        } else {
          if (response.response.status == 403) {
            this.$emit("session_timeout", true);
          }
        }
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    importSharedAnnotationDetails() {
      const form = new FormData();
      form.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
      form.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
      form.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
      form.append("model", "importSharedAnnotationDetails");
      form.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
      form.append("annotationId", this.annotations.ufa_id);
      form.append("documentId", UserData.getDocumentId() ? UserData.getDocumentId() : this.index.getDocumentId);

      axios.post((process.env.NODE_ENV == 'production' ? UserData.getProductionAdminBaseUrl() : UserData.getLocalAdminBaseUrl()) + '/import-annotation-details', form, { headers: authHeader() }).then((response) => {
        if (response.status == 200) {
          this.selected_topics.push(...response.data);
          this.$emit('shared_topics', this.selected_topics);
        } else {
          if (response.response.status == 403) {
            this.$emit("session_timeout", true);
          }
        }
      })
      .catch((e) => {
        console.log("Error", e);
      });
    },

    getAttendanceStatus(status) {
      var index = 0;
      switch(status) {
        case "1":
          index = 1;
          break;
        case "2":
          index = 2;
          break;
        case "3":
          index = 3;
          break;
      }

      return index;
    },
  },
};
</script>

<style scoped>
.list-striped a {
  text-decoration: none;
  color: #000;
}

.list-striped a:hover {
  color: rgb(72, 61, 139);
}

.list-striped a:active {
  color: rgb(72, 61, 139);
}
</style>