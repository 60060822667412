<template>
  <div>
    <!-- REJECT MODAL -->
    <div class="text-center">
      <v-dialog v-model="dialog_reject" max-width="500px" scrollable>
        <v-row justify="center">
          <v-card
            style="padding: 3%"
          >
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="12" sm="12">
                    <v-textarea
                      type="text"
                      v-model="comments"
                      label="Comments"
                      variant="outlined"
                      :rules="rules.textRules"
                    ></v-textarea>
                  </v-col>

                  <v-col cols="12" md="12" sm="12">
                    <v-text-field
                      :rules="rules.passwordRules"
                      v-model="password"
                      :type="show ? 'text' : 'password'"
                      label="Password (Required)"
                      variant="outlined"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="primary"
                  @click="
                    close();
                    reset();
                  "
                  variant="flat"
                  elevation="1"
                >
                  Close
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="error"
                  @click="
                    updateApproval();
                  "
                  style="float: right"
                  variant="flat"
                  elevation="1"
                  :loading="store.loading"
                >
                  Submit
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-row>
      </v-dialog>
    </div>
  </div>
</template>

<script>
var moment = require("moment");
import axios from "axios";
import UserData from "../repository/UserData";
import { useAuthStore } from "../../stores/auth";
import { useIndexStore } from "../../stores/index";
import { useAlertStore } from "../../stores/alert";
import GlobalMethods from "../repository/GlobalMethods";

export default {
  name: "RejectApproval",

  data: () => ({
    valid: false,
    dialog: false,
    interestDisclosed: "",
    interestConflict: "",
    hasConflict: false,
    confirm: false,
    comments: "",
    conditions: "",
    password: "",
    rules: {
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
      selectRules: [(v) => !!v || "Select is required"],
      textRules: [
        v => !!v || 'Field is required',
        v => (v && v.length >= 3) || 'Min 3 characters',
      ],
      characterRules: [
        v => (v && v.length >= 3) || 'Min 3 characters',
      ],
    },
    token: localStorage.getItem("token") ? JSON.stringify(localStorage.getItem("token")).slice(1, -1) : "",
    auth: useAuthStore(),
    store: useIndexStore(),
    alert: useAlertStore(),
    globalMethods: GlobalMethods,
  }),

  computed: {
    user() {
      return this.auth.getLoggedInUser;
    },
    
    dialog_reject: {
      get: function () {
        return this.store.getDialogReject;
      },
      set: function (value) {
        this.store.setDialogReject(value);
      }
    },

    current_approval: {
      get: function () {
        return this.store.getCurrentApproval;
      },
      set: function (value) {
        this.store.setCurrentApproval(value);
      }
    },
  },
  
  methods: {
    formatDate(date) {
      return moment.unix(date).format("DD MMM YYYY");
    },

    validate() {
      return this.$refs.form.validate();
    },

    resetForm() {
      return this.$refs.form.reset();
    },

    resetValidation() {
      return this.$refs.form.resetValidation();
    },

    close() {
      this.store.setDialogReject(false);
    },

    reset() {
      this.comments = "";
      this.conditions = "";
      this.password = "";
      this.interestDisclosed = "";
      this.interestConflict = "";
      this.confirm = false;
      this.hasConflict = false;
      document.querySelector('html').classList.remove('v-overlay-scroll-blocked');
    },

    updateApproval() {
      this.validate().then(() => {
        if (this.valid) {
          this.store.setLoading(true);
          this.globalMethods.renewTokenLease(this.password).then((response) => {
            if (response.code == 200) {
              // update approval
              this.actionType = 3;

              const formData = new FormData();
              formData.append("userId", UserData.getUserId() ? UserData.getUserId() : this.user.userInfo.userId);
              formData.append("companyCode", UserData.getCompanyCode() ? UserData.getCompanyCode() : this.user.companyDetail.companyCode);
              formData.append("accessToken", UserData.getAccessToken() ? UserData.getAccessToken() : this.token);
              formData.append("model", "updateApproval");
              formData.append("companyId", UserData.getCompanyId() ? UserData.getCompanyId() : this.user.companyDetail.companyId);
              formData.append("actionType", this.actionType == 4 ? this.radio : this.actionType);
              formData.append("approvalId", UserData.getApprovalId() ? UserData.getApprovalId() : this.current_approval.approvalId);
              formData.append("comments", this.comments);
              formData.append("conditions", this.conditions);
              formData.append("password", this.password);
              formData.append("categoryId", this.current_approval.categoryId);

              this.store.setLoading(true);
              axios.post(UserData.getBaseUrl(), formData).then((response) => {
                this.$emit('approval_updated');
                this.alert.showSuccess(response.data.message);
                this.store.setLoading(false);
                document.querySelector('html').classList.remove('v-overlay-scroll-blocked');
                this.close();
                this.reset();
              })
              .catch((e) => {
                console.log("Error", e);
                this.store.setLoading(false);
              });
            } else {
              this.store.setLoading(false);
              this.alert.showError(response.response.data.message);
            }
          })
          .catch((e) => {
            this.store.setLoading(false);
            console.log("Error", e);
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.list-striped a {
  text-decoration: none;
  color: #000;
}

.list-striped a:hover {
  color: rgb(72, 61, 139);
}

.list-striped a:active {
  color: rgb(72, 61, 139);
}
</style>