import { defineStore } from 'pinia';

export const useAlertStore = defineStore('alert', {
  persist: true,
  state: () => {
    return {
      color: '',
      message: '',
      title: '',
      button: '',
      cancel: '',
      show: false,
      confirm: false,
      checked: null,
      session: false,
    }
  },
  getters: {
    getColor() {
      return this.color
    },
    getMessage() {
      return this.message
    }, 
    getTitle() {
      return this.title
    }, 
    getButton() {
      return this.button
    }, 
    getCancel() {
      return this.cancel
    },
    getShow() {
      return this.show
    },
    getConfirm() {
      return this.confirm
    },
    getChecked() {
      return this.checked
    },
    getSession() {
      return this.session
    },
  },
  actions: {
    setColor(val) {
      this.color = val
    },
    setMessage(val) {
      this.message = val
    },
    setTitle(val) {
      this.title = val
    }, 
    setButton(val) {
      this.button = val
    }, 
    setCancel(val) {
      this.cancel = val
    }, 
    setShow(val) {
      this.show = val
    },
    setConfirm(val) {
      this.confirm = val
    },
    setChecked(val) {
      this.checked = val
    },
    showSuccess(message) {
      this.color = 'success'
      this.message = message
      this.show = true
    },
    showError(message) {
      this.color = 'error'
      this.message = message
      this.show = true
    },
    showInfo(message) {
      this.color = 'info'
      this.message = message
      this.show = true
    },
    showConfirm(title, message, button, cancel) {
      this.color = 'question'
      this.title = title
      this.message = message
      this.button = button
      this.cancel = cancel
      this.confirm = true
    },
    clearAlert() {
      this.color = ''
      this.message = ''
      this.show = false
    },
    clearConfirm() {
      this.color = ''
      this.title = ''
      this.message = ''
      this.button = ''
      this.cancel = ''
      this.confirm = false
    },
    setSession(value) {
      this.session = value
    },
  }
});